/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "CDERestAPI",
            "endpoint": "https://e80cy2t5s9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "communityRestApi",
            "endpoint": "https://xa35yje473.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "contractRestAPI",
            "endpoint": "https://vc5mv22yma.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "messageRestAPI",
            "endpoint": "https://jthxf7375m.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "platformRestApi",
            "endpoint": "https://jyn52y2jsk.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "sourceRestApi",
            "endpoint": "https://pb11xb3vuh.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "workspaceRestAPI",
            "endpoint": "https://k7qq9dkb26.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zvjummn2orgj3mreyxdo7rvete.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yljfrpsdpnaepo6qgsaok5rue4",
    "aws_cognito_identity_pool_id": "us-east-1:f15628c7-029d-4319-ab61-b21ebbf38255",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JnFMPZq1n",
    "aws_user_pools_web_client_id": "6l2475l36grktn8smml8bi14de",
    "oauth": {
        "domain": "cosmo-prod-prod-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://cosmoplatform.com/",
        "redirectSignOut": "https://cosmoplatform.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cosmo-contract-resources150636-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
