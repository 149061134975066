import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react"
import React from "react"
import { Button, IconButton } from "theme/components"

const Layout = ({ isOpen, closeModal, children, onDeleteClick }) => (
  <Modal isCentered isOpen={isOpen} onClose={closeModal}>
    <ModalOverlay />

    <ModalContent rounded="2xl" height="310px" maxWidth="420px">
      <ModalCloseButton
        as={IconButton}
        iconName="close"
        top="4"
        right="4"
        _hover={{ bg: "transparent" }}
      />

      <Header />

      <ModalBody pt={3} px="30px">
        {children}
      </ModalBody>

      <Footer onDeleteClick={onDeleteClick} />
    </ModalContent>
  </Modal>
)

export default Layout

const Header = () => (
  <ModalHeader pt={9} px="30px" pb={2}>
    <Heading as="h3" fontSize="2xl" lineHeight="2xl">
      Contract settings
    </Heading>
  </ModalHeader>
)

const Footer = ({ onDeleteClick }) => (
  <ModalFooter pb={5} px="30px">
    <Button onClick={onDeleteClick} variant="textDark" fontWeight="medium">
      Delete contract
    </Button>
  </ModalFooter>
)
