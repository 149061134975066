import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  VStack
} from "@chakra-ui/react"
import React from "react"
import TeamOption from "./TeamOption"

const TeamOptionsGroupAccordion = ({
  groupName,
  teamOptions = [],
  onTeamOptionChange,
  isTeamEmojiEditable,
  ...props
}) => (
  <Accordion variant="containerVisible" allowToggle width="full" {...props}>
    <AccordionItem border="none">
      <AccordionButton
        bgColor="transparent"
        height="20px"
        px="0"
        _hover={{ bgColor: "white" }}
      >
        <AccordionIcon />

        <Heading
          as="h5"
          fontSize="md"
          lineHeight="md"
          textTransform="capitalize"
          ml="2"
        >
          {groupName}
        </Heading>
      </AccordionButton>

      <AccordionPanel mt="3" p="0">
        <VStack align="stretch" w="full" spacing="2">
          {teamOptions.map((team, index) => (
            <TeamOption
              key={`${team.id}-${team.name}-${index}`}
              team={team}
              onChange={onTeamOptionChange}
              isEmojiEditable={isTeamEmojiEditable}
            />
          ))}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)

export default TeamOptionsGroupAccordion
