import PlatformApiService from "../services/PlatformApiService"
import { GetWorkspaceInput, GetWorkspaceResponse } from "../services/types"

class PlatformApiRepository {
  constructor(
    private readonly platformApiService: PlatformApiService
  ) {}

  async getWorkspace(input: GetWorkspaceInput): Promise<GetWorkspaceResponse> {
    return this.platformApiService.getWorkspace(input)
  }
}

export default PlatformApiRepository
