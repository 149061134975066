import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Checkbox } from "theme/components"

function ProjectEditPage({
  sourceWorkspaceLabels,
  project = {},
  onUpdate,
  onDisconnect
}) {
  const [selectedLabels, setSelectedLabels] = useState([])
  useEffect(() => {
    setSelectedLabels([...(project.labels || [])])
  }, [JSON.stringify(project.labels)])

  const handleLabelChange = (action, label) => {
    if (action === "add") {
      setSelectedLabels(prevState => [...prevState, label])
    } else if (action === "remove") {
      setSelectedLabels(prevState => prevState.filter(l => l !== label))
    }
  }

  const handleUpdate = () => {
    if (JSON.stringify(selectedLabels) === JSON.stringify(project.labels)) {
      return
    }

    onUpdate && onUpdate(project.id, selectedLabels)
  }

  const handleDisconnect = () => {
    onDisconnect && onDisconnect(project.id)
  }

  return (
    <Layout
      onUpdate={handleUpdate}
      showUpdate={!!onUpdate}
      onDisconnect={handleDisconnect}
    >
      <Grid
        templateRows="min-content min-content"
        width="full"
        height="fit-content"
      >
        <VStack spacing="2" align="stretch">
          {sourceWorkspaceLabels?.map(label => (
            <LabelOption
              key={label}
              label={label}
              isSelected={selectedLabels.includes(label)}
              onChange={handleLabelChange}
            />
          ))}
        </VStack>
      </Grid>
    </Layout>
  )
}

export default ProjectEditPage

const Layout = ({ showUpdate, onUpdate, onDisconnect, children }) => {
  return (
    <Grid templateRows="auto 1fr auto" className="h-screen">
      <Header />

      <Box px="4" pb="8" overflowY="auto">
        {children}
      </Box>

      <Footer
        onUpdate={onUpdate}
        onDisconnect={onDisconnect}
        showUpdate={showUpdate}
      />
    </Grid>
  )
}

function Header() {
  return (
    <Box p="3" height="72px">
      <Heading as="h4" fontSize="2xl" lineHeight="2xl" fontWeight="bold">
        Add Source Tags
      </Heading>

      <Text fontSize="xs" lineHeight="xs" mt="1" color="secondary">
        Click on Emojis to change the design icon
      </Text>
    </Box>
  )
}

function Footer({ onUpdate, onDisconnect, showUpdate }) {
  return (
    <Flex
      height="56px"
      shadow="0px -6px 20px rgba(0, 0, 0, 0.05)"
      align="center"
      px="4"
    >
      <Button
        variant="textDark"
        color="#666666"
        onClick={onDisconnect}
        size="md"
      >
        Disconnect
      </Button>

      <ButtonGroup spacing="8" width="full" justifyContent="end">
        <Button
          as={RouterLink}
          to="?tab=source"
          relative="path"
          variant="textDark"
          color="#666666"
        >
          Cancel
        </Button>

        {showUpdate ? <Button onClick={onUpdate}>Update</Button> : null}
      </ButtonGroup>
    </Flex>
  )
}

function LabelOption({ label, isSelected, onChange }) {
  const handleChange = event => {
    const isSelected = event.target.checked
    onChange(isSelected ? "add" : "remove", label)
  }

  return (
    <Flex align="center">
      <Checkbox isChecked={isSelected} onChange={handleChange} />

      <Flex
        align="center"
        justify="center"
        bgColor="#F3F3F3"
        width="9"
        height="9"
        rounded="full"
        ml="3"
      >
        <Text fontSize="md" lineHeight="md" fontWeight="medium">
          #
        </Text>
      </Flex>

      <Text fontSize="md" lineHeight="md" fontWeight="medium" ml="3">
        {label}
      </Text>
    </Flex>
  )
}
