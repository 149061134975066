import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import Select from "react-select"
import { Icon } from "../Icon"

function ConfigurableSelect({
  options = [],
  defaultValue,
  value = defaultValue,
  placeholder,
  isDisabled,
  onChange,
  ...props
}) {
  const handleChange = option => {
    onChange && onChange(option)
  }

  return (
    <Box
      as={Select}
      defaultValue={defaultValue}
      value={value}
      options={options}
      placeholder={placeholder}
      onChange={handleChange}
      isDisabled={isDisabled}
      components={{
        DropdownIndicator
      }}
      menuPortalTarget={document.body}
      styles={{
        container: baseStyles => ({
          ...baseStyles,
          zIndex: "100"
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: "40px",
          backgroundColor: "white",
          opacity: state.isDisabled ? "0.4" : "1",
          boxShadow: "none",
          border: "1px solid #dddddd", // color: line
          borderRadius: "8px",
          ":hover": {
            borderColor: "#dddddd" // color: line
          }
        }),
        valueContainer: baseStyles => ({
          ...baseStyles,
          paddingLeft: "10px",
          color: "#2b2b2b" // primaryDark
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: "500",
          fontFamily: "Gilory, sans-serif",
          color: "#2b2b2b"
        }),
        input: baseStyles => ({
          ...baseStyles,
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: "500",
          fontFamily: "Gilory, sans-serif",
          color: "#2b2b2b"
        }),
        indicatorSeparator: () => ({
          display: "none"
        }),
        dropdownIndicator: baseStyles => ({
          ...baseStyles,
          backgroundColor: "red"
        }),
        menu: baseStyles => ({
          ...baseStyles,
          marginTop: "0"
        }),
        noOptionsMessage: baseStyles => ({
          ...baseStyles,
          fontSize: "14px",
          fontFamily: "Gilory, sans-serif"
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: "14px",
          backgroundColor:
            state.isSelected || state.isFocused ? "#ebebee" : "white",
          cursor: state.isSelected ? "default" : "pointer",
          color: "#2b2b2b"
        }),
        menuPortal: baseStyles => ({
          ...baseStyles,
          zIndex: 9999 // Adjust this value as needed to ensure the dropdown appears above everything else
        })
      }}
      {...props}
    />
  )
}

export default ConfigurableSelect

function DropdownIndicator(props) {
  return (
    <Flex
      align="center"
      justify="center"
      width="20px"
      height="40px"
      mr="10px"
      cursor="pointer"
      {...props}
    >
      <Icon name="chevron-down" width="12px" />
    </Flex>
  )
}
