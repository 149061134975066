import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const getSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)
    const params: Record<string, string> = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }

    return params
  }, [location.search])

  const queryParams = useMemo(getSearchParams, [getSearchParams])

  const setQueryParams = useCallback(
    (params: Record<string, string>) => {
      const searchParams = new URLSearchParams(location.search)

      for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, value)
      }

      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location.pathname, location.search]
  )

  const removeQueryParams = useCallback(
    (...params: string[]) => {
      const searchParams = new URLSearchParams(location.search)

      for (const param of params) {
        searchParams.delete(param)
      }

      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location.pathname, location.search]
  )

  return {
    queryParams,
    setQueryParams,
    removeQueryParams
  }
}
