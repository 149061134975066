import {
  useCheckIfUserExistsInPlatformConfig,
  useGetPlatformWorkspace
} from "modules/Platform/src/hooks"
import { useSignInWithToken, useSignOut } from "modules/User/src/core/hooks"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const usePlatformWorkspaceConfig = () => {
  const { platformWorkspaceConfigId = "" } = useParams()
  const [platformDomain, setPlatformDomain] = useState("")

  // here is the useGetPlatformWorkspace hook
  const {
    data: {
      platformWorkspaceConfig,
      workspace
    } = {}
  } = useGetPlatformWorkspace(
    {
      platformDomain,
      platformWorkspaceConfigId
    },
    {
      enabled: !!platformDomain && !!platformWorkspaceConfigId
    }
  )

  const { mutateAsync: checkIfUserExistsInPlatformConfigAsync } =
    useCheckIfUserExistsInPlatformConfig()
  const { mutateAsync: signInWithToken } = useSignInWithToken()
  const { mutateAsync: signOutAsync } = useSignOut()

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      const { token, email } = event.data
      if (!email || !token) {
        return
      }

      const platformHostname = new URL(event.origin).hostname

      try {
        await signOutAsync()

        const userExists = await checkIfUserExistsInPlatformConfigAsync({
          platformDomain: platformHostname,
          email
        })

        if (userExists) {
          await signInWithToken({ email, token })
          setPlatformDomain(platformHostname)
        }
      } catch (error) {
        console.error("Error handling auth postMessage:", error)
      }
    }

    // Add the event listener
    window.addEventListener("message", handlePostMessage)

    return () => {
      window.removeEventListener("message", handlePostMessage)
    }
  }, [
    checkIfUserExistsInPlatformConfigAsync,
    signInWithToken,
    signOutAsync
  ])

  return {
    platformWorkspaceConfig,
    workspace
  }
}

export default usePlatformWorkspaceConfig
