import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react"
import TeamOptionsGroupAccordion from "modules/Workspace/src/components/TeamOptionsGroupAccordion"
import { usePreviousPath } from "providers/PreviousPathProvider"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "theme/components"

function WorkspaceTeamEditPage({
  communityWorkspace = {},
  teamOptionsGroups,
  onTeamOptionChange,
  onUpdate,
  onDisconnect,
  showDisconnect
}) {
  const handleUpdate = () => {
    onUpdate && onUpdate(communityWorkspace.id)
  }

  const handleDisconnect = () => {
    onDisconnect && onDisconnect(communityWorkspace.id)
  }

  const navigate = useNavigate()
  const previousPath = usePreviousPath()

  const handleCancel = () => navigate(previousPath || "./")

  return (
    <Layout
      onUpdate={handleUpdate}
      onDisconnect={handleDisconnect}
      onCancel={handleCancel}
      showDisconnect={showDisconnect}
    >
      <Grid>
        <WorkspaceTeamOptions
          teamOptionsGroups={teamOptionsGroups}
          onTeamOptionChange={onTeamOptionChange}
        />
      </Grid>
    </Layout>
  )
}

export default WorkspaceTeamEditPage

const Layout = ({
  children,
  onUpdate,
  onDisconnect,
  onCancel,
  showDisconnect
}) => (
  <Grid templateRows="auto 1fr auto" className="h-screen">
    <Header />

    <Box
      px="4"
      pb="8"
      overflowY="auto"
      bgImage="url(/assets/images/configure.png)"
      bgRepeat="no-repeat"
      bgPosition="calc(100% + 96px) calc(100% + 32px)"
    >
      {children}
    </Box>

    <Footer
      onUpdate={onUpdate}
      onDisconnect={onDisconnect}
      onCancel={onCancel}
      showDisconnect={showDisconnect}
    />
  </Grid>
)

const Header = () => (
  <Box p="3" height="72px">
    <Heading as="h4" fontSize="2xl" lineHeight="2xl" fontWeight="bold">
      Configure Your Teams
    </Heading>

    <Text fontSize="xs" lineHeight="xs" mt="1" color="secondary">
      Click on Emojis to change the design icon
    </Text>
  </Box>
)

const Footer = ({
  onUpdate,
  onDisconnect,
  onCancel,
  showDisconnect = true
}) => (
  <Flex
    height="56px"
    shadow="0px -6px 20px rgba(0, 0, 0, 0.05)"
    align="center"
    px="4"
  >
    {showDisconnect ? (
      <Button
        variant="textDark"
        color="#666666"
        onClick={onDisconnect}
        size="md"
      >
        Disconnect
      </Button>
    ) : null}

    <ButtonGroup spacing="8" width="full" justifyContent="end">
      <Button onClick={onCancel} variant="textDark" color="#666666">
        Cancel
      </Button>

      <Button onClick={onUpdate}>Update</Button>
    </ButtonGroup>
  </Flex>
)

const WorkspaceTeamOptions = ({
  teamOptionsGroups = [],
  onTeamOptionChange
}) => (
  <VStack align="stretch" w="full" maxW="380px" spacing="4">
    {teamOptionsGroups.map(teamGroup =>
      teamGroup.teamOptions.length ? (
        <TeamOptionsGroupAccordion
          key={teamGroup.type}
          groupName={teamGroup.label}
          teamOptions={teamGroup.teamOptions}
          onTeamOptionChange={onTeamOptionChange}
          defaultIndex={0}
          isTeamEmojiEditable
        />
      ) : null
    )}
  </VStack>
)
