import { useMutation } from "react-query"
import { userUseCase } from "../index"
import {
  useForgotPasswordSubmitInputType,
  useForgotPasswordSubmitOptionsType
} from "../types/hooks"

export const useForgotPasswordSubmit = (
  options: useForgotPasswordSubmitOptionsType = {}
) =>
  useMutation(
    (input: useForgotPasswordSubmitInputType) =>
      userUseCase.forgotPasswordSubmit(input),
    options
  )
