import { API } from "aws-amplify"
import { CDEType } from "../types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class CDEAPIService {
  async getUser(CDEType: CDEType) {
    const init = await withCognitoAccessToken({})
    return API.get("CDERestAPI", `/${CDEType}/user`, init)
  }

  async getAllCodespaces(CDEType: CDEType) {
    const init = await withCognitoAccessToken({})
    return API.get("CDERestAPI", `/${CDEType}/codespaces`, init)
  }
}

export default CDEAPIService
