import { useGetCurrentUser } from "modules/User/src/core/hooks"
import { Navigate, Outlet } from "react-router-dom"

export function PrivateRoute({ redirectPath = "/sign-in" }) {
  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser()

  if (!user && !isLoadingUser) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

export function AuthRoute() {
  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser()

  if (user && !isLoadingUser) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export function NavigateToDefaultRoute({ defaultRoute = "/sign-in" }) {
  return <Navigate to={defaultRoute} replace />
}
