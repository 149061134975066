import { VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import debounce from "utils/debounce"
import { SwitchFormControl } from "../ui"
import Layout from "./Layout"

function PlatformContractSettingsModal({
  closeModal,
  isOpen,
  onContractDeleteClick,
  settingsData = {},
  onChange,
  content,
}) {
  const [settings, setSettings] = useState({ ...settingsData })

  const debouncedOnChange = useCallback(
    debounce(onChange, SETTINGS_CHANGE_DEBOUNCE_TIMEOUT),
    [onChange]
  )

  const handleChange = (field, value) => {
    setSettings(prevState => {
      const newSettings = {
        ...prevState,
        [field]: value
      }

      debouncedOnChange(newSettings)

      return newSettings
    })
  }

  return (
    <Layout
      closeModal={closeModal}
      isOpen={isOpen}
      onDeleteClick={onContractDeleteClick}
      content={content}
    >
      <VStack align="stretch" spacing={3} mb={6}>
        <SwitchFormControl
          label={content.emailNotificationsLabel}
          onChange={event => handleChange("isSubscribed", event.target.checked)}
          isChecked={settings.isSubscribed}
        />
      </VStack>
    </Layout>
  )
}

export default PlatformContractSettingsModal

const SETTINGS_CHANGE_DEBOUNCE_TIMEOUT = 500

