import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import contractResourceUseCase from "../core"
import { ContractResourceWithLoading } from "../core/types/contract-resource-model-types"

export const useDeleteContractResource = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "deleteContractResource",
    ({ contractResourceId }) =>
      contractResourceUseCase.deleteContractResource(contractResourceId),
    {
      onMutate: async ({ contractResourceId, contractPk }) => {
        await queryClient.cancelQueries(["contractResources", contractPk])

        queryClient.setQueryData(["contractResources", contractPk], oldData => {
          const oldContractResources = oldData as ContractResourceWithLoading[]

          const newContractResources = oldContractResources?.filter(
            contractResource => contractResource.id !== contractResourceId
          )

          return newContractResources || []
        })
      },
      ...options
    }
  )
}

type Options = Omit<
  UseMutationOptions<
    ContractResourceWithLoading | null,
    Error,
    {
      contractPk: string
      contractResourceId: string
    }
  >,
  "mutationFn"
>
