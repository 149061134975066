import PlatformWorkspaceConfigCRUDRepository from "../repositories/PlatformWorkspaceConfigCRUDRepository"

class PlatformWorkspaceConfigUseCase {
  constructor(
    private readonly platformWorkspaceConfigCRUDRepository: PlatformWorkspaceConfigCRUDRepository
  ) {
  }

  async getById(id: string) {
    return this.platformWorkspaceConfigCRUDRepository.getById(id)
  }
}

export default PlatformWorkspaceConfigUseCase
