import { Box, Flex, Heading, VStack } from "@chakra-ui/react"
import React from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import { Link as RouterLink } from "react-router-dom"
import { IconButton } from "theme/components"
import LayoutVariant from "../layouts/LayoutVariant"

const Layout = ({ layoutVariant, children }) => (
  <LayoutVariant isBgImage variant={layoutVariant}>
    <Header />

    <Box pt="4" px="4" pb="8" overflowY="auto">
      <VStack height="full" align="stretch" spacing={8}>
        {children}
      </VStack>
    </Box>
  </LayoutVariant>
)

export default Layout

function Header() {
  return (
    <Flex width="full" align="center" height="40px" px="4" pb="2">
      <IconButton
        icon={<IoArrowBackOutline />}
        aria-label="arrow-back"
        as={RouterLink}
        to="./"
        relative="path"
      />

      <Heading
        as="h3"
        fontSize="xl"
        lineheight="xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        ml="3"
        mb="-1"
      >
        Workspace settings
      </Heading>
    </Flex>
  )
}
