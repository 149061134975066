import React, { useState } from "react"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { IoSend } from "react-icons/io5"

export function MessageInput({ onSubmit, placeholder }) {
  const [inputValue, setInputValue] = useState("")

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    const value = inputValue.trim()

    if (value) {
      onSubmit && onSubmit(value)
      setInputValue("")
    }
  }

  return (
    <InputGroup>
      <InputRightElement
        display={inputValue.trim() ? "inline-flex" : "none"}
        children={<IoSend />}
        cursor="pointer"
        onClick={handleSubmit}
      />

      <Input
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        variant="unstyled"
        placeholder={placeholder || "Write a message"}
        width="full"
        height="40px"
        pl={{ base: "3", md: "4" }}
        pr="10"
        borderRadius="12px"
        bgColor="lightBlue"
        border="none"
        fontSize="sm"
        lineHeight="sm"
        fontWeight="medium"
        color="primaryDark"
        _placeholder={{ color: "#7f7f81" }}
        onKeyDown={handleKeyDown}
      />
    </InputGroup>
  )
}
