import { useCallback, useEffect } from "react"
import { useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { IContract, IContractNormalized } from "../types"
import { normalizeContract } from "../utils"

const useWorkspaceContractSubscriptions = (workspaceId: string) => {
  const queryClient = useQueryClient()

  const handleCreateContractSubscription = useCallback(
    (contract: IContract) => {
      if (contract.workspaceId !== workspaceId) {
        return
      }

      const isContractExist = queryClient
        .getQueryData<IContractNormalized[]>([
          "workspaceContracts",
          workspaceId
        ])
        ?.some(c => c.pk === contract.pk)

      if (isContractExist) {
        return
      }

      const contractNormalized = normalizeContract(contract)

      queryClient.setQueryData(
        ["workspaceContracts", workspaceId],
        (oldData: IContractNormalized[] | undefined) => [
          contractNormalized,
          ...(oldData || [])
        ]
      )
    },
    [workspaceId]
  )

  useEffect(() => {
    const createContractSubscription =
      contractUseCase.createContractSubscription(
        handleCreateContractSubscription
      )

    return () => {
      createContractSubscription()
    }
  }, [handleCreateContractSubscription])

  const handleUpdateContractSubscription = useCallback(
    (contract: IContract) => {
      if (contract.workspaceId !== workspaceId) {
        return
      }

      queryClient.invalidateQueries(["workspaceContracts", workspaceId])
    },
    [workspaceId]
  )

  useEffect(() => {
    const updateContractSubscription =
      contractUseCase.updateContractSubscription(
        handleUpdateContractSubscription
      )

    return () => {
      updateContractSubscription()
    }
  }, [handleUpdateContractSubscription])
}

export default useWorkspaceContractSubscriptions
