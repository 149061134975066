import { Box, Flex, HStack } from "@chakra-ui/react"
import React, { useCallback } from "react"
import {
  ContractDescription,
  ContractMessages,
  ContractName,
  ContractResources, ContractSubtasksSection
} from "../ui"
import Header from "./Header"
import {
  usePlatformWorkspaceContent
} from "../../../../../providers/PlatformWorkspaceProvider"
import { Button, Checkbox, Icon } from "../../../../../theme/components"
import { SendToJiraAction } from "../ContractDetails/SendToJiraAction"

function PlatformContractDetails({
  contract,
  messages = [],
  onDeleteContractClick,
  onContractSettingsClick,
  onDelegateClick,
  onCommentCreate,
  toggleCompleted,
  onUpdate,
  resources,
  onResourcesUpload,
  onBackClick,
  platformConfig = {},
  isLoadingDeleteContract,
  onChildContractCreate,
  onChildContractCompleteToggle,
  childContracts,
  onChildContractClick,
  onChatClick,
  ...props
}) {
  const { contractDetails } = usePlatformWorkspaceContent()

  const handleUpdate = updatedObj => {
    onUpdate && onUpdate(updatedObj)
  }

  const renderHeader = useCallback(
    () => (
      <Header
        contract={contract}
        toggleCompleted={toggleCompleted}
        onDeleteContractClick={onDeleteContractClick}
        onContractSettingsClick={onContractSettingsClick}
        onBackClick={onBackClick}
        platformConfig={platformConfig}
        onDelegateClick={onDelegateClick}
        isLoadingDeleteContract={isLoadingDeleteContract}
      />
    ),
    [JSON.stringify(contract), JSON.stringify(platformConfig), isLoadingDeleteContract]
  )

  return (
    <Layout renderHeader={renderHeader} {...props}>
      <Flex width="full">
        <Box display={{ base: "block", md: "none" }} mr={3} mt={2}>
          <Checkbox
            isChecked={contract.completed}
            onChange={e => toggleCompleted(contract.id)}
            size="lg"
          />
        </Box>

        <ContractName
          defaultValue={contract.name}
          onSubmit={name => handleUpdate({ name })}
        />
      </Flex>

      {!contract.sourceContractPk &&
        <HStack width="full" mt="4" spacing="4">
          <Button
            variant="secondary"
            onClick={onChatClick}
          >
            <Icon name="comment" mr="2" mt="-0.5"/>
            Chat
          </Button>
        </HStack>
      }

      <ContractDescription
        defaultValue={contract.description}
        onSubmit={description => handleUpdate({ description })}
        heading={contractDetails?.descriptionSectionHeading}
        placeholder={contractDetails?.descriptionPlaceholder}
        saveButtonLabel={contractDetails?.descriptionSaveButtonLabel}
        cancelButtonLabel={contractDetails?.descriptionCancelButtonLabel}
      />

      <ContractResources
        contractPk={contract.pk}
        resources={resources}
        onUpload={onResourcesUpload}
        heading={contractDetails?.attachmentsSectionHeading}
        addButtonLabel={contractDetails?.attachmentsAddButtonLabel}
      />

      {!contract?.sourceContractPk && (
        <ContractSubtasksSection
          onCreate={onChildContractCreate}
          onCompleteToggle={onChildContractCompleteToggle}
          contracts={childContracts}
          onContractClick={onChildContractClick}
        />
      )}

      {contract?.sourceContractPk ? (
        <ContractMessages
          messages={messages}
          onCommentCreate={onCommentCreate}
          heading={contractDetails?.commentsSectionHeading}
          placeholder={contractDetails?.commentsInputPlaceholder}
        />
      ) : null}
    </Layout>
  )
}

export default PlatformContractDetails

const Layout = ({ renderHeader, children, ...props }) => (
  <Box
    roundedTop={{ base: "0", md: "lg" }}
    bgColor="white"
    overflowY="scroll"
    height="full"
    {...props}
  >
    {renderHeader && renderHeader()}

    <Flex
      direction="column"
      overflowY="scroll"
      pt={{ base: "3", md: "0" }}
      pb={{ base: "6", md: "8" }}
      pl={{ base: "0", md: "30px" }}
      pr={{ base: 0, md: "60px" }}
    >
      {children}
    </Flex>
  </Box>
)
