import { IContractNormalized } from "modules/Contract/src//core/types"
import { useQuery } from "react-query"
import { workspaceUseCase } from "../index"
import { UseGetWorkspaceContractsOptions } from "../types/hooks"
import {
  ModelContractFilterInput
} from "modules/Contract/src/core/types/graphql"

export const useGetWorkspaceContracts = (
  { workspaceId, filter, isArchivedContracts }: FnProps,
  options: UseGetWorkspaceContractsOptions = {}
) =>
  useQuery<IContractNormalized[], Error>(
    isArchivedContracts ? ["workspaceContracts", workspaceId, "archived"] : ["workspaceContracts", workspaceId],
    () => workspaceUseCase.getWorkspaceContracts(workspaceId, filter),
    options
  )

type FnProps = {
  workspaceId: string
  filter?: ModelContractFilterInput,
  isArchivedContracts?: boolean
}
