import { Flex, Text } from "@chakra-ui/react"
import React from "react"
import { IconButton } from "theme/components"
import { WorkspaceTeamCommunityType } from "../../core/types/workspace-team"
import TeamEmoji from "../TeamEmoji"

function TeamItem({ team, onEdit }) {
  const handleEdit = () => {
    onEdit && onEdit(team)
  }

  return (
    <Flex
      align="center"
      sx={{
        "&:hover": {
          ".team-item__edit-btn": {
            visibility: "visible"
          }
        }
      }}
    >
      <TeamEmoji emoji={team.emoji} bgColor={team.emojiBgColor} />

      <Text fontSize="sm" lineHeight="sm" fontWeight="medium" ml="2">
        {team.communityType === WorkspaceTeamCommunityType.EMAIL
          ? team.teamId
          : team.name}
      </Text>

      <IconButton
        className="team-item__edit-btn"
        iconName="edit"
        ml="3"
        visibility={{ base: "visible", md: "hidden" }}
        onClick={handleEdit}
      />
    </Flex>
  )
}

export default TeamItem
