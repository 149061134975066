import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack
} from "@chakra-ui/react"
import React from "react"
import {
  Button,
  FormControl,
  IconButton,
  Input,
  Textarea
} from "theme/components"
import { useContractCreateForm } from "../../core/hooks"

function ContractCreateModal({ onSubmit, closeModal, isOpen }) {
  const handleSubmit = (name, description) => {
    onSubmit && onSubmit(name, description)
    closeModal()
  }

  const {
    handleNameChange,
    handleDescriptionChange,
    handleContractCreate,
    isDisabledCreateButton,
    name,
    description
  } = useContractCreateForm(handleSubmit)

  return (
    <Layout closeModal={closeModal} isOpen={isOpen}>
      <VStack align="start">
        <Box as="form" w="full" onSubmit={handleContractCreate}>
          <FormControl label="Contract Name" maxW="full" width="full">
            <Input name="name" value={name} onChange={handleNameChange} />
          </FormControl>

          <FormControl label="Description" maxW="full" width="full" mt="6">
            <Textarea
              name="description"
              value={description}
              onChange={handleDescriptionChange}
              resize="none"
              height="64px"
            />
          </FormControl>

          <Button
            variant="primaryPurple"
            maxW="full"
            width="full"
            mt="8"
            type="submit"
            disabled={isDisabledCreateButton}
          >
            Create Contract
          </Button>
        </Box>
      </VStack>
    </Layout>
  )
}

export default ContractCreateModal

function Layout({ isOpen, closeModal, children }) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={closeModal}
      variant="contractCreate"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton
          as={IconButton}
          iconName="close"
          top="4"
          right="4"
          _hover={{ bg: "transparent" }}
        />

        <Header />

        <ModalBody minH="20">{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

function Header() {
  return (
    <ModalHeader>
      <Heading as="h3" fontSize="xl" lineHeight="xl" align="center">
        What’s the name of your contract?
      </Heading>
    </ModalHeader>
  )
}
