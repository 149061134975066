import { useQueryParams } from "hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { useMemo, useState } from "react"
import { ConnectedCommunityWorkspace } from "../../types/hooks"
import useDiscordSettings from "./discord"
import useEmailSettings from "./email"
import useSlackSettings from "./slack"

export const useCommunitySettings = ({ workspace, user }: Props) => {
  const discord = useDiscordSettings({
    workspace,
    user
  })

  const slack = useSlackSettings({
    workspace,
    user
  })

  const email = useEmailSettings({
    workspace,
    user
  })

  // source settings drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const toggleDrawer = () => setIsOpenDrawer(prevState => !prevState)
  const openDrawer = () => setIsOpenDrawer(true)
  const closeDrawer = () => setIsOpenDrawer(false)
  const onDrawerClose = () => {
    setIsOpenDrawer(false)
  }
  // source settings drawer

  // connected workspaces
  const connectedWorkspaces = useMemo(
    () => [...discord.connectedGuilds, ...slack.connectedSlackWorkspaces],
    [discord.connectedGuilds.length, slack.connectedSlackWorkspaces.length]
  )

  const { setQueryParams } = useQueryParams()
  const handleWorkspaceEdit = (workspace: ConnectedCommunityWorkspace) => {
    setQueryParams({
      tab: "workspace-team-edit",
      communityType: workspace.communityType,
      workspaceId: workspace.id
    })
  }

  return {
    community: {
      connectedWorkspaces,
      handleWorkspaceEdit
    },
    discord,
    slack,
    email,
    isOpenDrawer,
    onDrawerClose,
    toggleDrawer,
    openDrawer,
    closeDrawer
  }
}

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
