import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { communityUseCase } from "../index"
import {
  CommunityTeam,
  CommunityType,
  GetWorkspaceTeamsOptions
} from "../types"

const useGetCommunityWorkspaceTeams = (input: FnInput, options: Options = {}) =>
  useQuery<CommunityTeam[], Error>(
    ["communityWorkspaceTeams", JSON.stringify(input.options)],
    () =>
      communityUseCase.getWorkspaceTeams(input.communityType, input.options),
    options
  )

export default useGetCommunityWorkspaceTeams

interface FnInput {
  communityType: CommunityType
  options: GetWorkspaceTeamsOptions
}

type Options = Omit<
  UseQueryOptions<CommunityTeam[], Error, CommunityTeam[], QueryKey>,
  "queryKey" | "queryFn"
>
