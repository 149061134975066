import { IUserNormalized } from "modules/User/src//core/types"
import { useMemo } from "react"
import { useGetCommunityAuthUrl } from "./useGetCommunityAuthUrl"

const useDiscordConnect = (
  workspaceId: string,
  user: IUserNormalized | null
) => {
  const { data: discordAuthUrl } = useGetCommunityAuthUrl(
    {
      communityType: "discord",
      options: {
        workspaceId: workspaceId as string,
        userId: user?.id || ""
      }
    },
    {
      enabled: !!workspaceId && !!user?.id
    }
  )
  const isDiscordConnected = useMemo(() => !!user?.discordId, [user?.discordId])

  const handleDiscordConnect = () => {
    window.location.href = discordAuthUrl || ""
  }

  return {
    isDiscordConnected,
    handleDiscordConnect
  }
}

export { useDiscordConnect }
