import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Image,
  Text
} from "@chakra-ui/react"
import React from "react"

export function SettingsAccordion({
  name,
  iconName,
  isDisabled = false,
  onChange,
  children,
  ...props
}) {
  return (
    <Accordion
      variant="containerVisible"
      allowToggle
      width="full"
      onChange={onChange}
      {...props}
    >
      <AccordionItem border="none" isDisabled={isDisabled}>
        <AccordionButton bgColor="#f0f0f0" rounded="10px" height="48px">
          <Flex flex="1" textAlign="left">
            <Image
              src={`/assets/icons/${iconName}.svg`}
              alt={iconName}
              maxW="20px"
              maxH="20px"
            />

            <Text
              fontWeight="medium"
              fontSize="md"
              lineHeight="md"
              ml="4"
              mb="-1"
            >
              {name}
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel mt="18px" px="0" pt="0" pb="3">
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
