import { IApiResponse } from "global-types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalizedWithLoading } from "../types"

const useSoftDeleteContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "softDeleteContract",
    ({ contractId }) => contractUseCase.softDeleteContract(contractId),
    {
      onSuccess: (data, variables) => {
        const { contractId, workspaceId } = variables

        const queryKey = workspaceId
          ? ["workspaceContracts", workspaceId]
          : "homepageContracts"

        queryClient.setQueryData<IContractNormalizedWithLoading[]>(
          queryKey,
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]
            const contractIndex = contracts.findIndex(
              contract => contract.id === contractId
            )

            if (contractIndex > -1) {
              contracts.splice(contractIndex, 1)
            }

            return contracts
          }
        )
      },
      ...options
    }
  )
}

export default useSoftDeleteContract

type Options = Omit<
  UseMutationOptions<
    IApiResponse,
    Error,
    {
      contractId: string
      workspaceId?: string
    }
  >,
  "mutationFn"
>
