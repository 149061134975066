import { UseQueryOptions, useQuery } from "react-query"
import { sourceUseCase } from "../index"
import { SourceType } from "../types"

export const useGetSourceWorkspaceLabels = (
  input: UseGetSourceWorkspaceLabelsInput,
  options: Options
) =>
  useQuery<string[], Error>(
    ["sourceWorkspaceLabels", input.jiraWorkspaceId],
    () => sourceUseCase.getLabels(input.type, input.jiraWorkspaceId),
    options
  )

type UseGetSourceWorkspaceLabelsInput = {
  type: SourceType
  jiraWorkspaceId: string
}

type Options = Omit<UseQueryOptions<string[], Error>, "queryKey">
