import {
  ContractCreateInputNormalized,
  IContractCreateInput,
  contractUpdateInputNormalizedType,
  contractUpdateInputType
} from "modules/Contract/src//core/types"

export function denormalizeContract(
  contract: ContractCreateInputNormalized
): IContractCreateInput
export function denormalizeContract(
  contract: contractUpdateInputNormalizedType
): contractUpdateInputType
export function denormalizeContract(
  contract: ContractCreateInputNormalized | contractUpdateInputNormalizedType
): IContractCreateInput | contractUpdateInputType {
  const denormalizedContract = { ...contract } as
    | IContractCreateInput
    | contractUpdateInputType
  if (contract.skIds) {
    denormalizedContract.skIds = JSON.stringify(contract.skIds)
  }
  return denormalizedContract
}
