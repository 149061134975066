import { useGetContractCommunityMessages } from "modules/Message/src/core/hooks"
import { useMemo } from "react"
import { IContractNormalized } from "../types"
import { formatMessageTimestamp } from "../utils"

const useContractCommunityMessages = (contract: IContractNormalized | null) => {
  const { data: contractCommunityMessages = [] } =
    useGetContractCommunityMessages(contract?.pk as string, {
      enabled: !!contract?.pk,
      keepPreviousData: false
    })

  const contractCommunityMessagesNormalized = useMemo(() => {
    return contractCommunityMessages.map(message => {
      const teamEmojiBgColor = contract?.teams?.find(
        team => team.id === message.workspaceTeamId
      )?.emojiBgColor

      return {
        ...message,
        timestamp: formatMessageTimestamp(message.createdAt),
        teamEmojiBgColor
      }
    })
  }, [contractCommunityMessages.length, contract?.teams?.length])

  return {
    contractCommunityMessages: contractCommunityMessagesNormalized
  }
}

export default useContractCommunityMessages
