import { useCDESettings } from "modules/CDE/src/hooks"
import { IUserNormalized } from "modules/User/src//core/types"
import { Workspace } from "../../types"
import useSettingsTabRouter from "./useSettingsTabRouter"

const useCDE = ({ workspace, user }: Props) => {
  const { github, isOpenDrawer, onDrawerClose, openDrawer, closeDrawer } =
    useCDESettings({
      workspace: workspace,
      user
    })

  const { handleSettingsClick, handleClose } = useSettingsTabRouter({
    closeDrawer,
    isOpenDrawer,
    onDrawerClose,
    openDrawer,
    settingsTabName: "cde"
  })

  return {
    handleCDESettingsClick: handleSettingsClick,
    CDESettingsProps: {
      isOpen: isOpenDrawer,
      onClose: handleClose,
      github
    }
  }
}

export default useCDE

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
