import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized } from "../types"

export const useGetMappedContract = (
  contractId: string,
  options: Options = {}
) =>
  useQuery<IContractNormalized | null, Error>(
    ["mappedContract", contractId],
    () => contractUseCase.getMappedContract(contractId),
    options
  )

export default useGetMappedContract

type Options = Omit<
  UseQueryOptions<
    IContractNormalized | null,
    Error,
    IContractNormalized | null,
    QueryKey
  >,
  "queryKey" | "queryFn"
>
