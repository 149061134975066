import { useMediaQuery } from "@chakra-ui/react"
import React, { ReactNode, createContext, useContext, useMemo } from "react"
import theme from "../theme"

interface BreakpointContextType {
  isMobile: boolean
}

const BreakpointContext = createContext<BreakpointContextType | undefined>(
  undefined
)

function BreakpointProvider({
  children
}: {
  children: ReactNode | ReactNode[]
}) {
  const [isBase] = useMediaQuery("(min-width: 0px)")
  const [isMd] = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)

  const isMobile = useMemo(() => isBase && !isMd, [isBase, isMd])

  return (
    <BreakpointContext.Provider value={{ isMobile }}>
      {children}
    </BreakpointContext.Provider>
  )
}

export default BreakpointProvider

export const useIsMobile = () => {
  const context = useContext(BreakpointContext)
  if (!context) {
    throw new Error("useIsMobile must be used within a BreakpointProvider")
  }
  return context.isMobile
}
