import React from "react"
import ProjectEditPage from "./ProjectEditPage"
import SettingsOptionsPage from "./SettingsOptionsPage"

function SourceSettingsPage({ source, jira, discord, slack, layoutVariant }) {
  if (!source.projectEditProps) {
    return (
      <SettingsOptionsPage
        source={source}
        jira={jira}
        discord={discord}
        slack={slack}
        layoutVariant={layoutVariant}
      />
    )
  }

  return <ProjectEditPage {...source.projectEditProps} />
}

export default SourceSettingsPage
