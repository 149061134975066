import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { CDEUseCase } from "../core"
import { CDEType, ICDENormalized } from "../core/types"

export const useGetCDEAllCodespaces = (
  CDEType: CDEType,
  options: Options = {}
) =>
  useQuery<ICDENormalized[], Error>(
    ["CDEAllCodespaces", CDEType],
    () => CDEUseCase.getAllCodespaces(CDEType),
    options
  )

export type Options = Omit<
  UseQueryOptions<ICDENormalized[], Error, ICDENormalized[], QueryKey>,
  "queryKey" | "queryFn"
>
