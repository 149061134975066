import React from "react"
import { ContractsSidebarLight } from "../../../modules/Contract/src/components"
import EmptyWorkspace from "../EmptyWorkspace"
import Layout from "./Layout"
import LoadingWorkspace from "../LoadingWorkspace"

function ContractsListPage({
  contractSidebarProps,
  onContractCreateClick,
  platformConfig
}) {
  if (contractSidebarProps.isLoading) {
    return <LoadingWorkspace />
  }

  if (!contractSidebarProps.contracts.length) {
    return <EmptyWorkspace onContractCreateClick={onContractCreateClick} />
  }

  return (
    <Layout
      onContractCreateClick={onContractCreateClick}
      platformConfig={platformConfig}
      isWithNavbar
    >
      <ContractsSidebarLight {...contractSidebarProps} pb="3" />
    </Layout>
  )
}

export default ContractsListPage
