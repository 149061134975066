import { Flex } from "@chakra-ui/react"
import React from "react"
import TeamEmoji from "./TeamEmoji"

function TeamEmojiGroup({ teamEmojis = [], emojiOutlineColor, ...props }) {
  return (
    <Flex
      position="relative"
      zIndex="1"
      {...props}
      sx={{
        "& > *:not(:first-of-type)": {
          ml: "-3px"
        },
        ">*": {
          boxShadow: emojiOutlineColor
            ? `0 0 0 1.5px ${emojiOutlineColor}`
            : "none"
        }
      }}
    >
      {teamEmojis.map((teamEmoji, index) => (
        <TeamEmoji
          key={index}
          zIndex={teamEmojis.length - index}
          emoji={teamEmoji.emoji}
          bgColor={teamEmoji.emojiBgColor}
        />
      ))}
    </Flex>
  )
}

export default TeamEmojiGroup
