import { API } from "aws-amplify"
import {
  WorkspaceTeamCreateInput,
  WorkspaceTeamUpdateInput
} from "../types/workspace-team"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class WorkspaceTeamApiService {
  async createWorkspaceTeams(createInput: WorkspaceTeamCreateInput[]) {
    const init = await withCognitoAccessToken({
      body: createInput
    })
    return API.post("workspaceRestAPI", `/workspace-team/`, init)
  }

  async updateWorkspaceTeams(updateInput: WorkspaceTeamUpdateInput[]) {
    const init = await withCognitoAccessToken({
      body: updateInput
    })

    return API.put("workspaceRestAPI", `/workspace-team/`, init)
  }

  async deleteWorkspaceTeams(teamIds: string[]) {
    const init = await withCognitoAccessToken({
      body: teamIds
    })

    return API.del("workspaceRestAPI", `/workspace-team/`, init)
  }
}

export default WorkspaceTeamApiService
