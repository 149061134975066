import { Flex, Grid, Input } from "@chakra-ui/react"
import React, { useRef } from "react"
import { Button } from "theme/components"
import { ContractResourceCard, ContractSectionLayout } from "./index"

function ContractResources({
  resources = [],
  onUpload,
  contractPk,
  heading,
  addButtonLabel
}) {
  return (
    <ContractSectionLayout
      heading={heading || "Attachments"}
      iconName="attachment"
    >
      {resources?.length > 0 ? (
        <ResourcesList resources={resources} contractPk={contractPk} />
      ) : null}

      <ResourcesUploadButton
        onUpload={onUpload}
        buttonLabel={addButtonLabel}
      />
    </ContractSectionLayout>
  )
}

export default ContractResources

function ResourcesUploadButton({ onUpload, buttonLabel }) {
  const fileInputRef = useRef(null)
  const maxFileSize = 3 * 1024 * 1024 // 3MB in bytes

  const handleUpload = event => {
    const fileList = Array.from(event.target.files)

    const areFilesValid = fileList.every(file => file.size <= maxFileSize && ALLOWED_FILE_TYPES.includes(file.type))
    if (!areFilesValid) {
      alert("One or more files exceed the maximum size of 3MB or are not of the allowed file types")
      return
    }

    onUpload && onUpload(fileList)

    setTimeout(() => {
      fileInputRef.current.value = null
    }, 0)
  }

  return (
    <Flex width="full">
      <Button
        variant="secondary"
        width="fit-content"
        bgColor="bgGray"
        px="4"
        onClick={() => {
          fileInputRef.current.click()
        }}
      >
        {buttonLabel || "Add Resources"}
      </Button>

      <Input
        ref={fileInputRef}
        type="file"
        onChange={handleUpload}
        multiple
        display="none"
        accept={ALLOWED_FILE_TYPES.join(",")}
      />
    </Flex>
  )
}

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "text/plain",
  "application/zip",

  // images
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",

  // excel
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv"
]

const ResourcesList = ({ resources, contractPk }) => (
  <Grid templateColumns="repeat(auto-fill, 96px)" gap="3" mb="5">
    {resources.map(resource => (
      <ContractResourceCard
        key={resource.id}
        resource={resource}
        contractPk={contractPk}
        isDeletable
      />
    ))}
  </Grid>
)
