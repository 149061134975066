import { useQuery } from "react-query"
import { userUseCase } from "../index"
import { IUserNormalized } from "../types"
import { useGetCurrentUserOptionsType } from "../types/hooks"

export const useGetCurrentUser = (options: useGetCurrentUserOptionsType = {}) =>
  useQuery<IUserNormalized | null, Error>(
    "currentUser",
    () => userUseCase.getCurrentUser(),
    options
  )
