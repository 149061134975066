import accordionTheme from "./components/Accordion/theme"
import badgeTheme from "./components/ui/Badge/theme"
import buttonTheme from "./components/ui/Button/theme"
import checkboxTheme from "./components/ui/Checkbox/theme"
import drawerTheme from "./components/ui/Drawer/theme"
import inputTheme from "./components/ui/Input/theme"
import menuTheme from "./components/ui/Menu/theme"
import modalTheme from "./components/ui/Modal/theme"
import popoverTheme from "./components/ui/Popover/theme"
import selectTheme from "./components/ui/Select/theme"
import switchTheme from "./components/ui/Switch/theme"
import tabsTheme from "./components/ui/Tabs/theme"
import textareaTheme from "./components/ui/Textarea/theme"

const componentsTheme = {
  components: {
    ...buttonTheme,
    ...selectTheme,
    ...inputTheme,
    ...switchTheme,
    ...textareaTheme,
    ...tabsTheme,
    ...modalTheme,
    ...badgeTheme,
    ...popoverTheme,
    ...drawerTheme,
    ...accordionTheme,
    ...checkboxTheme,
    ...menuTheme
  }
}
export default componentsTheme
