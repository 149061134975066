import { Box, VStack } from "@chakra-ui/react"
import React from "react"
import ContractCard from "./ContractCard"

const ContractsSidebarLight = ({
  contracts = [],
  activeContractPk,
  onContractClick,
  toggleContractCompleted,
  ...props
}) => (
  <Layout {...props}>
    {contracts.map(contract => (
      <ContractCard
        key={contract.id}
        contract={contract}
        onClick={onContractClick}
        toggleCompleted={toggleContractCompleted}
        isActive={contract.pk === activeContractPk}
      />
    ))}
  </Layout>
)

export default ContractsSidebarLight

const Layout = ({ children, ...props }) => (
  <Box width="100%" height="100%" overflowY="scroll" pb="8" {...props}>
    <VStack spacing="3" minH="full" data-id="test" align="stretch">
      {children}
    </VStack>
  </Box>
)
