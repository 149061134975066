import {
  CDEResponseType,
  CDEType,
  CDEUserType,
  ICDENormalized,
  ICDEUserNormalized
} from "./types"

export const normalizeCDEUser = (
  CDEType: CDEType,
  CDEUser: CDEUserType
): ICDEUserNormalized => {
  switch (CDEType) {
    case "github":
      return {
        id: CDEUser.id,
        username: CDEUser.login
      }
  }
}

export const normalizeCDE = (
  CDEType: CDEType,
  CDEResponse: CDEResponseType
): ICDENormalized => {
  switch (CDEType) {
    case "github":
      return {
        id: "" + CDEResponse.id,
        name: CDEResponse.name,
        url: CDEResponse.web_url,
        ownerName: CDEResponse.billable_owner.login,
        repoName: CDEResponse.repository.name
      }
  }
}
