import {
  CommunityType,
  CommunityWorkspace,
  CommunityWorkspaceNormalized,
  ICommunityMessageNormalized,
  communityMemberNormalizedType,
  communityMemberType,
  communityMessageType
} from "./types"

export const normalizeCommunityWorkspaces = (
  type: CommunityType,
  workspaces: CommunityWorkspace[]
): CommunityWorkspaceNormalized[] => {
  switch (type) {
    case "discord":
      return workspaces.map(workspace => ({
        id: workspace.id,
        name: workspace.name
      }))
    case "slack":
      return workspaces.map(workspace => ({
        id: workspace.id,
        name: workspace.name
      }))
  }

  return []
}

export const normalizeCommunityWorkspaceMembers = (
  type: CommunityType,
  communityMembers: communityMemberType[]
): communityMemberNormalizedType[] => {
  switch (type) {
    case "discord":
      return communityMembers
        .filter(member => member.user.bot !== true)
        .map(member => ({
          id: member.user.id,
          username: member.user.username
        }))
  }

  return []
}

export const normalizeCommunityMessage = (
  communityType: CommunityType,
  message: communityMessageType
): ICommunityMessageNormalized | null => {
  switch (communityType) {
    case "discord":
      return {
        id: message.id,
        content: message.content,
        authorId: message.author.id,
        authorName: message.author.username,
        createdAt: message.timestamp,
        updatedAt: message.edited_timestamp
      }
  }

  return null
}
