import ContractCreatePage from "modules/Contract/src/components/ContractCreatePage"
import WorkspaceCreatePage from "modules/Workspace/src/components/WorkspaceCreatePage"
import { useHomepage } from "modules/Workspace/src/core/hooks"
import React from "react"
import HomepageLayout from "./Layout"
import ResourcesPanel from "./ResourcesPanel"

function Homepage() {
  const {
    handleTabChange,
    workspacesTabProps,
    contractsTabProps,
    activeTab,
    handleContractCreate,
    handleWorkspaceCreate,
    isMobile
  } = useHomepage()

  if (isMobile && activeTab === "contract-create") {
    return (
      <HomepageLayout>
        <ContractCreatePage
          onCreate={handleContractCreate}
          isLoadingCreate={contractsTabProps.isLoadingContractCreate}
        />
      </HomepageLayout>
    )
  }

  if (isMobile && activeTab === "workspace-create") {
    return (
      <HomepageLayout>
        <WorkspaceCreatePage
          onCreate={handleWorkspaceCreate}
          isLoadingCreate={workspacesTabProps.isLoadingWorkspaceCreate}
        />
      </HomepageLayout>
    )
  }

  return (
    <HomepageLayout isMainPage>
      <ResourcesPanel
        onTabChange={handleTabChange}
        workspacesTabProps={workspacesTabProps}
        contractsTabProps={contractsTabProps}
        activeTab={activeTab}
      />
    </HomepageLayout>
  )
}

export default Homepage
