import { useGetCurrentUser } from "modules/User/src/core/hooks"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  useGetByPk,
  useGetShareLinkContract,
  usePickContract
} from "../index"

export const useShareContract = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data: user } = useGetCurrentUser()

  // contract
  const linkId = location.pathname.split("/")[2]
  const { data: contract = null } = useGetShareLinkContract(linkId, {
    enabled: !!linkId
  })

  const { data: userContract = null } = useGetByPk({
    pk: contract?.pk || "",
    filter: {
      ownerId: { eq: user?.id || "" }
    }
  }, {
    enabled: !!contract?.pk
  })

  const { mutateAsync: pickContractAsync } = usePickContract()
  // contract

  const [contractLink, setContractLink] = useState("")

  useEffect(() => {
    if (userContract) {
      setContractLink(`/contract/${userContract?.id}`)
    }
  }, [userContract])

  const handleContractPick = async () => {
    if (!user) {
      navigate("/sign-in")
    }

    if (!contract) {
      return
    }
    const userContract = await pickContractAsync({
      contractId: contract.id,
      workspaceId: contract.workspaceId!
    })

    if (userContract) {
      setContractLink(`/contract/${userContract?.id}`)
    }
  }

  return {
    contract,
    handleContractPick,
    contractLink,
    contractActionsMessages: []
  }
}
