import {
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"

const Layout = ({
  isOpen, closeModal, children, onDelegate, isLoadingDelegate
}) => (
  <Modal isOpen={isOpen} onClose={closeModal} variant="delegate" size="md">
    <ModalOverlay />

    <ModalContent>
      <Header />

      <ModalBody pt={3}>{children}</ModalBody>

      <Footer
        closeModal={closeModal}
        onDelegate={onDelegate}
        isLoadingDelegate={isLoadingDelegate}
      />
    </ModalContent>
  </Modal>
)

export default Layout

const Header = () => (
  <ModalHeader>
    <Heading as="h4" fontSize="2xl" lineHeight="2xl" fontWeight="bold">
      Delegate
    </Heading>
  </ModalHeader>
)

const Footer = ({ closeModal, onDelegate, isLoadingDelegate }) => (
  <ModalFooter>
    <ButtonGroup spacing="3" width="full" justifyContent="end">
      <Button
        variant="textDark"
        color="#666666"
        onClick={closeModal}
        width="80px"
      >
        Cancel
      </Button>

      <Button
        onClick={onDelegate}
        isLoading={isLoadingDelegate}
        width="112px">
        Add
      </Button>
    </ButtonGroup>
  </ModalFooter>
)
