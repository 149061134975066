import WorkspaceTeamApiRepository
  from "../repositories/WorkspaceTeamApiRepository"
import WorkspaceTeamCRUDRepository
  from "../repositories/WorkspaceTeamCRUDRepository"
import { GetWorkspaceTeamsByWorkspaceIdInput } from "../types/graphql"
import { GetWorkspaceTeamsByWorkspaceIdResponse } from "../types"
import {
  WorkspaceTeam,
  WorkspaceTeamCreateInput
} from "../types/workspace-team"

class WorkspaceTeamUseCase {
  constructor(
    private readonly workspaceTeamCRUDRepository: WorkspaceTeamCRUDRepository,
    private readonly workspaceTeamApiRepository: WorkspaceTeamApiRepository
  ) {
  }

  async getAllByWorkspaceId(
    input: GetWorkspaceTeamsByWorkspaceIdInput
  ): Promise<GetWorkspaceTeamsByWorkspaceIdResponse> {
    return this.workspaceTeamCRUDRepository.getAllByWorkspaceId(input)
  }

  async getByIds(ids: string[]): Promise<WorkspaceTeam[]> {
    try {
      if (!ids.length) {
        return []
      }

      return await Promise.all(ids.map((id) =>
        this.workspaceTeamCRUDRepository.getById(id)
      ))
    } catch (error) {
      console.error("get workspace teams by ids : ", error)
      return []
    }
  }

  async createWorkspaceTeams(createInputs: WorkspaceTeamCreateInput[]) {
    return this.workspaceTeamApiRepository.createWorkspaceTeams(createInputs)
  }

  async updateWorkspaceTeams(updateInputs: WorkspaceTeamCreateInput[]) {
    return this.workspaceTeamApiRepository.updateWorkspaceTeams(updateInputs)
  }

  async deleteWorkspaceTeams(teamIds: string[]) {
    return this.workspaceTeamApiRepository.deleteWorkspaceTeams(teamIds)
  }
}

export default WorkspaceTeamUseCase
