import { Box, ButtonGroup, Flex, Grid, Heading, Text } from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"

const WorkspaceDeletePage = ({
  onCancel,
  onConfirm,
  text,
  headerText,
  confirmButtonLabel
}) => (
  <Grid className="h-screen" width="full" templateRows="1fr auto" px="4">
    <Box py="6" height="full">
      <Heading
        as="h3"
        fontSize="2xl"
        lineheight="2xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        mb="-1"
      >
        {headerText}
      </Heading>

      <Text fontSize="md" lineHeight="sm" mt="6">
        {text}
      </Text>
    </Box>

    <Flex height="56px" align="center">
      <ButtonGroup spacing="3" width="full" justifyContent="end">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button variant="danger" onClick={onConfirm}>
          {confirmButtonLabel || "Delete"}
        </Button>
      </ButtonGroup>
    </Flex>
  </Grid>
)

export default WorkspaceDeletePage
