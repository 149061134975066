import { Box, Heading, VStack } from "@chakra-ui/react"
import React from "react"
import { TeamGroup } from "./ui"

const TeamsSidebar = ({ teamsGroups = [], onTeamEdit }) => (
  <Box pb="8" overflowY="scroll">
    <Heading as="h5" fontSize="lg" lineHeight="lg" pb="4">
      Teams
    </Heading>

    <VStack spacing="6" align="stretch">
      {teamsGroups.map(teamGroup => (
        <TeamGroup
          key={teamGroup.type}
          teamGroup={teamGroup}
          onTeamEdit={onTeamEdit}
        />
      ))}
    </VStack>
  </Box>
)

export default TeamsSidebar
