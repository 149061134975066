import { API } from "aws-amplify"
import { IContractNormalized } from "modules/Contract/src/core/types"
import { Workspace } from "modules/Workspace/src//core/types"
import {
  CommunityTeam,
  CommunityType,
  CommunityWorkspace,
  ConnectWorkspaceOptions,
  DisconnectWorkspaceOptions,
  GetWorkspaceTeamsOptions,
  UpdateWorkspaceOptions,
  assignContractToCommunityUserOptionsType,
  communityMemberType,
  communityMessageType,
  createCommunityChannelMessageOptionsType,
  getCommunityAuthUrlOptionsType,
  getCommunityChannelMessagesOptionsType,
  getCommunityWorkspaceMembersOptionsType
} from "../types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class CommunityAPIService {
  async getUserCommunityWorkspacesWithBot(
    communityType: CommunityType
  ): Promise<CommunityWorkspace[]> {
    const init = await withCognitoAccessToken({})
    return API.get(
      "communityRestApi",
      `/${communityType}/workspaces-with-bot`,
      init
    )
  }

  async getCommunityWorkspaceMembers(
    communityType: CommunityType,
    options: getCommunityWorkspaceMembersOptionsType
  ): Promise<communityMemberType[]> {
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        options: JSON.stringify(options)
      }
    })
    return API.get("communityRestApi", `/${communityType}/workspace/members`, init)
  }

  async getWorkspaceTeams(
    communityType: CommunityType,
    options: GetWorkspaceTeamsOptions
  ): Promise<CommunityTeam[]> {
    const init = await withCognitoAccessToken({
      queryStringParameters: options
    })
    return API.get("communityRestApi", `/${communityType}/workspace/teams`, init)
  }

  async connectWorkspace(
    communityType: CommunityType,
    options: ConnectWorkspaceOptions
  ): Promise<Workspace> {
    const init = await withCognitoAccessToken({
      body: { ...options }
    })
    return API.post("communityRestApi", `/${communityType}/workspace/connect`, init)
  }

  async updateWorkspace(
    communityType: CommunityType,
    options: UpdateWorkspaceOptions
  ): Promise<void> {
    const init = await withCognitoAccessToken({
      body: { ...options }
    })
    return API.post("communityRestApi", `/${communityType}/workspace/update`, init)
  }

  async disconnectWorkspace(
    communityType: CommunityType,
    options: DisconnectWorkspaceOptions
  ): Promise<Workspace> {
    const init = await withCognitoAccessToken({
      body: { ...options }
    })
    return API.post(
      "communityRestApi",
      `/${communityType}/workspace/disconnect`,
      init
    )
  }

  async assignContractToCommunityUser(
    communityType: CommunityType,
    options: assignContractToCommunityUserOptionsType
  ): Promise<IContractNormalized> {
    const init = await withCognitoAccessToken({
      body: {
        options
      }
    })
    return API.post("communityRestApi", `/${communityType}/assign`, init)
  }

  async createCommunityChannelMessage(
    communityType: CommunityType,
    options: createCommunityChannelMessageOptionsType
  ): Promise<communityMessageType> {
    const init = await withCognitoAccessToken({
      body: {
        options
      }
    })
    return API.post("communityRestApi", `/${communityType}/channel/message`, init)
  }

  async getCommunityChannelMessages(
    communityType: CommunityType,
    options: getCommunityChannelMessagesOptionsType
  ): Promise<communityMessageType[]> {
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        options: JSON.stringify(options)
      }
    })
    return API.get("communityRestApi", `/${communityType}/channel/messages`, init)
  }

  async getCommunityAuthUrl(
    communityType: CommunityType,
    options: getCommunityAuthUrlOptionsType
  ): Promise<string> {
    const init = await withCognitoAccessToken({
      queryStringParameters: options
    })
    return API.get("communityRestApi", `/${communityType}/auth-url`, init)
  }
}

export default CommunityAPIService
