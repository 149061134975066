import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalizedWithLoading } from "../types"

export const useDeleteContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    ["deleteContract"],
    ({ contractId }: FnInput) => contractUseCase.deleteContract(contractId),
    {
      onSuccess: (_data, variables) => {
        const { contractId, sourceContractPk } = variables

        const queryKey = ["childContracts", sourceContractPk]

        queryClient.setQueryData<IContractNormalizedWithLoading[]>(
          queryKey,
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]
            const contractIndex = contracts.findIndex(
              contract => contract.id === contractId
            )

            if (contractIndex > -1) {
              contracts.splice(contractIndex, 1)
            }

            return contracts
          }
        )
      },
      ...options
    }
  )
}

type FnInput = {
  contractId: string
  sourceContractPk?: string
}

type Options<TData = unknown, TError = unknown, TContext = unknown> = UseMutationOptions<TData, TError, FnInput, TContext>
