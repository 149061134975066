import {
  FormControl as ChakraFormControl,
  FormErrorMessage as ChakraFormErrorText,
  FormHelperText as ChakraFormHelperText,
  FormLabel as ChakraFormLabel
} from "@chakra-ui/react"
import React from "react"

export const FormLabel = ({ label }) => (
  <ChakraFormLabel
    fontSize="xs"
    lineHeight="xs"
    mb={1}
    px="10px"
    color="secondary"
  >
    {label}
  </ChakraFormLabel>
)

export const FormHelperText = ({ children }) => (
  <ChakraFormHelperText
    fontSize="xs"
    lineHeight="xs"
    mt="1"
    px="3"
    color="secondary"
  >
    {children}
  </ChakraFormHelperText>
)

export const FormErrorText = ({ children }) => (
  <ChakraFormErrorText
    fontSize="xs"
    lineHeight="xs"
    mt="1"
    px="3"
    color="error"
  >
    {children}
  </ChakraFormErrorText>
)

export const FormControl = ({
  label,
  helperText,
  errorMessage,
  children,
  ...props
}) => (
  <ChakraFormControl width="100%" maxW="380px" {...props}>
    {label && <FormLabel label={label} />}

    {children}

    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    {errorMessage ? <FormErrorText>{errorMessage}</FormErrorText> : null}
  </ChakraFormControl>
)
