import { EmptyObject } from "global-types"
import ContractResourceAppSyncService from "../services/ContractResourceAppSyncService"
import { ContractResourceCreateInput } from "../types/contract-resource-model-types"
import { ModelContractResourceFilterInput } from "../types/graphql-types"

class ContractResourceCRUDRepository {
  constructor(
    private readonly contractResourceService: ContractResourceAppSyncService
  ) {}

  getById(id: string) {
    return this.contractResourceService.getById(id)
  }

  findByContractPk(
    contractPk: string,
    filter: ModelContractResourceFilterInput | EmptyObject = {}
  ) {
    return this.contractResourceService.findByContractPk(contractPk, filter)
  }

  createOne(input: ContractResourceCreateInput) {
    return this.contractResourceService.createOne(input)
  }

  deleteOne(id: string) {
    return this.contractResourceService.deleteOne(id)
  }
}

export default ContractResourceCRUDRepository
