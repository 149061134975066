import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"
import { Icon } from "theme/components/ui/Icon"

function ContractSectionLayout({
  children,
  iconName,
  heading,
  spacing = 4,
  ...props
}) {
  return (
    <Flex
      align="stretch" direction="column"
      mt="12"
      {...props}>
      <Flex align="center">
        <Icon name={iconName} />

        <Heading as="h4" fontSize="md" lineHeight="md" ml="3">
          {heading}
        </Heading>
      </Flex>

      <Box
        ml={{
          base: "0",
          md: "8"
        }}
        mt={spacing}
      >
        {children}
      </Box>
    </Flex>
  )
}

export default ContractSectionLayout
