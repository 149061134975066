export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
