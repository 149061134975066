export const regex = {
  email: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/
}

export const validateEmail = {
  pattern: {
    value: regex.email,
    message: "Email is invalid"
  }
}
