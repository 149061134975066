import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, Flex, Grid, Image } from "@chakra-ui/react"
import {
  useAdminPlatformConfigState
} from "providers/AdminPlatformConfigProvider"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, IconButton } from "theme/components"
import { PlatformWorkspaceSidebar } from "../ui"

const PlatformAdminLayout = ({
  onCreateClick,
  children,
  isBgImage,
  showNavbar = false,
  ...props
}) => {
  const {
    platformConfig
  } = useAdminPlatformConfigState()

  return (
    <Grid
      id="platform-admin-layout"
      className="h-screen"
      templateColumns={{
        md: platformConfig?.showSidebar ? "80px 1fr" : "1fr",
        base: "1fr"
      }}
      templateRows={{
        base: platformConfig?.showSidebar && showNavbar ? "56px 1fr" : "1fr",
        md: "1fr"
      }}
      bgImage={isBgImage ? "/assets/images/background.jpg" : undefined}
      bgSize="cover"
      {...props}
    >
      {platformConfig?.showSidebar ? (
        <>
          <PlatformWorkspaceSidebar
            platformConfig={platformConfig}
            onCreateClick={onCreateClick}
            display={{ base: "none", md: "grid" }}
          />

          <MobileNavbar
            display={{ base: showNavbar ? "flex" : "none", md: "none" }}
            platformConfig={platformConfig}
          />
        </>
      ) : null}

      <Box
        overflowY="auto" id="platform-admin-body"
        mt={{ base: "3", md: 0 }}
      >
        {children}
      </Box>
    </Grid>
  )
}

export default PlatformAdminLayout

const MobileNavbar = ({ platformConfig, ...props }) => (
  <Flex
    px="4"
    height="56px"
    width="full"
    align="center"
    justify="space-between"
    {...props}
    id="MobileNavbar"
  >
    {platformConfig?.showLogo ? (
      <Image src="/assets/images/logo-symbol.png" width="32px" height="32px" />
    ) : null}

    <Flex justify="end" width="full">
      <Button variant="secondary" size="md" as={RouterLink} to="?tab=teams">
        Teams
      </Button>

      <IconButton
        as={RouterLink}
        to="?tab=menu"
        relative="path"
        icon={<HamburgerIcon />}
        ml="3"
      />
    </Flex>
  </Flex>
)
