import { IApiResponse } from "global-types"
import {
  GetContractsByPkInput
} from "modules/Contract/src//core/types/graphql"
import { Workspace } from "modules/Workspace/src/core/types"
import {
  ContractCreateInputNormalized,
  DelegateInput,
  IContractNormalized,
  contractSubscriptionCallbackFunctionType,
  contractSubscriptionResponseType,
  contractUpdateInputNormalizedType
} from "../types"
import { denormalizeContract, normalizeContract } from "../utils"
import ContractAppSyncService from "../services/ContractAppSyncService"
import ContractAPIService from "../services/ContractAPIService"
import {
  ModelSortDirection
} from "../../../../Workspace/src/core/types/graphql"

class ContractUseCase {
  constructor(
    private readonly contractAppSyncService: ContractAppSyncService,
    private readonly contractAPIService: ContractAPIService
  ) {
  }

  async createContract(
    input: ContractCreateInputNormalized
  ): Promise<IContractNormalized | null> {
    try {
      const contract = await this.contractAppSyncService.createContract({
        ...denormalizeContract(input),
        // default
        isSubscribed: true
      })
      return normalizeContract(contract)
    } catch (error) {
      console.error("createContract error : ", error)
      return null
    }
  }

  async updateContract(
    input: contractUpdateInputNormalizedType
  ): Promise<IContractNormalized | null> {
    try {
      const contract = await this.contractAppSyncService.updateContract(
        denormalizeContract(input)
      )
      return normalizeContract(contract)
    } catch (error) {
      console.error("updateContract error : ", error)
      return null
    }
  }

  async deleteContract(contractId: string): Promise<IContractNormalized> {
    try {
      const res =  await this.contractAppSyncService.deleteContract(contractId)
      return normalizeContract(res)
    } catch (error) {
      console.error("deleteContract error : ", error)
      throw error
    }
  }

  async getContract(id: string): Promise<IContractNormalized | null> {
    try {
      const contract = await this.contractAppSyncService.getContract(id)
      return normalizeContract(contract)
    } catch (error) {
      console.error("getContract error : ", error)
      return null
    }
  }

  async getByPk(input: GetContractsByPkInput): Promise<IContractNormalized | null> {
    try {
      const contract = (
        await this.contractAppSyncService.getAllByPk(input)
      ).items[0]

      return normalizeContract(contract)
    } catch (error) {
      console.error("getByPk error : ", error)
      return null
    }
  }

  async softDeleteContract(contractId: string): Promise<IApiResponse> {
    try {
      return await this.contractAPIService.softDeleteContract(contractId)
    } catch (error) {
      console.error("softDeleteContract error : ", error)
      throw error
    }
  }

  createContractSubscription = (
    callback: contractSubscriptionCallbackFunctionType
  ): contractSubscriptionResponseType =>
    this.contractAppSyncService.createContractSubscription(callback)

  updateContractSubscription = (
    callback: contractSubscriptionCallbackFunctionType
  ): contractSubscriptionResponseType =>
    this.contractAppSyncService.updateContractSubscription(callback)

  async delegate(
    contractId: string,
    input: DelegateInput
  ): Promise<IContractNormalized | null> {
    try {
      return await this.contractAPIService.delegate(contractId, input)
    } catch (error) {
      console.error("delegate error : ", error)
      return null
    }
  }

  async workspaceDelegate(contractId: string): Promise<{
    workspace: Workspace
    contract: IContractNormalized
  }> {
    try {
      return await this.contractAPIService.workspaceDelegate(contractId)
    } catch (error) {
      console.error("workspaceDelegate error : ", error)
      throw error
    }
  }

  async pickContract(contractId: string): Promise<IContractNormalized | null> {
    try {
      return await this.contractAPIService.pickContract(contractId)
    } catch (error) {
      console.error("pickContract error : ", error)
      return null
    }
  }

  async getMappedContract(
    contractId: string
  ): Promise<IContractNormalized | null> {
    try {
      return await this.contractAPIService.getMappedContract(contractId)
    } catch (error) {
      console.error("getMappedContract error : ", error)
      return null
    }
  }

  async getShareLinkContract(
    linkId: string
  ): Promise<IContractNormalized | null> {
    try {
      const res =  await this.contractAPIService.getShareLinkContract(linkId)
      return res
    } catch (error) {
      console.error("getShareLinkContract error : ", error)
      return null
    }
  }

  async getHomepageContracts(): Promise<IContractNormalized[]> {
    try {
      const contracts = await this.contractAPIService.getHomepageContracts()
      return contracts || []
    } catch (error) {
      console.error("getHomepageContracts error : ", error)
      return []
    }
  }

  async getChildContracts(contractPk: string): Promise<IContractNormalized[]> {
    try {
      const contracts = await this.contractAppSyncService.getContractsBySourceContractPk({
        sourceContractPk: contractPk,
        sortDirection: ModelSortDirection.ASC
      })
      return contracts.items.map(normalizeContract)
    } catch (error) {
      console.error("getHomepageContracts error : ", error)
      return []
    }
  }
}

export default ContractUseCase
