import { Grid } from "@chakra-ui/react"
import MainLayout from "modules/Workspace/src/components/layouts/MainLayout"
import React from "react"

function Layout({ children, renderHeader }) {
  return (
    <MainLayout isBgImage showNavbar>
      <Grid
        height="full"
        templateRows="auto repeat(1, minmax(100px, 1fr))"
        rowGap="3"
      >
        {renderHeader()}

        <Grid overflow="hidden">{children}</Grid>
      </Grid>
    </MainLayout>
  )
}

export default Layout

export function ContractsBoxLayout({ children }) {
  return (
    <Grid overflowY="scroll" pl="3" pr="1">
      {children}
    </Grid>
  )
}
