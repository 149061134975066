import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react"
import React from "react"
import { Button, IconButton } from "theme/components"

const Layout = ({ isOpen, closeModal, children, onDeleteClick, content }) => (
  <Modal isCentered isOpen={isOpen} onClose={closeModal}>
    <ModalOverlay />

    <ModalContent rounded="2xl" height="310px" maxWidth="420px">
      <ModalCloseButton
        as={IconButton}
        iconName="close"
        top="3"
        right="3"
        _hover={{ bg: "transparent" }}
      />

      <Header heading={content.heading} />

      <ModalBody pt={3} px="30px">
        {children}
      </ModalBody>

      <Footer
        onDeleteClick={onDeleteClick}
        deleteContractButtonLabel={content.deleteContractButtonLabel}
      />
    </ModalContent>
  </Modal>
)

export default Layout

const Header = ({ heading }) => (
  <ModalHeader pt={10} px="30px" pb={2}>
    <Heading as="h3" fontSize="2xl" lineHeight="2xl">
      {heading}
    </Heading>
  </ModalHeader>
)

const Footer = ({ onDeleteClick, deleteContractButtonLabel }) => (
  <ModalFooter pb={5} px="30px">
    {onDeleteClick ? (
      <Button onClick={onDeleteClick} variant="textDark" fontWeight="medium">
        {deleteContractButtonLabel}
      </Button>
    ) : null}
  </ModalFooter>
)
