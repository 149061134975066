import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import {
  IContractNormalized,
  IContractNormalizedWithLoading,
  contractUpdateInputNormalizedType
} from "../types"
import { mergeContracts } from "../utils"

export const useUpdateContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "updateContract",
    input => contractUseCase.updateContract(input.input),

    {
      onMutate: async ({ input, workspaceId = "" , sourceContractPk}: FnInput) => {
        const constantFields = [
          !input.name ? "name" : null,
          !input.description ? "description" : null,
          !input.pointEstimation ? "pointEstimation" : null,
          !input.githubCodespaces ? "githubCodespaces" : null
        ].filter(Boolean) as (keyof IContractNormalized)[]

        if (sourceContractPk) {
          const queryKey = ["childContracts", sourceContractPk]

          await queryClient.cancelQueries(queryKey)

          const previousContracts = queryClient.getQueryData<IContractNormalizedWithLoading[]>(queryKey) || []

          const updatedContracts = previousContracts.map(contract => {
            if (contract.id === input.id) {
              return { ...contract, ...input }
            }
            return contract
          })

          queryClient.setQueryData(queryKey, updatedContracts)
          return {
            previousWorkspaceContract: previousContracts,
            queryKey,
            constantFields
          }
        }

        const mappedContractQueryKey = ["mappedContract", input.id]
        const previousContract = queryClient.getQueryData<IContractNormalized>(
          mappedContractQueryKey
        )

        if (previousContract) {
          await queryClient.cancelQueries(mappedContractQueryKey)

          queryClient.setQueryData(mappedContractQueryKey, (oldContract: any) =>
            mergeContracts(
              oldContract as IContractNormalized,
              input,
              constantFields
            )
          )
        }

        const queryKey = workspaceId
          ? ["workspaceContracts", workspaceId]
          : "homepageContracts"

        const previousWorkspaceContracts =
          queryClient.getQueryData<IContractNormalized[]>(queryKey) || []

        if (previousWorkspaceContracts.length) {
          await queryClient.cancelQueries(queryKey)

          queryClient.setQueryData(queryKey, (old: any) => {
            const oldContracts = old as IContractNormalized[]
            const index = oldContracts.findIndex(
              contract => contract.id === input.id
            )

            oldContracts[index] = mergeContracts(
              oldContracts[index],
              input,
              constantFields
            )
            return oldContracts
          })
        }

        return {
          previousWorkspaceContracts,
          previousContract,
          constantFields,
          queryKey
        }
      },
      onError: (_err, variables, context) => {
        if (context!.previousContract) {
          queryClient.setQueryData(
            ["mappedContract", variables.input.id],
            context!.previousContract
          )
        }

        queryClient.setQueryData(
          context!.queryKey,
          context!.previousWorkspaceContracts
        )
      },
      onSettled: (data, _error, variables, context) => {
        if (!data?.id || !context) {
          return
        }
        const updatedContract = data as IContractNormalized

        if (context.previousContract) {
          queryClient.setQueryData<IContractNormalized>(
            ["mappedContract", variables.input.id],
            oldContract =>
              mergeContracts(
                oldContract as IContractNormalized,
                data as IContractNormalized,
                context.constantFields
              )
          )
        }

        if (context.previousWorkspaceContracts?.length) {
          queryClient.setQueryData<IContractNormalizedWithLoading[]>(
            context.queryKey,
            oldContracts => {
              const contracts = [
                ...(oldContracts || [])
              ] as IContractNormalizedWithLoading[]
              const contractIndex = contracts.findIndex(
                contract => contract.pk === updatedContract.pk
              )
              contracts[contractIndex] = mergeContracts(
                contracts[contractIndex],
                updatedContract,
                context.constantFields
              )

              return contracts
            }
          )
        }
      },
      ...options
    }
  )
}

export type FnInput = {
  input: contractUpdateInputNormalizedType
  workspaceId?: string
  sourceContractPk?: string
}

export type Options = Omit<
  UseMutationOptions<
    IContractNormalized | null,
    Error,
    FnInput,
    {
      previousWorkspaceContracts?: IContractNormalized[]
      previousContract?: IContractNormalized
      constantFields: (keyof IContractNormalized)[]
      queryKey: string | string[]
    }
  >,
  "mutationFn"
>
