import { Text } from "@chakra-ui/react"
import {
  useForgotPassword,
  useForgotPasswordSubmit,
  useSignIn
} from "modules/User/src/core/hooks"
import React, { useMemo, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { AuthLayout, Button } from "theme/components"
import ResetPasswordForm from "./ResetPasswordForm"
import SendEmailForm from "./SendEmailForm"

function ResetPassword() {
  const [resetPasswordStep, setResetPasswordStep] = useState("sendEmail")
  const [sendEmailFormData, setSendEmailFormData] = useState("")
  const {
    mutateAsync: forgotPasswordAsync,
    isLoading: isLoadingForgetPassword
  } = useForgotPassword()
  const {
    mutateAsync: forgotPasswordSubmitAsync,
    isLoading: isLoadingForgetPasswordSubmit
  } = useForgotPasswordSubmit()
  const { mutateAsync: signInAsync } = useSignIn()

  const headerText = useMemo(
    () =>
      resetPasswordStep === "sendEmail"
        ? "Enter your email to reset password"
        : "Reset password",
    [resetPasswordStep]
  )

  const handleSendEmail = async (email, { onError }) => {
    try {
      await forgotPasswordAsync(email)
      setSendEmailFormData(email)
      setResetPasswordStep("resetPassword")
    } catch (error) {
      if (!error?.code) {
        return
      }

      onError(error.message)
    }
  }

  const handleResetPassword = async ({ code, password }, { onError }) => {
    try {
      await forgotPasswordSubmitAsync({
        email: sendEmailFormData,
        code,
        password
      })

      await signInAsync({
        email: sendEmailFormData,
        password
      })
    } catch (error) {
      if (!error?.code) {
        return
      }
      const field = error.code === "INVALID_CODE" ? "code" : "password"

      onError({
        field,
        message: error.message
      })
    }
  }

  return (
    <AuthLayout headerText={headerText}>
      <ResetPasswordRouter
        step={resetPasswordStep}
        onSendEmail={handleSendEmail}
        isLoadingSendEmail={isLoadingForgetPassword}
        onResetPassword={handleResetPassword}
        isLoadingResetPassword={isLoadingForgetPasswordSubmit}
      />
    </AuthLayout>
  )
}

export default ResetPassword

function ResetPasswordRouter({
  step = "sendEmail",
  onSendEmail,
  isLoadingSendEmail,
  onResetPassword,
  isLoadingResetPassword

}) {
  if (step === "sendEmail") {
    return (
      <>
        <SendEmailForm
          onSendEmail={onSendEmail}
          isLoadingSendEmail={isLoadingSendEmail}
        />

        <Text fontSize="md" align="center" mt="10">
          <Button as={RouterLink} to="/sign-in" variant="outline" width="full">
            Cancel
          </Button>
        </Text>
      </>
    )
  } else if (step === "resetPassword") {
    return <ResetPasswordForm
      onResetPassword={onResetPassword}
      isLoadingResetPassword={isLoadingResetPassword}
    />
  }
}
