import React from "react"
import WorkspaceDesktop from "./WorkspaceDesktop"
import WorkspaceMobileTabRouter from "./WorkspaceMobileTabRouter"

function WorkspaceResponsive({
  workspace,
  contractSidebarProps,
  contractDetailsProps,
  contractSettingsProps,
  workspaceDeleteProps,
  sourceSettingsProps,
  communitySettingsProps,
  CDESettingsProps,
  handleContractCreate,
  handleCommunitySettingsClick,
  handleArchivedContractsClick,
  handleSourceSettingsClick,
  handleContractCreateClick,
  handleCDESettingsClick,
  teamsProps,
  workspaceTeamEditProps,
  contractDelegateProps,
  openWorkspaceDeleteModal,
  handleChatBackClick,
  isMobile,
  activeTab,
  user
}) {
  if (!isMobile) {
    return (
      <WorkspaceDesktop
        workspace={workspace}
        contractSidebarProps={contractSidebarProps}
        contractDetailsProps={contractDetailsProps}
        sourceSettingsProps={sourceSettingsProps}
        communitySettingsProps={communitySettingsProps}
        CDESettingsProps={CDESettingsProps}
        teamsSidebarProps={teamsProps}
        onCDESettingsClick={handleCDESettingsClick}
        onCommunitySettingsClick={handleCommunitySettingsClick}
        onSourceSettingsClick={handleSourceSettingsClick}
        onContractCreateClick={handleContractCreateClick}
        onDeleteWorkspaceClick={openWorkspaceDeleteModal}
        onArchivedContractsClick={handleArchivedContractsClick}
        user={user}
        activeTab={activeTab}
        onChatBackClick={handleChatBackClick}
      />
    )
  }

  return (
    <WorkspaceMobileTabRouter
      workspace={workspace}
      contractSidebarProps={contractSidebarProps}
      contractDetailsProps={contractDetailsProps}
      contractSettingsProps={contractSettingsProps}
      workspaceDeleteProps={workspaceDeleteProps}
      sourceSettingsProps={sourceSettingsProps}
      communitySettingsProps={communitySettingsProps}
      CDESettingsProps={CDESettingsProps}
      handleContractCreate={handleContractCreate}
      handleContractCreateClick={handleContractCreateClick}
      teamsProps={teamsProps}
      workspaceTeamEditProps={workspaceTeamEditProps}
      contractDelegateProps={contractDelegateProps}
      openWorkspaceDeleteModal={openWorkspaceDeleteModal}
      activeTab={activeTab}
      user={user}
      onArchivedContractsClick={handleArchivedContractsClick}
      onChatBackClick={handleChatBackClick}
    />
  )
}

export default WorkspaceResponsive
