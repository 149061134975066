import { ContractResource } from "modules/ContractResource/src//core/types/contract-resource-model-types"
import { ModelMessageFilterInput } from "./graphql"

export type AnyObject = {
  [key: string]: any
}

export interface Message {
  id: string

  contractPk: string
  contractId: string
  workspaceTeamId: string | null
  message: string | null
  actionType: MessageActionType | null
  type: MessageType
  ownerId: string
  username: string | null
  teamName: string | null
  teamEmoji: string | null
  attachmentIds: string[] | null
  communityMessageId: string | null
  communityType: MessageCommunityType | null

  oldValue: string | null
  newValue: string | null

  createdAt: string
  updatedAt: string
}

export enum MessageType {
  COMMENT = "COMMENT",
  ACTION = "ACTION",
  PIN = "PIN",
  COMMUNITY_MESSAGE = "COMMUNITY_MESSAGE"
}

enum MessageActionType {
  CREATE = "CREATE",
  RENAME = "RENAME",
  UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION"
}

enum MessageCommunityType {
  DISCORD = "DISCORD",
  SLACK = "SLACK"
}

export interface MessageNormalized
  extends Omit<Message, "oldValue" | "newValue"> {
  oldValue: AnyObject
  newValue: AnyObject
}

export interface MessageWithAttachments extends MessageNormalized {
  attachments: ContractResource[]
}

export type FindById = (id: string) => Promise<Message>

export type FindByContractPkInput = {
  contractPk: string
  filter?: ModelMessageFilterInput | AnyObject
  sortDirection?: "ASC" | "DESC",
  nextToken?: string
}

export type ListMessagesResponse = {
  items: Message[]
  nextToken: string | null
}
export type FindByContractPk = (input: FindByContractPkInput) => Promise<ListMessagesResponse>
export type FindAll = (
  filter?: ModelMessageFilterInput | AnyObject
) => Promise<Message[]>
export type CreateMessageSubscription = (
  callback: MessageSubscriptionCallbackFunction
) => MessageSubscriptionResponse
export type MessageSubscriptionCallbackFunction = (message: Message) => void
export type MessageSubscriptionResponse = () => void

export type GetAllContractCommunityMessages = (
  contractPk: string
) => Promise<Message[]>

export type MessageCreateInput = Partial<
  Omit<Message, "id" | "createdAt" | "updatedAt">
>
export type CreateOne = (input: MessageCreateInput) => Promise<Message>
export type CommentCreateInput = Pick<
  Message,
  "contractId" | "contractPk" | "message" | "username" | "ownerId"
>
export type CreateComment = (input: CommentCreateInput) => Promise<Message>
export type GetAllContractComments = (contractPk: string) => Promise<Message[]>

export interface MessageAppSyncService {
  findById: FindById
  findByContractPk: FindByContractPk
  findAll: FindAll
  createOne: CreateOne
  createMessageSubscription: CreateMessageSubscription
}

export interface MessageCRUDRepository {
  findById: FindById
  findByContractPk: FindByContractPk
  findAll: FindAll
  createOne: CreateOne
  createMessageSubscription: CreateMessageSubscription
}

export interface MessageUseCase {
  createMessageSubscription: CreateMessageSubscription
  getAllContractCommunityMessages: GetAllContractCommunityMessages
  createComment: CreateComment
  getAllContractComments: GetAllContractComments
}
