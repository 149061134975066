import { Box, Flex, HStack } from "@chakra-ui/react"
import TeamEmojiGroup from "modules/Workspace/src/components/TeamEmojiGroup"
import React from "react"
import { IoArrowBackOutline, IoSettingsOutline } from "react-icons/io5"
import { Button, Checkbox, IconButton } from "theme/components"
import GitHubCodespaceActions from "./GitHubCodespaceActions"
import { ContractSettingsMenu } from "../ui"

function Header({
  contract,
  toggleCompleted,
  onDelegateClick,
  onContractSettingsClick,
  onContractDeleteClick,
  githubProps = {},
  onBackClick
}) {
  const handleToggleCompleted = () => {
    toggleCompleted && toggleCompleted(contract.id)
  }

  return (
    <Layout contract={contract}>
      <HStack width="full" justifyContent="space-between">
        <Flex align="center">
          {onBackClick ? <BackButton onClick={onBackClick} /> : null}

          <ToggleComplete
            isCompleted={contract.completed}
            onChange={handleToggleCompleted}
          />
        </Flex>
      </HStack>

      <Flex align="center" width="auto">
        {contract.teams?.length ? (
          <TeamEmojiGroup
            teamEmojis={contract.teams}
            emojiOutlineColor="white"
            ml="auto"
          />
        ) : null}

        {!contract?.deletedAt ? (
          <ContractActions
            contract={contract}
            onDelegateClick={onDelegateClick}
            githubProps={githubProps}
            onContractSettingsClick={onContractSettingsClick}
            onContractDeleteClick={onContractDeleteClick}
          />
        ) : null}
      </Flex>
    </Layout>
  )
}

export default Header

const Layout = ({ children, contract }) => (
  <Flex
    width="100%"
    shrink="0"
    align="center"
    justify="space-between"
    direction="row"
    px={{ base: "0", md: "30px" }}
    height={{
      base: "40px",
      md: "76px"
    }}
  >
    {children}
  </Flex>
)

const ContractActions = ({
  contract,
  onDelegateClick,
  githubProps,
  onContractSettingsClick,
  onContractDeleteClick
}) => {
  if (!contract.sourceContractPk) {
    return <HStack
      spacing={{ base: 4, md: 5 }}
      ml="4"
      align="center"
      width={{ base: "full", md: "auto" }}
      justify="flex-end"
    >
      {/*<GitHubCodespaceActions*/}
      {/*  contract={contract}*/}
      {/*  onSubmit={githubProps.handleSubmit}*/}
      {/*  isLoadingSubmit={githubProps.isLoadingSubmit}*/}
      {/*  codespaceOptions={githubProps.codespaceOptions}*/}
      {/*  onPopoverChange={githubProps.handlePopoverChange}*/}
      {/*/>*/}
      <Button
        width={{ base: "fit-content", md: "112px" }}
        onClick={onDelegateClick}
      >
        Delegate
      </Button>


      <ContractSettingsButton onClick={onContractSettingsClick} />
    </HStack>
  }

  return <ContractSettingsMenu onDeleteClick={onContractDeleteClick} />
}

function ToggleComplete({ isCompleted, onChange }) {
  const handleChange = isCompleted => {
    onChange && onChange(isCompleted)
  }

  return (
    <Box display={{ base: "none", md: "block" }}>
      <ToggleCompleteButton
        onChange={handleChange}
        isCompleted={isCompleted}
      />
    </Box>
  )
}

function ToggleCompleteButton({ isCompleted, onChange }) {
  if (isCompleted) {
    return (
      <Button
        variant="green"
        iconName="checkmark"
        width="164px"
        justifyContent="start"
        pl="18px"
        onClick={() => onChange(false)}
      >
        Completed
      </Button>
    )
  }

  return (
    <Button variant="outlineDark" width="164px" onClick={() => onChange(true)}>
      Mark as Completed
    </Button>
  )
}

const BackButton = ({ onClick }) => (
  <IconButton
    icon={<IoArrowBackOutline />}
    aria-label="arrow-back"
    onClick={onClick}
    mr="3"
  />
)

const ContractSettingsButton = ({ onClick }) => (
  <IconButton
    aria-label="settings"
    ml={{ base: "auto", md: 4 }}
    icon={<IoSettingsOutline />}
    onClick={onClick}
  />
)
