import { ContractCreateInputNormalized, IContractNormalized } from "../types"

export const mergeContracts = (
  firstContract: IContractNormalized | ContractCreateInputNormalized,
  secondContract: Partial<IContractNormalized>,
  constantFields: (keyof typeof secondContract)[] = []
): IContractNormalized => {
  const mergedContract = {
    ...firstContract,
    skIds: { ...(firstContract?.skIds || []) }
  } as IContractNormalized

  ;(Object.keys(secondContract) as (keyof typeof secondContract)[]).forEach(
    key => {
      if (key === "teams") {
        const allTeams = [
          ...(firstContract?.teams || []),
          ...(secondContract?.teams || [])
        ]
        mergedContract.teams = [...new Set(allTeams.map(t => t.teamId))]
          .map(teamId => allTeams.find(team => team.teamId === teamId))
          .filter(Boolean) as never
      } else if (
        (secondContract[key] ||
          typeof secondContract[key] === "number" ||
          typeof secondContract[key] === "boolean") &&
        ((key === "id" && secondContract.pk === secondContract.sk) ||
          key !== "id") &&
        ![
          "pk",
          "sk",
          "skIds",
          "createdAt",
          "updatedAt",
          ...constantFields
        ].includes(key)
      ) {
        mergedContract[key] = secondContract[key] as never
      }
    }
  )

  if (
    secondContract.sk !== secondContract.pk &&
    secondContract.sk &&
    secondContract.id &&
    !mergedContract.skIds[secondContract.sk]
  ) {
    mergedContract.skIds[secondContract.sk] = secondContract.id
  }

  return mergedContract
}
