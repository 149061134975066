import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized } from "../types"
import { GetContractsByPkInput } from "../types/graphql"

export const useGetByPk = (
  input: FnInput,
  options: Options = {}
) =>
  useQuery<IContractNormalized | null, Error>(
    ["getByPk", input.pk],
    () => contractUseCase.getByPk(input),
    options
  )

type FnInput = GetContractsByPkInput

type Options = Omit<
  UseQueryOptions<
    IContractNormalized | null,
    Error,
    IContractNormalized | null,
    QueryKey
  >,
  "queryKey" | "queryFn"
>
