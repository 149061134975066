import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { sourceUseCase } from "../index"
import {
  SourceType,
  getSourceWorkspaceProjectsOptionsType,
  normalizedProjectType
} from "../types"

export const useGetSourceWorkspaceProjects = (
  input: IFnInput,
  options: Options
) =>
  useQuery<normalizedProjectType[], Error>(
    ["getSourceWorkspaceProjects", input.options],
    () => sourceUseCase.getSourceWorkspaceProjects(input.type, input.options),
    options
  )

// useGetSourceWorkspaceProjects
export interface IFnInput {
  type: SourceType
  options: getSourceWorkspaceProjectsOptionsType
}

export type Options = Omit<
  UseQueryOptions<
    normalizedProjectType[],
    Error,
    normalizedProjectType[],
    QueryKey
  >,
  "queryKey" | "queryFn"
>
