import { API } from "aws-amplify"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class MessageAPIService {
  async getContractAllCommunityMessages(contractPk: string) {
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        contractPk
      }
    })
    return API.get("messageRestAPI", "/contract-community-messages", init)
  }
}

export default MessageAPIService
