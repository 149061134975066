import { Flex, VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { Button, ConfigurableSelect, FormControl } from "theme/components/ui"
import debounce from "utils/debounce"
import { SwitchFormControl } from "../ui"
import Layout from "./Layout"

function ContractSettingsModal({
  closeModal,
  isOpen,
  onContractDeleteClick,
  settingsData = {},
  onChange,
  onAddPredefinedTeamClick
}) {
  const [settings, setSettings] = useState({ ...settingsData })

  const debouncedOnChange = useCallback(
    debounce(onChange, SETTINGS_CHANGE_DEBOUNCE_TIMEOUT),
    [onChange]
  )

  const handleChange = (field, value) => {
    setSettings(prevState => {
      const newSettings = {
        ...prevState,
        [field]: value
      }

      debouncedOnChange(newSettings)

      return newSettings
    })
  }

  return (
    <Layout
      closeModal={closeModal}
      isOpen={isOpen}
      onDeleteClick={onContractDeleteClick}
    >
      <VStack align="stretch" spacing={3} mb={6}>
        {settings.showAllMessagesSync ? (
          <SwitchFormControl
            label="Get all community messages"
            onChange={event =>
              handleChange("allMessagesSync", event.target.checked)
            }
            isChecked={settings.allMessagesSync}
          />
        ) : null}

        <SwitchFormControl
          label="Receive notifications via Email"
          onChange={event => handleChange("isSubscribed", event.target.checked)}
          isChecked={settings.isSubscribed}
        />
      </VStack>

      <AddTeamAction
        options={settingsData.predefinedTeamOptions}
        onAddClick={onAddPredefinedTeamClick}
      />
    </Layout>
  )
}

export default ContractSettingsModal

const SETTINGS_CHANGE_DEBOUNCE_TIMEOUT = 500

const AddTeamAction = ({ options = [], onAddClick }) => {
  const [team, setTeam] = useState(null)

  const handleAdd = () => {
    const onSuccess = () => {
      setTeam(null)
    }

    team &&
      onAddClick &&
      onAddClick(team.value, {
        onSuccess
      })
  }

  return (
    <Flex align="end">
      <FormControl label="Add predefined team">
        <ConfigurableSelect
          width="232px"
          placeholder="Select team"
          options={options}
          onChange={option => setTeam(option)}
          value={team}
        />
      </FormControl>

      <Button onClick={handleAdd} isDisabled={!team} px={5}>
        Add
      </Button>
    </Flex>
  )
}
