import { Box, ButtonGroup, Flex, HStack } from "@chakra-ui/react"
import TeamEmojiGroup from "modules/Workspace/src/components/TeamEmojiGroup"
import React from "react"
import { IoArrowBackOutline, IoSettingsOutline } from "react-icons/io5"
import { Button, Checkbox, IconButton } from "theme/components"
import {
  usePlatformWorkspaceContent
} from "../../../../../providers/PlatformWorkspaceProvider"

function Header({
  contract,
  toggleCompleted,
  onDeleteContractClick,
  onContractSettingsClick,
  onBackClick,
  platformConfig,
  onDelegateClick,
  isLoadingDeleteContract
}) {
  const handleToggleCompleted = () => {
    toggleCompleted && toggleCompleted(contract.id)
  }
  const { contractDetails } = usePlatformWorkspaceContent()

  return (
    <Layout contract={contract}>
      <HStack width="full" justifyContent="space-between">
        <Flex align="center">
          {onBackClick ? <BackButton onClick={onBackClick} /> : null}

          <ToggleComplete
            isCompleted={contract.completed}
            onChange={handleToggleCompleted}
          />
        </Flex>
      </HStack>

      <Flex align="center" width="auto">
        {contract.teams?.length ? (
          <TeamEmojiGroup
            teamEmojis={contract.teams}
            emojiOutlineColor="white"
            ml="auto"
          />
        ) : null}

        {(!contract?.deletedAt && !contract?.sourceContractPk) ? (
          <ContractActions
            contract={contract}
            onContractSettingsClick={onContractSettingsClick}
            platformConfig={platformConfig}
            contractDetailsContent={contractDetails}
            onDeleteContractClick={onDeleteContractClick}
            onDelegateClick={onDelegateClick}
            isLoadingDeleteContract={isLoadingDeleteContract}
          />
        ) : null}
      </Flex>
    </Layout>
  )
}

export default Header

const Layout = ({ children, contract }) => (
  <Flex
    width="100%"
    shrink="0"
    align="center"
    justify="space-between"
    direction="row"
    px={{ base: "0", md: "30px" }}
    height={{
      base: "40px",
      md: "76px"
    }}
  >
    {children}
  </Flex>
)

const ContractActions = ({
  contract,
  onContractSettingsClick,
  platformConfig,
  contractDetailsContent,
  onDeleteContractClick,
  onDelegateClick,
  isLoadingDeleteContract
}) => (
  <Flex
    spacing={{ base: 4, md: 5 }}
    ml="4"
    align="center"
    width={{ base: "full", md: "auto" }}
    justify="flex-end"
  >
    <ButtonGroup spacing={5}>
      {platformConfig.delegateButton?.show ? (
        <DelegateButton onClick={() => onDelegateClick(contract.pk)}>

          {contractDetailsContent.delegateButtonLabel}
        </DelegateButton>
      ) : null}

      {platformConfig.contractDeleteButton?.isPrimaryView ? (
        <DeleteButton
          onClick={onDeleteContractClick}
          isLoading={isLoadingDeleteContract}
        >
          {contractDetailsContent.deleteButtonLabel}
        </DeleteButton>
      ) : null}
    </ButtonGroup>

    <ContractSettingsButton onClick={onContractSettingsClick} />
  </Flex>
)

function ToggleComplete({ isCompleted, onChange }) {
  const handleChange = isCompleted => {
    onChange && onChange(isCompleted)
  }

  return (
    <Box display={{ base: "none", md: "block" }}>
      <ToggleCompleteButton
        onChange={handleChange}
        isCompleted={isCompleted}
      />
    </Box>
  )
}

function ToggleCompleteButton({ isCompleted, onChange }) {
  const { contractDetails } = usePlatformWorkspaceContent()

  if (isCompleted) {
    return (
      <Button
        variant="green"
        iconName="checkmark"
        justifyContent="start"
        pl="18px"
        onClick={() => onChange(false)}
      >
        {contractDetails.done}
      </Button>
    )
  }

  return (
    <Button variant="outlineDark" onClick={() => onChange(true)}>
      {contractDetails.markAsDone}
    </Button>
  )
}

const BackButton = ({ onClick }) => (
  <IconButton
    icon={<IoArrowBackOutline />}
    aria-label="arrow-back"
    onClick={onClick}
    mr="3"
  />
)

const ContractSettingsButton = ({ onClick }) => (
  <IconButton
    aria-label="settings"
    icon={<IoSettingsOutline />}
    onClick={onClick}
    ml={{ base: "auto", md: 5 }}
  />
)

const DelegateButton = ({ onClick, children }) => (
  <Button onClick={onClick} px={5}>
    {children}
  </Button>
)

const DeleteButton = ({ onClick, children, ...props }) => (
  <Button variant="secondary" onClick={onClick} px={5} {...props}>
    {children}
  </Button>
)
