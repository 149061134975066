import {
  IContractNormalizedWithLoading
} from "modules/Contract/src/core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { useDelegateContract } from "./useDelegateContract"
import { useSearchParams } from "react-router-dom"

const useAutoContractDelegate = () => {
  const {
    mutate: delegateContract,
    isLoading: isLoadingDelegate
  } = useDelegateContract()
  const [searchParams] = useSearchParams()


  const autoDelegate = (contract: IContractNormalizedWithLoading, workspace: Workspace) => {
    const teamId = searchParams.get("teamId")

    const isAlreadyDelegated = contract.teams?.some(
      (team) => team.id === teamId
    )

    if (isAlreadyDelegated) {
      return
    }

    delegateContract({
      workspaceId: workspace?.id,
      contractId: contract?.id || "",
      input: {
        workspaceTeams: [{ id: teamId || "", isPrivate: false }]
      }
    })
  }

  return { autoDelegate, isLoading: isLoadingDelegate}
}

export default useAutoContractDelegate
