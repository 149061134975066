import { Checkbox as ChakraCheckbox } from "@chakra-ui/react"
import React, { forwardRef } from "react"

const Checkbox = forwardRef(
  ({ isChecked, onChange, children, ...props }, ref) => {
    return (
      <ChakraCheckbox
        ref={ref}
        isChecked={!!isChecked}
        icon={<CheckboxIcon isChecked={isChecked} />}
        onChange={onChange}
        {...props}
      >
        {children}
      </ChakraCheckbox>
    )
  }
)
Checkbox.displayName = "Checkbox"

export { Checkbox }

function CheckboxIcon({ isChecked }) {
  if (!isChecked) {
    return <></>
  }

  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66663 4.71429L3.80948 6.85714L9.16663 1.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
