import { API } from "aws-amplify"
import { DelegateInput } from "modules/Contract/src//core/types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class ContractAPIService {
  async delegate(contractId: string, input: DelegateInput) {
    const init = await withCognitoAccessToken({
      body: input
    })
    return API.post("contractRestAPI", `/delegate/${contractId}`, init)
  }

  async workspaceDelegate(contractId: string) {
    const init = await withCognitoAccessToken({
      body: { contractId }
    })
    return API.post("contractRestAPI", "/workspace-delegate", init)
  }

  async pickContract(contractId: string) {
    const init = await withCognitoAccessToken({})
    return API.post("contractRestAPI", `/pick/${contractId}`, init)
  }

  async getShareLinkContract(linkId: string) {
    const init = {}
    return API.get("contractRestAPI", `/share-link/${linkId}`, init)
  }

  async getMappedContract(contractId: string) {
    const init = await withCognitoAccessToken({})
    const res =  await API.get("contractRestAPI", `/mapped/${contractId}`, init)
    return res
  }

  async getHomepageContracts() {
    const init = await withCognitoAccessToken({})
    return API.get("contractRestAPI", "/homepage-contracts", init)
  }

  async softDeleteContract(contractId: string) {
    const init = await withCognitoAccessToken({
      body: { contractId }
    })
    return API.post("contractRestAPI", `/soft-delete`, init)
  }
}

export default ContractAPIService
