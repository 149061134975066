import { PlatformContractDetails } from "modules/Contract/src/components"
import React from "react"
import Layout from "./Layout"

function ContractPage({ platformConfig, contractDetailsProps }) {
  if (!contractDetailsProps.contract?.id) {
    return <Layout />
  }

  return (
    <Layout platformConfig={platformConfig}>
      <PlatformContractDetails
        {...contractDetailsProps}
        platformConfig={platformConfig}
      />
    </Layout>
  )
}

export default ContractPage
