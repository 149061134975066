import { API, graphqlOperation } from "aws-amplify"
import {
  getPlatformWorkspaceConfig as getPlatformWorkspaceConfigQuery
} from "../graphql/queries"
import { PlatformWorkspaceConfig } from "../types"

class PlatformWorkspaceConfigAppSyncService {
  async getPlatformWorkspaceConfig(id: string): Promise<PlatformWorkspaceConfig> {
    const response = await (API.graphql(
      graphqlOperation(getPlatformWorkspaceConfigQuery, { id })
    ) as Promise<any>)

    return response.data.getPlatformWorkspaceConfig
  }
}

export default PlatformWorkspaceConfigAppSyncService
