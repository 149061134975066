const tabsTheme = {
  Tabs: {
    variants: {
      homepage: {
        root: {
          display: "grid",
          gridTemplateRows: "auto repeat(1, minmax(0, 1fr))",
          overflow: "auto"
        },
        tablist: {
          borderBottom: "1px solid",
          borderColor: "rgba(234, 234, 234, 0.1)"
        },
        tab: {
          color: "white",
          mb: "-1px",
          pb: "12px",
          pt: "0",
          px: "6",
          _selected: {
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid",
            borderColor: "white"
          }
        },
        tabpanel: {
          position: "relative",
          height: "full",
          p: 0
        }
      }
    }
  }
}

export default tabsTheme
