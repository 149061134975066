import { Workspace } from "modules/Workspace/src//core/types"
import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { workspaceUseCase } from "../index"

export const useGetUserWorkspaces = (userId: string, options: Options = {}) =>
  useQuery<Workspace[], Error>(
    "userWorkspaces",
    () => workspaceUseCase.getUserWorkspaces(userId),
    options
  )

type Options = Omit<
  UseQueryOptions<Workspace[], Error, Workspace[], QueryKey>,
  "queryKey" | "queryFn"
>
