import { Box, Flex, Grid, Text } from "@chakra-ui/react"
import TeamEmojiGroup from "modules/Workspace/src/components/TeamEmojiGroup"
import React from "react"
import { Checkbox } from "theme/components"

function ContractCard({ contract, toggleCompleted, onClick, isActive }) {
  const handleCompleteChange = () => {
    toggleCompleted && toggleCompleted(contract.id)
  }

  const handleClick = () => {
    onClick && onClick(contract.pk)
  }

  return (
    <Layout isActive={isActive} onClick={handleClick}>
      <Flex justify="space-between" align="start">
        <ContractName name={contract.name} />

        <CompleteCheckbox
          isChecked={contract.completed}
          onChange={handleCompleteChange}
        />
      </Flex>

      <Flex justify="end" align="center">
        {contract.teams?.length ? (
          <TeamEmojiGroup
            teamEmojis={contract.teams}
            emojiOutlineColor={isActive ? "white" : "#faf7fa"}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Layout>
  )
}

export default ContractCard

const Layout = ({ children, onClick, isActive }) => (
  <Grid
    onClick={event => {
      event.stopPropagation()
      onClick && onClick()
    }}
    py="4"
    px="5"
    minH="102px"
    height="auto"
    bgColor={isActive ? "white" : "bgCard"}
    border="1px solid"
    borderColor="line"
    rounded="12px"
    cursor="pointer"
    gridTemplateRows="auto auto"
    rowGap="2"
    alignContent="space-between"
    overflow="hidden"
  >
    {children}
  </Grid>
)

const ContractName = ({ name }) => (
  <Text
    fontSize="sm"
    lineHeight="sm"
    fontWeight="medium"
    overflowWrap="anywhere"
  >
    {name}
  </Text>
)

function CompleteCheckbox({ isChecked, onChange }) {
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()

    onChange && onChange()
  }

  return (
    <Box
      display="inline-flex"
      data-element="complete-checkbox"
      onClick={handleClick}
      ml="3"
    >
      <Checkbox ml="auto" isChecked={isChecked} onChange={onChange} />
    </Box>
  )
}
