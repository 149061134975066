import { Box, ButtonGroup, Flex, Grid, Heading } from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"

const Layout = ({ children, onCancel, onDelegate, isLoadingDelegate }) => (
  <Grid className="h-screen" templateRows="auto 1fr auto">
    <Header />

    <Box px="4" pb="8" overflowY="auto">
      {children}
    </Box>

    <Footer
      onCancel={onCancel}
      onDelegate={onDelegate}
      isLoadingDelegate={isLoadingDelegate}
    />
  </Grid>
)

export default Layout

const Header = () => (
  <Box p="3" height="72px">
    <Heading as="h4" fontSize="2xl" lineHeight="2xl" fontWeight="bold">
      Delegate
    </Heading>
  </Box>
)

const Footer = ({ onDelegate, isLoadingDelegate, onCancel }) => (
  <Flex
    height="56px"
    shadow="0px -6px 20px rgba(0, 0, 0, 0.05)"
    align="center"
    px="4"
  >
    <ButtonGroup spacing="8" width="full" justifyContent="end">
      <Button onClick={onCancel} variant="textDark" color="#666666">
        Cancel
      </Button>

      <Button
        onClick={onDelegate}
        isLoading={isLoadingDelegate}
        width="80px"
      >
        Add
      </Button>
    </ButtonGroup>
  </Flex>
)
