import {
  ContractCreateModal,
  ContractSettingsModal,
  PlatformContractDelegateModal,
  PlatformContractSettingsModal
} from "modules/Contract/src/components"
import DelegateModal from "modules/Contract/src/components/DelegateModal"
import SourceEditModal from "modules/Source/src/components/SourceEditModal"
import WorkspaceCreateModal
  from "modules/Workspace/src/components/WorkspaceCreateModal"
import WorkspaceTeamEditModal
  from "modules/Workspace/src/components/WorkspaceTeamEditModal"
import ModalProvider from "providers/ModalProvider"
import React from "react"
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom"
import { ConfirmationModal } from "theme/components/ui"
import AdminPlatformConfigProvider
  from "../providers/AdminPlatformConfigProvider"
import Contract from "./Contract"
import ContractResource from "./ContractResource"
import Homepage from "./Homepage"
import PlatformAdminWorkspace from "./PlatformAdminWorkspace"
import PlatformWorkspace from "./PlatformWorkspace"
import ResetPassword from "./ResetPassword"
import {
  AuthRoute,
  PrivateRoute
} from "./RouteWrappers"
import ShareContract from "./ShareContract"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import Workspace from "./Workspace"
import PlatformWorkspaceProvider from "../providers/PlatformWorkspaceProvider"

export const RouterProvider = BrowserRouter

const Routes = () => (
  <ReactRoutes>
    <Route
      element={
        <ModalProvider
          modals={{
            contractCreate: ContractCreateModal,
            contractSettings: ContractSettingsModal,
            platformContractSettings: PlatformContractSettingsModal,
            sourceEdit: SourceEditModal,
            workspaceTeamEdit: WorkspaceTeamEditModal,
            delegate: DelegateModal,
            platformContractDelegate: PlatformContractDelegateModal,
            workspaceCreate: WorkspaceCreateModal,
            confirmation: ConfirmationModal
          }}
        />
      }
    >
      <Route element={<PrivateRoute />}>
        <Route path="/workspace/:id" element={<Workspace />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/contract/:contractId" element={<Contract />} />
      </Route>

      <Route path="/c/:id" element={<ShareContract />} />
      <Route path="/resource" element={<ContractResource />} />

      <Route element={<AuthRoute />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>

      <Route element={<PlatformWorkspaceProvider />}>
        <Route
          path=":lang/platform/workspace/:platformWorkspaceConfigId"
          element={<PlatformWorkspace />}
        />
      </Route>

      <Route element={<AdminPlatformConfigProvider />}>
        <Route path="/platform/admin" element={<PlatformAdminWorkspace />} />
      </Route>
    </Route>

    {/*<Route path="*" element={<NavigateToDefaultRoute />} />*/}
  </ReactRoutes>
)

export default Routes
