import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import contractResourceUseCase from "../core"
import {
  ContractResource,
  ContractResourceType,
  ContractResourceWithLoading
} from "../core/types/contract-resource-model-types"

export const useUploadContractResources = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "uploadContractResources",
    input =>
      contractResourceUseCase.uploadContractResources(
        input.contractPk,
        input.files
      ),
    {
      onMutate: async ({ contractPk, files }) => {
        // add contract resources to the ["contractResources", variables.contractPk] with loading statec
        await queryClient.cancelQueries(["contractResources", contractPk])

        queryClient.setQueryData<ContractResource[]>(
          ["contractResources", contractPk],
          oldContractResources => {
            const contractResourcesWithLoading: ContractResourceWithLoading[] =
              Array.from(files).map(file => ({
                id: Math.random().toString(36).substring(7),
                contractPk,
                type: file.type.startsWith("image/") ? ContractResourceType.CONTRACT_IMAGE : ContractResourceType.CONTRACT_FILE,
                size: file.size,
                name: file.name,
                isLoading: true,
                url: "",
                createdAt: "",
                updatedAt: ""
              }))
            return [
              ...contractResourcesWithLoading,
              ...(oldContractResources || [])
            ]
          }
        )
      },

      onSettled: (data, _error, variables) => {
        if (!data?.length) {
          return
        }

        const newContractResources = data as ContractResource[]

        queryClient.setQueryData<ContractResourceWithLoading[]>(
          ["contractResources", variables.contractPk],
          oldContractResources => {
            const newContractResourcesWithLoading = oldContractResources?.map(
              contractResource => {
                if (!contractResource.isLoading) {
                  return contractResource
                }

                const newContractResource = newContractResources.find(
                  newContractResource =>
                    newContractResource.name === contractResource.name
                )
                if (!newContractResource) {
                  return contractResource
                }

                return newContractResource
              }
            )

            return newContractResourcesWithLoading || []
          }
        )
      },
      ...options
    }
  )
}

type Options = Omit<
  UseMutationOptions<
    ContractResourceWithLoading[],
    Error,
    {
      contractPk: string
      files: FileList
    }
  >,
  "mutationFn"
>
