import { Flex, Heading, Image, Text } from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"

export function SourceProjectsList({ projects = [], onProjectEdit }) {
  return (
    <Flex direction="column" width="full" mt="8">
      <Heading as="h4" fontSize="md" lineHeight="md" fontWeight="bold">
        Projects
      </Heading>

      <Flex width="full" direction="column" mt="2">
        {projects.map(project => (
          <WorkspaceItem
            key={project.id}
            project={project}
            onEdit={onProjectEdit}
          />
        ))}
      </Flex>
    </Flex>
  )
}

function WorkspaceItem({ project, onEdit }) {
  const handleEdit = () => {
    onEdit && onEdit(project)
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      height="10"
      pr="4"
      _notLast={{
        borderBottom: "1px solid",
        borderColor: "line"
      }}
    >
      <Flex align="center">
        <Image
          src={`/assets/icons/${project.iconName}.svg`}
          alt={project.sourceType}
          width="16px"
          height="16px"
        />

        <Text fontSize="sm" lineHeight="sm" fontWeight="bold" ml="3" mb="-1px">
          {project.name}
        </Text>
      </Flex>

      <EditButton onClick={handleEdit} />
    </Flex>
  )
}

function EditButton({ onClick }) {
  return (
    <Button
      variant="textDark"
      fontSize="xs"
      lineHeight="xs"
      fontWeight="medium"
      color="#666666"
      minW="auto"
      width="fit-content"
      onClick={onClick}
    >
      Edit
    </Button>
  )
}
