import { IUserNormalized } from "modules/User/src//core/types"
import { Workspace } from "modules/Workspace/src//core/types"
import { useGetCDEUser } from "../index"

export const useCDESettingsGitHub = ({ workspace, user }: Props) => {
  const { data: account } = useGetCDEUser(
    { CDEType: "github" },
    { enabled: !!user }
  )

  const handleGitHubConnect = () => {
    const authorizationUrl = "https://github.com/login/oauth/authorize"
    const workspaceId = workspace?.id
    const userId = user?.id
    const params = {
      client_id: process.env.REACT_APP_GITHUB_CLIENT_ID || "",
      scope: "repo, read:user, user:email, codespace, admin:org",
      state: `${userId}${workspaceId}${Date.now()}`,
      redirect_uri: `${process.env.REACT_APP_GITHUB_REDIRECT_URI}?userId=${userId}&workspaceId=${workspaceId}`
    }
    const url = new URL(authorizationUrl)
    url.search = new URLSearchParams(params).toString()
    window.location.href = url.toString()
  }

  return {
    account,
    handleGitHubConnect
  }
}

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
