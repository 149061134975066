import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack
} from "@chakra-ui/react"
import React, { useMemo, useRef, useState } from "react"
import { Button, FormControl, IconButton, Input } from "theme/components"

function WorkspaceCreateModal({
  onSubmit,
  isLoadingSubmit,
  closeModal,
  isOpen
}) {
  const nameInputRef = useRef()
  const [name, setName] = useState("")
  const isDisabledCreateButton = useMemo(() => !name.trim().length, [name])
  const handleSubmit = event => {
    event.preventDefault()
    const nameNormalized = name.trim()
    const onSuccess = () => {
      closeModal()
    }

    onSubmit &&
      onSubmit(nameNormalized, {
        onSuccess
      })
  }

  return (
    <Layout
      closeModal={closeModal}
      isOpen={isOpen}
      initialFocusRef={nameInputRef}
    >
      <VStack align="start">
        <Box as="form" w="full" onSubmit={handleSubmit}>
          <FormControl label="Workspace name" maxW="full" width="full">
            <Input
              ref={nameInputRef}
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              maxLength={60}
            />
          </FormControl>

          <Button
            variant="primaryPurple"
            maxW="full"
            width="full"
            mt="8"
            type="submit"
            disabled={isDisabledCreateButton}
            isLoading={isLoadingSubmit}
            loadingText="Creating..."
          >
            Create Workspace
          </Button>
        </Box>
      </VStack>
    </Layout>
  )
}

export default WorkspaceCreateModal

function Layout({ isOpen, closeModal, initialFocusRef, children }) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={closeModal}
      size="sm"
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay bgColor="rgba(48, 48, 48, .4)" />

      <ModalContent height="auto" p="0">
        <ModalCloseButton
          as={IconButton}
          iconName="close"
          top="3"
          right="3"
          _hover={{ bg: "transparent" }}
        />

        <Header />

        <ModalBody pt="26px" pb="42px" px="30px">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function Header() {
  return (
    <ModalHeader px="30px" pt="36px" pb="0">
      <Heading as="h3" fontSize="xl" lineHeight="xl">
        What&apos;s you workspace name?
      </Heading>
    </ModalHeader>
  )
}
