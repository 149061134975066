import { IApiResponse } from "global-types"
import { IContractNormalized } from "modules/Contract/src/core/types"
import WorkspaceAPIRepository from "../repositories/WorkspaceAPIRepository"
import WorkspaceCRUDRepository from "../repositories/WorkspaceCRUDRepository"
import { Workspace, WorkspaceCreateInput, WorkspaceUpdateInput } from "../types"
import {
  ModelSortDirection,
} from "../types/graphql"
import {
  ModelContractFilterInput
} from "../../../../Contract/src/core/types/graphql"

class WorkspaceUseCase {
  constructor(
    private readonly workspaceCRUDRepo: WorkspaceCRUDRepository,
    private readonly workspaceAPIRepo: WorkspaceAPIRepository
  ) {}

  async getWorkspace(id: string): Promise<Workspace | null> {
    try {
      return await this.workspaceCRUDRepo.getWorkspace(id)
    } catch (error) {
      console.error("getWorkspace : ", error)
      return null
    }
  }

  async getUserWorkspaces(userId: string): Promise<Workspace[]> {
    try {
      const workspaces = (await this.workspaceCRUDRepo.getAllByOwnerId({
        ownerId: userId,
        filter: {
          or: [
            { deletedAt: { attributeExists: false } }
          ]
        },
        sortDirection: ModelSortDirection.DESC,
      })).items

      return workspaces
    } catch (error) {
      console.error("getUserWorkspaces : ", error)
      return []
    }
  }

  async updateWorkspace(
    input: WorkspaceUpdateInput
  ): Promise<Workspace | null> {
    try {
      return await this.workspaceCRUDRepo.updateWorkspace(input)
    } catch (error) {
      console.error("updateWorkspace : ", error)
      return null
    }
  }

  async createOne(input: WorkspaceCreateInput): Promise<Workspace | null> {
    try {
      return await this.workspaceCRUDRepo.createOne(input)
    } catch (error) {
      console.error("createWorkspace : ", error)
      return null
    }
  }

  async softDeleteWorkspace(workspaceId: string): Promise<IApiResponse> {
    try {
      return await this.workspaceAPIRepo.softDeleteWorkspace(workspaceId)
    } catch (error) {
      console.error("softDeleteWorkspace : ", error)
      throw error
    }
  }

  async getWorkspaceContracts(
    workspaceId: string,
    filter?: ModelContractFilterInput
  ): Promise<IContractNormalized[]> {
    try {
      const res =  await this.workspaceAPIRepo.getWorkspaceContracts(workspaceId, filter)
      return res
    } catch (error) {
      console.error("getWorkspaceContracts : ", error)
      return []
    }
  }
}

export default WorkspaceUseCase
