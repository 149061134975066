import { UseMutationOptions, useMutation } from "react-query"
import { platformConfigUseCase } from "../core"

const useCheckIfUserExistsInPlatformConfig = (options: Options = {}) =>
  useMutation(
    "checkIfUserExistsInPlatformConfig",
    input =>
      platformConfigUseCase.checkIfUserExistsInPlatform(
        input.email,
        input.platformDomain
      ),
    options
  )

export default useCheckIfUserExistsInPlatformConfig

export type FnInput = {
  email: string
  platformDomain: string
}

export type Options = Omit<
  UseMutationOptions<boolean, Error, FnInput>,
  "mutationFn"
>
