import { VStack, useDisclosure } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { SettingsAccordion, SettingsDrawer } from "theme/components"
import { GitHub } from "../CDESettings"

function CDESettings({ github, isOpen, onClose }) {
  const {
    isOpen: isOpenDrawer,
    onOpen,
    onClose: onCloseDrawer
  } = useDisclosure()

  const handleClose = () => {
    onClose && onClose()
    onCloseDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      onOpen()
    } else {
      handleClose()
    }
  }, [isOpen])

  return (
    <SettingsDrawer
      headerText="CDE"
      isOpen={isOpenDrawer}
      onClose={handleClose}
    >
      <VStack spacing="3" align="start" pb="20">
        <GitHub
          account={github.account}
          onGitHubConnect={github.handleGitHubConnect}
        />

        <DisabledCDEs />
      </VStack>
    </SettingsDrawer>
  )
}

export default CDESettings

function DisabledCDEs() {
  return (
    <>
      <SettingsAccordion name="Gitpod" iconName="gitpod" isDisabled />

      <SettingsAccordion
        name="Codecatalyst"
        iconName="codecatalyst"
        isDisabled
      />
    </>
  )
}
