import React from "react"
import { Button } from "theme/components"
import Layout from "./Layout"

const WorkspaceSettingsPage = ({
  onDeleteWorkspaceClick,
  onArchivedContractsClick,
  layoutVariant
}) => (
  <Layout layoutVariant={layoutVariant}>
    {onArchivedContractsClick ? (
      <ArchivedContractsButton onClick={onArchivedContractsClick} />
    ) : null}

    {onDeleteWorkspaceClick ? (
      <DeleteWorkspaceButton onClick={onDeleteWorkspaceClick} />
    ) : null}
  </Layout>
)

export default WorkspaceSettingsPage

const ArchivedContractsButton = ({ onClick }) => (
  <Button variant="textDark" onClick={onClick} fontSize="md">
    Archived contracts
  </Button>
)

const DeleteWorkspaceButton = ({ onClick }) => (
  <Button variant="danger" onClick={onClick}>
    Delete workspace
  </Button>
)
