import {
  Box,
  Flex,
  Grid,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react"
import emojiData from "@emoji-mart/data"
import EmojiPicker from "@emoji-mart/react"
import React, { useEffect, useRef, useState } from "react"
import { EMOJI_BG_COLORS } from "../../core/config"
import TeamEmoji from "../TeamEmoji"
import applyEmojiPickerStyles from "./applyEmojiPickerStyles"

function TeamEmojiPicker({
  team,
  onEmojiSelect,
  onEmojiBgColorChange,
  ...props
}) {
  const popoverContentRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen && popoverContentRef.current) {
      applyEmojiPickerStyles(popoverContentRef.current)
    }
  }, [isOpen, popoverContentRef])

  return (
    <Popover
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      variant="emojiConfig"
      placement="auto-start"
      {...props}
    >
      <PopoverTrigger>
        <TeamEmoji
          emoji={team.emoji}
          bgColor={team.emojiBgColor}
          size="md"
          ml="3"
          cursor="pointer"
        />
      </PopoverTrigger>
      <PopoverContent ref={popoverContentRef}>
        <EmojiBgColorConfig
          emoji={team.emoji}
          bgColor={team.emojiBgColor}
          onBgColorChange={onEmojiBgColorChange}
        />

        {isOpen ? (
          <EmojiPicker
            data={emojiData}
            theme="dark"
            onEmojiSelect={onEmojiSelect}
          />
        ) : null}
      </PopoverContent>
    </Popover>
  )
}

export default TeamEmojiPicker

function EmojiBgColorConfig({ emoji, bgColor, onBgColorChange }) {
  return (
    <Flex py="6" px="4" justify="space-between">
      <TeamEmoji emoji={emoji} bgColor={bgColor} size="lg" />

      <Grid templateColumns="repeat(4, 22px)" gap="2" ml="4">
        {EMOJI_BG_COLORS.map(color => (
          <EmojiBgColorOption
            key={color}
            color={color}
            isActive={color === bgColor}
            onClick={() => onBgColorChange(color)}
          />
        ))}
      </Grid>
    </Flex>
  )
}

function EmojiBgColorOption({ color, isActive, onClick }) {
  return (
    <Box
      as="button"
      rounded="full"
      bgColor={color}
      onClick={onClick}
      height="22px"
      width="22px"
      boxShadow={isActive ? "0 0 0 1.5px #222222, 0 0 0px 3px white" : "none"}
    />
  )
}
