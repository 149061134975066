import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react"
import TeamEmojiGroup from "modules/Workspace/src/components/TeamEmojiGroup"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Checkbox } from "theme/components"
import PointEstimationField from "../ContractDetails/PointEstimationField"

function ContractCard({
  contract,
  toggleCompleted,
  onPointEstimationChange,
  onDelegateClick,
  onClick,
  isActive
}) {
  const handleCompleteChange = () => {
    toggleCompleted && toggleCompleted(contract.id)
  }

  const handlePointEstimationChange = pointEstimation => {
    if (contract.pointEstimation === pointEstimation) {
      return
    }

    onPointEstimationChange &&
    onPointEstimationChange(contract.id, pointEstimation)
  }

  const handleDelegateClick = () => {
    onDelegateClick && onDelegateClick(contract.pk)
  }

  return (
    <Layout onClick={() => onClick(contract.pk)} isActive={isActive}>
      <Flex justify="space-between" align="start">
        <ContractName name={contract.name} />

        <CompleteCheckbox
          isChecked={contract.completed}
          onChange={handleCompleteChange}
        />
      </Flex>

      <Flex justify="space-between" align="center">
        {contract.teams?.length ? (
          <TeamEmojiGroup
            teamEmojis={contract.teams}
            emojiOutlineColor={isActive ? "white" : "#faf7fa"}
          />
        ) : (
          <DelegateButton onClick={handleDelegateClick} />
        )}

        {/*<PointEstimationField*/}
        {/*  value={contract.pointEstimation}*/}
        {/*  onChange={handlePointEstimationChange}*/}
        {/*/>*/}
      </Flex>
    </Layout>
  )
}

export default ContractCard

const Layout = ({ children, onClick, isActive }) =>
  <Grid
    onClick={onClick}
    relative="path"
    py="4"
    px="5"
    minH="102px"
    height="auto"
    bgColor={isActive ? "white" : "bgCard"}
    border="1px solid"
    borderColor="line"
    rounded="12px"
    cursor="pointer"
    gridTemplateRows="auto auto"
    rowGap="2"
    alignContent="space-between"
    overflow="hidden"
  >
    {children}
  </Grid>

function ContractName({ name }) {
  return (
    <Text
      fontSize="sm"
      lineHeight="sm"
      fontWeight="medium"
      overflowWrap="anywhere"
    >
      {name}
    </Text>
  )
}

function CompleteCheckbox({ isChecked, onChange }) {
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()

    onChange && onChange()
  }

  return (
    <Box
      display="inline-flex"
      data-element="complete-checkbox"
      onClick={handleClick}
      ml="3"
    >
      <Checkbox ml="auto" isChecked={isChecked} onChange={onChange} />
    </Box>
  )
}

function DelegateButton({ onClick }) {
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()

    onClick && onClick()
  }

  return (
    <Button
      variant="unstyled"
      color="darkBlue"
      border="none"
      shadow="none"
      fontWeight="bold"
      fontSize="xs"
      lineHeight="xs"
      height="fit-content"
      minH="0"
      onClick={handleClick}
    >
      Delegate
    </Button>
  )
}
