const switchTheme = {
  Switch: {
    variants: {
      primary: {
        track: {
          bgColor: "bgGray",
          _checked: {
            bgColor: "primaryPurple"
          },
          _focus: {
            boxShadow: "none"
          },
          _focusVisible: {
            boxShadow: "none",
            outline: "unset"
          }
        },
        _focus: {
          boxShadow: "none"
        }
      }
    },
    sizes: {
      md: {
        container: {
          width: "44px",
          height: "20px"
        },
        track: {
          width: "40px",
          height: "16px"
        },
        thumb: {
          width: "16px",
          height: "16px",
          _checked: {
            transform: "translateX(24px)"
          }
        }
      }
    },
    defaultProps: {
      variant: "primary",
      size: "md"
    }
  }
}

export default switchTheme
