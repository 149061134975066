import { Box, Flex, Grid, Heading, VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import {
  Button,
  IconButton
} from "theme/components"
import debounce from "utils/debounce"
import { SwitchFormControl } from "./ui"
import {
  usePlatformWorkspaceContent
} from "../../../../providers/PlatformWorkspaceProvider"

function PlatformContractSettingsPage({
  onBackClick,
  onContractDeleteClick,
  settingsData = {},
  onChange,
}) {
  const { contractSettingsModal } = usePlatformWorkspaceContent()
  const [settings, setSettings] = useState({ ...settingsData })

  const debouncedOnChange = useCallback(
    debounce(onChange, SETTINGS_CHANGE_DEBOUNCE_TIMEOUT),
    [onChange]
  )

  const handleChange = (field, value) => {
    setSettings(prevState => {
      const newSettings = {
        ...prevState,
        [field]: value
      }

      debouncedOnChange(newSettings)

      return newSettings
    })
  }

  return (
    <Layout onBackClick={onBackClick}>
      <VStack align="stretch" spacing={3} mb={6}>
        <SwitchFormControl
          label={contractSettingsModal.emailNotificationsLabel}
          onChange={event => handleChange("isSubscribed", event.target.checked)}
          isChecked={settings.isSubscribed}
        />
      </VStack>

      {onContractDeleteClick ? (
        <DeleteButton onClick={onContractDeleteClick} />
      ) : null}
    </Layout>
  )
}

export default PlatformContractSettingsPage

const SETTINGS_CHANGE_DEBOUNCE_TIMEOUT = 500

const Layout = ({ children, onBackClick }) => {
  const { contractSettingsModal } = usePlatformWorkspaceContent()

  return (
    <Grid className="h-screen" width="full" templateRows="40px auto" px={4} mt="3">
      <Flex width="full" align="center">
        <IconButton
          icon={<IoArrowBackOutline />}
          aria-label="arrow-back"
          onClick={onBackClick}
        />

        <Heading
          as="h3"
          fontSize="xl"
          lineheight="xl"
          fontWeight="semibold"
          display="flex"
          align="center"
          ml="3"
          mb="-1"
        >
          {contractSettingsModal.heading}
        </Heading>
      </Flex>

      <Box mt={3}>{children}</Box>
    </Grid>
  )
}

const DeleteButton = ({ onClick }) => {
  const { contractSettingsModal } = usePlatformWorkspaceContent()

  return (
    <Flex mt={12} justify="flex-end">
      {onClick ? (
        <Button onClick={onClick} variant="textDark" fontWeight="medium">
          {contractSettingsModal.deleteContractButtonLabel}
        </Button>
      ) : null}
    </Flex>
  )
}
