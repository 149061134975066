export type SelectOption = {
  label: string
  value: string
}

export enum WorkspacePageTabs {
  WORKSPACE_SETTINGS = "workspace-settings",
  WORKSPACE_DELETE_CONFIRM = "workspace-delete-confirm",
  CONTRACT_CREATE = "contract-create",
  ARCHIVED_CONTRACTS = "archived-contracts"
}

export enum ContractTypes {
  ARCHIVED = "archived"
}

export type WorkspaceActiveTab = WorkspacePageTabs | string | undefined
