import { API } from "aws-amplify"
import { GetWorkspaceInput, GetWorkspaceResponse } from "./types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class PlatformApiService {
  async getWorkspace(input: GetWorkspaceInput): Promise<GetWorkspaceResponse> {
    const init = await withCognitoAccessToken({ queryStringParameters: input })
    const res = await API.get("platformRestApi", `/workspace`, init)
    return res.data
  }
}

export default PlatformApiService
