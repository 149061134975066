import { Grid } from "@chakra-ui/react"
import PlatformAdminLayout from "modules/Workspace/src/components/layouts/PlatformAdminLayout"
import React from "react"

const Layout = ({ children, renderHeader }) => (
  <PlatformAdminLayout isBgImage showNavbar>
    <Grid
      height="full"
      templateRows="auto repeat(1, minmax(100px, 1fr))"
      rowGap="3"
    >
      {renderHeader()}

      <Grid overflow="hidden">{children}</Grid>
    </Grid>
  </PlatformAdminLayout>
)

export default Layout

export function ContractsBoxLayout({ children }) {
  return (
    <Grid overflowY="scroll" pl="3" pr="1">
      {children}
    </Grid>
  )
}
