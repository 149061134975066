import { Box, Heading, VStack } from "@chakra-ui/react"
import React from "react"
import { TeamItem } from "./index"

const TeamGroup = ({ teamGroup, onTeamEdit }) => (
  <Box>
    <Heading as="h5" fontSize="md" lineHeight="md">
      {teamGroup.label}
    </Heading>

    <VStack mt="3" spacing="3" align="stretch">
      {teamGroup.teams.map(team => (
        <TeamItem key={team.id} team={team} onEdit={onTeamEdit} />
      ))}
    </VStack>
  </Box>
)

export default TeamGroup
