import {
  WorkspacePageTabs
} from "../../../modules/Workspace/src/core/types/global"

export const MENU_LIST = [
  {
    label: "Create contract",
    buttonVariant: "secondary",
    to: "?tab=contract-create"
  },
  {
    label: "Community",
    iconName: "community",
    buttonVariant: "primaryPurple",
    to: "?tab=community"
  },
  {
    label: "Source",
    buttonVariant: "secondary",
    iconName: "source",
    to: "?tab=source"
  },
  // {
  //   label: "CDE",
  //   buttonVariant: "primaryDark",
  //   to: "?tab=cde"
  // },
  {
    label: "Settings",
    buttonVariant: "secondary",
    to: `?tab=${WorkspacePageTabs.WORKSPACE_SETTINGS}`
  }
]
