const popoverTheme = {
  Popover: {
    variants: {
      contractAction: {
        content: {
          mt: "-24px !important",
          border: "1px solid",
          borderColor: "bgSmall",
          rounded: "12px",
          shadow: "popover",
          width: "314px"
        },
        header: {
          pt: "4",
          pb: "3",
          pl: "30px",
          pr: "10",
          fontSize: "sm",
          lineHeight: "sm",
          fontWeight: "medium",
          borderColor: "line"
        },
        closeButton: {
          p: "0",
          minW: "4",
          width: "4",
          height: "4",
          _hover: { bg: "transparent" },
          _active: { bg: "transparent" },
          _focus: { boxShadow: "none" },
          right: "24px",
          top: "16px"
        },
        body: {
          px: "6",
          pt: "6",
          pb: "30px"
        }
      },
      emojiConfig: {
        popper: {
          '&[data-popper-placement^="top"]': {
            mb: "-22px !important",
            ml: "-8 !important"
          },
          '&[data-popper-placement^="bottom"]': {
            mt: "-22px !important",
            ml: "-8 !important"
          }
        },
        content: {
          border: "none",
          bgColor: "#222222",
          rounded: "16px",
          width: "auto",
          maxW: "312px",
          "em-emoji-picker": {
            "--rgb-background": "34 34 34",
            maxW: "100%"
          }
        }
      }
    }
  }
}

export default popoverTheme
