import WorkspaceTeamApiService from "../services/WorkspaceTeamAPIService"
import {
  WorkspaceTeamCreateInput,
  WorkspaceTeamUpdateInput
} from "../types/workspace-team"

class WorkspaceTeamApiRepository {
  constructor(
    private readonly workspaceTeamApiService: WorkspaceTeamApiService
  ) {}

  createWorkspaceTeams(createInputs: WorkspaceTeamCreateInput[]) {
    return this.workspaceTeamApiService.createWorkspaceTeams(createInputs)
  }

  updateWorkspaceTeams(updateInputs: WorkspaceTeamUpdateInput[]) {
    return this.workspaceTeamApiService.updateWorkspaceTeams(updateInputs)
  }

  deleteWorkspaceTeams(teamIds: string[]) {
    return this.workspaceTeamApiService.deleteWorkspaceTeams(teamIds)
  }
}

export default WorkspaceTeamApiRepository
