import { useCreateContract } from "./useCreateContract"
import {
  ContractSourceType,
  EmptyObject,
  IContractNormalized
} from "../types"
import { useGetChildContracts } from "./useGetChildContracts"
import { useUpdateContract } from "./useUpdateContract"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"

export const useChildContracts = (
  activeContract: IContractNormalized | EmptyObject,
  isPlatform = false
) => {
  const navigate = useNavigate()
  const { mutateAsync: createContractAsync } = useCreateContract()
  const { mutateAsync: updateContractAsync} = useUpdateContract()
  const { data: childContracts = [] } = useGetChildContracts(activeContract.pk || "", {
    enabled: !!activeContract.pk,
    keepPreviousData: false
  })

  const handleChildContractCreate = async (subtaskName: string) => {
    const contractPk = uuidv4()

    try {
      await createContractAsync({
        sourceContractPk: activeContract.pk,
        input: {
          pk: contractPk,
          name: subtaskName,
          sourceType: ContractSourceType.CONTRACT,
          sourceContractPk: activeContract.pk
        }
      })
    } catch (error) {
      console.error("Contract create : ", error)
    }
  }

  const handleChildContractCompleteToggle = async (contractId: string) => {
    const contract = childContracts.find(
      contract => contract.id === contractId
    )
    await updateContractAsync({
      sourceContractPk: activeContract.pk,
      input: {
        id: contractId,
        completed: !contract?.completed
      }
    })
  }

  const handleChildContractClick = (contractId: string) => {
    if (!contractId) {
      return
    }

    if (!activeContract.workspaceId) {
      return navigate(`/contract/${contractId}?contractId=${activeContract.id}`)
    }

    if (isPlatform) {
      return navigate(`/contract/${contractId}?workspaceId=${activeContract.workspaceId}&contractPk=${activeContract.pk}&platform=true`)
    }

    return navigate(`/contract/${contractId}?workspaceId=${activeContract.workspaceId}&contractPk=${activeContract.pk}`)
  }

  return {
    handleChildContractCreate,
    handleChildContractCompleteToggle,
    handleChildContractClick,
    childContracts
  }
}
