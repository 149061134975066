import { Box, VStack } from "@chakra-ui/react"
import TeamOptionsGroupAccordion
  from "modules/Workspace/src/components/TeamOptionsGroupAccordion"
import { usePreviousPath } from "providers/PreviousPathProvider"
import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Input } from "../../../../../theme/components"
import Layout from "./Layout"
import {
  usePlatformWorkspaceContent
} from "../../../../../providers/PlatformWorkspaceProvider"
import {
  PLATFORM_TEAM_GROUP_FIELD_MAP
} from "../../../../Workspace/src/core/config"

function PlatformContractDelegatePage({
  teamOptionsGroups,
  onTeamOptionChange,
  onDelegate,
  isLoadingDelegate
}) {
  const navigate = useNavigate()
  const previousPath = usePreviousPath()
  const handleCancel = () => navigate(previousPath || "./")

  const [search, setSearch] = useState("")
  const handleSearchChange = event => setSearch(event.target.value)

  const filteredTeamOptionsGroups = useMemo(() => {
    if (!teamOptionsGroups) {
      return []
    }

    const searchValue = search.trim().toLowerCase()

    if (!searchValue) {
      return teamOptionsGroups
    }

    return teamOptionsGroups.map(teamGroup => {
      const filteredTeamOptions = teamGroup.teamOptions.filter(teamOption =>
        teamOption.name.toLowerCase().includes(searchValue)
      )

      return {
        ...teamGroup,
        teamOptions: filteredTeamOptions
      }
    })
  }, [teamOptionsGroups, search])

  return (
    <Layout onDelegate={onDelegate} onCancel={handleCancel} isLoadingDelegate={isLoadingDelegate}>
      <SearchInput onChange={handleSearchChange} value={search} />

      <TeamOptionsGroups
        teamOptionsGroups={filteredTeamOptionsGroups}
        onTeamOptionChange={onTeamOptionChange}
      />
    </Layout>
  )
}

export default PlatformContractDelegatePage

const TeamOptionsGroups = ({ teamOptionsGroups = [], onTeamOptionChange }) => {
  const { delegateModal } = usePlatformWorkspaceContent()

  return (
    <VStack align="stretch" w="full" maxW="full" spacing="4">
      {teamOptionsGroups.map(teamGroup =>
        teamGroup.teamOptions.length ? (
          <TeamOptionsGroupAccordion
            key={teamGroup.type}
            groupName={delegateModal[PLATFORM_TEAM_GROUP_FIELD_MAP[teamGroup.name]]}
            teamOptions={teamGroup.teamOptions}
            onTeamOptionChange={onTeamOptionChange}
            defaultIndex={0}
          />
        ) : null
      )}
    </VStack>
  )
}

const SearchInput = ({ onChange, value }) => {
  const { delegateModal } = usePlatformWorkspaceContent()

  return (
    <Box pb={6}>
      <Input
        placeholder={delegateModal.searchInputPlaceholder}
        onInput={onChange} value={value} width="full"
      />
    </Box>
  )
}
