import { Workspace } from "modules/Workspace/src//core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { communityUseCase } from "../index"
import { CommunityType, DisconnectWorkspaceOptions } from "../types"

export const useDisconnectCommunityWorkspace = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "disconnectCommunityWorkspace",
    input =>
      communityUseCase.disconnectWorkspace(input.communityType, input.options),
    {
      onMutate: async ({ options }) => {
        const previousWorkspace = queryClient.getQueryData<Workspace>([
          "workspace",
          options.workspaceId
        ])

        return { previousWorkspace }
      },
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }
        const workspaceSkCommunity = data as Workspace

        queryClient.setQueryData<Workspace>(
          ["workspace", variables.options.workspaceId],
          workspace => ({ ...workspace, ...workspaceSkCommunity })
        )

        queryClient.invalidateQueries([
          "workspaceTeams",
          variables.options.workspaceId
        ])
      },
      ...options
    }
  )
}

export type IFnInput = {
  communityType: CommunityType
  options: DisconnectWorkspaceOptions
}

export type Options = Omit<
  UseMutationOptions<
    Workspace | null,
    unknown,
    IFnInput,
    {
      previousWorkspace: Workspace | undefined
    }
  >,
  "mutationKey" | "mutationFn"
>
