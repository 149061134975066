import SourceAPIService from "../services/SourceAPIService"
import {
  ConnectProjectOptions,
  DisconnectProjectOptions,
  SendContractToSourceOptions,
  SourceType,
  UpdateProjectOptions,
  getSourceContractsOptionsType,
  getSourceWorkspaceProjectsOptionsType
} from "../types"

class SourceAPIRepository {
  constructor(private sourceAPIService: SourceAPIService) {}

  getSourceContracts(type: SourceType, options: getSourceContractsOptionsType) {
    return this.sourceAPIService.getSourceContracts(type, options)
  }

  sendContractToSource(
    sourceType: SourceType,
    options: SendContractToSourceOptions
  ) {
    return this.sourceAPIService.sendContractToSource(sourceType, options)
  }

  getSourceWorkspaceProjects(
    type: SourceType,
    options: getSourceWorkspaceProjectsOptionsType
  ) {
    return this.sourceAPIService.getSourceWorkspaceProjects(type, options)
  }

  connectProject(sourceType: SourceType, options: ConnectProjectOptions) {
    return this.sourceAPIService.connectProject(sourceType, options)
  }

  updateProject(sourceType: SourceType, options: UpdateProjectOptions) {
    return this.sourceAPIService.updateProject(sourceType, options)
  }

  disconnectProject(sourceType: SourceType, options: DisconnectProjectOptions) {
    return this.sourceAPIService.disconnectProject(sourceType, options)
  }

  // label
  getLabels(sourceType: SourceType, jiraWorkspaceId: string) {
    return this.sourceAPIService.getLabels(sourceType, jiraWorkspaceId)
  }

  // workspace
  getWorkspaces(sourceType: SourceType) {
    return this.sourceAPIService.getWorkspaces(sourceType)
  }
}

export default SourceAPIRepository
