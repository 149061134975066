import { Grid, Image, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"

function EmptyWorkspace({
  onContractCreateClick,
  isArchivedContractsTab,
  onArchivedContractsBackClick
})
{
  return (
    <Grid px={{ base: "4", md: "0" }} pb={{ base: "3", md: "7" }}>
      <Grid
        justifyContent="center"
        alignItems="center"
        bgColor="rgba(255, 255, 255, 0.4)"
        rounded="12px"
        border="1px solid"
        borderColor="bgSmall"
        p="4"
      >
        <Grid rowGap="4">
          <Image
            src="/assets/images/empty-workspace.png"
            alt="Empty workspace"
          />

          <VStack spacing="4" ml="10%">
            {isArchivedContractsTab ? (
              <ArchiveContractsContent
                onArchivedContractsBackClick={onArchivedContractsBackClick}
              />
            ) : (
              <CreateFirstContractContent
                onContractCreateClick={onContractCreateClick}
              />
            )}
          </VStack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EmptyWorkspace

const ArchiveContractsContent = ({ onArchivedContractsBackClick }) =>
  <Text fontSize="md" lineHeight="md" align="center">
    You don&apos;t have any archived contracts yet. <br />
    Go back to <Button
      onClick={onArchivedContractsBackClick} variant="textDark"
      fontWeight="medium"
      textDecoration="underline"
      fontSize="md" lineHeight="md"
    >
    active contracts
    </Button>
  </Text>

const CreateFirstContractContent = ({ onContractCreateClick }) =>
  <>
    <Text fontSize="md" lineHeight="md">
      Create your first contract
    </Text>

    <Button
      variant="primaryPurple"
      px="6"
      onClick={onContractCreateClick}
      width={{ base: "fit-content", md: "272px" }}
    >
      Create First Contract
    </Button>
  </>

