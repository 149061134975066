import { Box, Flex, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { Button, SettingsAccordion } from "theme/components"

export function GitHub({ account = {}, onGitHubConnect }) {
  const handleGitHubConnect = () => {
    onGitHubConnect && onGitHubConnect()
  }

  const isGitHubConnected = useMemo(
    () => !!account.username,
    [account.username]
  )

  return (
    <SettingsAccordion iconName="github" name="GitHub">
      <Box
        pb={{ base: "4", md: "8" }}
        borderBottom="1px solid"
        borderColor="line"
      >
        <GitHubActionRouter
          action={isGitHubConnected ? "account" : "connect"}
          account={account}
          onGitHubConnect={handleGitHubConnect}
        />
      </Box>
    </SettingsAccordion>
  )
}

const GitHubAccount = ({ account }) => (
  <Flex direction="column" px="2">
    <Text fontSize="md" fontWeight="semibold">
      Account :{" "}
      <Text fontWeight="normal" as="span">
        {account.username}
      </Text>
    </Text>
  </Flex>
)

const ConnectGitHub = ({ onGitHubConnect }) => (
  <Flex direction="column">
    <Text fontWeight="medium">Connect your GitHub account</Text>

    <Button onClick={onGitHubConnect} mt="2" width="fit-content">
      Connect GitHub
    </Button>
  </Flex>
)

function GitHubActionRouter({ action, account, onGitHubConnect }) {
  if (action === "connect") {
    return <ConnectGitHub onGitHubConnect={onGitHubConnect} />
  } else if (action === "account") {
    return <GitHubAccount account={account} />
  }
}
