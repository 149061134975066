import { Grid } from "@chakra-ui/react"
import PlatformAdminLayout from "modules/Workspace/src/components/layouts/PlatformAdminLayout"
import React from "react"

const Layout = ({ children, renderHeader, onContractCreateClick }) => (
  <PlatformAdminLayout isBgImage onCreateClick={onContractCreateClick}>
    {renderHeader()}

    <Grid
      height="calc(100vh - 80px)"
      maxH="calc(100vh - 80px)"
      pl="30px"
      overflow="hidden"
      templateColumns="auto 256px"
      columnGap="6"
    >
      {children}
    </Grid>
  </PlatformAdminLayout>
)

export default Layout

export function ContractsBoxLayout({ children }) {
  return (
    <Grid
      templateColumns="358px minmax(680px, auto)"
      columnGap="3"
      overflowY="scroll"
    >
      {children}
    </Grid>
  )
}
