import { Box, FormControl, FormErrorMessage, VStack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { Button, Input } from "theme/components"

function SignUpForm({ onSignUp, isLoadingSignUp }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: ""
    }
  })

  const handleFormSubmit = values => {
    const email = values.email.trim().toLowerCase()
    const name = values.name.trim()

    const onError = ({ field, message }) => {
      setError(field, {
        type: "custom",
        message: message
      })
    }

    onSignUp(
      {
        name,
        email,
        password: values.password
      },
      { onError }
    )
  }

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)} w="full" mt="6">
      <VStack spacing="5">
        <FormControl isInvalid={errors.name}>
          <Input
            type="text"
            placeholder="Name"
            {...register("name", { required: "Name is required" })}
          />

          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email}>
          <Input
            type="email"
            placeholder="Email"
            {...register("email", { required: "Email is required" })}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.password}>
          <Input
            type="password"
            placeholder="Password"
            {...register("password", { required: "Password is required" })}
          />

          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" w="full" variant="primaryDark"
          isLoading={isLoadingSignUp}>
          Sign Up
        </Button>
      </VStack>
    </Box>
  )
}

export default SignUpForm
