import { CheckIcon, CloseIcon } from "@chakra-ui/icons"
import {
  ButtonGroup,
  Editable,
  EditablePreview,
  EditableTextarea,
  IconButton,
  useEditableControls
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"

function ContractName({ defaultValue, onSubmit, isEditable = true, ...props }) {
  const [value, setValue] = useState(defaultValue)

  const textAreaRef = useRef(null)
  const handleSubmit = value => {
    const name = value.trim()
    if (name !== defaultValue) {
      onSubmit && onSubmit(name)
    }
  }

  const textAreaAdjust = event => {
    if (event.key === "Enter") {
      event.target.blur()
      return
    }

    event.target.style.height = "1px"
    event.target.style.height = event.target.scrollHeight + "px"
  }

  const handleChange = value => {
    setValue(value)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Editable
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      isPreviewFocusable={true}
      onSubmit={handleSubmit}
      onEdit={() => textAreaAdjust({ target: textAreaRef.current })}
      position="relative"
      isDisabled={!isEditable}
      gridArea="name"
      {...props}
      width="full"
    >
      <EditablePreview
        as="h3"
        fontSize="2xl"
        fontWeight="bold"
        _hover={{
          bgColor: "lightBlue"
        }}
        width="full"
        p="1"
        _disabled={{
          bg: "white"
        }}
      />
      <EditableTextarea
        ref={textAreaRef}
        resize="none"
        fontSize="2xl"
        fontWeight="bold"
        onKeyDown={textAreaAdjust}
        p="1"
        _focus={{
          bg: "lightBlue",
          border: "none",
          boxShadow: "none"
        }}
      />
      <ContractNameControls />
    </Editable>
  )
}

export default ContractName

function ContractNameControls() {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
    useEditableControls()

  return isEditing ? (
    <ButtonGroup
      justifyContent="end"
      size="sm"
      w="full"
      position="absolute"
      bottom="-8"
      right="0"
      zIndex={10}
    >
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    </ButtonGroup>
  ) : (
    <></>
  )
}
