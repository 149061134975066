import { CloseIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons"
import { Box, Flex, IconButton, Image, Text } from "@chakra-ui/react"
import FileSaver from "file-saver"
import { useQueryParams } from "hooks"
import { useDeleteContractResource } from "modules/ContractResource/src/hooks"
import { usePreviousPath } from "providers/PreviousPathProvider"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Link as RouterLink } from "react-router-dom"
import { FaFile } from "react-icons/fa"

function ContractResource() {
  const navigate = useNavigate()
  const { queryParams } = useQueryParams()
  const previousPath = usePreviousPath()

  const { mutateAsync: deleteContractResourceAsync } =
    useDeleteContractResource()

  const handleDelete = async () => {
    await deleteContractResourceAsync({
      contractPk: queryParams.contractPk,
      contractResourceId: queryParams.id
    })

    navigate(previousPath)
  }

  const isImageType = useMemo(() => queryParams.type === "image", [queryParams.type])

  return (
    <Layout>
      <Header
        filename={queryParams.name}
        url={queryParams.url}
        onDelete={handleDelete}
        isDeletable={queryParams.isDeletable}
        previousPath={previousPath}
      />

      <Flex width="100%" height="100vh" justify="center" align="center">
        {isImageType ? (
          <Image
            src={queryParams.url}
            alt={name}
            objectFit="contain"
            minH="10%"
            maxH="100%"
            maxW="100%"
          />
        ) :
          <Flex justify="center" align="center" width="100%" height="100vh">
            <Box as={FaFile} size="50%" color="primaryPurple" />
          </Flex>        }
      </Flex>
    </Layout>
  )
}

export default ContractResource

function Layout({ children }) {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="relative"
      bgColor="#E8E6FE"
    >
      {children}
    </Flex>
  )
}

function Header({ filename, url, isDeletable, onDelete, previousPath }) {
  return (
    <Flex
      position="absolute"
      zIndex="3"
      top="0"
      left="0"
      right="0"
      bgGradient="linear(180deg, primaryDark, rgba(0, 0, 0, 0.15))"
      height="64px"
      px={{ base: "4", md: "10" }}
      justify="space-between"
      align="center"
    >
      <Text fontSize="lg" lineHeight="lg" color="white" noOfLines={1}>
        {filename}
      </Text>

      <Flex align="center" ml="2">
        <DownloadButton url={url} filename={filename} />

        {isDeletable ? (
          <ActionIconButton
            icon={<DeleteIcon />}
            aria-label="Delete"
            ml="3"
            onClick={onDelete}
          />
        ) : null}

        <ActionIconButton
          as={RouterLink}
          to={previousPath}
          icon={<CloseIcon />}
          aria-label="Close"
          ml="3"
        />
      </Flex>
    </Flex>
  )
}

const ActionIconButton = ({ icon, ...props }) => {
  return (
    <IconButton
      display="flex"
      justifyContent="center"
      alignItems="center"
      variant="unstyled"
      shadow="none"
      aria-label="Close"
      icon={icon}
      size="sm"
      color="white"
      {...props}
    />
  )
}

const DownloadButton = ({ url, filename }) => {
  const handleDownloadClick = () => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        FileSaver.saveAs(blob, filename)
      })
  }

  return (
    <Box>
      <ActionIconButton
        icon={<DownloadIcon />}
        aria-label="Download"
        onClick={handleDownloadClick}
      />
    </Box>
  )
}
