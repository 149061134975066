import { useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized, IContractNormalizedWithLoading } from "../types"
import { UsePickContractInput, UsePickContractOptions } from "../types/hooks"

export const usePickContract = (options: UsePickContractOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createContract",
    ({ contractId }) => contractUseCase.pickContract(contractId),

    {
      onMutate: async ({ workspaceId = "" }: UsePickContractInput) => {
        const workspaceContractsQueryKey = ["workspaceContracts", workspaceId]
        const previousWorkspaceContracts =
          queryClient.getQueryData<IContractNormalizedWithLoading[]>(
            workspaceContractsQueryKey
          ) || []

        return {
          previousWorkspaceContracts
        }
      },
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }
        const createdContract = data as IContractNormalized

        queryClient.setQueryData<IContractNormalizedWithLoading[]>(
          ["workspaceContracts", variables.workspaceId],
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]
            contracts.push(createdContract)

            return contracts
          }
        )
      },
      ...options
    }
  )
}
