import "./aws-configure"
import Routes from "./pages"
import Providers from "./providers"

export function App() {
  return (
    <Providers>
      <Routes />
    </Providers>
  )
}
