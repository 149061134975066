import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { communityUseCase } from "../index"
import { CommunityType, UpdateWorkspaceOptions } from "../types"

const useUpdateCommunityWorkspace = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "updateCommunityWorkspace",
    input =>
      communityUseCase.updateWorkspace(input.communityType, input.options),
    {
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries([
          "workspaceTeams",
          variables.options.workspaceId
        ])
      },
      ...options
    }
  )
}

export default useUpdateCommunityWorkspace

export type FnInput = {
  communityType: CommunityType
  options: UpdateWorkspaceOptions
}

export type Options = Omit<
  UseMutationOptions<void, unknown, FnInput, unknown>,
  "mutationKey" | "mutationFn"
>
