import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient
} from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized } from "../types"

export const useGetContract = (contractId: string, options: Options = {}) =>
  useQuery<IContractNormalized | null, Error>(
    ["contract", contractId],
    () => contractUseCase.getContract(contractId),
    options
  )

type Options = Omit<
  UseQueryOptions<
    IContractNormalized | null,
    Error,
    IContractNormalized | null,
    QueryKey
  >,
  "queryKey" | "queryFn"
>

export const useGetContractAsync = () => {
  const queryClient = useQueryClient()

  return async (contractId: string) =>
    queryClient.fetchQuery(["contract", contractId], () =>
      contractUseCase.getContract(contractId)
    )
}
