import {
  FindByContractPkInput,
  MessageCRUDRepository as IMessageCRUDRepository,
  Message,
  MessageAppSyncService,
  MessageCreateInput,
  MessageSubscriptionCallbackFunction,
  MessageSubscriptionResponse
} from "../types"

class MessageCRUDRepository implements IMessageCRUDRepository {
  constructor(private readonly _messageAppSyncService: MessageAppSyncService) {}

  async findById(id: string): Promise<Message> {
    return this._messageAppSyncService.findById(id)
  }

  async findByContractPk(input: FindByContractPkInput) {
    return this._messageAppSyncService.findByContractPk(input)
  }

  async findAll(filter = {}): Promise<Message[]> {
    return this._messageAppSyncService.findAll(filter)
  }

  async createOne(input: MessageCreateInput): Promise<Message> {
    return this._messageAppSyncService.createOne(input)
  }

  createMessageSubscription(
    callback: MessageSubscriptionCallbackFunction
  ): MessageSubscriptionResponse {
    return this._messageAppSyncService.createMessageSubscription(callback)
  }
}

export default MessageCRUDRepository
