import React, { useCallback, useEffect, useRef } from "react"
import { Box, Flex, Grid, VStack } from "@chakra-ui/react"
import Header from "./Header"
import { MessageInput } from "./MessageInput"
import { MessageItem } from "./MessageItem"
import {
  useContractChat
} from "../../../../Message/src/core/hooks/useContractChat"

export function ContractChat({
  onBackClick,
  heading,
  placeholder,
  user,
  contract,
  ...props
}) {
  const {
    contractMessages,
    handleMessageCreate
  } = useContractChat(user, contract)

  const endOfMessagesRef = useRef(null)

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView()
  }

  useEffect(() => {
    scrollToBottom()
  }, [contractMessages])


  const loadOlderMessages = () => {
    // Logic to use nextToken and fetch older messages
    // This could involve calling a function from useContractChat
    // that triggers fetching older messages using the current nextToken
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]
        if (firstEntry.isIntersecting) {
          loadOlderMessages()
        }
      },
      { rootMargin: "100px" }
    )

    const topElement = document.getElementById("top-of-messages")
    if (topElement) {
      observer.observe(topElement)
    }

    return () => {
      if (topElement) {
        observer.unobserve(topElement)
      }
    }
  }, [loadOlderMessages])


  const renderHeader = useCallback(
    () => (
      <Header
        onBackClick={onBackClick}
        title={heading}
      />
    ),
    []
  )

  return (
    <Layout renderHeader={renderHeader} {...props}>
      <VStack
        overflowY="scroll"
        width="full" alignItems="normal"
        spacing="4"
        px={{ base: 4, md: "30px" }}
        flexDirection="column-reverse"
      >
        <Box ref={endOfMessagesRef} mt="6" />

        {contractMessages.map(message => (
          <MessageItem key={message.id} message={message} />
        ))}
      </VStack>

      <Flex
        px={{ base: 4, md: "30px" }}
        height="64px"
        width="full"
        align="center"
      >
        <MessageInput placeholder={placeholder}
          onSubmit={handleMessageCreate} />
      </Flex>
    </Layout>
  )
}

function Layout({ renderHeader, children, ...props }) {
  return (
    <Box
      roundedTop={{ base: "0", md: "lg" }}
      bgColor="white"
      overflowY="scroll"
      height="full"
      {...props}
    >
      {renderHeader && renderHeader()}

      <Grid
        direction="column"
        overflowY="scroll"
        templateRows="1fr 64px"
        maxHeight="full"
        height={{
          base: "calc(100% - 40px)",
          md: "calc(100% - 76px)"
        }}
      >
        {children}
      </Grid>
    </Box>
  )
}
