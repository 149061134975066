const stylesTheme = {
  styles: {
    global: {
      body: {
        letterSpacing: "-0.3px",
        color: "primaryDark",
        fontWeight: "medium"
      },
      ".h-screen": {
        height: "100vh",
        "@supports (height: 100dvh)": {
          height: "100dvh"
        }
      }
    }
  }
}

export default stylesTheme
