import PlatformConfigCRUDRepository from "./repositories/PlatformConfigCRUDRepository"
import PlatformConfigAppSyncService from "./services/PlatformConfigAppSyncService"
import PlatformConfigUseCase from "./useCases/PlatformConfigUseCase"
import PlatformApiRepository from "./repositories/PlatformApiRepository"
import PlatformApiService from "./services/PlatformApiService"
import PlatformApiUseCase from "./useCases/PlatformApiUseCase"
import PlatformWorkspaceConfigUseCase from "./useCases/PlatformWorkspaceConfigUseCase"
import PlatformWorkspaceConfigCRUDRepository from "./repositories/PlatformWorkspaceConfigCRUDRepository"
import PlatformWorkspaceConfigAppSyncService from "./services/PlatformWorkspaceConfigAppSyncService"

export const platformConfigUseCase = new PlatformConfigUseCase(
  new PlatformConfigCRUDRepository(new PlatformConfigAppSyncService())
)

export const platformApiUseCase = new PlatformApiUseCase(
  new PlatformApiRepository(new PlatformApiService())
)

export const platformWorkspaceConfigUseCase = new PlatformWorkspaceConfigUseCase(
  new PlatformWorkspaceConfigCRUDRepository(new PlatformWorkspaceConfigAppSyncService())
)

