import { Box, Image } from "@chakra-ui/react"
import IcomoonReact from "react-icomoon"
import iconSet from "./selection.json"

export function IcomoonIcon({
  name,
  color = "#4c4c4c",
  size = "32",
  ...props
}) {
  return (
    <Box
      as={IcomoonReact}
      iconSet={iconSet}
      size={size}
      icon={name}
      color={color}
      {...props}
    />
  )
}

export function Icon({ name, size = "auto", width, height, ...props }) {
  return (
    <Image
      src={`/assets/icons/${name}.svg`}
      width={width || size}
      height={height || size}
      maxW={width || size}
      maxH={height || size}
      {...props}
    />
  )
}
