import { PlatformWorkspaceConfig } from "modules/Platform/src/core/types"
import { createContext, useContext } from "react"
import { Outlet } from "react-router-dom"
import { useAdminPlatformConfig } from "../modules/Platform/src/hooks"

type AdminPlatformConfigContext = {
  platformConfig: undefined | PlatformWorkspaceConfig
  adminWorkspaceId: undefined | string
}

export const AdminPlatformConfigContext =
  createContext<AdminPlatformConfigContext>({
    platformConfig: undefined,
    adminWorkspaceId: undefined,
  })

function AdminPlatformConfigProvider() {
  const { platformConfig, adminWorkspaceId } = useAdminPlatformConfig()

  return (
    <AdminPlatformConfigContext.Provider value={{ platformConfig, adminWorkspaceId }}>
      <Outlet />
    </AdminPlatformConfigContext.Provider>
  )
}

export default AdminPlatformConfigProvider

export const useAdminPlatformConfigState = (): AdminPlatformConfigContext => {
  const context = useContext(AdminPlatformConfigContext)

  if (!context) {
    throw new Error(
      "useAdminPlatformConfigState must be used within a AdminPlatformConfigProvider"
    )
  }

  return context
}
