export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      ownerId
      contractId
      name
      jiraProjects {
        id
        name
        workspaceId
        workspaceName
        labels
      }
      jiraProjectIds
      discordChannels {
        id
        name
        guildId
        guildName
      }
      discordChannelIds
      discordGuilds {
        id
        name
      }
      discordCosmoChannels {
        id
        guildId
      }
      slackChannels {
        id
        name
        workspaceId
        workspaceName
      }
      slackChannelIds
      slackWorkspaces {
        id
        name
      }
      slackCosmoChannels {
        id
        workspaceId
      }
      platformWorkspaceConfigId
      defaultTeamEmoji {
        emoji
        backgroundColor
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      ownerId
      contractId
      name
      jiraProjects {
        id
        name
        workspaceId
        workspaceName
        labels
      }
      jiraProjectIds
      discordChannels {
        id
        name
        guildId
        guildName
      }
      discordChannelIds
      discordGuilds {
        id
        name
      }
      discordCosmoChannels {
        id
        guildId
      }
      slackChannels {
        id
        name
        workspaceId
        workspaceName
      }
      slackChannelIds
      slackWorkspaces {
        id
        name
      }
      slackCosmoChannels {
        id
        workspaceId
      }
      platformWorkspaceConfigId
      defaultTeamEmoji {
        emoji
        backgroundColor
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`
export const createWorkspaceTeam = /* GraphQL */ `
  mutation CreateWorkspaceTeam(
    $input: CreateWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    createWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspaceId
      ownerId
      communityWorkspaceId
      communityType
      teamId
      name
      surname
      type
      emoji
      emojiBgColor
      createdAt
      updatedAt
    }
  }
`
export const updateWorkspaceTeam = /* GraphQL */ `
  mutation UpdateWorkspaceTeam(
    $input: UpdateWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    updateWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspaceId
      ownerId
      communityWorkspaceId
      communityType
      teamId
      name
      surname
      type
      emoji
      emojiBgColor
      createdAt
      updatedAt
    }
  }
`
export const deleteWorkspaceTeam = /* GraphQL */ `
  mutation DeleteWorkspaceTeam(
    $input: DeleteWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    deleteWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspaceId
      ownerId
      communityWorkspaceId
      communityType
      teamId
      name
      surname
      type
      emoji
      emojiBgColor
      createdAt
      updatedAt
    }
  }
`
