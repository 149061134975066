export type GetContractsByPkInput = {
  pk: string
  createdAt?: ModelStringKeyConditionInput
  sortDirection?: ModelSortDirection
  filter?: ModelContractFilterInput
  limit?: number
  nextToken?: string
}

export type GetContractsBySourceContractPkInput = {
  sourceContractPk: string
  createdAt?: ModelStringKeyConditionInput
  sortDirection?: ModelSortDirection
  filter?: ModelContractFilterInput
  limit?: number
  nextToken?: string
}

export type ModelContractFilterInput =  {
  allMessagesSync?: ModelBooleanInput
  and?: ModelContractFilterInput[]
  completed?: ModelBooleanInput
  createdAt?: ModelStringInput
  deletedAt?: ModelStringInput
  description?: ModelStringInput
  id?: ModelIDInput
  isSubscribed?: ModelBooleanInput
  jiraIssueId?: ModelStringInput
  name?: ModelStringInput
  not?: ModelContractFilterInput
  or?: ModelContractFilterInput[]
  ownerId?: ModelStringInput
  pk?: ModelStringInput
  pointEstimation?: ModelIntInput
  predefinedTeamIds?: ModelStringInput
  reporterId?: ModelStringInput
  sk?: ModelStringInput
  skIds?: ModelStringInput
  sourceType?: ModelContractSourceTypeInput
  updatedAt?: ModelStringInput
  workspaceId?: ModelStringInput
}

export type ModelIDInput = {
  ne?: string
  eq?: string
  le?: string
  lt?: string
  ge?: string
  gt?: string
  contains?: string
  notContains?: string
  between?: [string]
  beginsWith?: string
  attributeExists?: boolean
  attributeType?: ModelAttribute
  size?: ModelSizeInput
}

enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}


export enum ModelAttribute {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number
  eq?: number
  le?: number
  lt?: number
  ge?: number
  gt?: number
  between?: [number]
}

export type ModelStringInput = {
  ne?: string
  eq?: string
  le?: string
  lt?: string
  ge?: string
  gt?: string
  contains?: string
  notContains?: string
  between?: [string]
  beginsWith?: string
  attributeExists?: boolean
  attributeType?: ModelAttribute
  size?: ModelSizeInput
}

export type ModelIntInput = {
  ne?: number
  eq?: number
  le?: number
  lt?: number
  ge?: number
  gt?: number
  between?: [number]
  attributeExists?: boolean
  attributeType?: ModelAttribute
}

type ModelContractSourceTypeInput = {
  eq?: ContractSourceType
  ne?: ContractSourceType
}

export enum ContractSourceType {
  JIRA = "JIRA",
  DISCORD = "DISCORD",
  REPORTER = "REPORTER",
  COSMO_WORKSPACE = "COSMO_WORKSPACE",
  COSMO_HOMEPAGE = "COSMO_HOMEPAGE"
}

export type ModelBooleanInput = {
  ne?: boolean
  eq?: boolean
  attributeExists?: boolean
  attributeType?: ModelAttribute
}

export type ModelStringKeyConditionInput = {
  eq?: string
  le?: string
  lt?: string
  ge?: string
  gt?: string
  between?: [string]
  beginsWith?: string
}
