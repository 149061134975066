import { Box, Flex, Grid, Heading, VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import {
  Button,
  ConfigurableSelect,
  FormControl,
  IconButton
} from "theme/components"
import debounce from "utils/debounce"
import { SwitchFormControl } from "./ui"

function ContractSettingsPage({
  onBackClick,
  onContractDeleteClick,
  settingsData = {},
  onChange,
  onAddPredefinedTeamClick
}) {
  const [settings, setSettings] = useState({ ...settingsData })

  const debouncedOnChange = useCallback(
    debounce(onChange, SETTINGS_CHANGE_DEBOUNCE_TIMEOUT),
    [onChange]
  )

  const handleChange = (field, value) => {
    setSettings(prevState => {
      const newSettings = {
        ...prevState,
        [field]: value
      }

      debouncedOnChange(newSettings)

      return newSettings
    })
  }

  return (
    <Layout onBackClick={onBackClick}>
      <VStack align="stretch" spacing={3} mb={6}>
        {settings.showAllMessagesSync ? (
          <SwitchFormControl
            label="Get all community messages"
            onChange={event =>
              handleChange("allMessagesSync", event.target.checked)
            }
            isChecked={settings.allMessagesSync}
          />
        ) : null}

        <SwitchFormControl
          label="Receive notifications via Email"
          onChange={event => handleChange("isSubscribed", event.target.checked)}
          isChecked={settings.isSubscribed}
        />
      </VStack>

      <AddTeamAction
        options={settingsData.predefinedTeamOptions}
        onAddClick={onAddPredefinedTeamClick}
      />

      <DeleteButton onClick={onContractDeleteClick} />
    </Layout>
  )
}

export default ContractSettingsPage

const SETTINGS_CHANGE_DEBOUNCE_TIMEOUT = 500

const Layout = ({ children, onBackClick }) => (
  <Grid
    className="h-screen"
    width="full"
    templateRows="40px auto"
    px={4}
    pt="3"
  >
    <Flex width="full" align="center" >
      <IconButton
        icon={<IoArrowBackOutline />}
        aria-label="arrow-back"
        onClick={onBackClick}
      />

      <Heading
        as="h3"
        fontSize="xl"
        lineheight="xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        ml="3"
        mb="-1"
      >
        Contract settings
      </Heading>
    </Flex>

    <Box mt={3}>{children}</Box>
  </Grid>
)

const DeleteButton = ({ onClick }) => (
  <Flex mt={12} justify="flex-end">
    <Button onClick={onClick} variant="textDark" fontWeight="medium">
      Delete contract
    </Button>
  </Flex>
)

const AddTeamAction = ({ options = [], onAddClick }) => {
  const [team, setTeam] = useState(null)

  const handleAdd = () => {
    const onSuccess = () => {
      setTeam(null)
    }

    team &&
      onAddClick &&
      onAddClick(team.value, {
        onSuccess
      })
  }

  return (
    <Flex align="end" justify="space-between">
      <FormControl label="Add predefined team">
        <ConfigurableSelect
          width="full"
          maxWidth="320px"
          placeholder="Select team"
          options={options}
          onChange={option => setTeam(option)}
          value={team}
        />
      </FormControl>

      <Button onClick={handleAdd} isDisabled={!team} px={5} ml={6}>
        Add
      </Button>
    </Flex>
  )
}
