import { Box, Flex, Grid, Input, Text, VStack } from "@chakra-ui/react"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import { Button, Checkbox, IcomoonIcon, IconButton } from "theme/components"
import { ContractSectionLayout } from "./index"

export function ContractSubtasksSection({
  heading,
  onCreate,
  onCompleteToggle,
  contracts,
  onContractClick
}) {
  const [isCreating, setIsCreating] = useState(false)
  const [defaultContractName, setDefaultContractName] = useState("")

  const handleCreateSubtask = name => {
    const normalized = name.trim()
    setDefaultContractName("")
    setIsCreating(false)

    if (normalized) {
      onCreate?.(name)
    }
  }

  const handleCompleteToggle = contractId => {
    onCompleteToggle?.(contractId)
  }

  const handleCancelCreate = contractName => {
    setIsCreating(false)
    setDefaultContractName(contractName)
  }

  return (
    <ContractSectionLayout
      heading={heading || "Child Contracts"}
      iconName="docs"
    >
      <VStack width="full" spacing="14px">
        {contracts?.map(contract => (
          <ContractCard
            key={contract.id}
            contract={contract}
            toggleCompleted={id => handleCompleteToggle(id)}
            onClick={() => onContractClick?.(contract.id)}
          />
        ))}

        {isCreating ? (
          <CreateSubtaskForm
            onSubmit={handleCreateSubtask}
            onCancel={handleCancelCreate}
            defaultValue={defaultContractName}
          />
        ) : (
          <CreateSubtaskButton onClick={() => setIsCreating(true)} />
        )}
      </VStack>
    </ContractSectionLayout>
  )
}

function CreateSubtaskButton({ onClick }) {
  return (
    <Button
      variant="outlineDark"
      width="full"
      maxWidth="full"
      onClick={onClick}
      height="54px"
      fontWeight="semibold"
    >
      <Grid
        placeItems="center"
        height="5"
        width="5"
        bgColor="primaryDark"
        rounded="full"
        mr="3"
      >
        <IcomoonIcon name="plus-8" color="white" size="10" />
      </Grid>

      <Text as="span" mb="-0.5">
        Create child
      </Text>
    </Button>
  )
}

function CreateSubtaskForm({ defaultValue, onSubmit, onCancel }) {
  const cardLayoutRef = useRef()
  const inputRef = useRef()
  const [value, setValue] = useState(defaultValue)

  const handleSubmit = event => {
    event.preventDefault()
    onSubmit?.(inputRef.current.value)
  }

  const handleMouseDown = (event) => {
    if (cardLayoutRef.current && !cardLayoutRef.current.contains(event.target)) {
      onCancel?.(inputRef.current.value)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }

    document.addEventListener("mousedown", handleMouseDown)
    document.addEventListener("touchstart", handleMouseDown)

    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
      document.removeEventListener("touchstart", handleMouseDown)
    }
  }, [])

  return (
    <CardLayout ref={cardLayoutRef} height="54px" py={0}>
      <Box
        display="flex"
        width="full"
        alignItems="center"
        as="form"
        onSubmit={handleSubmit}
      >
        <Input
          ref={inputRef}
          value={value}
          onChange={event => setValue(event.target.value)}
          variant="text"
        />

        <IconButton
          display={value ? "inline-flex" : "none"}
          type="submit"
          icon={<IcomoonIcon name="plus" size="16" />}
          justifySelf="end"
          size="sm"
          mr="-0.5"
          ml="4"
        />
      </Box>
    </CardLayout>
  )
}

function ContractCard({ contract, toggleCompleted, onClick }) {
  const handleCompleteChange = event => {
    event.stopPropagation()
    event.preventDefault()

    toggleCompleted && toggleCompleted(contract.id)
  }

  return (
    <CardLayout onClick={onClick}>
      <Flex align="center" justify="space-between">
        <Text
          fontSize="sm"
          lineHeight="sm"
          fontWeight="medium"
          overflowWrap="anywhere"
          color="primaryDark"
          noOfLines={2}
        >
          {contract.name}
        </Text>

        <Flex
          display="inline-flex"
          data-element="complete-checkbox"
          onClick={handleCompleteChange}
          height="full"
          ml="4"
          align="start"
        >
          <Checkbox
            ml="auto"
            isChecked={contract.completed}
            onChange={handleCompleteChange}
          />
        </Flex>
      </Flex>
    </CardLayout>
  )
}

const CardLayout = forwardRef(({ children, onClick, ...props }, ref) => {
  return (
    <Grid
      ref={ref}
      width="full"
      py="4"
      px="5"
      height="auto"
      bgColor="white"
      border="1px solid"
      borderColor="line"
      rounded="12px"
      cursor="pointer"
      overflow="hidden"
      alignItems="center"
      onClick={onClick}
      {...props}
    >
      {children}
    </Grid>
  )
})
CardLayout.displayName = "CardLayout"
