import { Link, Text } from "@chakra-ui/react"
import {
  useConfirmSignUp,
  useSignIn,
  useSignUp
} from "modules/User/src/core/hooks"
import React, { useMemo, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { AuthLayout } from "theme/components"
import ConfirmationForm from "./ConfirmationForm"
import SignUpForm from "./SignUpForm"

function SignUp() {
  const [signUpStep, setSignUpStep] = useState("signUp")
  const [signUpFormData, setSignUpFormData] = useState({})
  const { mutateAsync: signUpAsync, isLoading: isLoadingSignUp } = useSignUp()
  const {
    mutateAsync: confirmSignUpAsync,
    isLoading: isLoadingConfirmSignUp
  } = useConfirmSignUp()
  const { mutateAsync: signInAsync } = useSignIn()
  const headerText = useMemo(
    () =>
      signUpStep === "signUp" ? "Create an account" : "Confirm your email",
    [signUpStep]
  )

  const handleSignUp = async (values, { onError }) => {
    try {
      await signUpAsync(values)
      setSignUpFormData(values)
      setSignUpStep("confirmation")
    } catch (error) {
      if (!error?.code) {
        return
      }

      const field =
        error.code === "EMAIL_EXISTS" || error.code === "EMAIL_NOT_FOUND"
          ? "email"
          : "password"
      onError({
        field,
        message: error.message
      })
    }
  }

  const handleConfirm = async (code, { onError }) => {
    try {
      await confirmSignUpAsync({
        email: signUpFormData.email,
        code
      })
      await signInAsync({
        email: signUpFormData.email,
        password: signUpFormData.password
      })
    } catch (error) {
      if (!error?.code) {
        return
      }
      onError(error.message)
    }
  }

  return (
    <AuthLayout headerText={headerText}>
      <SignUpRouter
        step={signUpStep}
        handleSignUp={handleSignUp}
        isLoadingSignUp={isLoadingSignUp}
        handleConfirm={handleConfirm}
        isLoadingConfirm={isLoadingConfirmSignUp}
      />
    </AuthLayout>
  )
}

export default SignUp

function SignUpRouter({
  step = "signUp",
  handleSignUp,
  isLoadingSignUp,
  handleConfirm,
  isLoadingConfirm
}) {
  if (step === "signUp") {
    return (
      <>
        <SignUpForm
          onSignUp={handleSignUp}
          isLoadingSignUp={isLoadingSignUp}
        />

        <Text fontSize="md" align="center" mt="6">
          Already have an account?{" "}
          <Link as={RouterLink} fontWeight="bold" to="/sign-in">
            Log in
          </Link>
        </Text>
      </>
    )
  } else if (step === "confirmation") {
    return <ConfirmationForm
      onConfirm={handleConfirm}
      isLoadingConfirm={isLoadingConfirm}
    />
  }
}
