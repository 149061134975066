import { Box, ButtonGroup, Flex, Grid, Heading } from "@chakra-ui/react"
import React from "react"
import { Button } from "theme/components"
import {
  usePlatformWorkspaceContent
} from "../../../../../providers/PlatformWorkspaceProvider"

const Layout = ({ children, onCancel, onDelegate, isLoadingDelegate }) => (
  <Grid className="h-screen" templateRows="auto 1fr auto">
    <Header />

    <Box px="4" pb="8" overflowY="auto">
      {children}
    </Box>

    <Footer onCancel={onCancel} onDelegate={onDelegate}
      isLoadingDelegate={isLoadingDelegate} />
  </Grid>
)

export default Layout

const Header = () => {
  const { delegateModal } = usePlatformWorkspaceContent()

  return (
    <Box p="3" height="72px">
      <Heading as="h4" fontSize="2xl" lineHeight="2xl" fontWeight="bold">
        {delegateModal.heading}
      </Heading>
    </Box>
  )
}

const Footer = ({ onDelegate, onCancel, isLoadingDelegate }) => {
  const { delegateModal } = usePlatformWorkspaceContent()

  return (
    <Flex
      height="56px"
      shadow="0px -6px 20px rgba(0, 0, 0, 0.05)"
      align="center"
      px="4"
    >
      <ButtonGroup spacing="8" width="full" justifyContent="end">
        <Button onClick={onCancel} variant="textDark" color="#666666">
          {delegateModal.cancelButtonLabel}
        </Button>

        <Button onClick={onDelegate} isLoading={isLoadingDelegate}>
          {delegateModal.addButtonLabel}
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
