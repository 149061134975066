import { IApiResponse } from "global-types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { workspaceUseCase } from "../index"
import { Workspace } from "../types"

const useSoftDeleteWorkspace = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "softDeleteWorkspace",
    workspaceId => workspaceUseCase.softDeleteWorkspace(workspaceId),
    {
      onSuccess: (data, workspaceId) => {
        queryClient.setQueryData<Workspace[]>(
          "userWorkspaces",
          oldWorkspaces => {
            const workspaces = [...(oldWorkspaces || [])]
            const workspaceIndex = workspaces.findIndex(
              workspace => workspace.id === workspaceId
            )

            if (workspaceIndex > -1) {
              workspaces.splice(workspaceIndex, 1)
            }

            return workspaces
          }
        )
      },
      ...options
    }
  )
}

export default useSoftDeleteWorkspace

type Options = Omit<
  UseMutationOptions<IApiResponse, Error, string>,
  "mutationFn"
>
