import { Flex, Text } from "@chakra-ui/react"
import TeamEmoji from "modules/Workspace/src/components/TeamEmoji"
import React from "react"
import { Button, Checkbox } from "theme/components"
import TeamEmojiPicker from "../TeamEmojiPicker"

function TeamOption({ team, onChange, isEmojiEditable }) {
  const handleChange = changeObj => {
    onChange &&
      onChange({
        ...team,
        ...changeObj
      })
  }

  const handleEmojiSelect = emojiData => {
    handleChange({ emoji: emojiData.native })
  }

  const handleCheckboxChange = event => {
    handleChange({ isSelected: event.target.checked })
  }

  const handleEmojiBgColorChange = bgColor => {
    handleChange({ emojiBgColor: bgColor })
  }

  const handlePrivateToggle = () => {
    if (!(team.isEditable ?? true)) {
      return
    }

    handleChange({ isPrivate: !team.isPrivate })
  }

  return (
    <Flex align="center" justify="start" height="36px" pl="4">
      <Checkbox
        isChecked={team.isSelected}
        onChange={handleCheckboxChange}
        isDisabled={!(team.isEditable ?? true)}
      />

      {isEmojiEditable ? (
        <TeamEmojiPicker
          team={team}
          onEmojiSelect={handleEmojiSelect}
          onEmojiBgColorChange={handleEmojiBgColorChange}
        />
      ) : (
        <TeamEmoji
          emoji={team.emoji}
          bgColor={team.emojiBgColor}
          size="md"
          ml="3"
        />
      )}

      <Text
        fontSize="md"
        lineHeight="md"
        fontWeight="medium"
        mb="-0.5"
        ml="3"
        noOfLines={1}
      >
        {team.name}
      </Text>

      {team.showIsPrivate ? (
        <Button
          variant="icon"
          size="icon-md"
          shadow="none"
          ml="auto"
          onClick={handlePrivateToggle}
          cursor={team.isEditable ? "pointer" : "default"}
        >
          {team.isPrivate ? "🔒" : "🔓"}
        </Button>
      ) : null}
    </Flex>
  )
}

export default TeamOption
