import { Flex, Heading } from "@chakra-ui/react"
import React from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import { IconButton } from "theme/components"

function Header({
  onBackClick,
  title
}) {
  return (
    <Layout>
      <BackButton onClick={onBackClick} />

      <Heading fontSize="xl" mb="-1">{title || "Contract chat"}</Heading>
    </Layout>
  )
}

export default Header

const Layout = ({ children }) => (
  <Flex
    width="100%"
    shrink="0"
    direction="row"
    align="center"
    px={{ base: "4", md: "30px" }}
    height={{
      base: "40px",
      md: "76px"
    }}
  >
    {children}
  </Flex>
)

const BackButton = ({ onClick }) => (
  <IconButton
    icon={<IoArrowBackOutline />}
    aria-label="arrow-back"
    onClick={onClick}
    mr="3"
  />
)

