import { ButtonGroup, Flex } from "@chakra-ui/react"
import React, { useState } from "react"
import {
  Button,
  FormControl,
  Select,
  SettingsAccordion
} from "theme/components"
import { SlackButton } from "../index"

function Slack({
  onAccordionChange,
  onAddWorkspace,
  onWorkspaceConnect,
  onBotConnect,
  workspaceOptions,
  isSlackConnected
}) {
  const handleAccordionChange = index => {
    const isOpen = index === 0
    onAccordionChange && onAccordionChange(isOpen)
  }

  return (
    <SettingsAccordion
      iconName="slack"
      name="Slack"
      onChange={handleAccordionChange}
    >
      {isSlackConnected ? (
        <ConnectSlackWorkspaceForm
          onAddWorkspace={onAddWorkspace}
          workspaceOptions={workspaceOptions}
        />
      ) : null}

      <ConnectSlackActions
        onWorkspaceConnect={onWorkspaceConnect}
        onBotConnect={onBotConnect}
      />
    </SettingsAccordion>
  )
}

export default Slack

function ConnectSlackActions({ onWorkspaceConnect, onBotConnect }) {
  return (
    <ButtonGroup spacing="4">
      <SlackButton onClick={onWorkspaceConnect}>Connect workspace</SlackButton>

      <SlackButton onClick={onBotConnect}>Connect bot</SlackButton>
    </ButtonGroup>
  )
}

function ConnectSlackWorkspaceForm({ onAddWorkspace, workspaceOptions = [] }) {
  const [isLoadingConnect, setIsLoadingConnect] = useState(false)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("")
  const handleAddWorkspace = event => {
    event.preventDefault()
    setIsLoadingConnect(true)

    const onSuccess = () => {
      setIsLoadingConnect(false)
      setSelectedWorkspaceId("")
    }

    onAddWorkspace(selectedWorkspaceId, { onSuccess })
  }

  const handleWorkspaceChange = event => {
    const workspaceId = event.target.value
    setSelectedWorkspaceId(workspaceId)
  }

  return (
    <Flex pb="4" direction={{ base: "column", md: "row" }}>
      <FormControl label="Slack workspaces" maxW="full">
        <Select
          value={selectedWorkspaceId}
          placeholder="Slack workspace"
          onChange={handleWorkspaceChange}
          options={workspaceOptions}
          flexShrink="0"
        />
      </FormControl>

      <ConnectSlackButton
        isDisabled={!selectedWorkspaceId}
        isLoading={isLoadingConnect}
        onClick={handleAddWorkspace}
      />
    </Flex>
  )
}

function ConnectSlackButton({ onClick, isLoading, isDisabled }) {
  return (
    <Button
      variant="primaryPurple"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      width="fit-content"
      px="10"
      mt="4"
      ml={{ base: "0", md: "6" }}
      alignSelf={{ base: "flex-end", md: "auto" }}
    >
      Connect
    </Button>
  )
}
