import { ReactNode } from "react"
import { RouterProvider } from "../pages"
import BreakpointProvider from "./BreakpointProvider"
import ChakraProvider from "./ChakraProvider"
import PreviousPathProvider from "./PreviousPathProvider"
import QueryProvider from "./QueryProvider"

function Providers({ children }: { children: ReactNode | ReactNode[] }) {
  return (
    <QueryProvider>
      <ChakraProvider>
        <BreakpointProvider>
          <RouterProvider>
            <PreviousPathProvider>{children}</PreviousPathProvider>
          </RouterProvider>
        </BreakpointProvider>
      </ChakraProvider>
    </QueryProvider>
  )
}

export default Providers
