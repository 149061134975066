import {
  ContractChat,
  ContractDelegatePage,
  ContractDetails,
  ContractSettingsPage
} from "modules/Contract/src/components"
import { useContract } from "modules/Contract/src/core/hooks"
import React, { useMemo } from "react"
import Layout from "./Layout"

function Contract() {
  const contractId = useMemo(
    () => location.pathname.split("/").pop() || "",
    [location.pathname]
  )

  const {
    contractDetailsProps,
    contractDelegateProps,
    contractSettingsProps,
    isMobile,
    activeTab,
    isPlatform,
    handleChatBackClick,
    user
  } = useContract(contractId)

  if (!contractDetailsProps.contract) {
    return <Layout isPlatform/>
  }

  if (activeTab === "contract-delegate" && isMobile) {
    return <ContractDelegatePage {...contractDelegateProps}
      isPlatform={isPlatform} />
  }

  if (activeTab === "contract-settings" && isMobile) {
    return <ContractSettingsPage {...contractSettingsProps}
      isPlatform={isPlatform} />
  }

  return (
    <Layout isPlatform={isPlatform}>
      {activeTab === "chat" ? (
        <ContractChat
          contract={contractDetailsProps.contract}
          user={user}
          onBackClick={handleChatBackClick}
        />
      ) : (
        <ContractDetails {...contractDetailsProps}  px={{ base: "4", md: "0" }}/>
      )}
    </Layout>
  )
}

export default Contract
