import { Workspace } from "modules/Workspace/src//core/types"
import { useMutation, useQueryClient } from "react-query"
import { sourceUseCase } from "../index"
import { UseUpdateSourceProjectOptions } from "../types/hooks"

const useUpdateSourceProject = (
  options: UseUpdateSourceProjectOptions = {}
) => {
  const queryClient = useQueryClient()

  return useMutation(
    "updateSourceProject",
    input => sourceUseCase.updateProject(input.sourceType, input.options),
    {
      onMutate: async ({ options }) => {
        const workspaceKey = ["workspace", options.workspaceId]
        await queryClient.cancelQueries(workspaceKey)
        const previousWorkspace =
          queryClient.getQueryData<Workspace>(workspaceKey)

        return { previousWorkspace }
      },
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }
        const workspaceSkSource = data as Workspace

        queryClient.setQueryData<Workspace>(
          ["workspace", variables.options.workspaceId],
          workspace => ({ ...workspace, ...workspaceSkSource })
        )
      },
      ...options
    }
  )
}

export { useUpdateSourceProject }
