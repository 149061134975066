import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure
} from "@chakra-ui/react"
import { useIsMobile } from "providers/BreakpointProvider"
import React, { forwardRef, useMemo } from "react"
import { Button, IconButton } from "theme/components"

export function ActionLayout({ children, onClose, onOpen, ...props }) {
  const isMobile = useIsMobile()

  if (isMobile) {
    const {
      isOpen,
      onClose: onDrawerClose,
      onOpen: onDrawerOpen,
      onToggle
    } = useDisclosure()
    const [actionTriggerButton, actionForm] = useMemo(() => {
      const [actionTriggerButton, actionForm] = React.Children.toArray(children)
      return [actionTriggerButton, actionForm]
    }, [children])

    const handleClose = () => {
      onDrawerClose()
      onClose && props.onClose()
    }

    const handleOpen = () => {
      onDrawerOpen()
      onOpen && props.onOpen()
    }

    return (
      <>
        {React.cloneElement(actionTriggerButton, { onClick: onToggle })}

        <ActionDrawerLayout
          isOpen={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          {...props}
        >
          {actionForm}
        </ActionDrawerLayout>
      </>
    )
  }

  return <ActionPopoverLayout {...props}>{children}</ActionPopoverLayout>
}

function ActionDrawerLayout({ children, isOpen = false, onClose, ...props }) {
  return (
    <Drawer
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}
      variant="contractAction"
      {...props}
    >
      <DrawerOverlay />

      {children}
    </Drawer>
  )
}

function ActionPopoverLayout({ children, ...props }) {
  return (
    <Popover placement="bottom-start" variant="contractAction" {...props}>
      {children}
    </Popover>
  )
}

export function ActionForm({ children, ...props }) {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <ActionDrawer {...props}>{children}</ActionDrawer>
  }

  return <ActionPopover {...props}>{children}</ActionPopover>
}

export function ActionPopover({ header, children, ...props }) {
  return (
    <PopoverContent {...props}>
      <PopoverHeader>{header}</PopoverHeader>

      <PopoverCloseButton as={IconButton} iconName="close" />

      <PopoverBody>{children}</PopoverBody>
    </PopoverContent>
  )
}

function ActionDrawer({ header, children, ...props }) {
  return (
    <DrawerContent {...props}>
      <DrawerCloseButton as={IconButton} iconName="close" top="2" />

      <DrawerHeader>{header}</DrawerHeader>

      <DrawerBody>
        <Box maxW="340px" mx="auto">
          {children}
        </Box>
      </DrawerBody>
    </DrawerContent>
  )
}

export const ActionTriggerButton = forwardRef(
  ({ iconName, children, onClick, ...props }, ref) => {
    const isMobile = useIsMobile()

    if (isMobile) {
      return (
        <ActionButton
          ref={ref}
          iconName={iconName}
          onClick={onClick}
          {...props}
        >
          {children}
        </ActionButton>
      )
    }

    return (
      <PopoverTrigger ref={ref}>
        <ActionButton iconName={iconName} onClick={onClick} {...props}>
          {children}
        </ActionButton>
      </PopoverTrigger>
    )
  }
)
ActionTriggerButton.displayName = "ActionTriggerButton"

export const ActionButton = forwardRef(
  ({ iconName, icon, onClick, children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="secondary"
        iconName={iconName}
        icon={icon}
        onClick={onClick}
        {...props}
      >
        {children}
      </Button>
    )
  }
)
ActionButton.displayName = "ActionButton"

export function ActionFormButton({
  children,
  link,
  onClick,
  isDisabled,
  isLoading,
  ...props
}) {
  const linkProps = useMemo(() => {
    if (link) {
      return {
        as: "a",
        href: link,
        target: "_blank",
        rel: "noreferrer"
      }
    }
    return {}
  }, [link])

  return (
    <Button
      variant="primaryPurple"
      mt="14px"
      width="full"
      maxW="100%"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      {...linkProps}
      {...props}
    >
      {children}
    </Button>
  )
}
