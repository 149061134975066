import { Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Button, Select, SettingsAccordion } from "theme/components"
import { SlackButton } from "../../../../Community/src/components"

export function Slack({
  slackWorkspaceOptions,
  slackWorkspaceChannelOptions,
  onWorkspaceChannelConnect,
  isLoadingSlackChannelConnect,
  onSlackWorkspaceChange,
  isSlackConnected,
  onSlackConnect,
  onAccordionChange
}) {
  const handleWorkspaceChannelConnect = (slackWorkspace, slackChannel) => {
    onWorkspaceChannelConnect(slackChannel.id)
  }

  const handleAccordionChange = index => {
    const isOpen = index === 0
    onAccordionChange && onAccordionChange(isOpen)
  }

  return (
    <SettingsAccordion
      iconName="slack"
      name="Slack"
      onChange={handleAccordionChange}
    >
      <SlackActionRouter
        action={isSlackConnected ? "add-channel" : "connect"}
        slackWorkspaceOptions={slackWorkspaceOptions}
        slackWorkspaceChannelOptions={slackWorkspaceChannelOptions}
        onWorkspaceChannelConnect={handleWorkspaceChannelConnect}
        onSlackWorkspaceChange={onSlackWorkspaceChange}
        isLoadingSlackChannelConnect={isLoadingSlackChannelConnect}
        onConnect={onSlackConnect}
      />
    </SettingsAccordion>
  )
}

function SlackActionRouter({
  action,
  slackWorkspaceOptions,
  slackWorkspaceChannelOptions,
  onSlackWorkspaceChange,
  isLoadingSlackChannelConnect = false,
  onWorkspaceChannelConnect,
  onConnect
}) {
  if (action === "add-channel") {
    return (
      <AddSlackChannelAction
        slackWorkspaceOptions={slackWorkspaceOptions}
        slackWorkspaceChannelOptions={slackWorkspaceChannelOptions}
        onSlackWorkspaceChange={onSlackWorkspaceChange}
        isLoadingSlackChannelConnect={isLoadingSlackChannelConnect}
        onWorkspaceChannelConnect={onWorkspaceChannelConnect}
      />
    )
  }

  return <ConnectSlackAction onConnect={onConnect} />
}

function AddSlackChannelAction({
  slackWorkspaceOptions,
  slackWorkspaceChannelOptions,
  onSlackWorkspaceChange,
  isLoadingSlackChannelConnect = false,
  onWorkspaceChannelConnect
}) {
  return (
    <AddSlackChannelForm
      slackWorkspaceOptions={slackWorkspaceOptions}
      slackWorkspaceChannelOptions={slackWorkspaceChannelOptions}
      onSlackWorkspaceChange={onSlackWorkspaceChange}
      onWorkspaceChannelConnect={onWorkspaceChannelConnect}
      isLoadingSlackChannelConnect={isLoadingSlackChannelConnect}
    />
  )
}

function ConnectSlackAction({ onConnect }) {
  return (
    <>
      <Text fontWeight="medium">Connect your Slack account</Text>

      <SlackButton onClick={onConnect} mt="2">
        Connect workspace
      </SlackButton>
    </>
  )
}

function AddSlackChannelForm({
  slackWorkspaceOptions,
  slackWorkspaceChannelOptions,
  onSlackWorkspaceChange,
  onWorkspaceChannelConnect,
  isLoadingSlackChannelConnect = false
}) {
  const [guildId, setGuildId] = useState("")
  const [channelId, setChannelId] = useState("")
  const handleWorkspaceChannelConnect = () => {
    onWorkspaceChannelConnect(
      {
        name: slackWorkspaceOptions.find(guild => guild.value === guildId)
          .label,
        id: guildId
      },
      {
        name: slackWorkspaceChannelOptions.find(
          channel => channel.value === channelId
        ).label,
        id: channelId
      }
    )
  }
  const handleSlackWorkspaceChange = event => {
    const value = event.target.value
    onSlackWorkspaceChange(value)
    setGuildId(value)
  }

  useEffect(() => {
    if (slackWorkspaceChannelOptions.length > 0) {
      setChannelId(slackWorkspaceChannelOptions[0].value)
    }
  }, [slackWorkspaceChannelOptions.length])

  useEffect(() => {
    onSlackWorkspaceChange("")
    setChannelId("")
  }, [])

  return (
    <VStack
      align="start"
      w="full"
      spacing="4"
      pb="8"
      borderBottom="1px solid"
      borderColor="line"
    >
      <Select
        placeholder="Slack guilds"
        w="full"
        onChange={handleSlackWorkspaceChange}
        value={guildId}
        options={slackWorkspaceOptions}
      />

      <Select
        placeholder="Slack workspace channels"
        w="full"
        onChange={e => setChannelId(e.target.value)}
        value={channelId}
        options={slackWorkspaceChannelOptions}
      />

      <Button
        variant="primaryPurple"
        onClick={handleWorkspaceChannelConnect}
        isDisabled={!channelId || isLoadingSlackChannelConnect}
        loadingText="Adding channel..."
        isLoading={isLoadingSlackChannelConnect}
        alignSelf="flex-end"
      >
        Add channel
      </Button>
    </VStack>
  )
}
