import { QueryOptions, useQuery } from "react-query"
import { userUseCase } from "../index"

export const useCurrentAuthenticatedUser = (options: QueryOptions = {}) =>
  useQuery(
    "currentAuthenticatedUser",
    () => userUseCase.currentAuthenticatedUser(),
    options
  )

