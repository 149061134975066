export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      ownerId
      contractId
      name
      jiraProjects {
        id
        name
        workspaceId
        workspaceName
        labels
      }
      jiraProjectIds
      discordChannels {
        id
        name
        guildId
        guildName
      }
      discordChannelIds
      discordGuilds {
        id
        name
      }
      discordCosmoChannels {
        id
        guildId
      }
      slackChannels {
        id
        name
        workspaceId
        workspaceName
      }
      slackChannelIds
      slackWorkspaces {
        id
        name
      }
      slackCosmoChannels {
        id
        workspaceId
      }
      platformWorkspaceConfigId
      defaultTeamEmoji {
        emoji
        backgroundColor
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`
export const getWorkspacesByOwnerId = /* GraphQL */ `
  query GetWorkspacesByOwnerId(
    $ownerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWorkspacesByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        contractId
        name
        jiraProjects {
          id
          name
          workspaceId
          workspaceName
          labels
        }
        jiraProjectIds
        discordChannels {
          id
          name
          guildId
          guildName
        }
        discordChannelIds
        discordGuilds {
          id
          name
        }
        discordCosmoChannels {
          id
          guildId
        }
        slackChannels {
          id
          name
          workspaceId
          workspaceName
        }
        slackChannelIds
        slackWorkspaces {
          id
          name
        }
        slackCosmoChannels {
          id
          workspaceId
        }
        platformWorkspaceConfigId
        defaultTeamEmoji {
          emoji
          backgroundColor
        }
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getWorkspaceTeamsByWorkspaceId = /* GraphQL */ `
  query GetWorkspaceTeamsByWorkspaceId(
    $workspaceId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWorkspaceTeamsByWorkspaceId(
      workspaceId: $workspaceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getWorkspaceTeam = /* GraphQL */ `
  query GetWorkspaceTeam($id: ID!) {
    getWorkspaceTeam(id: $id) {
      id
      workspaceId
      ownerId
      communityWorkspaceId
      communityType
      teamId
      name
      surname
      type
      emoji
      emojiBgColor
      createdAt
      updatedAt
    }
  }
`
