import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { Auth } from "aws-amplify"
import { ICredentials } from "aws-amplify/lib/Common/types/types"
import {
  ICognitoUser,
  ISignUpResponse,
  SignUpInput,
  forgotPasswordSubmitInputType
} from "../types"

class CognitoAuthService {
  async currentAuthenticatedUser(): Promise<ICognitoUser> {
    return Auth.currentAuthenticatedUser()
  }

  async signOut(): Promise<void> {
    return Auth.signOut()
  }

  async signIn(email: string, password: string): Promise<ICognitoUser> {
    Auth.configure({
      authenticationFlowType: "USER_SRP_AUTH"
    })
    return Auth.signIn(email, password)
  }

  async signInWithToken(email: string, token: string): Promise<ICognitoUser> {
    Auth.configure({
      authenticationFlowType: "CUSTOM_AUTH"
    })
    try {
      const user = await Auth.signIn(email)
      const loggedInUser = await Auth.sendCustomChallengeAnswer(user, token)
      return loggedInUser
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  async signInGoogle(): Promise<ICredentials> {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    })
  }

  async signInFacebook(): Promise<ICredentials> {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook
    })
  }

  async signUp({
    name,
    email,
    password
  }: SignUpInput): Promise<ISignUpResponse> {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name
      }
    })
  }

  async confirmSignUp(email: string, code: string): Promise<any> {
    return Auth.confirmSignUp(email, code)
  }

  async forgotPassword(email: string): Promise<any> {
    return Auth.forgotPassword(email)
  }

  async forgotPasswordSubmit({
    email,
    code,
    password
  }: forgotPasswordSubmitInputType): Promise<string> {
    return Auth.forgotPasswordSubmit(email, code, password)
  }
}

export default CognitoAuthService
