import { ICredentials } from "aws-amplify/lib/Common/types/types"
import {
  IAuthError,
  ICognitoUser,
  ISignUpResponse,
  IUserNormalized,
  SignUpInput,
  forgotPasswordSubmitInputType
} from "../types"
import { normalizeUser } from "../utils"
import { normalizeAuthError } from "../utils/normalizeAuthError"
import CognitoAuthService from "../services/CognitoAuthService"
import UserAppSyncService from "../services/UserAppSyncService"

class UserUseCase {
  constructor(
    private authService: CognitoAuthService,
    private userAppSyncService: UserAppSyncService
  ) {
  }

  async currentAuthenticatedUser(): Promise<ICognitoUser> {
    return this.authService.currentAuthenticatedUser()
  }

  async signOut(): Promise<void> {
    return this.authService.signOut()
  }

  async signIn(email: string, password: string): Promise<ICognitoUser> {
    try {
      return await this.authService.signIn(email, password)
    } catch (error) {
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async signInWithToken(email: string, token: string): Promise<ICognitoUser> {
    try {
      return this.authService.signInWithToken(email, token)
    } catch (error) {
      console.error("signInWithToken error : ", error)
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async signInGoogle(): Promise<ICredentials> {
    return this.authService.signInGoogle()
  }

  async signInFacebook(): Promise<ICredentials> {
    return this.authService.signInFacebook()
  }

  async signUp(input: SignUpInput): Promise<ISignUpResponse> {
    try {
      return await this.authService.signUp(input)
    } catch (error) {
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async confirmSignUp(email: string, code: string): Promise<void> {
    try {
      return await this.authService.confirmSignUp(email, code)
    } catch (error) {
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      return await this.authService.forgotPassword(email)
    } catch (error) {
      console.error("forgotPassword : ", error)
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async forgotPasswordSubmit(
    input: forgotPasswordSubmitInputType
  ): Promise<string> {
    try {
      return await this.authService.forgotPasswordSubmit(input)
    } catch (error) {
      throw normalizeAuthError(error as IAuthError)
    }
  }

  async getCurrentUser(): Promise<IUserNormalized | null> {
    try {
      const cognitoUser = await this.currentAuthenticatedUser()
      const user = (
        await this.userAppSyncService.getAllByPk({
          pk: cognitoUser.attributes.email,
          sk: { eq: cognitoUser.attributes.email }
        })
      ).items[0]

      return normalizeUser(user)
    } catch (error) {
      console.error("getCurrentUser error : ", error)
      return null
    }
  }
}

export default UserUseCase
