import {
  Input as ChakraInput,
  FormControl,
  FormHelperText,
  Text
} from "@chakra-ui/react"
import React, { forwardRef } from "react"

export const Input = forwardRef(
  ({ value, onChange, type = "text", maxLength, ...props }, ref) => {
    return (
      <FormControl>
        <ChakraInput
          ref={ref}
          variant="primary"
          value={value}
          onChange={onChange}
          type={type}
          maxLength={maxLength}
          {...props}
        />

        {maxLength ? (
          <FormHelperText px="3" mt="1" display="flex">
            <Text
              fontSize="xs"
              fontWeight="medium"
              lineHeight="xs"
              color="secondaryLight"
              ml="auto"
            >
              {value.length}/{maxLength}
            </Text>
          </FormHelperText>
        ) : null}
      </FormControl>
    )
  }
)
Input.displayName = "Input"
