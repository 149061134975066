import { EmptyObject } from "global-types"
import { useSendContractToSource } from "modules/Source/src//core/hooks"
import { Workspace } from "modules/Workspace/src//core/types"
import { SelectOption } from "modules/Workspace/src//core/types/global"
import { useMemo } from "react"
import { IContractNormalized } from "../../types"

const useWorkspaceContractJira = ({ workspace, contract }: Props) => {
  const projectOptions = useMemo<SelectOption[]>(
    () =>
      workspace.jiraProjects?.map(project => ({
        label: project.name,
        value: project.id
      })) || [],
    [workspace.jiraProjects?.length]
  )

  const {
    mutateAsync: sendContractToSourceAsync,
    isLoading: isLoadingSendToJira
  } = useSendContractToSource()

  const handleSendToJira = async (jiraProjectId: string) => {
    await sendContractToSourceAsync({
      workspaceId: workspace.id,
      sourceType: "jira",
      options: {
        contractId: contract?.id || "",
        jiraProjectId,
        workspaceId: workspace.id
      }
    })
  }

  return {
    projectOptions,
    handleSendToJira,
    isLoadingSendToJira
  }
}

export default useWorkspaceContractJira

type Props = {
  workspace: Workspace | EmptyObject
  contract: IContractNormalized | null
}
