import { API, graphqlOperation } from "aws-amplify"
import { EmptyObject } from "global-types"
import {
  createContractResource,
  deleteContractResource
} from "../graphql/mutations"
import {
  getContractResource,
  getContractResourcesByContractPk,
  listContractResources
} from "../graphql/queries"
import {
  ContractResource,
  ContractResourceCreateInput
} from "../types/contract-resource-model-types"
import { ModelContractResourceFilterInput } from "../types/graphql-types"

class ContractResourceAppSyncService {
  async getById(id: string): Promise<ContractResource> {
    const res = await (API.graphql(
      graphqlOperation(getContractResource, { id })
    ) as Promise<any>)

    return res.data.getContractResource
  }

  async findByContractPk(
    contractPk: string,
    filter: ModelContractResourceFilterInput | EmptyObject = {}
  ) {
    const res = await (API.graphql(
      graphqlOperation(getContractResourcesByContractPk, {
        contractPk,
        sortDirection: "DESC",
        filter
      })
    ) as Promise<any>)
    return res.data.getContractResourcesByContractPk.items
  }

  async createOne(input: ContractResourceCreateInput) {
    const res = await (API.graphql(
      graphqlOperation(createContractResource, { input })
    ) as Promise<any>)
    return res.data.createContractResource
  }

  async deleteOne(id: string) {
    const res = await (API.graphql(
      graphqlOperation(deleteContractResource, { input: { id } })
    ) as Promise<any>)
    return res.data.deleteContractResource
  }
}

export default ContractResourceAppSyncService
