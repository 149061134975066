import WorkspaceTeamAppSyncService from "../services/WorkspaceTeamAppSyncService"
import {
  GetWorkspaceTeamsByWorkspaceIdInput,
} from "../types/graphql"

class WorkspaceTeamCRUDRepository {
  constructor(
    private readonly _workspaceTeamAppSyncService: WorkspaceTeamAppSyncService
  ) {}

  getById(id: string) {
    return this._workspaceTeamAppSyncService.getById(id)
  }

  getAllByWorkspaceId(input: GetWorkspaceTeamsByWorkspaceIdInput) {
    return this._workspaceTeamAppSyncService.getAllByWorkspaceId(input)
  }
}

export default WorkspaceTeamCRUDRepository
