import { IAuthError } from "../types"

export const authErrorMessages = {
  EMAIL_EXISTS: "Email already exists",
  EMAIL_NOT_FOUND: "Email or password is incorrect",
  INVALID_PASSWORD: "Password should be at least 8 characters",
  INVALID_CODE: "Code is incorrect",
  INVALID_PARAMETER: "Invalid email or password",
  UNKNOWN: "Unknown error"
}

export const normalizeAuthError = (error: IAuthError) => {
  let code = "UNKNOWN" as keyof typeof authErrorMessages

  switch (error.code) {
    case "UsernameExistsException":
      code = "EMAIL_EXISTS"
      break
    case "UserNotFoundException":
      code = "EMAIL_NOT_FOUND"
      break
    case "InvalidPasswordException":
      code = "INVALID_PASSWORD"
      break
    case "CodeMismatchException":
      code = "INVALID_CODE"
      break
    case "NotAuthorizedException":
      code = "INVALID_PARAMETER"
      break
  }

  return {
    code,
    message: authErrorMessages[code]
  }
}
