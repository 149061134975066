import {
  Flex,
  Grid,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack
} from "@chakra-ui/react"
import TeamEmoji from "modules/Workspace/src/components/TeamEmoji"
import React, { useState } from "react"
import { IoSend } from "react-icons/io5"
import { ContractResourceCard, ContractSectionLayout } from "./index"

const ContractMessages = ({
  messages = [],
  onCommentCreate,
  heading,
  placeholder
}) => (
  <ContractSectionLayout
    heading={heading || "Comments"}
    iconName="comment"
  >
    <CommentInput
      onSubmit={onCommentCreate}
      placeholder={placeholder}
    />

    <VStack align="stretch" spacing="2" mt="4">
      {messages.map(message => (
        <MessageItem key={message.id} message={message} />
      ))}
    </VStack>
  </ContractSectionLayout>
)

export default ContractMessages

function CommentInput({ onSubmit, placeholder }) {
  const [inputValue, setInputValue] = useState("")

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    const value = inputValue.trim()

    if (value) {
      onSubmit && onSubmit(value)
      setInputValue("")
    }
  }

  return (
    <InputGroup>
      <InputRightElement
        display={inputValue.trim() ? "inline-flex" : "none"}
        children={<IoSend />}
        cursor="pointer"
        onClick={handleSubmit}
      />

      <Input
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        variant="unstyled"
        placeholder={placeholder || "Leave a comment"}
        width="full"
        height="40px"
        px={{ base: "3", md: "4" }}
        borderRadius="12px"
        bgColor="lightBlue"
        border="none"
        fontSize="sm"
        lineHeight="sm"
        fontWeight="medium"
        color="primaryDark"
        _placeholder={{ color: "#7f7f81" }}
        onKeyDown={handleKeyDown}
      />
    </InputGroup>
  )
}

const MessageItem = ({ message }) => (
  <Flex
    direction="column"
    bgColor="#F9F9FD"
    p={{ base: 3, md: 4 }}
    borderRadius="12px"
  >
    <Flex fontSize="sm" lineHeight="sm">
      <Text fontWeight="bold" color="primaryDark">
        {message.username}
      </Text>
      <Text color="secondaryLight" fontWeight="medium" ml="2">
        {message.timestamp}
      </Text>
    </Flex>

    <Text
      as="pre"
      fontWeight="medium"
      fontSize="sm"
      lineHeight="sm"
      fontFamily="body"
      color="primaryDark"
      whiteSpace="pre-wrap"
      mt="2"
    >
      {message.message}
    </Text>

    {message.attachments?.length > 0 ? (
      <Grid templateColumns="repeat(auto-fill, 96px)" gap="3" mt="3">
        {message.attachments.map(attachment => (
          <ContractResourceCard
            key={attachment.id}
            resource={attachment}
            contractPk={message.contractPk}
          />
        ))}
      </Grid>
    ) : null}

    {message.teamName ? (
      <Flex justify="end" mt="3">
        <Flex align="center">
          <Text fontSize="xs" lineHeight="xs" color="secondaryLight">
            From {message.teamName}
          </Text>

          <TeamEmoji
            emoji={message.teamEmoji}
            bgColor={message.teamEmojiBgColor}
            ml="6px"
          />
        </Flex>
      </Flex>
    ) : null}
  </Flex>
)
