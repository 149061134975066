import { Select as ChakraSelect } from "@chakra-ui/react"
import React from "react"
import { Icon } from "../Icon"

export function Select({
  value,
  placeholder = "",
  options = [],
  onChange,
  ...props
}) {
  return (
    <ChakraSelect
      variant="primary"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      icon={<Icon name="chevron-down" width="12px" />}
      {...props}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </ChakraSelect>
  )
}
