import {
  WorkspaceTeamEditProps
} from "modules/Workspace/src//core/hooks/useWorkspaceTeams/types"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType,
  WorkspaceTeamOption,
  WorkspaceTeamOptionsGroup
} from "modules/Workspace/src//core/types/workspace-team"
import {
  WORKSPACE_TEAM_GROUPS
} from "modules/Workspace/src/core/config"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Workspace } from "../../types"
import { useDeleteWorkspaceTeams, useGetWorkspaceTeams } from "../index"
import useUpdateWorkspaceTeams from "../useUpdateWorkspaceTeams"
import { CommunityType } from "../../types/graphql"

const useEmailTeams = ({ workspace, workspaceTeams = [] }: Props) => {
  // community
  const { mutateAsync: deleteWorkspaceTeamsAsync } = useDeleteWorkspaceTeams()
  const { mutateAsync: updateWorkspaceTeamsAsync } = useUpdateWorkspaceTeams()

  // community

  // team edit
  const { data: getWorkspaceTeamsResponse } = useGetWorkspaceTeams({
    communityType: CommunityType.EMAIL,
    input: {
      workspaceId: workspace?.id || "",
      filter: {
        communityType: { eq: CommunityType.EMAIL }
      }
    }
  },
  {
    keepPreviousData: false,
    enabled: !!workspace?.id
  }
  )

  const [emailTeamOptions, setEmailTeamOptions] = useState<
    WorkspaceTeamOption[]
  >([])

  useEffect(() => {
    if (getWorkspaceTeamsResponse?.items.length === 0) {
      setEmailTeamOptions([])
      return
    }

    const teamOptions = getWorkspaceTeamsResponse?.items.map(team => {
      return {
        id: team.id,
        name:
          team.communityType === WorkspaceTeamCommunityType.EMAIL
            ? team.teamId
            : team.name,
        emoji: team?.emoji,
        emojiBgColor: team?.emojiBgColor,
        type: team.type,
        isSelected: true
      }
    }) as WorkspaceTeamOption[]

    setEmailTeamOptions(teamOptions)
  }, [getWorkspaceTeamsResponse?.items.length, workspaceTeams?.length])

  const teamOptionsGroups = useMemo<WorkspaceTeamOptionsGroup[]>(
    () =>
      WORKSPACE_TEAM_GROUPS.map(group => {
        const teamOptions =
          emailTeamOptions?.filter(team => team.type === group.type) || []

        return {
          ...group,
          teamOptions
        }
      }),
    [JSON.stringify(emailTeamOptions)]
  )

  const handleTeamOptionChange = (newTeam: WorkspaceTeamOption) => {
    const newTeamOption = emailTeamOptions.map(team => {
      if (team.id === newTeam.id) {
        return {
          ...team,
          ...newTeam
        }
      }

      return team
    })

    setEmailTeamOptions(newTeamOption)
  }

  const handleUpdate = async () => {
    const removedTeamIds = emailTeamOptions
      .filter(team => !team.isSelected)
      .map(team => team.id)

    const updateTeamInputs = emailTeamOptions
      .filter(team => team.isSelected)
      .filter(team => {
        const workspaceTeam = workspaceTeams.find(
          workspaceTeam => workspaceTeam.id === team.id
        )
        if (!workspaceTeam) {
          return false
        }
        return (
          workspaceTeam.emoji !== team.emoji ||
          workspaceTeam.emojiBgColor !== team.emojiBgColor
        )
      })
      .map(team => {
        const workspaceTeam = workspaceTeams.find(
          workspaceTeam => workspaceTeam.id === team.id
        )
        if (!workspaceTeam) {
          return null
        }
        return {
          ...workspaceTeam,
          emoji: team.emoji,
          emojiBgColor: team.emojiBgColor
        }
      })
      .filter(team => team !== null) as WorkspaceTeam[]

    if (removedTeamIds.length > 0) {
      await deleteWorkspaceTeamsAsync({
        teamIds: removedTeamIds,
        workspaceId: workspace?.id || ""
      })
    }

    if (updateTeamInputs.length > 0) {
      await updateWorkspaceTeamsAsync({
        workspaceId: workspace?.id || "",
        updateInputs: updateTeamInputs
      })
    }
  }

  const getTeamEditProps = useCallback(
    (): WorkspaceTeamEditProps => ({
      teamOptionsGroups,
      onTeamOptionChange: handleTeamOptionChange,
      onUpdate: handleUpdate,
      showDisconnect: false
    }),
    [JSON.stringify(teamOptionsGroups)]
  )

  // team edit

  return {
    getTeamEditProps,
    handleUpdate
  }
}

export default useEmailTeams

type Props = {
  workspace: Workspace | null
  workspaceTeams: WorkspaceTeam[]
}
