export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
