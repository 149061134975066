import { Workspace } from "modules/Workspace/src//core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { communityUseCase } from "../index"
import { CommunityType, ConnectWorkspaceOptions } from "../types"

export const useConnectCommunityWorkspace = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "connectCommunityWorkspace",
    input =>
      communityUseCase.connectWorkspace(input.communityType, input.options),
    {
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }
        const workspaceSkCommunity = data as Workspace

        queryClient.setQueryData<Workspace>(
          ["workspace", variables.options.workspaceId],
          workspace => ({ ...workspace, ...workspaceSkCommunity })
        )

        queryClient.invalidateQueries([
          "workspaceTeams",
          variables.options.workspaceId
        ])
      },
      ...options
    }
  )
}

export type FnInput = {
  communityType: CommunityType
  options: ConnectWorkspaceOptions
}

export type Options = Omit<
  UseMutationOptions<Workspace | null, Error, FnInput>,
  "mutationFn"
>
