const applyEmojiPickerStyles = containerElement => {
  const emojiPicker = containerElement.querySelector("em-emoji-picker")
  if (emojiPicker) {
    const style = document.createElement("style")
    style.innerHTML = `
    section > .scroll > div {
     width: 100% !important;
     }
     section > .scroll {
      max-width: calc(100% - (var(--padding) / 3));
      }
     `
    emojiPicker.shadowRoot.appendChild(style)
  }
}

export default applyEmojiPickerStyles
