import { Flex, Grid, Stack, Text } from "@chakra-ui/react"
import { ContractResourceCard } from "../ui"
import React from "react"

export function MessageItem({ message }) {
  return (
    <Flex
      direction="column"
      width="full"
      maxWidth={{
        base: "80%",
        md: "70%"
      }}
      alignSelf={message.isCurrentUser ? "flex-end" : "flex-start"}
    >
      <Flex
        fontSize="sm" lineHeight="sm" align="center" px={1}
        alignSelf={message.isCurrentUser ? "flex-end" : "flex-start"}
      >
        {message.teamName ? (
          <Text
            fontSize="xs" lineHeight="xs" color="secondaryLight" mr="2"
            noOfLines={1}
          >
            From {message.teamName} :
          </Text>
        ) : null}

        <Text
          fontWeight="bold" color="primaryDark"
          noOfLines={1}
        >
          {message.username}
        </Text>

        <Text
          color="secondaryLight" fontWeight="medium" ml="2" fontSize="xs"
          noOfLines={1}
        >
          {message.timestamp}
        </Text>
      </Flex>

      <Stack
        spacing="3"
        mt="1"
        alignItems={message.isCurrentUser ? "flex-end" : "flex-start"}
      >
        {message.message ? (
          <Text
            as="pre"
            fontWeight="medium"
            fontSize="sm"
            lineHeight="sm"
            fontFamily="body"
            color="primaryDark"
            whiteSpace="pre-wrap"
            bgColor={message.isCurrentUser ? "#f9f9fd" : "#f8f8fa"}
            borderRadius="12px"
            p="14px"
            width="fit-content"
            maxWidth="full"
          >
            {message.message}
          </Text>
        ) : null}


        {message.attachments?.length > 0 ? (
          <Grid
            templateColumns="repeat(auto-fill, 96px)"
            gap="3"
            width="full"
            maxWidth={(96 * message.attachments?.length + 12 * (message.attachments?.length - 1)) + "px"}
            justifyContent={message.isCurrentUser ? "flex-end" : "flex-start"}
          >
            {message.attachments.map(attachment => (
              <ContractResourceCard
                key={attachment.id}
                resource={attachment}
                contractPk={message.contractPk}
              />
            ))}
          </Grid>
        ) : null}
      </Stack>
    </Flex>
  )
}
