import { useMutation, useQueryClient } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { messageUseCase } from "../index"
import { ListMessagesResponse, Message, MessageType } from "../types"
import { UseCreateCommentOptions } from "../types/hooks"

export const useCreateComment = (options: UseCreateCommentOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createComment",
    input => messageUseCase.createComment(input),

    {
      onMutate: async input => {
        const contractCommentsQuery = ["contractComments", input.contractPk]
        await queryClient.cancelQueries(contractCommentsQuery)
        const previousContractComments =
          queryClient.getQueryData<ListMessagesResponse>(contractCommentsQuery) || {
            items: [],
            nextToken: null
          }
        queryClient.setQueryData<ListMessagesResponse>(
          contractCommentsQuery,
          (oldComments: any) => {
            const comments = [...(oldComments?.items || [])]
            comments.unshift({
              id: uuidv4(),
              type: MessageType.COMMENT,
              contractId: input.contractId || "",
              contractPk: input.contractPk || "",
              message: input.message || "",
              username: input.username || "",
              createdAt: new Date().toISOString(),
              ownerId: input.ownerId,
            } as Message)

            return {
              items: comments,
              nextToken: oldComments?.nextToken
            }
          }
        )

        return {
          previousContractComments
        }
      },
      onError: (_err, variables, context) => {
        queryClient.setQueryData(
          ["contractComments", variables.contractPk],
          context?.previousContractComments
        )
      },
      ...options
    }
  )
}
