import { useMutation, useQueryClient } from "react-query"
import { userUseCase } from "../index"
import { useSignInInputType, useSignInOptionsType } from "../types/hooks"

export const useSignIn = (options: useSignInOptionsType = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (input: useSignInInputType) =>
      userUseCase.signIn(input.email, input.password),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries("currentUser")

        if (options.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      }
    }
  )
}
