import PlatformApiRepository from "../repositories/PlatformApiRepository"
import { GetWorkspaceInput, GetWorkspaceResponse } from "../services/types"

class PlatformApiUseCase {
  constructor(
    private readonly platformApiRepository: PlatformApiRepository
  ) {}

  getWorkspace(input: GetWorkspaceInput): Promise<GetWorkspaceResponse> {
    return this.platformApiRepository.getWorkspace(input)
  }
}

export default PlatformApiUseCase
