import { useQueryParams } from "hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useCallback, useEffect, useMemo } from "react"

const useSettingsTabRouter = ({
  isOpenDrawer,
  openDrawer,
  closeDrawer,
  onDrawerClose,
  settingsTabName
}: Props) => {
  const { queryParams, removeQueryParams, setQueryParams } = useQueryParams()
  const isMobile = useIsMobile()
  const activeTab = useMemo(() => queryParams.tab, [queryParams.tab])

  const handleSettingsClick = useCallback(() => {
    setQueryParams({ tab: settingsTabName })
  }, [setQueryParams])

  useEffect(() => {
    if (isOpenDrawer && activeTab !== settingsTabName) {
      closeDrawer()
    }

    if (activeTab === settingsTabName && !isOpenDrawer && !isMobile) {
      openDrawer()
    }
  }, [activeTab, isMobile])

  const handleClose = () => {
    if (!isMobile && activeTab === settingsTabName) {
      removeQueryParams("tab")
    }

    onDrawerClose()
  }

  return {
    handleClose,
    handleSettingsClick
  }
}

export default useSettingsTabRouter

type Props = {
  isOpenDrawer: boolean
  openDrawer: () => void
  closeDrawer: () => void
  onDrawerClose: () => void
  settingsTabName: string
}
