import { Box, ButtonGroup, Flex, Grid, Heading } from "@chakra-ui/react"
import { usePreviousPath } from "providers/PreviousPathProvider"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, FormControl, Input } from "theme/components"
import { useWorkspaceCreateForm } from "../core/hooks"

function WorkspaceCreatePage({ onCreate, isLoadingCreate }) {
  const handleSubmit = name => {
    onCreate && onCreate(name)
  }

  const {
    handleNameChange,
    handleWorkspaceCreate,
    isDisabledCreateButton,
    name
  } = useWorkspaceCreateForm(handleSubmit)

  const previousPath = usePreviousPath()

  return (
    <Layout
      isDisabledCreate={isDisabledCreateButton}
      onCreate={handleWorkspaceCreate}
      isLoadingCreate={isLoadingCreate}
      previousPath={previousPath}
    >
      <FormControl label="Workspace Name *" maxW="full" width="full">
        <Input
          name="name"
          value={name}
          onChange={handleNameChange}
          maxLength={60}
        />
      </FormControl>
    </Layout>
  )
}

export default WorkspaceCreatePage

function Layout({
  children,
  onCreate,
  isLoadingCreate,
  isDisabledCreate,
  previousPath
}) {
  return (
    <Grid
      height="full"
      width="full"
      templateRows="32px 1fr 56px"
      p={{ base: "4", md: "10" }}
    >
      <Header />

      <Box pt="3" pb="8" overflowY="scroll">
        {children}
      </Box>

      <Footer
        onCreate={onCreate}
        isLoadingCreate={isLoadingCreate}
        isDisabledCreate={isDisabledCreate}
        previousPath={previousPath}
      />
    </Grid>
  )
}

function Header() {
  return (
    <Flex width="full" align="center" height="32px" pb="2">
      <Heading
        as="h3"
        fontSize="xl"
        lineheight="xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        ml="3"
        mb="-1"
      >
        Create workspace
      </Heading>
    </Flex>
  )
}

function Footer({ onCreate, isLoadingCreate, isDisabledCreate, previousPath }) {
  return (
    <Flex height="56px" align="center">
      <ButtonGroup spacing="8" width="full" justifyContent="end" size="lg">
        <Button
          as={RouterLink}
          relative="path"
          to={previousPath || "/"}
          variant="textDark"
          color="#666666"
        >
          Cancel
        </Button>

        <Button
          onClick={onCreate} isDisabled={isDisabledCreate}
          isLoading={isLoadingCreate}
        >
          Create
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
