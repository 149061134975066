import { IContractNormalized } from "modules/Contract/src//core/types"
import { Workspace } from "modules/Workspace/src//core/types"
import CommunityRepository from "../repositories/CommunityRepository"
import {
  CommunityTeam,
  CommunityType,
  CommunityWorkspaceNormalized,
  ConnectWorkspaceOptions,
  DisconnectWorkspaceOptions,
  GetWorkspaceTeamsOptions,
  ICommunityMessageNormalized,
  UpdateWorkspaceOptions,
  assignContractToCommunityUserOptionsType,
  communityMemberNormalizedType,
  createCommunityChannelMessageOptionsType,
  getCommunityAuthUrlOptionsType,
  getCommunityChannelMessagesOptionsType,
  getCommunityWorkspaceMembersOptionsType
} from "../types"
import {
  normalizeCommunityMessage,
  normalizeCommunityWorkspaceMembers,
  normalizeCommunityWorkspaces
} from "../utils"

export class CommunityUseCase {
  constructor(private communityRepo: CommunityRepository) {}

  async getUserCommunityWorkspacesWithBot(
    communityType: CommunityType
  ): Promise<CommunityWorkspaceNormalized[]> {
    try {
      const workspaces =
        await this.communityRepo.getUserCommunityWorkspacesWithBot(
          communityType
        )
      return normalizeCommunityWorkspaces(communityType, workspaces)
    } catch (error) {
      console.error("getUserCommunityWorkspacesWithBot error : ", error)
      throw error
    }
  }

  async getCommunityWorkspaceMembers(
    communityType: CommunityType,
    options: getCommunityWorkspaceMembersOptionsType
  ): Promise<communityMemberNormalizedType[]> {
    try {
      const members = await this.communityRepo.getCommunityWorkspaceMembers(
        communityType,
        options
      )
      return normalizeCommunityWorkspaceMembers(communityType, members)
    } catch (error) {
      console.error("getCommunityWorkspaceMembers error : ", error)
      return []
    }
  }

  async assignContractToCommunityUser(
    communityType: CommunityType,
    options: assignContractToCommunityUserOptionsType
  ): Promise<IContractNormalized | null> {
    try {
      return await this.communityRepo.assignContractToCommunityUser(
        communityType,
        options
      )
    } catch (error) {
      console.error("assignContractToCommunityUser error : ", error)
      return null
    }
  }

  async createCommunityChannelMessage(
    communityType: CommunityType,
    options: createCommunityChannelMessageOptionsType
  ): Promise<ICommunityMessageNormalized | null> {
    try {
      const message = await this.communityRepo.createCommunityChannelMessage(
        communityType,
        options
      )
      return normalizeCommunityMessage(communityType, message)
    } catch (error) {
      console.error("createCommunityChannelMessage error : ", error)
      return null
    }
  }

  async getCommunityChannelMessages(
    communityType: CommunityType,
    options: getCommunityChannelMessagesOptionsType
  ): Promise<ICommunityMessageNormalized[]> {
    try {
      const response = await this.communityRepo.getCommunityChannelMessages(
        communityType,
        options
      )

      return response
        .map(message => normalizeCommunityMessage(communityType, message))
        .filter(message => message !== null) as ICommunityMessageNormalized[]
    } catch (e) {
      console.error("getCommunityChannelMessages error : ", e)
      return []
    }
  }

  async getCommunityAuthUrl(
    communityType: CommunityType,
    options: getCommunityAuthUrlOptionsType
  ): Promise<string> {
    return this.communityRepo.getCommunityAuthUrl(communityType, options)
  }

  async getWorkspaceTeams(
    communityType: CommunityType,
    options: GetWorkspaceTeamsOptions
  ): Promise<CommunityTeam[]> {
    try {
      return await this.communityRepo.getWorkspaceTeams(communityType, options)
    } catch (error) {
      console.error("getWorkspaceTeams error : ", error)
      return []
    }
  }

  async connectWorkspace(
    communityType: CommunityType,
    options: ConnectWorkspaceOptions
  ): Promise<Workspace | null> {
    try {
      return await this.communityRepo.connectWorkspace(communityType, options)
    } catch (error) {
      console.error("connectWorkspace error : ", error)
      return null
    }
  }

  async updateWorkspace(
    communityType: CommunityType,
    options: UpdateWorkspaceOptions
  ): Promise<void> {
    try {
      await this.communityRepo.updateWorkspace(communityType, options)
    } catch (error) {
      console.error("updateWorkspace error : ", error)
    }
  }

  async disconnectWorkspace(
    communityType: CommunityType,
    options: DisconnectWorkspaceOptions
  ): Promise<Workspace | null> {
    try {
      return await this.communityRepo.disconnectWorkspace(
        communityType,
        options
      )
    } catch (error) {
      console.error("disconnectWorkspace error : ", error)
      return null
    }
  }
}

export default CommunityUseCase
