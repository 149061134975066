import { Box, Flex, Grid, Image } from "@chakra-ui/react"
import { withSignOut } from "modules/User/src/hocs/withSignOut"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Sidebar } from "theme/components"

function Layout({ children, isPlatform, onSignOut }) {
  return (
    <Grid
      className="h-screen"
      templateColumns={{ base: "1fr", md: isPlatform ? "1fr" : "80px 1fr" }}
      templateRows={{
        base: "1fr",
        md: "1fr"
      }}
      bgImage={{ base: "none", md: "/assets/images/background.jpg" }}
    >
      {!isPlatform && (
        <>
          <Sidebar onSignOut={onSignOut}
            display={{ base: "none", md: "grid" }} />
        </>
      )}

      <Box overflowY="auto">
        <Box height="full" maxWidth="960px" mx="auto" mt={{
          base: 3,
          md: 0
        }}>
          {children}
        </Box>
      </Box>
    </Grid>
  )
}

export default withSignOut(Layout)

function MobileNavbar(props) {
  return (
    <Flex
      px="4"
      height="56px"
      width="full"
      align="center"
      justify="space-between"
      {...props}
    >
      <RouterLink to="/">
        <Image
          src="/assets/images/logo-symbol.png"
          width="32px"
          height="32px"
        />
      </RouterLink>
    </Flex>
  )
}
