import { Box } from "@chakra-ui/react"
import ContractDetails from "modules/Contract/src/components/ContractDetails"
import React from "react"
import LayoutVariant from "modules/Workspace/src/components/layouts/LayoutVariant"

function ContractPage({ contractDetailsProps, layoutVariant }) {
  if (!contractDetailsProps.contract?.id) {
    return <Layout layoutVariant={layoutVariant} />
  }

  return (
    <Layout layoutVariant={layoutVariant}>
      <ContractDetails {...contractDetailsProps} />
    </Layout>
  )
}

export default ContractPage

const Layout = ({ layoutVariant = "main", children }) => (
  <LayoutVariant variant={layoutVariant}>
    <Box overflow="auto" px="4" pb="8">
      {children ? children : <></>}
    </Box>
  </LayoutVariant>
)
