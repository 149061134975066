import { useQuery } from "react-query"
import { CDEUseCase } from "../core"
import { ICDEUserNormalized } from "../core/types"
import {
  useGetCDEAuthenticatedUserInputType,
  useGetCDEAuthenticatedUserOptionsType
} from "../core/types/hooks"

export const useGetCDEUser = (
  input: useGetCDEAuthenticatedUserInputType,
  options: useGetCDEAuthenticatedUserOptionsType = {}
) =>
  useQuery<ICDEUserNormalized, Error>(
    ["CDEUser", input.CDEType],
    () => CDEUseCase.getUser(input.CDEType),
    options
  )
