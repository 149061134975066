import { Box, Flex, Heading, VStack } from "@chakra-ui/react"
import LayoutVariant from "modules/Workspace/src/components/layouts/LayoutVariant"
import React from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import { Link as RouterLink } from "react-router-dom"
import { IconButton, SettingsAccordion } from "theme/components"
import { Discord, Jira, SourceProjectsList } from "../SourceSettings"
import { Slack } from "../SourceSettings/Slack"

const SettingsOptionsPage = ({
  source,
  jira,
  discord,
  slack,
  layoutVariant
}) => (
  <Layout layoutVariant={layoutVariant}>
    <VStack spacing="3" align="start">
      <Jira
        onJiraProjectConnect={jira.handleJiraProjectConnect}
        jiraWorkspaceOptions={jira.jiraWorkspaceOptions}
        jiraWorkspaceProjectOptions={jira.jiraWorkspaceProjectOptions}
        onWorkspaceChange={jira.handleWorkspaceChange}
        onJiraConnect={jira.handleJiraConnect}
        isLoadingJiraProjectConnect={jira.isLoadingJiraProjectConnect}
        onAccordionChange={jira.handleAccordionChange}
      />

      <Discord
        discordGuildOptions={discord.discordGuildOptions}
        discordGuildChannelOptions={discord.discordGuildChannelOptions}
        onDiscordGuildChange={discord.handleDiscordGuildChange}
        onGuildChannelConnect={discord.handleGuildChannelConnect}
        isLoadingDiscordChannelConnect={discord.isLoadingDiscordChannelConnect}
        isDiscordConnected={discord.isDiscordConnected}
        onDiscordConnect={discord.handleDiscordConnect}
        onAccordionChange={discord.handleAccordionChange}
      />

      <Slack
        slackWorkspaceOptions={slack.slackWorkspaceOptions}
        slackWorkspaceChannelOptions={slack.slackWorkspaceChannelOptions}
        onSlackWorkspaceChange={slack.handleSlackWorkspaceChange}
        onWorkspaceChannelConnect={slack.handleWorkspaceChannelConnect}
        isLoadingSlackChannelConnect={slack.isLoadingSlackChannelConnect}
        isSlackConnected={slack.isSlackConnected}
        onSlackConnect={slack.handleSlackConnect}
        onAccordionChange={slack.handleAccordionChange}
      />

      <DisabledSources />
    </VStack>

    <SourceProjectsList
      projects={source.connectedProjects}
      onProjectEdit={source.handleProjectEdit}
    />
  </Layout>
)

export default SettingsOptionsPage

const Layout = ({ children, layoutVariant }) => (
  <LayoutVariant
    bgImage="/assets/images/background.jpg"
    variant={layoutVariant}
  >
    <Header />

    <Box pt="3" px="4" pb="8" overflowY="auto">
      <VStack height="full" align="stretch" spacing={5}>
        {children}
      </VStack>
    </Box>
  </LayoutVariant>
)

function Header() {
  return (
    <Flex width="full" align="center" height="40px" px="4" pb="2">
      <IconButton
        icon={<IoArrowBackOutline />}
        aria-label="arrow-back"
        as={RouterLink}
        to="./"
        relative="path"
      />

      <Heading
        as="h3"
        fontSize="xl"
        lineheight="xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        ml="3"
        mb="-1"
      >
        Source
      </Heading>
    </Flex>
  )
}

function DisabledSources() {
  return (
    <>
      <SettingsAccordion name="Asana" iconName="asana" isDisabled />

      <SettingsAccordion name="Trello" iconName="trello" isDisabled />
    </>
  )
}
