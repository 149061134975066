const badgeTheme = {
  Badge: {
    baseStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold"
    },
    variants: {
      green: {
        color: "rgba(46, 181, 59)",
        bgColor: "rgba(46, 181, 59, 0.09)"
      },
      orange: {
        color: "rgba(255, 153, 0)",
        bgColor: "rgba(255, 153, 0, 0.09)"
      }
    },
    sizes: {
      sm: {
        rounded: "6px",
        height: "16px",
        px: "6px",
        fontSize: "xxs",
        lineHeight: "xxs"
      }
    }
  }
}

export default badgeTheme
