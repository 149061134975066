import { useQueryParams } from "hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { usePreviousPath } from "providers/PreviousPathProvider"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import {
  ContractTypes,
  WorkspaceActiveTab,
  WorkspacePageTabs
} from "../types/global"
import { useSoftDeleteWorkspace } from "./index"
import { ModalType } from "../../../../../providers/ModalProvider/types"

const useWorkspaceSettings = ({ workspaceId, activeTab }: Props) => {
  const { setQueryParams, removeQueryParams } = useQueryParams()
  const navigate = useNavigate()
  const previousPath = usePreviousPath()
  const isMobile = useIsMobile()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { mutateAsync: softDeleteWorkspaceAsync } = useSoftDeleteWorkspace()

  const deleteWorkspace = useCallback(async () => {
    if (!workspaceId) {
      return
    }

    try {
      await softDeleteWorkspaceAsync(workspaceId)
      modalDispatch({
        type: "update",
        onClose: () => {
        }
      })
      navigate("/?tab=workspaces")
    } catch (error) {
      console.error("delete workspace : ", error)
    }

    if (!isMobile) {
      modalDispatch({ type: "close" })
    }
  }, [workspaceId, isMobile])

  const openWorkspaceDeleteModal = () => {
    setQueryParams({
      tab: WorkspacePageTabs.WORKSPACE_DELETE_CONFIRM
    })
  }

  const isDeleteModalOpen = useMemo(
    () =>
      modalState.modalType === "confirmation" &&
      modalState.attributes.context === "workspace",
    [modalState.modalType]
  )

  const workspaceDeleteProps = useMemo(
    () => ({
      headerText: "Confirm workspace deletion",
      text: "Are you sure you want to delete this workspace?",
      onConfirm: deleteWorkspace,
      onCancel: () => navigate(previousPath || "./")
    }),
    [deleteWorkspace, previousPath]
  )

  const handleDeleteModalClose = useCallback(() => {
    if (!isMobile && activeTab === WorkspacePageTabs.WORKSPACE_DELETE_CONFIRM) {
      removeQueryParams("tab")
    }
  }, [activeTab, isMobile, removeQueryParams])

  useEffect(() => {
    if (isDeleteModalOpen) {
      modalDispatch({
        type: "update",
        props: workspaceDeleteProps,
        onClose: handleDeleteModalClose
      })
    }

    if (
      isDeleteModalOpen &&
      (activeTab !== WorkspacePageTabs.WORKSPACE_DELETE_CONFIRM || isMobile)
    ) {
      modalDispatch({ type: "close" })
    }

    if (
      activeTab === WorkspacePageTabs.WORKSPACE_DELETE_CONFIRM &&
      !isMobile &&
      !isDeleteModalOpen
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.Confirmation,
        props: workspaceDeleteProps,
        attributes: { context: "workspace" },
        onClose: handleDeleteModalClose
      })
    }
  }, [
    JSON.stringify(workspaceDeleteProps),
    isDeleteModalOpen,
    handleDeleteModalClose,
    isMobile,
    activeTab
  ])

  const handleArchivedContractsClick = useCallback(() => {
    setQueryParams({
      type: ContractTypes.ARCHIVED
    })
    removeQueryParams("tab")
  }, [setQueryParams])

  const handleArchivedContractsBackClick = useCallback(() => {
    removeQueryParams("type")
  }, [removeQueryParams])


  return {
    openWorkspaceDeleteModal,
    workspaceDeleteProps,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  }
}

export default useWorkspaceSettings

type Props = {
  workspaceId: string
  activeTab: WorkspaceActiveTab
}
