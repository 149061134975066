import { FormEvent, useMemo, useState } from "react"

const useWorkspaceCreateForm = (onSubmit: OnSubmit) => {
  const [name, setName] = useState("")
  const handleNameChange = (event: any) => setName(event.target.value)

  const isDisabledCreateButton = useMemo(() => !name.trim(), [name])

  const handleWorkspaceCreate = (event: FormEvent) => {
    event.preventDefault()

    const nameNormalized = name.trim()

    if (nameNormalized && onSubmit) {
      onSubmit(nameNormalized)
    }
  }

  return {
    name,
    isDisabledCreateButton,
    handleWorkspaceCreate,
    handleNameChange
  }
}

export default useWorkspaceCreateForm

type OnSubmit = (name: string) => void
