import CDEAPIService from "../services/CDEAPIService"
import { CDEType } from "../types"

class CDEAPIRepository {
  constructor(private readonly CDEAPIService: CDEAPIService) {}

  getUser(CDEType: CDEType) {
    return this.CDEAPIService.getUser(CDEType)
  }

  getAllCodespaces(CDEType: CDEType) {
    return this.CDEAPIService.getAllCodespaces(CDEType)
  }
}

export default CDEAPIRepository
