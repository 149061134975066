import CommunitySettingsPage
  from "modules/Community/src/components/CommunitySettingsPage"
import {
  ContractChat,
  ContractCreatePage,
  ContractDelegatePage,
  ContractSettingsPage
} from "modules/Contract/src/components"
import SourceSettingsPage
  from "modules/Source/src/components/SourceSettingsPage"
import {
  WorkspaceSettingsPage,
  WorkspaceTeamsPage
} from "modules/Workspace/src/components"
import WorkspaceTeamEditPage
  from "modules/Workspace/src/components/WorkspaceTeamEditPage"
import PlatformAdminLayout
  from "modules/Workspace/src/components/layouts/PlatformAdminLayout"
import React from "react"
import { ConfirmationPage } from "theme/components/ui"
import ContractPage from "./ContractPage"
import MenuMobile from "./MenuMobile"
import WorkspaceMobile from "./WorkspaceMobile"

function WorkspaceMobileTabRouter({
  workspace,
  contractSidebarProps,
  contractDetailsProps,
  contractSettingsProps,
  workspaceDeleteProps,
  sourceSettingsProps,
  communitySettingsProps,
  CDESettingsProps,
  handleContractCreate,
  handleContractCreateClick,
  teamsProps,
  workspaceTeamEditProps,
  contractDelegateProps,
  activeTab,
  navigateToWorkspace,
  onArchivedContractsClick,
  onChatBackClick,
  user
}) {
  switch (activeTab) {
    case "menu":
      return <MenuMobile />
    case "teams":
      return (
        <WorkspaceTeamsPage {...teamsProps} layoutVariant="platformAdmin" />
      )
    case "contract":
      return (
        <ContractPage
          contractDetailsProps={contractDetailsProps}
          layoutVariant="platformAdmin"
        />
      )
    case "workspace-settings":
      return (
        <WorkspaceSettingsPage
          onArchivedContractsClick={onArchivedContractsClick}
        />
      )
    case "contract-delegate":
      return <ContractDelegatePage {...contractDelegateProps} />
    case "contract-settings":
      return <ContractSettingsPage {...contractSettingsProps} />
    case "contract-create":
      return (
        <PlatformAdminLayout>
          <ContractCreatePage
            onCreate={handleContractCreate}
            onCancel={navigateToWorkspace}
          />
        </PlatformAdminLayout>
      )
    case "chat":
      return (
        <PlatformAdminLayout>
          <ContractChat
            user={user} onBackClick={onChatBackClick}
            contract={contractDetailsProps.contract}
          />
        </PlatformAdminLayout>
      )
    case "workspace-delete-confirm":
      return <ConfirmationPage {...workspaceDeleteProps} />
    case "source":
      return (
        <SourceSettingsPage
          {...sourceSettingsProps}
          layoutVariant="platformAdmin"
        />
      )
    case "community":
      return (
        <CommunitySettingsPage
          {...communitySettingsProps}
          layoutVariant="platformAdmin"
        />
      )
    case "workspace-team-edit":
      return <WorkspaceTeamEditPage {...workspaceTeamEditProps} />
    // case "cde":
    // return (
    //   <CDESettingsPage {...CDESettingsProps} layoutVariant="platformAdmin" />
    // )
    default:
      return (
        <WorkspaceMobile
          workspace={workspace}
          contractSidebarProps={contractSidebarProps}
          onContractCreateClick={handleContractCreateClick}
        />
      )
  }
}

export default WorkspaceMobileTabRouter
