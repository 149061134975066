import {
  UpdateWorkspaceOptionsWorkspaceTeam
} from "modules/Community/src//core/types"
import {
  useDisconnectCommunityWorkspace,
  useGetCommunityWorkspaceTeams,
  useUpdateCommunityWorkspace
} from "modules/Community/src/core/hooks"
import {
  WorkspaceTeamEditProps
} from "modules/Workspace/src//core/hooks/useWorkspaceTeams/types"
import {
  WorkspaceTeam,
  WorkspaceTeamOption,
  WorkspaceTeamOptionsGroup
} from "modules/Workspace/src//core/types/workspace-team"
import {
  DEFAULT_EMOJI_BG_COLOR,
  DEFAULT_TEAM_EMOJI,
  WORKSPACE_TEAM_GROUPS
} from "modules/Workspace/src/core/config"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Workspace } from "../../types"

export const useWorkspaceTeamsSlack = ({
  workspace,
  workspaceTeams = []
}: Props) => {
  // community
  const { mutateAsync: updateCommunityWorkspaceAsync } =
    useUpdateCommunityWorkspace()
  const { mutateAsync: disconnectCommunityWorkspaceAsync } =
    useDisconnectCommunityWorkspace()

  // community

  // team edit
  const [activeEditSlackWorkspaceId, setActiveEditSlackWorkspaceId] = useState<
    string | null
  >(null)
  const { data: editSlackWorkspaceTeams = [] } = useGetCommunityWorkspaceTeams(
    {
      communityType: "slack",
      options: {
        slackWorkspaceId: activeEditSlackWorkspaceId || ""
      }
    },
    {
      enabled: !!activeEditSlackWorkspaceId,
      keepPreviousData: false
    }
  )

  const [editSlackWorkspaceTeamOptions, setEditSlackWorkspaceTeamOptions] =
    useState<WorkspaceTeamOption[]>([])

  useEffect(() => {
    if (!activeEditSlackWorkspaceId || !editSlackWorkspaceTeams.length) {
      setEditSlackWorkspaceTeamOptions([])
      return
    }

    const connectedSlackWorkspaceTeams = workspaceTeams?.filter(
      team => team.communityWorkspaceId === activeEditSlackWorkspaceId
    )

    const editSlackWorkspaceTeamOptions = editSlackWorkspaceTeams.map(team => {
      const connectedTeam = connectedSlackWorkspaceTeams.find(
        connectedTeam => connectedTeam.teamId === team.id
      )

      return {
        id: team.id,
        name: team.name,
        emoji: connectedTeam?.emoji || workspace?.defaultTeamEmoji?.emoji || DEFAULT_TEAM_EMOJI,
        emojiBgColor: connectedTeam?.emojiBgColor || workspace?.defaultTeamEmoji?.backgroundColor || DEFAULT_EMOJI_BG_COLOR,
        type: team.type,
        isSelected: !!connectedTeam
      }
    }) as WorkspaceTeamOption[]

    setEditSlackWorkspaceTeamOptions(editSlackWorkspaceTeamOptions)
  }, [
    activeEditSlackWorkspaceId,
    editSlackWorkspaceTeams.length,
    workspaceTeams?.length
  ])

  const teamOptionsGroups = useMemo<WorkspaceTeamOptionsGroup[]>(
    () =>
      WORKSPACE_TEAM_GROUPS.map(group => {
        const teamOptions =
          editSlackWorkspaceTeamOptions?.filter(
            team => team.type === group.type
          ) || []

        return {
          ...group,
          teamOptions
        }
      }),
    [JSON.stringify(editSlackWorkspaceTeamOptions)]
  )

  const handleSlackWorkspaceDisconnect = async (slackWorkspaceId: string) => {
    await disconnectCommunityWorkspaceAsync({
      communityType: "slack",
      options: {
        slackWorkspaceId,
        workspaceId: workspace?.id || ""
      }
    })
  }

  const handleSlackWorkspaceUpdate = async (slackWorkspaceId: string) => {
    const selectedEditSlackWorkspaceTeamOptions =
      editSlackWorkspaceTeamOptions.filter(team => team.isSelected)

    const updatedSlackWorkspaceTeams: UpdateWorkspaceOptionsWorkspaceTeam[] =
      selectedEditSlackWorkspaceTeamOptions.map(team => {
        const teamInWorkspaceTeams = workspaceTeams?.find(
          workspaceTeam => workspaceTeam.teamId === team.id
        )

        const updatedWorkspaceTeam: UpdateWorkspaceOptionsWorkspaceTeam = {
          teamId: team.id,
          emoji: team.emoji,
          emojiBgColor: team.emojiBgColor,
          type: team.type,
          name: team.name
        }

        if (teamInWorkspaceTeams) {
          updatedWorkspaceTeam.id = teamInWorkspaceTeams.id
        }

        return updatedWorkspaceTeam
      })

    await updateCommunityWorkspaceAsync({
      communityType: "slack",
      options: {
        workspaceId: workspace?.id || "",
        workspaceTeams: updatedSlackWorkspaceTeams,
        slackWorkspaceId
      }
    })
  }

  const handleEditTeamOptionChange = (newTeam: WorkspaceTeamOption) => {
    const newEditSlackWorkspaceTeamOptions = editSlackWorkspaceTeamOptions.map(
      team => {
        if (team.id === newTeam.id) {
          return {
            ...team,
            ...newTeam
          }
        }

        return team
      }
    )

    setEditSlackWorkspaceTeamOptions(newEditSlackWorkspaceTeamOptions)
  }

  const getTeamEditProps = useCallback(
    (slackWorkspaceId: string): WorkspaceTeamEditProps => {
      setActiveEditSlackWorkspaceId(slackWorkspaceId)
      const slackWorkspace = workspace?.slackWorkspaces?.find(
        slackWorkspace => slackWorkspace.id === activeEditSlackWorkspaceId
      )
      const communityWorkspace: WorkspaceTeamEditProps["communityWorkspace"] = {
        id: slackWorkspace?.id || "",
        name: slackWorkspace?.name || "",
        communityType: "slack",
        iconName: "slack"
      }

      return {
        communityWorkspace,
        teamOptionsGroups,
        onTeamOptionChange: handleEditTeamOptionChange,
        onDisconnect: handleSlackWorkspaceDisconnect,
        onUpdate: handleSlackWorkspaceUpdate
      }
    },
    [
      activeEditSlackWorkspaceId,
      JSON.stringify(workspace?.slackWorkspaces),
      JSON.stringify(teamOptionsGroups)
    ]
  )
  const resetEditForm = () => {
    setActiveEditSlackWorkspaceId(null)
    setEditSlackWorkspaceTeamOptions([])
  }

  // team edit

  return {
    getTeamEditProps,
    handleSlackWorkspaceUpdate,
    resetEditForm
  }
}

type Props = {
  workspace: Workspace | null
  workspaceTeams: WorkspaceTeam[]
}
