import {
  useCreateComment,
  useGetContractComments
} from "modules/Message/src/core/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { useMemo } from "react"
import { IContractNormalized } from "../types"
import { formatMessageTimestamp } from "../utils"

const useContractComments = (
  user: IUserNormalized | null,
  contract: IContractNormalized | null
) => {
  const { mutateAsync: createCommentAsync } = useCreateComment()

  const { data: contractComments } = useGetContractComments(
    {
      contractPk: contract?.pk || "",
    },
    {
      enabled: !!contract?.pk,
      keepPreviousData: false
    }
  )
  const contractCommentsNormalized = useMemo(
    () =>
      contractComments?.items.map(comment =>  ({
        ...comment,
        timestamp: formatMessageTimestamp(comment.createdAt)
      })) || [],
    [JSON.stringify(contractComments)]
  )

  const handleCommentCreate = (message: string) => {
    if (!user || !contract) {
      return
    }

    createCommentAsync({
      message,
      username: user.name,
      contractId: contract.id,
      contractPk: contract.pk,
      ownerId: user.id
    })
  }

  return {
    contractComments: contractCommentsNormalized,
    handleCommentCreate
  }
}

export default useContractComments
