import { IUserNormalized } from "modules/User/src/core/types"
import { useEffect, useMemo, useState } from "react"
import { IContractNormalized } from "../../../../Contract/src/core/types"
import { useCreateComment } from "./useCreateComment"
import { useGetContractComments } from "./useGetContractComments"
import { Message } from "../types"
import {
  useContractCommunityMessages
} from "../../../../Contract/src/core/hooks"
import moment from "moment"

export const useContractChat = (
  user: IUserNormalized | null,
  contract: IContractNormalized | null
) => {
  const { contractCommunityMessages } = useContractCommunityMessages(contract)
  const [nextToken, setNextToken] = useState<string | null>(null)

  const { mutateAsync: createMessageAsync } = useCreateComment()

  const getContractMessagesInput = useMemo(() => {
    const input: any = {
      contractPk: contract?.pk || "",
      filter: {
        type: { eq: "COMMENT" }
      }
    }

    if (nextToken) {
      input.nextToken = nextToken
    }

    return input
  }, [contract?.pk, nextToken])

  const { data: contractMessagesData } = useGetContractComments(
    getContractMessagesInput,
    {
      enabled: !!contract?.pk,
      keepPreviousData: true,
      onSuccess: (data) => {
        setNextToken?.(data.nextToken)
      }
    }
  )
  const [allMessages, setAllMessages] = useState<Message[]>([])

  useEffect(() => {
    if (contractMessagesData?.items) {
      setAllMessages(prevMessages => {
        const existingIds = new Set(prevMessages.map(message => message.id))
        const newUniqueMessages = contractMessagesData.items.filter(message => !existingIds.has(message.id))
        return [...prevMessages, ...newUniqueMessages]
      })
    }
  }, [contractMessagesData?.items])

  const handleMessageCreate = (message: string) => {
    if (!user || !contract) {
      return
    }

    createMessageAsync({
      message,
      username: user.name,
      contractId: contract.id,
      contractPk: contract.pk,
      ownerId: user.id
    })
  }

  const contractMessages = useMemo(() => {
    const combinedMessages = [...contractCommunityMessages, ...allMessages]
    const messagesMap = new Map(combinedMessages.map(message => [message.id, message]))
    const uniqueMessages = Array.from(messagesMap.values())
    const sortedMessages = uniqueMessages.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    return sortedMessages.map(message => ({
      ...message,
      timestamp: moment(message.createdAt).format("D.MM.YYYY HH:mm"),
      isCurrentUser: message.ownerId === user?.id,
    }))
  }, [contractCommunityMessages.length, allMessages.length, user?.id])
  return {
    contractMessages,
    handleMessageCreate
  }
}
