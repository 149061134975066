import { ChakraProvider as ReactChakraProvider } from "@chakra-ui/react"
import React from "react"
import theme from "theme"
import Fonts from "theme/fonts"

function ChakraProvider({ children }) {
  return (
    <ReactChakraProvider theme={theme}>
      <Fonts />

      {children}
    </ReactChakraProvider>
  )
}

export default ChakraProvider
