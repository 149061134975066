import { Workspace } from "modules/Workspace/src//core/types"
import { useQuery } from "react-query"
import { workspaceUseCase } from "../index"
import { useGetWorkspaceOptionsType } from "../types/hooks"

export const useGetWorkspace = (
  id: string,
  options: useGetWorkspaceOptionsType = {}
) =>
  useQuery<Workspace | null, Error>(
    ["workspace", id],
    () => workspaceUseCase.getWorkspace(id),
    options
  )
