import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { userUseCase } from "../index"
import { ICognitoUser } from "../types"

const useSignInWithToken = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (input: FnInput) => userUseCase.signInWithToken(input.email, input.token),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries("currentUser")

        if (options.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      }
    }
  )
}

export default useSignInWithToken

type FnInput = {
  email: string
  token: string
}

type Options = Omit<
  UseMutationOptions<ICognitoUser, unknown, FnInput, unknown>,
  "mutationFn"
>
