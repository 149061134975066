import {
  PlatformContractDetails,
  ContractsSidebarLight, ContractChat
} from "modules/Contract/src/components"
import React from "react"
import EmptyWorkspace from "../EmptyWorkspace"
import Layout from "./Layout"
import LoadingWorkspace from "../LoadingWorkspace"

function WorkspaceDesktop({
  contractSidebarProps,
  contractDetailsProps,
  onContractCreateClick,
  platformConfig,
  user,
  activeTab,
  onChatBackClick
}) {
  if (contractSidebarProps.isLoading) {
    return <LoadingWorkspace />
  }

  if (!contractDetailsProps.contract?.id) {
    return <EmptyWorkspace onContractCreateClick={onContractCreateClick} />
  }

  return (
    <Layout
      onContractCreateClick={onContractCreateClick}
      platformConfig={platformConfig}
    >
      <ContractsSidebarLight {...contractSidebarProps} />

      {activeTab === "chat" ? (
        <ContractChat
          contract={contractDetailsProps.contract}
          user={user}
          onBackClick={onChatBackClick}
        />
      ) : (
        <PlatformContractDetails
          {...contractDetailsProps}
          platformConfig={platformConfig}
        />
      )}
    </Layout>
  )
}

export default WorkspaceDesktop
