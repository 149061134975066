import { FormEvent, useMemo, useState } from "react"

export const useContractCreateForm = (onSubmit: OnSubmit) => {
  const [name, setName] = useState("")
  const handleNameChange = (event: any) => setName(event.target.value)

  const [description, setDescription] = useState("")
  const handleDescriptionChange = (event: any) =>
    setDescription(event.target.value)

  const isDisabledCreateButton = useMemo(() => !name.trim(), [name])

  const handleContractCreate = (event: FormEvent) => {
    event.preventDefault()

    const nameNormalized = name.trim()
    const descriptionNormalized = description.trim()

    if (nameNormalized && onSubmit) {
      onSubmit(nameNormalized, descriptionNormalized)
    }
  }

  return {
    name,
    description,
    isDisabledCreateButton,
    handleContractCreate,
    handleNameChange,
    handleDescriptionChange
  }
}

type OnSubmit = (name: string, description: string) => void
