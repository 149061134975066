import {
  useQueryClient
} from "react-query"
import { platformConfigUseCase } from "../core"
import { ModelPlatformConfigFilterInput } from "../core/graphql/types"

const useGetAdminPlatformConfigAsync = () => {
  const queryClient = useQueryClient()

  return async (filter: ModelPlatformConfigFilterInput) =>
    queryClient.fetchQuery(["adminPlatformConfig", filter], () =>
      platformConfigUseCase.getAdminPlatformConfig(filter)
    )
}

export default useGetAdminPlatformConfigAsync
