import { Auth } from "aws-amplify"

const withCognitoAccessToken = async (init: any = {}): Promise<any> => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()
  const initWithAccessToken = {
    ...init,
    headers: {
      ...init.headers,
      "x-api-key": token
    }
  }

  return initWithAccessToken
}

export default withCognitoAccessToken
