import {
  Box,
  Flex,
  Grid,
  LinkBox,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Checkbox, Icon } from "theme/components"

const ContractsTab = ({
  contracts = [],
  onCreateClick,
  onDelegate,
  onCompleteToggle
}) =>
  <Layout onCreateClick={onCreateClick}>
    {contracts.map(contract => (
      <ContractCard
        key={contract.id}
        contract={contract}
        onDelegate={onDelegate}
        onCompleteToggle={onCompleteToggle}
      />
    ))}
  </Layout>

export default ContractsTab

const Layout = ({ onCreateClick, children }) =>
  <Grid height="full" templateRows="1fr auto">
    <VStack
      align="stretch"
      overflowY="scroll"
      p={{ base: "3", md: "5" }}
      spacing="3"
    >
      {children}
    </VStack>

    <Footer onCreateClick={onCreateClick} />
  </Grid>

function Footer({ onCreateClick }) {
  return (
    <Grid
      height="50px"
      placeItems="center"
      position="relative"
      px="5"
      _before={{
        content: "\"\"",
        position: "absolute",
        height: "1px",
        bgColor: "hsla(0, 0%, 92%, 0.1)",
        left: 5,
        right: 5,
        top: 0
      }}
    >
      <Button variant="textWhite" onClick={onCreateClick}>
        <Grid
          placeItems="center"
          height="6"
          width="6"
          bgColor="primaryDark"
          rounded="full"
          mr="2"
        >
          <Icon name="plus-8" />
        </Grid>
        Create Contract
      </Button>
    </Grid>
  )
}

function ContractCard({ contract, onDelegate, onCompleteToggle }) {
  const [isLoadingDelegate, setIsLoadingDelegate] = useState(false)
  const isDelegated = useMemo(
    () => contract.workspaceId,
    [contract.workspaceId]
  )

  const handleDelegate = event => {
    event.preventDefault()
    setIsLoadingDelegate(true)

    const onSuccess = () => {
      setIsLoadingDelegate(false)
    }

    onDelegate(contract.id, {
      onSuccess
    })
  }

  const handleCompleteToggle = () => {
    onCompleteToggle(contract.id)
  }

  return (
    <LinkBox display="grid" bgColor="white" rounded="12px" px="4" py="3">
      <Flex justify="space-between" align="start">
        <Text
          fontSize={{ base: "md", md: "lg" }}
          lineHeight={{ base: "md", md: "lg" }}
          color="primaryDark"
          noOfLines={2}
        >
          <LinkOverlay as={RouterLink} to={`/contract/${contract.id}`}>
            {contract.name}
          </LinkOverlay>
        </Text>

        <CompleteCheckbox
          isChecked={contract.completed}
          onChange={handleCompleteToggle}
        />
      </Flex>

      <Flex mt="4" justify={isDelegated ? "flex-end" : "space-between"}>
        {!isDelegated ? (
          <Button
            size="md"
            px="4"
            onClick={handleDelegate}
            isLoading={isLoadingDelegate}
            loadingText="Delegating..."
          >
            Delegate
          </Button>
        ) : null}

        <Icon
          name="arrow-right"
          color="primaryDark"
          size="14px"
          alignSelf="center"
          ml="4"
        />
      </Flex>
    </LinkBox>
  )
}

function CompleteCheckbox({ isChecked, onChange }) {
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()

    onChange && onChange(event)
  }

  return (
    <Box
      display="inline-flex"
      data-element="complete-checkbox"
      onClick={handleClick}
      ml="3"
    >
      <Checkbox ml="auto" isChecked={isChecked} onChange={onChange} />
    </Box>
  )
}
