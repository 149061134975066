export const onCreateContract = /* GraphQL */ `
  subscription OnCreateContract($filter: ModelSubscriptionContractFilterInput) {
    onCreateContract(filter: $filter) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      pointEstimation
      sourceContractPk
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
      }
      predefinedTeamIds
      workspaceId
      completed
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
      }
      githubCodespaces {
        id
        name
        link
      }
      createdAt
      updatedAt
    }
  }
`
export const onUpdateContract = /* GraphQL */ `
  subscription OnUpdateContract($filter: ModelSubscriptionContractFilterInput) {
    onUpdateContract(filter: $filter) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      pointEstimation
      sourceContractPk
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
      }
      predefinedTeamIds
      workspaceId
      completed
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
      }
      githubCodespaces {
        id
        name
        link
      }
      createdAt
      updatedAt
    }
  }
`
