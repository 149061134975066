const drawerTheme = {
  Drawer: {
    variants: {
      contractAction: {
        dialog: {
          border: "1px solid",
          borderColor: "bgSmall",
          roundedTop: "12px",
          shadow: "popover",
          height: "50%"
        },
        header: {
          pt: "4",
          pb: "3",
          pl: "30px",
          pr: "10",
          fontSize: "sm",
          lineHeight: "sm",
          fontWeight: "medium",
          borderBottom: "1px solid",
          borderColor: "line"
        },
        closeButton: {
          p: "0",
          minW: "4",
          width: "4",
          height: "4",
          _hover: { bg: "transparent" },
          _active: { bg: "transparent" },
          _focus: { boxShadow: "none" },
          right: "6",
          top: "4"
        },
        body: {
          px: "4",
          py: "6"
        }
      }
    }
  }
}

export default drawerTheme
