import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { workspaceTeamUseCase } from "../index"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType
} from "../types/workspace-team"

const useDeleteWorkspaceTeams = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "deleteWorkspaceTeams",
    ({ teamIds }: FnInput) =>
      workspaceTeamUseCase.deleteWorkspaceTeams(teamIds),
    {
      onSettled: (data, _error, { workspaceId }) => {
        const deletedWorkspaceTeams = (data as WorkspaceTeam[]) || []

        queryClient.setQueryData<WorkspaceTeam[]>(
          ["workspaceTeams", workspaceId],
          workspaceTeams =>
            removeWorkspaceTeams(workspaceTeams || [], deletedWorkspaceTeams)
        )

        if (
          deletedWorkspaceTeams[0].communityType ===
          WorkspaceTeamCommunityType.EMAIL
        ) {
          queryClient.setQueryData<WorkspaceTeam[]>(
            [
              "workspaceTeams",
              workspaceId,
              WorkspaceTeamCommunityType.EMAIL
            ],
            workspaceTeams =>
              removeWorkspaceTeams(workspaceTeams || [], deletedWorkspaceTeams)
          )
        }
      },
      ...options
    }
  )
}

export default useDeleteWorkspaceTeams

const removeWorkspaceTeams = (
  workspaceTeams: WorkspaceTeam[],
  deletedWorkspaceTeams: WorkspaceTeam[]
) =>
  workspaceTeams.filter(
    workspaceTeam =>
      !deletedWorkspaceTeams.find(
        deletedWorkspaceTeam => deletedWorkspaceTeam.id === workspaceTeam.id
      )
  )

export type FnInput = {
  workspaceId: string
  teamIds: string[]
}

export type Options = Omit<
  UseMutationOptions<unknown, Error, FnInput>,
  "mutationFn"
>
