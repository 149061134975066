import React from "react"
import { useSignOut } from "../core/hooks"

export function withSignOut(Component) {
  return function WrappedComponent(props) {
    const {
      mutateAsync: signOutAsync,
      isLoading: isLoadingSignOut
    } = useSignOut()

    const handleSignOut = () => {
      try {
        signOutAsync()
      } catch (error) {
        console.error("Sign out : ", error)
      }
    }

    return <Component onSignOut={handleSignOut} isLoadingSignOut={isLoadingSignOut} {...props} />
  }
}
