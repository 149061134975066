import {
  ButtonGroup,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from "@chakra-ui/react"
import TeamOptionsGroupAccordion from "modules/Workspace/src/components/TeamOptionsGroupAccordion"
import React from "react"
import { Button } from "theme/components"

function WorkspaceTeamEditModal({
  communityWorkspace = {},
  teamOptionsGroups,
  onTeamOptionChange,
  onUpdate,
  onDisconnect,
  closeModal,
  isOpen,
  showDisconnect = true
}) {
  const handleUpdate = () => {
    onUpdate && onUpdate(communityWorkspace.id)
  }

  const handleDisconnect = () => {
    onDisconnect && onDisconnect(communityWorkspace.id)
  }

  return (
    <Layout
      isOpen={isOpen}
      closeModal={closeModal}
      onUpdate={handleUpdate}
      onDisconnect={handleDisconnect}
      showDisconnect={showDisconnect}
    >
      <Heading as="h4" fontSize="4xl" lineHeight="4xl" fontWeight="bold">
        Configure Your Teams
      </Heading>

      <Text fontSize="xs" lineHeight="xs" mt="1" color="secondary">
        Click on Emojis to change the design icon
      </Text>

      <Grid
        mt="18px"
        templateRows="min-content min-content"
        width="full"
        height="fit-content"
      >
        <WorkspaceTeamOptions
          teamOptionsGroups={teamOptionsGroups}
          onTeamOptionChange={onTeamOptionChange}
        />
      </Grid>
    </Layout>
  )
}

export default WorkspaceTeamEditModal

const Layout = ({
  isOpen,
  closeModal,
  children,
  onUpdate,
  onDisconnect,
  showDisconnect
}) => (
  <Modal isOpen={isOpen} onClose={closeModal} variant="edit" size="lg">
    <ModalOverlay />

    <ModalContent>
      <ModalBody
        bgImage="/assets/images/configure.png"
        bgRepeat="no-repeat"
        bgPosition="right calc(100% + 32px)"
      >
        {children}
      </ModalBody>

      <Footer
        closeModal={closeModal}
        onUpdate={onUpdate}
        onDisconnect={onDisconnect}
        showDisconnect={showDisconnect}
      />
    </ModalContent>
  </Modal>
)

const Footer = ({ closeModal, onUpdate, onDisconnect, showDisconnect }) => (
  <ModalFooter>
    {showDisconnect ? (
      <Button
        variant="textDark"
        color="#666666"
        width="80px"
        onClick={onDisconnect}
      >
        Disconnect
      </Button>
    ) : null}

    <ButtonGroup spacing="3" width="full" justifyContent="end">
      <Button
        variant="textDark"
        color="#666666"
        onClick={closeModal}
        width="80px"
      >
        Cancel
      </Button>

      <Button onClick={onUpdate} width="112px">
        Update
      </Button>
    </ButtonGroup>
  </ModalFooter>
)

const WorkspaceTeamOptions = ({
  teamOptionsGroups = [],
  onTeamOptionChange
}) => (
  <VStack align="stretch" w="full" maxW="380px" spacing="4">
    {teamOptionsGroups.map(teamGroup =>
      teamGroup.teamOptions.length ? (
        <TeamOptionsGroupAccordion
          key={teamGroup.type}
          groupName={teamGroup.label}
          teamOptions={teamGroup.teamOptions}
          onTeamOptionChange={onTeamOptionChange}
          defaultIndex={0}
          isTeamEmojiEditable
        />
      ) : null
    )}
  </VStack>
)
