import { useQuery, UseQueryOptions } from "react-query"
import { messageUseCase } from "../index"
import { FindByContractPkInput, ListMessagesResponse } from "../types"

export const useGetContractComments = (
  input: FindByContractPkInput,
  options: UseQueryOptions<ListMessagesResponse, Error> = {}
) =>
  useQuery<ListMessagesResponse, Error>(
    ["contractComments", input.contractPk],
    () => messageUseCase.getContractComments(input),
    options
  )
