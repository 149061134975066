import React from "react"
import SettingsOptionsPage from "./SettingsOptionsPage"

const CommunitySettingsPage = ({
  community,
  discord,
  slack,
  email,
  layoutVariant
}) => (
  <SettingsOptionsPage
    community={community}
    discord={discord}
    slack={slack}
    email={email}
    layoutVariant={layoutVariant}
  />
)

export default CommunitySettingsPage
