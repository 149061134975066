/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContractResource = /* GraphQL */ `
  mutation CreateContractResource(
    $input: CreateContractResourceInput!
    $condition: ModelContractResourceConditionInput
  ) {
    createContractResource(input: $input, condition: $condition) {
      id
      contractPk
      type
      size
      name
      url
      createdAt
      updatedAt
    }
  }
`
export const updateContractResource = /* GraphQL */ `
  mutation UpdateContractResource(
    $input: UpdateContractResourceInput!
    $condition: ModelContractResourceConditionInput
  ) {
    updateContractResource(input: $input, condition: $condition) {
      id
      contractPk
      type
      size
      name
      url
      createdAt
      updatedAt
    }
  }
`
export const deleteContractResource = /* GraphQL */ `
  mutation DeleteContractResource(
    $input: DeleteContractResourceInput!
    $condition: ModelContractResourceConditionInput
  ) {
    deleteContractResource(input: $input, condition: $condition) {
      id
      contractPk
      type
      size
      name
      url
      createdAt
      updatedAt
    }
  }
`
