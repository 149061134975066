import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { workspaceTeamUseCase } from "../index"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType,
  WorkspaceTeamCreateInput
} from "../types/workspace-team"
import { GetWorkspaceTeamsByWorkspaceIdResponse } from "../types"

const useCreateWorkspaceTeams = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createWorkspaceTeams",
    (workspaceTeamsCreateInput: FnInput) =>
      workspaceTeamUseCase.createWorkspaceTeams(workspaceTeamsCreateInput),
    {
      onSettled: (data, _error, variables) => {
        const createdWorkspaceTeams = (data as WorkspaceTeam[]) || []

        queryClient.setQueryData<GetWorkspaceTeamsByWorkspaceIdResponse>(
          ["workspaceTeams", variables[0].workspaceId],
          (workspaceTeams = { items: [], nextToken: null }) => ({
            items: [...createdWorkspaceTeams, ...workspaceTeams.items],
            nextToken: workspaceTeams.nextToken
          })
        )

        if (variables[0].communityType === WorkspaceTeamCommunityType.EMAIL) {
          queryClient.setQueryData<GetWorkspaceTeamsByWorkspaceIdResponse>(
            [
              "workspaceTeams",
              variables[0].workspaceId,
              WorkspaceTeamCommunityType.EMAIL
            ],
            (workspaceTeams) => {
              if (!workspaceTeams) {
                return {
                  items: createdWorkspaceTeams,
                  nextToken: null
                }
              }

              return {
                items: [...createdWorkspaceTeams, ...workspaceTeams.items],
                nextToken: workspaceTeams.nextToken
              }
            }
          )
        }
      },
      ...options
    }
  )
}

export default useCreateWorkspaceTeams

export type FnInput = WorkspaceTeamCreateInput[]

export type Options = Omit<
  UseMutationOptions<unknown, Error, FnInput>,
  "mutationFn"
>
