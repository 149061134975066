import { Button as ChakraButton, Image } from "@chakra-ui/react"
import React, { forwardRef } from "react"

export const IconButton = forwardRef(
  ({ iconName, icon, size = "md", ...props }, ref) => {
    return (
      <ChakraButton
        ref={ref}
        as={props.href ? "a" : "button"}
        variant="icon"
        bgColor="transparent"
        shadow="unset"
        p="0"
        size={`icon-${size}`}
        borderRadius="0"
        _focus={{
          outline: "none",
          boxShadow: "none",
          border: "none"
        }}
        _hover={{
          bgColor: "transparent"
        }}
        _active={{
          bgColor: "transparent"
        }}
        {...props}
      >
        {icon ? (
          icon
        ) : (
          <Image
            src={`/assets/icons/${iconName}.svg`}
            alt={iconName}
            maxW="full"
            maxH="full"
          />
        )}
      </ChakraButton>
    )
  }
)
IconButton.displayName = "IconButton"
