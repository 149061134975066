import ContractResourceCRUDRepository
  from "../repositories/ContractResourceCRUDRepository"
import FileRepo from "../repositories/FileRepo"
import {
  ContractResource,
  ContractResourceCreateInput,
  ContractResourceType
} from "../types/contract-resource-model-types"

class ContractResourceUseCase {
  constructor(
    private readonly contractResourceCRUDRepository: ContractResourceCRUDRepository,
    private readonly fileRepo: FileRepo
  ) {
  }

  async findByIds(ids: string[]): Promise<ContractResource[]> {
    try {
      return await Promise.all(
        ids.map(id => this.contractResourceCRUDRepository.getById(id))
      )
    } catch (error) {
      console.error("contractResourceUseCase.findByIds :", error)
      return []
    }
  }

  async getContractResources(contractPk: string): Promise<ContractResource[]> {
    try {
      return await this.contractResourceCRUDRepository.findByContractPk(
        contractPk,
        {
          or: [
            { type: { eq: ContractResourceType.CONTRACT_IMAGE } },
            { type: { eq: ContractResourceType.CONTRACT_FILE }}
          ] as never
        }
      )
    } catch (error) {
      console.error("contractResourceUseCase.findByContractPk :", error)
      return []
    }
  }

  async createOne(
    input: ContractResourceCreateInput
  ): Promise<ContractResource | null> {
    try {
      return await this.contractResourceCRUDRepository.createOne(input)
    } catch (error) {
      console.error("contractResourceUseCase.createOne :", error)
      return null
    }
  }

  async uploadFiles(files: File[]) {
    try {
      return await Promise.all(
        files.map(async file => {
          const randomNumber = Math.floor(Math.random() * 100000000000000000000)
          const timestamp = new Date().getTime()
          const fileName = `${randomNumber}${timestamp}-${file.name}`
          const fileLink = await this.fileRepo.uploadFile(file, fileName)

          return {
            name: file.name,
            size: file.size,
            type: file.type,
            url: fileLink
          }
        })
      )
    } catch (error) {
      console.error("contractResourceUseCase.uploadFiles :", error)
      return []
    }
  }

  async uploadContractResources(
    contractPk: string,
    files: FileList
  ): Promise<ContractResource[]> {
    try {
      const filteredFiles = Array.from(files)
      const filesData = await this.uploadFiles(filteredFiles)

      const contractResources = await Promise.all(
        filesData.map(fileData =>
          this.createOne({
            contractPk,
            name: fileData.name,
            type: fileData.type.startsWith("image/") ? ContractResourceType.CONTRACT_IMAGE : ContractResourceType.CONTRACT_FILE,
            size: fileData.size,
            url: fileData.url
          })
        )
      )

      return contractResources.filter(Boolean) as ContractResource[]
    } catch (error) {
      console.error("contractResourceUseCase.uploadContractResources :", error)
      return []
    }
  }

  async deleteContractResource(id: string) {
    try {
      const contractResource =
        await this.contractResourceCRUDRepository.getById(id)

      if (![ContractResourceType.CONTRACT_FILE, ContractResourceType.CONTRACT_IMAGE].includes(contractResource?.type)) {
        return null
      }

      await this.contractResourceCRUDRepository.deleteOne(id)
      const fileName = contractResource.url.split("/").pop() as string
      await this.fileRepo.deleteFile(fileName)
      return contractResource
    } catch (error) {
      console.error("contractResourceUseCase.deleteContractResource :", error)
      return null
    }
  }
}

export default ContractResourceUseCase
