const colorsTheme = {
  colors: {
    primaryDark: "#2b2b2b",
    primaryPurple: "#6457f9",
    bgMain: "#eff3f6",
    bgSmall: "#eaeaea",
    bgGray: "#ebebee",
    secondary: "#696969",
    secondaryLight: "#666666",
    line: "#dddddd",
    error: "#dc4126",
    contractCode: "#0747a6",
    darkBlue: "#0747a6",
    success: "#2eb53b",
    bgCard: "rgba(255, 255, 255, 0.4)",
    lightBlue: "#f5f6fc",

    black: {
      100: "#f4f5f7"
    },
    kanban: {
      column: "#f4f5f7"
    }
  },
  shadows: {
    button: "0px 15px 16px rgba(0, 0, 0, 0.05)",
    authContainer: "rgb(0 0 0 / 10%) 0px 0px 10px",
    nav: "4px 0px 71px rgba(0, 0, 0, 0.1)",
    card: "0px 0px 24px rgba(0, 0, 0, 0.05)",
    dialog: "0px 4px 71px rgba(0, 0, 0, 0.1)",
    popover: "0px 14px 71px rgba(0, 0, 0, 0.2)"
  }
}

export default colorsTheme
