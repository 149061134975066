import { Flex, Grid, ListItem, UnorderedList } from "@chakra-ui/react"
import { useSignOut } from "modules/User/src/core/hooks"
import { usePreviousPath } from "providers/PreviousPathProvider"
import React from "react"
import { IoCloseOutline } from "react-icons/io5"
import { Link as RouterLink } from "react-router-dom"
import { Button, IconButton } from "theme/components"
import { MENU_LIST } from "./data"

function MenuMobile() {
  const previousPath = usePreviousPath()

  const { mutateAsync: signOutAsync } = useSignOut()
  const handleSignOut = async () => {
    try {
      await signOutAsync()
    } catch (error) {
      console.error("Sign out : ", error)
    }
  }

  return (
    <Layout previousPath={previousPath}>
      <MenuList>
        {MENU_LIST.map(option => (
          <MenuItem key={`menu-item-${option.to}`} option={option} />
        ))}
      </MenuList>

      <LogoutButton onClick={handleSignOut} />
    </Layout>
  )
}

export default MenuMobile

function Layout({ children, previousPath }) {
  return (
    <Grid
      bgImage="/assets/images/background.jpg"
      templateRows="auto 1fr"
      className="h-screen"
    >
      <Header previousPath={previousPath} />

      <Grid pt="3" px="4" pb="8">
        {children}
      </Grid>
    </Grid>
  )
}

function Header({ previousPath }) {
  return (
    <Flex width="full" height="56px" align="center" px="4">
      <IconButton
        as={RouterLink}
        to={previousPath}
        icon={<IoCloseOutline />}
        aria-label="close"
        size="lg"
        ml="auto"
      />
    </Flex>
  )
}

function MenuList({ children }) {
  return (
    <UnorderedList listStyleType="none" ml="0" spacing="6">
      {children}
    </UnorderedList>
  )
}

function MenuItem({ option }) {
  return (
    <ListItem
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
    >
      <Button
        as={RouterLink}
        variant={option.buttonVariant}
        iconName={option.iconName}
        width="full"
        to={option.to}
        relative="path"
      >
        {option.label}
      </Button>
    </ListItem>
  )
}

function LogoutButton({ onClick }) {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <Button
      variant="secondary"
      width="full"
      onClick={handleClick}
      mt="auto"
      mx="auto"
    >
      Logout
    </Button>
  )
}
