import { Box, FormControl, FormErrorMessage, VStack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { Button, Input } from "theme/components"

function SendEmailForm({ onSendEmail, isLoadingSendEmail }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      email: ""
    }
  })

  const handleFormSubmit = values => {
    const emailNormalized = values.email.trim().toLowerCase()

    const onError = message => {
      setError("email", {
        type: "custom",
        message: message
      })
    }

    onSendEmail(emailNormalized, { onError })
  }

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)} w="full" mt="6">
      <VStack spacing="5">
        <FormControl isInvalid={errors.email}>
          <Input
            type="email"
            placeholder="Email"
            {...register("email", { required: "Enter an email address" })}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" w="full" colorScheme="blue"
          isLoading={isLoadingSendEmail}>
          Reset password
        </Button>
      </VStack>
    </Box>
  )
}

export default SendEmailForm
