import { IContractNormalized } from "modules/Contract/src//core/types"
import { useSourceSettings } from "modules/Source/src/core/hooks"
import { IUserNormalized } from "modules/User/src//core/types"
import { Workspace } from "../../types"
import useSettingsTabRouter from "./useSettingsTabRouter"

const useWorkspaceSource = ({
  workspace,
  workspaceContracts = [],
  user
}: Props) => {
  const {
    source,
    jira,
    discord,
    slack,
    isOpenDrawer,
    onDrawerClose,
    openDrawer,
    closeDrawer
  } = useSourceSettings({
    workspace,
    workspaceContracts,
    user
  })

  const { handleSettingsClick, handleClose } = useSettingsTabRouter({
    closeDrawer,
    isOpenDrawer,
    onDrawerClose,
    openDrawer,
    settingsTabName: "source"
  })

  return {
    handleSourceSettingsClick: handleSettingsClick,
    sourceSettingsProps: {
      isOpen: isOpenDrawer,
      onClose: handleClose,
      discord,
      jira,
      source,
      slack
    }
  }
}

export default useWorkspaceSource

type Props = {
  workspace: Workspace | null
  workspaceContracts: IContractNormalized[]
  user: IUserNormalized | null
}
