import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { Link as RouterLink } from "react-router-dom"
import { FaFile } from "react-icons/fa"

function ContractResourceCard({ resource, contractPk, isDeletable }) {
  const isImage = resource.type === "CONTRACT_IMAGE" || resource.type === "MESSAGE_IMAGE"
  const urlEncoded = encodeURIComponent(resource.url)
  const viewPageUrl = `/resource?type=${isImage ? "image" : "file"}&name=${
    resource.name
  }&url=${urlEncoded}&contractPk=${contractPk}&${
    isDeletable ? "isDeletable=true" : ""
  }&id=${resource.id}`

  const fileName = useMemo(() => resource.name.substring(0, resource.name.lastIndexOf(".")), [resource.name])
  const fileExtension = useMemo(() => resource.name.split(".").pop(), [resource.name])

  return (
    <Flex direction="column">
      <Flex
        as={RouterLink}
        to={viewPageUrl}
        align="center"
        justify="center"
        rounded="8px"
        overflow="hidden"
        width="96px"
        height="72px"
        bgColor="#E8E6FE"
      >
        {resource.isLoading ? (
          <Spinner speed="0.85s" />
        ) : (
          isImage ? (
            <Image src={resource.url} alt={resource.name} objectFit="contain" />
          ) : (
            <Box as={FaFile} size="32px" color="primaryPurple" />)
        )}
      </Flex>

      <Flex mt="2">
        <Text fontSize="sm" color="secondaryLight" noOfLines={1}>
          {fileName}
        </Text>
        <Text fontSize="sm" color="secondaryLight">
          .{fileExtension}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ContractResourceCard

