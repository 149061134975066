import { useQueryParams } from "hooks"
import {
  useChildContracts,
  useContractSettings,
  useContractSubscriptions, useDeleteContract,
  useUpdateContract
} from "modules/Contract/src/core/hooks"
import {
  contractUpdateInputNormalizedType
} from "modules/Contract/src/core/types"
import { useMessagesSubscriptions } from "modules/Message/src/core/hooks"
import { useGetCurrentUser } from "modules/User/src/core/hooks"
import {
  useGetWorkspace,
  useGetWorkspaceTeams
} from "modules/Workspace/src/core/hooks"
import {
  HomepageTabs
} from "modules/Workspace/src/core/hooks/useHomepage/types"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import useContractMessages
  from "../../../../../Message/src/core/hooks/useContractMessages"
import { ContractActiveTab } from "../../../components/types"
import { useGetMappedContract } from "../index"
import { useContractResources } from "../index"
import useContractDelegate from "./delegate"
import useContractGitHub from "./github"
import useContractJira from "./jira"
import { ContractSourceType } from "../../types/graphql"

const useContract = (contractId: string) => {
  const isMobile = useIsMobile()

  const navigate = useNavigate()
  const { queryParams, setQueryParams } = useQueryParams()
  const activeTab = useMemo<ContractActiveTab>(
    () => queryParams.tab as ContractActiveTab,
    [queryParams.tab]
  )

  const { mutateAsync: updateContractAsync } = useUpdateContract()

  // user
  const { data: user = null } = useGetCurrentUser()

  const isPlatform = useMemo(() => queryParams.platform === "true", [queryParams])

  // user

  // contract

  const { data: contract = null } = useGetMappedContract(contractId, {
    enabled: !!contractId,
    keepPreviousData: false
  })

  // contract

  // workspace
  const { data: workspace = null } = useGetWorkspace(
    contract?.workspaceId as string,
    {
      enabled: !!contract?.workspaceId
    }
  )

  const { data: getWorkspaceTeamsResponse } = useGetWorkspaceTeams(
    { input: { workspaceId: contract?.workspaceId as string } },
    {
      enabled: !!contract?.workspaceId
    }
  )
  // workspace

  // update completed
  const toggleCompleted = async (contractId: string) => {
    await updateContractAsync({
      input: {
        id: contractId,
        completed: !contract?.completed
      }
    })
  }

  // update completed

  // update pointEstimation
  const handlePointEstimationChange = async (
    contractId: string,
    pointEstimation: number
  ) => {
    await updateContractAsync({
      input: {
        id: contractId,
        pointEstimation
      }
    })
  }

  // update pointEstimation

  const { contractResources, handleResourcesUpload } = useContractResources(
    contract?.pk || ""
  )
  const { contractMessages, handleCommentCreate } = useContractMessages(
    user,
    contract?.sourceContractPk ? contract : null
  )
  const jiraProps = useContractJira({
    contract,
    workspace
  })
  const githubProps = useContractGitHub({
    user,
    contract
  })
  const { handleDelegateClick, contractDelegateProps } = useContractDelegate({
    contract,
    workspaceTeams: getWorkspaceTeamsResponse?.items || [],
    isMobile,
    activeTab
  })

  const handleBackCLick = () => {
    if (!contract) {
      return
    }

    const isPlatform = queryParams.platform === "true"
    const workspaceId = contract?.workspaceId || queryParams.workspaceId as string
    const contractPk = queryParams.contractPk || contract?.pk as string
    const contractId = queryParams.contractId

    // platform
    if (isPlatform) {
      return navigate(-1)
    }

    let path: any = -1

    // homepage contract -> contract -> homepage contract
    if (contract.sourceType === ContractSourceType.COSMO_HOMEPAGE) {
      path = "/?tab=contracts"
    } else if (contract.sourceContractPk) {
      // contract -> child contract -> contract
      if (contractId) {
        path = `/contract/${contractId}`
      }
      // platform workspace contract -> child contract -> platform workspace contract
      else if (!workspaceId) {
        path = -1
      }
    }
    // workspace contract -> child contract -> workspace contract
    else if (workspaceId) {
      path = `/workspace/${workspaceId}?contractPk=${contractPk}&tab=contract`
    }

    navigate(path)
  }

  const { handleContractSettingsClick, contractSettingsProps } =
    useContractSettings({
      contract,
      activeTab,
      onSuccessDelete: handleBackCLick
    })

  const { mutateAsync: deleteContractAsync } = useDeleteContract()

  const handleContractDeleteClick = async () => {
    await deleteContractAsync({
      contractId: contract?.id as string,
      sourceContractPk: contract?.sourceContractPk as string
    })

    handleBackCLick()
  }

  // contract settings

  // child contracts
  const {
    handleChildContractCreate,
    handleChildContractCompleteToggle,
    handleChildContractClick,
    childContracts
  } = useChildContracts(
    contract || {}
  )

  // child contracts

  const contractTeamOptions = useMemo(() => {
    return (
      getWorkspaceTeamsResponse?.items.map(team => ({
        label: team.name,
        value: team.id
      })) || []
    )
  }, [getWorkspaceTeamsResponse?.items.length])

  const handleContractUpdate = async (
    input: contractUpdateInputNormalizedType
  ) => {
    try {
      await updateContractAsync({
        input: {
          id: contract?.id,
          ...input
        }
      })
    } catch (error) {
      console.error("Contract update  : ", error)
    }
  }

  const handleChatClick = () => setQueryParams({ tab: "chat" })
  const handleChatBackClick = () => setQueryParams({ tab: "contract" })

  const contractDetailsProps = useMemo(() => {
    if (!contract) {
      return {}
    }

    return {
      contract: contract,
      teamOptions: contractTeamOptions,
      onDelegateClick: handleDelegateClick,
      onContractSettingsClick: handleContractSettingsClick,
      onContractDeleteClick: handleContractDeleteClick,
      workspace,
      toggleCompleted,
      onUpdate: handleContractUpdate,
      messages: contractMessages,
      onCommentCreate: handleCommentCreate,
      resources: contractResources,
      onResourcesUpload: handleResourcesUpload,
      jiraProps,
      githubProps,
      onBackClick: handleBackCLick,
      onChildContractCreate: handleChildContractCreate,
      onChildContractCompleteToggle: handleChildContractCompleteToggle,
      onChildContractClick: handleChildContractClick,
      childContracts,
      onChatClick: handleChatClick
    }
  }, [
    JSON.stringify(contract),
    JSON.stringify(contractTeamOptions),
    workspace,
    contractMessages.length,
    toggleCompleted,
    childContracts?.length
  ])

  // active contract

  // subscriptions
  useMessagesSubscriptions(contract?.pk || "", user?.id || "")
  useContractSubscriptions(contract?.pk || "")

  // subscriptions

  // close modals on mount
  const { dispatch: modalDispatch } = useModalContext()
  useEffect(() => {
    if (isMobile) {
      modalDispatch({ type: "close" })
    }
  }, [isMobile])

  return {
    toggleCompleted,
    handlePointEstimationChange,
    contractDetailsProps,
    handleDelegateClick,
    contractDelegateProps,
    handleContractSettingsClick,
    contractSettingsProps,
    isMobile,
    activeTab,
    isPlatform,
    handleChatBackClick,
    user
  }
}

export default useContract
