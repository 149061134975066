import { ListPlatformConfigsInput } from "../graphql/types"
import PlatformConfigAppSyncService from "../services/PlatformConfigAppSyncService"

class PlatformConfigCRUDRepository {
  constructor(
    private readonly platformConfigService: PlatformConfigAppSyncService
  ) {}

  async listPlatformConfigs(input: ListPlatformConfigsInput) {
    return this.platformConfigService.listPlatformConfigs(input)
  }

  async listAdminPlatformConfigs(input: ListPlatformConfigsInput) {
    return this.platformConfigService.listAdminPlatformConfigs(input)
  }
}

export default PlatformConfigCRUDRepository
