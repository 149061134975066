import FileService from "../services/FileService"

class FileRepo {
  constructor(private readonly fileService: FileService) {}

  uploadFile(file: File, fileName: string) {
    return this.fileService.uploadFile(file, fileName)
  }

  deleteFile(fileName: string) {
    return this.fileService.deleteFile(fileName)
  }
}

export default FileRepo
