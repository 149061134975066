import { Flex, Text } from "@chakra-ui/react"
import React, { forwardRef } from "react"

const TeamEmoji = forwardRef(
  ({ zIndex = 1, emoji, bgColor, size = "sm", ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        rounded="full"
        justify="center"
        align="center"
        bgColor={bgColor}
        zIndex={zIndex}
        {...teamEmojiTheme.sizes[size]}
        {...props}
      >
        <Text mb="-0.5">{emoji}</Text>
      </Flex>
    )
  }
)

TeamEmoji.displayName = "TeamEmoji"
export default TeamEmoji

const teamEmojiTheme = {
  sizes: {
    sm: {
      width: "6",
      height: "6",
      fontSize: "11px"
    },
    md: {
      width: "8",
      height: "8",
      fontSize: "14px"
    },
    lg: {
      width: "46px",
      height: "46px",
      fontSize: "20px"
    }
  }
}
