const textareaTheme = {
  Textarea: {
    variants: {
      primary: {
        border: "1px solid",
        borderColor: "line",
        rounded: "8px",

        _focus: {
          borderColor: "line"
        }
      }
    }
  }
}

export default textareaTheme
