import { Flex, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import {
  Button,
  FormControl,
  Select,
  SettingsAccordion
} from "theme/components"

export function Discord({
  guildOptions,
  isLoadingGuildConnect,
  isDiscordConnected,
  onDiscordConnect,
  onGuildConnect,
  onAccordionChange
}) {
  const handleAccordionChange = index => {
    const isOpen = index === 0
    onAccordionChange && onAccordionChange(isOpen)
  }

  return (
    <SettingsAccordion
      iconName="discord"
      name="Discord"
      onChange={handleAccordionChange}
    >
      <ActionRouter
        action={isDiscordConnected ? "add-guild" : "connect"}
        onGuildConnect={onGuildConnect}
        isLoadingGuildConnect={isLoadingGuildConnect}
        onDiscordConnect={onDiscordConnect}
        guildOptions={guildOptions}
      />
    </SettingsAccordion>
  )
}

function ActionRouter({
  action,
  onGuildConnect,
  isLoadingGuildConnect,
  onDiscordConnect,
  guildOptions
}) {
  if (action === "connect") {
    return <ConnectDiscordAction onDiscordConnect={onDiscordConnect} />
  } else if (action === "add-guild") {
    return (
      <ConnectGuildForm
        onGuildConnect={onGuildConnect}
        guildOptions={guildOptions}
        isLoadingGuildConnect={isLoadingGuildConnect}
      />
    )
  }
}

function ConnectDiscordAction({ onDiscordConnect }) {
  return (
    <>
      <Text fontWeight="medium">Connect your Discord account</Text>

      <Button variant="primaryPurple" mt="2" onClick={onDiscordConnect}>
        Connect Discord
      </Button>
    </>
  )
}

function ConnectDiscordBotToGuildLink() {
  return (
    <Text fontSize="sm" lineHeight="sm">
      <Button
        href={process.env.REACT_APP_DISCORD_CONNECT_BOT_URL}
        variant="linkDark"
        target="_blank"
        rel="noreferrer"
        color="primaryPurple"
      >
        Add bot
      </Button>
      &nbsp;to your guild
    </Text>
  )
}

function ConnectGuildForm({
  onGuildConnect,
  guildOptions = [],
  isLoadingGuildConnect = false
}) {
  const [selectGuildId, setSelectGuildId] = useState("")
  const handleGuildConnect = event => {
    event.preventDefault()
    onGuildConnect && onGuildConnect(selectGuildId)
    setSelectGuildId("")
  }

  const handleGuildChange = event => {
    const guildId = event.target.value
    setSelectGuildId(guildId)
  }

  return (
    <Flex direction={{ base: "column", md: "row" }}>
      <FormControl
        label="Discord guilds"
        helperText={<ConnectDiscordBotToGuildLink />}
        maxW="full"
      >
        <Select
          value={selectGuildId}
          placeholder="Discord guild"
          onChange={handleGuildChange}
          options={guildOptions}
          flexShrink="0"
        />
      </FormControl>

      <ConnectGuildButton
        isDisabled={!selectGuildId}
        isLoading={isLoadingGuildConnect}
        onClick={handleGuildConnect}
      />
    </Flex>
  )
}

function ConnectGuildButton({ onClick, isLoading, isDisabled }) {
  return (
    <Button
      variant="primaryPurple"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      width="fit-content"
      px="10"
      mt="4"
      ml={{ base: "0", md: "6" }}
      alignSelf={{ base: "flex-end", md: "auto" }}
    >
      Connect
    </Button>
  )
}
