const selectTheme = {
  Select: {
    variants: {
      primary: {
        field: {
          border: "1px solid",
          borderColor: "line",
          rounded: "8px",
          fontSize: "sm",
          lineHeight: "sm",
          fontWeight: "medium",
          color: "primaryDark",
          _focus: {
            borderColor: "line"
          }
        }
      }
    }
  }
}

export default selectTheme
