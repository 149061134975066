import { extendTheme } from "@chakra-ui/react"
import colorsTheme from "./colorsTheme"
import components from "./componentsTheme"
import stylesTheme from "./stylesTheme"
import typographyTheme from "./typographyTheme"

const theme = extendTheme({
  ...stylesTheme,
  ...colorsTheme,
  ...typographyTheme,
  ...components,
  breakpoints: {
    sm: "36em",
    md: "48em",
    lg: "62em"
  }
})

export default theme
