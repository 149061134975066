import { useQueryParams } from "hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useCreateWorkspace } from "../index"
import { useGetUserWorkspaces } from "../useGetUserWorkspaces"
import { HomepageTabs } from "./types"
import { DEFAULT_EMOJI_BG_COLOR, DEFAULT_TEAM_EMOJI } from "../../config"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

const useHomepageWorkspaces = ({ user, activeTab, isMobile }: Props) => {
  const { setQueryParams } = useQueryParams()
  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { data: workspaces = [] } = useGetUserWorkspaces(
    user?.id as string, {
      enabled: !!user?.id && activeTab === HomepageTabs.WORKSPACES
    })

  const handleWorkspaceCreateClick = () => {
    setQueryParams({ tab: HomepageTabs.WORKSPACE_CREATE })
  }

  const isWorkspaceCreateModalOpen = useMemo(
    () => modalState.modalType === "workspaceCreate",
    [modalState.modalType]
  )

  const isMobileRef = useRef(isMobile)

  const {
    mutateAsync: createWorkspaceAsync,
    isLoading: isLoadingWorkspaceCreate
  } = useCreateWorkspace()

  useEffect(() => {
    isMobileRef.current = isMobile
  }, [isMobile])

  const handleWorkspaceCreateClose = useCallback(() => {
    if (!isMobileRef.current && activeTab === HomepageTabs.WORKSPACE_CREATE) {
      setQueryParams({ tab: HomepageTabs.WORKSPACES })
    }
  }, [isMobileRef.current, activeTab, setQueryParams])

  useEffect(() => {
    if (
      isWorkspaceCreateModalOpen &&
      (activeTab !== HomepageTabs.WORKSPACE_CREATE || isMobile)
    ) {
      modalDispatch({ type: "close" })
    }

    if (
      activeTab === HomepageTabs.WORKSPACE_CREATE &&
      !isWorkspaceCreateModalOpen &&
      !isMobile
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.WorkspaceCreate,
        props: {
          onSubmit: handleWorkspaceCreate,
          isLoadingSubmit: isLoadingWorkspaceCreate
        },
        onClose: handleWorkspaceCreateClose
      })
    }
    if (
      isWorkspaceCreateModalOpen &&
      activeTab === HomepageTabs.WORKSPACE_CREATE
    ) {
      modalDispatch({
        type: "update",
        onClose: handleWorkspaceCreateClose
      })
    }
  }, [handleWorkspaceCreateClose, activeTab, isMobile, isLoadingWorkspaceCreate])

  const handleWorkspaceCreate = useCallback(
    async (workspaceName: string, callbacks: { onSuccess: () => void }) => {
      if (!workspaceName || !user?.id) {
        return
      }

      try {
        await createWorkspaceAsync({
          input: {
            name: workspaceName,
            ownerId: user.id,
            defaultTeamEmoji: {
              emoji: DEFAULT_TEAM_EMOJI,
              backgroundColor: DEFAULT_EMOJI_BG_COLOR
            }
          }
        })

        if (!isMobile) {
          callbacks.onSuccess && callbacks.onSuccess()
        } else {
          setQueryParams({ tab: HomepageTabs.WORKSPACES })
        }
      } catch (error) {
        console.error("Workspace create  : ", error)
      }
    },
    [user?.id]
  )

  return {
    workspaces,
    onCreateClick: handleWorkspaceCreateClick,
    handleWorkspaceCreate,
    isLoadingWorkspaceCreate
  }
}

export default useHomepageWorkspaces

type Props = {
  user: IUserNormalized | null
  activeTab: string
  isMobile: boolean
}
