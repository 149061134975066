import { IContractNormalized } from "modules/Contract/src/core/types"
import {
  useConnectSourceProject,
  useDisconnectSourceProject,
  useGetSourceWorkspaceLabels,
  useGetSourceWorkspaceProjects,
  useUpdateSourceProject
} from "modules/Source/src/core/hooks"
import {
  ConnectedSourceProject,
  IConnectJiraProjectOptions,
  SourceProjectEditProps
} from "modules/Source/src/core/types/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import {
  Workspace,
  WorkspaceJiraProject
} from "modules/Workspace/src//core/types"
import { useCallback, useMemo, useState } from "react"
import useGetSourceWorkspaces from "../useGetSourceWorkspaces"

const useSourceSettingsJira = ({
  workspace,
  workspaceContracts = [],
  user
}: Props) => {
  const [isLoadingJiraProjectConnect, setIsLoadingJiraProjectConnect] =
    useState(false)
  const { mutateAsync: connectSourceProjectAsync } = useConnectSourceProject()
  const { mutateAsync: updateSourceProjectAsync } = useUpdateSourceProject()
  const { mutateAsync: disconnectSourceProjectAsync } =
    useDisconnectSourceProject()
  const [activeJiraWorkspaceId, setActiveJiraWorkspaceId] = useState("")
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const handleAccordionChange = (isOpen: boolean) => setIsSettingsOpen(isOpen)

  const { data: jiraWorkspaces = [] } = useGetSourceWorkspaces("jira", {
    enabled: isSettingsOpen
  })
  const { data: jiraWorkspaceProjects = [] } = useGetSourceWorkspaceProjects(
    {
      type: "jira",
      options: {
        id: activeJiraWorkspaceId
      }
    },
    {
      enabled: !!activeJiraWorkspaceId
    }
  )

  const notConnectedJiraWorkspaceProjects = activeJiraWorkspaceId
    ? jiraWorkspaceProjects.filter(
      project =>
        !workspace?.jiraProjects?.find(
          workspaceProject =>
            workspaceProject.workspaceId === activeJiraWorkspaceId &&
              workspaceProject.id === project.id
        )
    )
    : []

  const jiraWorkspaceOptions = useMemo(
    () =>
      jiraWorkspaces?.map(jiraWorkspace => ({
        value: jiraWorkspace.id,
        label: jiraWorkspace.name
      })),
    [jiraWorkspaces?.length]
  )
  const jiraWorkspaceProjectOptions = useMemo(
    () =>
      notConnectedJiraWorkspaceProjects.map(project => ({
        value: project.id,
        label: project.name
      })),
    [JSON.stringify(notConnectedJiraWorkspaceProjects)]
  )

  const connectedProjects = useMemo<ConnectedSourceProject[]>(
    () =>
      workspace?.jiraProjects?.map(project => ({
        id: project.id,
        name: project.name,
        sourceType: "jira",
        iconName: "jira"
      })) || [],
    [JSON.stringify(workspace?.jiraProjects)]
  )

  const handleWorkspaceChange = (jiraWorkspaceId: string) =>
    setActiveJiraWorkspaceId(jiraWorkspaceId)

  const handleJiraProjectConnect = async (
    jiraProject: WorkspaceJiraProject
  ) => {
    setIsLoadingJiraProjectConnect(true)

    await connectSourceProjectAsync({
      sourceType: "jira",
      options: {
        jiraProject,
        workspaceId: workspace?.id as string
      }
    })
    setIsLoadingJiraProjectConnect(false)
    setActiveEditProjectId(jiraProject.id)
  }

  // jira project edit
  const [activeEditProjectId, setActiveEditProjectId] = useState<string | null>(
    null
  )
  const editProject = useMemo(
    () =>
      workspace?.jiraProjects?.find(
        project => project.id === activeEditProjectId
      ),
    [activeEditProjectId, JSON.stringify(workspace?.jiraProjects)]
  )
  const { data: jiraWorkspaceLabels = [] } = useGetSourceWorkspaceLabels(
    {
      type: "jira",
      jiraWorkspaceId: editProject?.workspaceId as string
    },
    {
      enabled: !!editProject?.workspaceId
    }
  )

  const handleProjectUpdate = async (
    projectId: string,
    projectLabels: string[]
  ) => {
    const workspaceJiraContractsIssueId = workspaceContracts
      .filter(contract => !!contract.jiraIssueId)
      .map(contract => contract.jiraIssueId) as string[]

    const jiraProject = {
      id: projectId,
      labels: projectLabels || []
    } as WorkspaceJiraProject

    await updateSourceProjectAsync({
      sourceType: "jira",
      options: {
        jiraProject,
        workspaceId: workspace?.id as string,
        workspaceJiraIssueIds: workspaceJiraContractsIssueId
      }
    })
  }

  const handleProjectDisconnect = async (jiraProjectId: string) => {
    try {
      await disconnectSourceProjectAsync({
        sourceType: "jira",
        options: {
          jiraProjectId,
          workspaceId: workspace?.id as string
        }
      })
    } catch (error) {
      console.error("handleJiraProjectDisconnect error : ", error)
    }
  }

  const getProjectEditProps = useCallback(
    (projectId: string): SourceProjectEditProps => {
      setActiveEditProjectId(projectId)

      return {
        sourceType: "jira",
        sourceWorkspaceLabels: jiraWorkspaceLabels,
        project: editProject!,
        onUpdate: handleProjectUpdate,
        onDisconnect: handleProjectDisconnect
      }
    },
    [JSON.stringify(editProject), JSON.stringify(jiraWorkspaceLabels)]
  )
  // jira project edit

  // jira account connect
  const handleJiraConnect = () => {
    const authorizationUrl = "https://auth.atlassian.com/authorize"
    const workspaceId = workspace?.id
    const userId = user?.id
    const params = {
      audience: "api.atlassian.com",
      client_id: process.env.REACT_APP_JIRA_CLIENT_ID || "",
      scope:
        "offline_access manage:jira-webhook read:jira_webhook read:jira-work write:jira-work",
      redirect_uri: `${process.env.REACT_APP_JIRA_REDIRECT_URI}?userId=${userId}&workspaceId=${workspaceId}`,
      state: `${userId}${workspaceId}${Date.now()}`
    }
    const url = new URL(authorizationUrl)
    url.search = new URLSearchParams(params).toString()
    const fullUrl = url.toString() + "&response_type=code&prompt=consent"
    window.location.href = fullUrl
  }

  return {
    jiraWorkspaceOptions,
    jiraWorkspaceProjectOptions,
    connectedProjects,
    isLoadingJiraProjectConnect,
    handleJiraProjectConnect,
    handleWorkspaceChange,
    handleJiraConnect,
    getProjectEditProps,
    handleAccordionChange
  }
}

export { useSourceSettingsJira }

type Props = {
  workspace: Workspace | null
  workspaceContracts: IContractNormalized[]
  user: IUserNormalized | null
}
