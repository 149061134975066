import { Box, VStack } from "@chakra-ui/react"
import TeamOptionsGroupAccordion
  from "modules/Workspace/src/components/TeamOptionsGroupAccordion"
import React, { useMemo, useState } from "react"
import { Input } from "theme/components"
import Layout from "./Layout"
import {
  PLATFORM_TEAM_GROUP_FIELD_MAP
} from "../../../../Workspace/src/core/config"

const PlatformContractDelegateModal = ({
  teamOptionsGroups,
  onTeamOptionChange,
  onDelegate,
  isLoadingDelegate,
  closeModal,
  isOpen,
  content
}) => {
  const [search, setSearch] = useState("")
  const handleSearchChange = event => setSearch(event.target.value)

  const filteredTeamOptionsGroups = useMemo(() => {
    if (!teamOptionsGroups) {
      return []
    }

    const searchValue = search.trim().toLowerCase()

    if (!searchValue) {
      return teamOptionsGroups
    }

    return teamOptionsGroups.map(teamGroup => {
      const filteredTeamOptions = teamGroup.teamOptions.filter(teamOption =>
        teamOption.name.toLowerCase().includes(searchValue)
      )

      return {
        ...teamGroup,
        teamOptions: filteredTeamOptions
      }
    })
  }, [JSON.stringify(teamOptionsGroups), search])

  return (
    <Layout
      isOpen={isOpen}
      closeModal={closeModal}
      onDelegate={onDelegate}
      isLoadingDelegate={isLoadingDelegate}
      content={content}
    >
      <SearchInput
        onChange={handleSearchChange}
        value={search}
        placeholder={content.searchInputPlaceholder}
      />

      <TeamOptionsGroups
        teamOptionsGroups={filteredTeamOptionsGroups}
        onTeamOptionChange={onTeamOptionChange}
        content={content}
      />
    </Layout>
  )
}

export default PlatformContractDelegateModal

const TeamOptionsGroups = ({
  teamOptionsGroups = [],
  onTeamOptionChange,
  content
}) => (
  <VStack align="stretch" w="full" maxW="380px" spacing="4">
    {teamOptionsGroups.map(teamGroup =>
      teamGroup.teamOptions.length && content[PLATFORM_TEAM_GROUP_FIELD_MAP[teamGroup.name]] ? (
        <TeamOptionsGroupAccordion
          key={teamGroup.name}
          groupName={content[PLATFORM_TEAM_GROUP_FIELD_MAP[teamGroup.name]]}
          teamOptions={teamGroup.teamOptions}
          onTeamOptionChange={onTeamOptionChange}
          defaultIndex={0}
        />
      ) : null
    )}
  </VStack>
)

const SearchInput = ({ onChange, value, placeholder }) =>
  <Box pb={6}>
    <Input
      placeholder={placeholder}
      onInput={onChange} value={value} width="full"
    />
  </Box>
