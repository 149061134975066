import { useMutation } from "react-query"
import { userUseCase } from "../index"
import {
  useConfirmSignUpInputType,
  useConfirmSignUpOptionsType
} from "../types/hooks"

export const useConfirmSignUp = (options: useConfirmSignUpOptionsType = {}) =>
  useMutation(
    (input: useConfirmSignUpInputType) =>
      userUseCase.confirmSignUp(input.email, input.code),
    options
  )
