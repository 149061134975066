import { API, graphqlOperation } from "aws-amplify"
import { Observable } from "zen-observable-ts"
import {
  createContract as createContractMutation,
  updateContract as updateContractMutation,
  deleteContract as deleteContractMutation
} from "../graphql/mutations"
import {
  getContract as getContractQuery,
  getContractsByPk as getContractsByPkQuery,
  getContractsBySourceContractPk
} from "../graphql/queries"
import { onCreateContract, onUpdateContract } from "../graphql/subscriptions"
import {
  IContract,
  IContractCreateInput,
  contractSubscriptionCallbackFunctionType,
  contractSubscriptionResponseType,
  contractUpdateInputType, GetContractsByPkResponse, GetContractResponse
} from "../types"
import {
  GetContractsByPkInput,
  GetContractsBySourceContractPkInput
} from "../types/graphql"

class ContractAppSyncService {
  async createContract(input: IContractCreateInput): Promise<IContract> {
    const contractData = await (API.graphql(
      graphqlOperation(createContractMutation, { input })
    ) as Promise<any>)
    return contractData.data.createContract
  }

  async getContract(id: string): Promise<IContract> {
    const res = await (API.graphql(
      graphqlOperation(getContractQuery, { id })
    ) as Promise<any>)
    return res.data.getContract
  }

  async getAllByPk(input: GetContractsByPkInput): Promise<GetContractsByPkResponse> {
    const res = await (API.graphql(
      graphqlOperation(getContractsByPkQuery, input)
    ) as Promise<any>)

    return {
      items: res.data.getContractsByPk.items,
      nextToken: res.data.getContractsByPk.nextToken
    }
  }

  async getContractsBySourceContractPk(input: GetContractsBySourceContractPkInput): Promise<GetContractResponse> {
    const res = await (API.graphql(
      graphqlOperation(getContractsBySourceContractPk, input)
    ) as Promise<any>)

    return {
      items: res.data.getContractsBySourceContractPk.items,
      nextToken: res.data.getContractsBySourceContractPk.nextToken
    }
  }

  async updateContract(contract: contractUpdateInputType): Promise<IContract> {
    const contractData = await (API.graphql(
      graphqlOperation(updateContractMutation, { input: contract })
    ) as Promise<any>)
    return contractData.data.updateContract
  }

  async deleteContract(id: string): Promise<IContract> {
    const contractData = await (API.graphql(
      graphqlOperation(deleteContractMutation, { input: { id } })
    ) as Promise<any>)
    return contractData.data.deleteContract
  }

  createContractSubscription(
    callback: contractSubscriptionCallbackFunctionType
  ): contractSubscriptionResponseType {
    const subscription = API.graphql(
      graphqlOperation(onCreateContract)
    ) as Observable<any>
    const subscriptionObserver = subscription.subscribe({
      next: (eventData: any) => {
        const contract = eventData.value.data.onCreateContract
        callback(contract)
      }
    })
    return () => subscriptionObserver.unsubscribe()
  }

  updateContractSubscription(
    callback: contractSubscriptionCallbackFunctionType
  ): contractSubscriptionResponseType {
    const subscription = API.graphql(
      graphqlOperation(onUpdateContract)
    ) as Observable<any>
    const subscriptionObserver = subscription.subscribe({
      next: (eventData: any) => {
        const contract = eventData.value.data.onUpdateContract
        callback(contract)
      }
    })
    return () => subscriptionObserver.unsubscribe()
  }
}

export default ContractAppSyncService
