import { useQueryParams } from "hooks"
import {
  useAutoContractDelegate,
  useCreateContract, usePlatformWorkspaceContract
} from "modules/Contract/src/core/hooks"
import { ContractSourceType } from "modules/Contract/src/core/types"
import { useGetCurrentUser } from "modules/User/src/core/hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { WorkspaceActiveTab } from "../../types/global"
import { ContractTypes, PlatformWorkspaceTabs } from "./types"
import usePlatformConfig from "./usePlatformConfig"
import { useGetWorkspaceContracts } from "../useGetWorkspaceContracts"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

const usePlatformWorkspace = () => {
  const isMobile = useIsMobile()
  const { data: user = null } = useGetCurrentUser()
  const { dispatch: modalDispatch, state: modalState } = useModalContext()
  const { queryParams, setQueryParams, removeQueryParams } = useQueryParams()

  const isMenuVisible = useMemo(
    () => (queryParams.menu ? queryParams.menu !== "false" : true),
    [queryParams.menu]
  )

  const activeTab = useMemo<WorkspaceActiveTab>(
    () => queryParams.tab as WorkspaceActiveTab,
    [queryParams.tab]
  )
  const contractsType = useMemo<WorkspaceActiveTab>(
    () => queryParams.type as WorkspaceActiveTab,
    [queryParams.type]
  )
  const isArchivedContracts = useMemo(
    () => contractsType === ContractTypes.ARCHIVED,
    [contractsType]
  )
  // platform config
  const contractsFilter = useMemo(
    () => (
      isArchivedContracts ?
        {
          or: [
            { completed: { eq: true } },
            { deletedAt: { attributeExists: true } }
          ] as any
        } : {
          or: [
            { completed: { eq: false } },
            { completed: { attributeExists: false } },
            { deletedAt: { attributeExists: false } },
            { deletedAt: { eq: null } }
          ] as any
        }

    ), [isArchivedContracts]
  )
  const { platformWorkspaceConfig, workspace } = usePlatformConfig()
  // contract

  const { autoDelegate } = useAutoContractDelegate()

  const {
    data: contractsResponse = [],
    isLoading: isLoadingWorkspaceContracts = true
  } = useGetWorkspaceContracts(
    {
      workspaceId: workspace?.id || "",
      filter: contractsFilter,
      isArchivedContracts
    },
    {
      enabled: !!workspace?.id,
      keepPreviousData: false
    }
  )

  // filter contracts
  const contracts = useMemo(() => {
    let contracts = contractsResponse

    if (isArchivedContracts) {
      contracts = contractsResponse
        .filter(contract => contract.completed || contract.deletedAt)
    } else {
      contracts = contractsResponse
        .filter(contract => !contract.deletedAt)
        .filter(contract => !contract.completed)
    }

    return contracts
  }, [JSON.stringify(contractsResponse), isArchivedContracts])

  const activeContractProps = usePlatformWorkspaceContract({
    workspace: workspace || {},
    platformWorkspaceConfig: platformWorkspaceConfig || {},
    workspaceContracts: contracts
  })

  // set active contract
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const CONTRACT_PK_QUERY_PARAM = "contractPk"

  const onContractClick = (contractPk: string) => {
    setQueryParams({ tab: PlatformWorkspaceTabs.CONTRACT, contractPk })
  }

  useEffect(() => {
    if (isMobile) {
      return
    }

    const contractPk = searchParams.get(CONTRACT_PK_QUERY_PARAM)
    let isContractPkExist = false

    if (contractPk) {
      contracts.forEach(contract => {
        if (contract.pk === contractPk) {
          isContractPkExist = true
        }
      })

      // check for query param action=delegate, if true, then auto delegate
      if (searchParams.get("action") === "contract-delegate" && activeContractProps.contractDetailsProps.contract?.id && workspace) {
        autoDelegate(activeContractProps.contractDetailsProps.contract, workspace)
      } else if (searchParams.get("action") === "contract-delete" && activeContractProps.contractDetailsProps.contract?.id) {
        activeContractProps.contractDetailsProps.onDeleteContractClick()
      }
    }

    if (!isContractPkExist && contracts.length > 0) {
      searchParams.set(CONTRACT_PK_QUERY_PARAM, contracts[0].pk)
      searchParams.set("tab", PlatformWorkspaceTabs.CONTRACT)
      navigate(`?${searchParams.toString()}`)
    }
  }, [searchParams, contracts.length, isMobile])

  // set active contract

  useEffect(() => {
    if (isMobile) {
      modalDispatch({ type: "close" })
    }
  }, [isMobile])

  // contract create
  const { mutateAsync: createContractAsync } = useCreateContract()

  const handleContractCreateClick = () => {
    setQueryParams({ tab: PlatformWorkspaceTabs.CONTRACT_CREATE })
  }

  const isContractCreateModalOpen = useMemo(
    () => modalState.modalType === "contractCreate",
    [modalState.modalType]
  )

  const handleContractCreateModalClose = useCallback(() => {
    if (!isMobile && activeTab === PlatformWorkspaceTabs.CONTRACT_CREATE) {
      removeQueryParams("tab")
    }
  }, [activeTab, isMobile, removeQueryParams])

  useEffect(() => {
    if (
      isContractCreateModalOpen &&
      activeTab !== PlatformWorkspaceTabs.CONTRACT_CREATE
    ) {
      modalDispatch({ type: "close" })
    }

    if (
      activeTab === "contract-create" &&
      !isContractCreateModalOpen &&
      !isMobile
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.ContractCreate,
        props: {
          onSubmit: handleContractCreate
        },
        onClose: handleContractCreateModalClose
      })
    }

    if (isContractCreateModalOpen && activeTab === "contract-create") {
      modalDispatch({
        type: "update",
        onClose: handleContractCreateModalClose
      })
    }
  }, [handleContractCreateModalClose, isContractCreateModalOpen])

  const handleContractCreate = async (name: string, description: string) => {
    const contractPk = uuidv4()

    try {
      const createdContract = await createContractAsync({
        input: {
          name,
          description,
          ownerId: user?.id || "",
          reporterId: user?.id,
          sourceType: ContractSourceType.COSMO_HOMEPAGE,
          pk: contractPk,
          sk: `owner#${user?.id}`
        }
      })

      setQueryParams({
        tab: PlatformWorkspaceTabs.CONTRACT,
        contractId: createdContract?.id || ""
      })
    } catch (error) {
      console.error("Contract create : ", error)
    }
  }
  const handleChatBackClick = () => setQueryParams({ tab: "contract" })

  // contract create

  return {
    contractSidebarProps: {
      contracts,
      activeContractPk: activeContractProps.contractDetailsProps.contract?.pk,
      toggleContractCompleted: activeContractProps.toggleCompleted,
      onContractClick,
      isLoading: !workspace?.id || isLoadingWorkspaceContracts
    },
    contractDetailsProps: activeContractProps.contractDetailsProps,
    contractSettingsProps: activeContractProps.contractSettingsProps,
    contractDelegateProps: activeContractProps.contractDelegateProps,
    handleContractCreateClick,
    handleContractCreate,
    isMobile,
    activeTab,
    isMenuVisible,
    platformConfig: platformWorkspaceConfig || {},
    user,
    handleChatBackClick
  }
}

export default usePlatformWorkspace
