import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import React from "react"
import {
  IoEllipsisVerticalSharp,
  IoTrashOutline,
  IoSettingsOutline
} from "react-icons/io5"
import { IconButton } from "theme/components"

export function ContractSettingsMenu({
  onDeleteClick,
  onSettingsClick
}) {
  return (
    <Menu variant="settings" placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<IoEllipsisVerticalSharp />}
      />

      <MenuList>
        {onSettingsClick ? (
          <MenuItem
            icon={<IoSettingsOutline size="16" color="#666666" />}
            onClick={onSettingsClick}
          >
            Settings
          </MenuItem>
        ) : null}

        {onDeleteClick ? (
          <MenuItem
            icon={<IoTrashOutline size="16" color="#EA3546 " />}
            onClick={onDeleteClick}
          >
            Delete contract
          </MenuItem>
        ) : null}
      </MenuList>
    </Menu>
  )
}
