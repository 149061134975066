import { Grid, Image } from "@chakra-ui/react"
import React from "react"
import { IconButton } from "theme/components/ui"

function PlatformWorkspaceSidebar({
  onCreateClick,
  platformConfig = {},
  ...props
}) {
  return (
    <Layout platformConfig={platformConfig} {...props}>
      {onCreateClick ? <CreateButton onClick={onCreateClick} /> : null}
    </Layout>
  )
}

export default PlatformWorkspaceSidebar

const Layout = ({ children, platformConfig = {}, ...props }) => (
  <Grid
    roundedRight="16px"
    shadow="4px 0px 70px rgba(0, 0, 0, 0.05)"
    bgColor="white"
    templateRows={platformConfig.showLogo ? "80px 1fr" : "1fr"}
    id="PlatformWorkspaceSidebar"
    {...props}
  >
    {platformConfig.showLogo ? <Header /> : null}

    <Grid justifyContent="center" pt="5">
      {children}
    </Grid>
  </Grid>
)

function Header() {
  return (
    <Grid
      height="80px"
      alignItems="center"
      justifyContent="center"
      px="3"
      position="relative"
      _after={{
        content: '""',
        bgColor: "#ededed",
        height: "1px",
        position: "absolute",
        bottom: "0",
        left: "3",
        right: "3"
      }}
    >
      <Image src="/assets/images/logo-symbol.png" width="36px" height="36px" />
    </Grid>
  )
}

function CreateButton({ onClick }) {
  return (
    <IconButton
      iconName="plus"
      bgColor="primaryPurple"
      rounded="full"
      size="md"
      _hover={{ bgColor: "primaryPurple" }}
      _active={{ bgColor: "primaryPurple" }}
      onClick={onClick}
      shadow="0px 4px 14px rgba(100, 87, 249, 0.5)"
    />
  )
}
