import { Textarea as ChakraTextarea } from "@chakra-ui/react"
import React, { forwardRef } from "react"

export const Textarea = forwardRef(({ value, onChange, ...props }, ref) => {
  return (
    <ChakraTextarea
      ref={ref}
      variant="primary"
      value={value}
      onChange={onChange}
      {...props}
    />
  )
})
Textarea.displayName = "Textarea"
