import WorkspaceAppSyncService from "../services/WorkspaceAppSyncService"

class WorkspaceCRUDRepository {
  constructor(private readonly workspaceService: WorkspaceAppSyncService) {}

  getWorkspace = this.workspaceService.getWorkspace
  getAllByOwnerId = this.workspaceService.getAllByOwnerId
  updateWorkspace = this.workspaceService.updateWorkspace
  createOne = this.workspaceService.createOne
}

export default WorkspaceCRUDRepository
