export interface WorkspaceTeam {
  id: string

  workspaceId: string
  ownerId: string
  communityWorkspaceId?: string
  communityType: WorkspaceTeamCommunityType
  teamId: string
  name: string
  surname?: string
  type: WorkspaceTeamType
  emoji: string
  emojiBgColor: string
  createdAt: string
  updatedAt: string
}

export enum WorkspaceTeamCommunityType {
  DISCORD = "DISCORD",
  SLACK = "SLACK",
  EMAIL = "EMAIL"
}

export enum WorkspaceTeamType {
  CHANNEL = "CHANNEL",
  USER = "USER"
}

export type WorkspaceTeamCreateInput = Omit<
  WorkspaceTeam,
  "id" | "createdAt" | "updatedAt"
>

export type WorkspaceTeamUpdateInput = Omit<
  WorkspaceTeam,
  "id" | "createdAt" | "updatedAt"
>

export type FindAll = (
  filter: any // IModelWorkspaceTeamFilterInput
) => Promise<WorkspaceTeam[]>

export type FindById = (id: string) => Promise<WorkspaceTeam>
export type FindByWorkspaceId = (
  workspaceId: string
) => Promise<WorkspaceTeam[]>

export type WorkspaceTeamOption = {
  id: string
  name: string
  emoji: string
  emojiBgColor: string
  type: WorkspaceTeamType
  isSelected: boolean
  showIsPrivate: boolean
  isPrivate: boolean
  isPredefined: boolean
}

export type WorkspaceTeamOptionsGroup = {
  type?: WorkspaceTeamType
  label: string
  teamOptions: WorkspaceTeamOption[]
}

export interface GetWorkspaceTeamsInput {
  workspaceId: string
  communityType?: WorkspaceTeamCommunityType
}
