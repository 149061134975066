import { API, graphqlOperation } from "aws-amplify"
import {
  createWorkspace as createWorkspaceMutation,
  updateWorkspace as updateWorkspaceMutation
} from "../graphql/mutations"
import {
  getWorkspace as getWorkspaceQuery,
  getWorkspacesByOwnerId as getWorkspacesByOwnerIdQuery
} from "../graphql/queries"
import {
  GetWorkspacesByOwnerIdResponse,
  Workspace,
  WorkspaceCreateInput,
  WorkspaceUpdateInput
} from "../types"
import {
  GetWorkspacesByOwnerIdInput
} from "../types/graphql"

class WorkspaceAppSyncService {
  constructor() {
  }

  async getWorkspace(id: string): Promise<Workspace> {
    const res = await (API.graphql(
      graphqlOperation(getWorkspaceQuery, { id })
    ) as Promise<any>)
    return res.data.getWorkspace
  }

  async getAllByOwnerId(input: GetWorkspacesByOwnerIdInput ): Promise<GetWorkspacesByOwnerIdResponse> {
    const res = await (API.graphql(
      graphqlOperation(getWorkspacesByOwnerIdQuery, input)
    ) as Promise<any>)

    return {
      items: res.data.getWorkspacesByOwnerId.items,
      nextToken: res.data.getWorkspacesByOwnerId.nextToken
    }
  }

  async updateWorkspace(input: WorkspaceUpdateInput): Promise<Workspace> {
    const res = await (API.graphql(
      graphqlOperation(updateWorkspaceMutation, { input })
    ) as Promise<any>)
    return res.data.updateWorkspace
  }

  async createOne(input: WorkspaceCreateInput): Promise<Workspace> {
    const res = await (API.graphql(
      graphqlOperation(createWorkspaceMutation, { input })
    ) as Promise<any>)
    return res.data.createWorkspace
  }
}

export default WorkspaceAppSyncService
