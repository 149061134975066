import { useDisclosure } from "@chakra-ui/react"
import { emptyObjectType } from "modules/Contract/src/core/types"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react"
import { Outlet } from "react-router-dom"
import {
  IModalContext,
  IModalProviderProps,
  IModalReducerAction,
  IModalReducerState,
  modalReducerType,
  modalTypes
} from "./types"

const initialState: IModalReducerState = {
  modalType: null,
  type: "close",
  props: {},
  attributes: {},
  activeModal: undefined,
  onClose: () => {}
}

export const ModalContext = createContext<IModalContext>({
  state: initialState,
  dispatch: () => {}
})

export const useModalContext = () => useContext(ModalContext)

const modalReducer =
  (modals: modalTypes | emptyObjectType) =>
    (state: IModalReducerState, action: IModalReducerAction) => {
      switch (action.type) {
        case "open": {
          if (action.modalType && modals[action.modalType]) {
            return {
              ...state,
              activeModal: modals[action.modalType],
              props: action.props || {},
              attributes: action.attributes || {},
              modalType: action.modalType,
              type: "open",
              onClose: action.onClose || (() => {})
            }
          }
          console.error("modal not found")
          return state
        }
        case "update": {
          return {
            ...state,
            props: {
              ...state.props,
              ...action.props
            },
            attributes: {
              ...state.attributes,
              ...action.attributes
            },
            // here null will always use the previous onClose
            onClose: action.onClose ?? state.onClose
          }
        }
        case "close": {
          state.onClose()
          return initialState
        }
        default:
          console.error("modal action not found")
      }
    }

function ModalProvider({ modals = {}, children }: IModalProviderProps) {
  const [state, dispatch] = useReducer(
    modalReducer(modals) as modalReducerType,
    initialState
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const closeModal = (callbackOptions?: any) => {
    state.onClose(callbackOptions)
    onClose()
    dispatch({ type: "close" })
  }

  useEffect(() => {
    if (state.activeModal && state.type === "open") {
      onOpen()
    } else if (state.type === "close") {
      closeModal()
    }
  }, [state.activeModal, state.type])

  const memorizedChildren = useMemo(() => children || <Outlet />, [])
  const ActiveModal = state.activeModal

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {ActiveModal && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ActiveModal isOpen={isOpen} closeModal={closeModal} {...state.props} />
      )}
      {memorizedChildren}
    </ModalContext.Provider>
  )
}

export default ModalProvider
