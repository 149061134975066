import ContractResourceCRUDRepository from "./repositories/ContractResourceCRUDRepository"
import FileRepo from "./repositories/FileRepo"
import ContractResourceAppSyncService from "./services/ContractResourceAppSyncService"
import FileService from "./services/FileService"
import ContractResourceUseCase from "./useCases/ContractResourceUseCase"

const contractResourceUseCase = new ContractResourceUseCase(
  new ContractResourceCRUDRepository(new ContractResourceAppSyncService()),
  new FileRepo(new FileService())
)

export default contractResourceUseCase
