import {
  useGetContractResources,
  useUploadContractResources
} from "modules/ContractResource/src/hooks"

const useContractResources = (contractPk: string) => {
  const { mutateAsync: uploadContractResourcesAsync } =
    useUploadContractResources()
  const handleResourcesUpload = async (files: FileList) => {
    await uploadContractResourcesAsync({
      contractPk: contractPk || "",
      files
    })
  }

  const { data: contractResources = [] } = useGetContractResources(
    contractPk || "",
    {
      enabled: !!contractPk,
      keepPreviousData: false
    }
  )

  return {
    handleResourcesUpload,
    contractResources
  }
}

export default useContractResources
