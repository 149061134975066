import { Box, FormControl, FormErrorMessage, VStack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { Button, Input } from "theme/components"

function ConfirmationForm({ onConfirm, isLoadingConfirm }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      code: ""
    }
  })

  const handleFormSubmit = values => {
    const codeNormalized = values.code.trim().toLowerCase()

    const onError = message => {
      setError("code", {
        type: "custom",
        message: message
      })
    }

    onConfirm(codeNormalized, { onError })
  }

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)} w="full" mt="6">
      <VStack spacing="5">
        <FormControl isInvalid={errors.code}>
          <Input
            type="number"
            placeholder="Confirmation code"
            {...register("code", { required: "Enter your confirmation code" })}
          />

          <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit" w="full" colorScheme="blue"
          isLoading={isLoadingConfirm}
        >
          Confirm
        </Button>
      </VStack>
    </Box>
  )
}

export default ConfirmationForm
