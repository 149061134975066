import { Box, ButtonGroup, Flex, Grid, Heading } from "@chakra-ui/react"
import { useContractCreateForm } from "modules/Contract/src/core/hooks"
import React from "react"
import { Button, FormControl, Input, Textarea } from "theme/components"
import { useNavigate } from "react-router-dom"

function ContractCreatePage({
  onCreate,
  isLoadingCreate,
  onCancel
}) {
  const navigate = useNavigate()
  const handleCancel = () => onCancel?.() || navigate("./")

  const handleSubmit = (name, description) => {
    onCreate && onCreate(name, description)
  }

  const {
    handleNameChange,
    handleDescriptionChange,
    handleContractCreate,
    isDisabledCreateButton,
    name,
    description
  } = useContractCreateForm(handleSubmit)

  return (
    <Layout
      isDisabledCreate={isDisabledCreateButton}
      onCreate={handleContractCreate}
      isLoadingCreate={isLoadingCreate}
      onCancel={handleCancel}
    >
      <FormControl label="Contract Name *" maxW="full" width="full">
        <Input name="name" value={name} onChange={handleNameChange} />
      </FormControl>

      <FormControl label="Description" maxW="full" width="full" mt="4">
        <Textarea
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          resize="none"
          height="64px"
        />
      </FormControl>
    </Layout>
  )
}

export default ContractCreatePage

const Layout = ({
  children,
  onCreate,
  isLoadingCreate,
  isDisabledCreate,
  onCancel
}) => (
  <Grid height="full" width="full" templateRows="auto 1fr auto" px="4">
    <Header />

    <Box pt="3" pb="8" overflowY="scroll">
      {children}
    </Box>

    <Footer
      onCreate={onCreate}
      isLoadingCreate={isLoadingCreate}
      isDisabledCreate={isDisabledCreate}
      onCancel={onCancel}
    />
  </Grid>
)

const Header = () => (
  <Flex width="full" align="center" height="32px" pb="2" mt={3}>
    <Heading
      as="h3"
      fontSize="xl"
      lineheight="xl"
      fontWeight="semibold"
      display="flex"
      align="center"
      ml="3"
      mb="-1"
    >
      Create contract
    </Heading>
  </Flex>
)

const Footer = ({ onCreate, isLoadingCreate, isDisabledCreate, onCancel }) => (
  <Flex height="56px" align="center">
    <ButtonGroup spacing="8" width="full" justifyContent="end" size="lg">
      <Button variant="textDark" color="#666666" onClick={onCancel}>
        Cancel
      </Button>

      <Button onClick={onCreate} isLoading={isLoadingCreate}
        isDisabled={isDisabledCreate}>
        Create
      </Button>
    </ButtonGroup>
  </Flex>
)
