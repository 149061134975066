import { useQueryParams } from "hooks"
import { useGetCurrentUser } from "modules/User/src/core/hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { useEffect, useMemo } from "react"
import useHomepageContracts from "./contracts"
import useHomepageWorkspaces from "./workspaces"

const useHomepage = () => {
  const { queryParams, setQueryParams } = useQueryParams()

  const { data: user = null } = useGetCurrentUser()

  const isMobile = useIsMobile()

  const activeTab = useMemo(() => {
    return queryParams.tab || "workspaces"
  }, [queryParams.tab])
  const handleTabChange = (tab: string) => {
    setQueryParams({ tab })
  }

  const { handleWorkspaceCreate, ...workspacesTabProps } =
    useHomepageWorkspaces({
      user,
      activeTab,
      isMobile
    })
  const { handleContractCreate, ...contractsTabProps } = useHomepageContracts({
    user,
    activeTab,
    isMobile
  })

  // close modals on mount
  const { dispatch: modalDispatch } = useModalContext()
  useEffect(() => {
    if (isMobile) {
      modalDispatch({ type: "close" })
    }
  }, [isMobile])

  return {
    handleTabChange,
    workspacesTabProps,
    contractsTabProps,
    activeTab,
    handleContractCreate,
    handleWorkspaceCreate,
    isMobile
  }
}

export default useHomepage
