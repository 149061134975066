import { API, graphqlOperation } from "aws-amplify"
import {
  getUser as getUserQuery,
  getUsersByPk as getUsersByPkQuery
} from "../graphql/queries"
import { GetUsersByPkResponse, IUser } from "../types"
import { GetUsersByPkInput } from "../types/graphql"

class UserAppSyncService {
  async getUser(id: string): Promise<IUser> {
    const res = await (API.graphql(
      graphqlOperation(getUserQuery, { id })
    ) as Promise<any>)
    return res.data.getUser
  }

  async getAllByPk(input: GetUsersByPkInput): Promise<GetUsersByPkResponse> {
    const res = await (API.graphql(
      graphqlOperation(getUsersByPkQuery, input)
    ) as Promise<any>)

    return {
      items: res.data.getUsersByPk.items,
      nextToken: res.data.getUsersByPk.nextToken
    }
  }
}

export default UserAppSyncService
