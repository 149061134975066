const inputTheme = {
  Input: {
    variants: {
      primary: {
        field: {
          border: "1px solid",
          borderColor: "line",
          rounded: "8px",

          _focus: {
            borderColor: "line"
          }
        }
      },
      // text variant should remove everything, only text should be visible
      text: {
        field: {
          border: "none",
          outline: "none",
          boxShadow: "none",
          borderRadius: "0",
          bg: "transparent",
          color: "primaryDark",
          fontSize: "sm",
          lineHeight: "sm",
          fontWeight: "medium",
          p: "0",
          height: "17px"
        }
      }
    },
    defaultProps: {
      variant: "primary"
    }
  }
}

export default inputTheme
