import { useCallback, useEffect } from "react"
import { useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { IContract } from "../types"

const useContractSubscriptions = (contractPk: string) => {
  const queryClient = useQueryClient()

  const handleUpdateContractSubscription = useCallback(
    (contract: IContract) => {
      if (contract.pk === contractPk) {
        queryClient.invalidateQueries(["mappedContract", contractPk])
      }
    },
    [contractPk]
  )

  useEffect(() => {
    const updateContractSubscription =
      contractUseCase.updateContractSubscription(
        handleUpdateContractSubscription
      )

    return () => {
      updateContractSubscription()
    }
  }, [handleUpdateContractSubscription])
}

export default useContractSubscriptions
