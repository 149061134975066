const accordionTheme = {
  Accordion: {
    variants: {
      containerVisible: {
        container: {
          '[aria-expanded="true"] + .chakra-collapse': {
            overflow: "visible !important"
          }
        }
      }
    }
  }
}

export default accordionTheme
