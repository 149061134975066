import { useGetCDEAllCodespaces } from "modules/CDE/src//hooks"
import { IUserNormalized } from "modules/User/src//core/types"
import { SelectOption } from "modules/Workspace/src//core/types/global"
import { useMemo, useState } from "react"
import { truncateEnd } from "utils/text"
import { IContractNormalized } from "../../types"
import { useUpdateContract } from "../useUpdateContract"

const useContractGitHub = ({ user, contract }: Props) => {
  const [isOpenGitHubCodespacesPopover, setIsOpenGitHubCodespacesPopover] =
    useState(false)
  const handlePopoverChange = (isOpen: boolean) => {
    setIsOpenGitHubCodespacesPopover(isOpen)
  }

  const { data: codespaces = [] } = useGetCDEAllCodespaces("github", {
    enabled: !!user?.id && isOpenGitHubCodespacesPopover
  })

  const codespaceOptions = useMemo<SelectOption[]>(() => {
    const githubCodespaceOptions = codespaces.map(codespace => {
      const fullLabel = `${codespace.ownerName}/${codespace.repoName} - ${codespace.name}`
      const label = truncateEnd(fullLabel, 28, "...")

      return {
        label,
        value: codespace.id
      }
    })
    const contractCodespacesOptions = (contract?.githubCodespaces || []).map(
      codespace => ({
        label: codespace.name,
        value: codespace.id
      })
    )

    const codespaceOptions = [
      ...githubCodespaceOptions,
      ...contractCodespacesOptions
    ]
    const uniqueCodespaceOptions = new Map(
      codespaceOptions.map(item => [item.value, item])
    )

    return [...uniqueCodespaceOptions.values()]
  }, [codespaces.length, contract?.githubCodespaces?.length])

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { mutateAsync: updateContractAsync } = useUpdateContract()
  const handleSubmit = async (codespaceId: string) => {
    if (!contract) {
      return
    }

    setIsLoadingSubmit(true)
    try {
      const codespaceOption = codespaceOptions.find(
        option => option.value === codespaceId
      )
      const codespace = codespaces.find(
        codespace => codespace.id === codespaceId
      )

      if (!codespaceOption || !codespace) {
        return
      }

      await updateContractAsync({
        input: {
          id: contract.id,
          githubCodespaces: [
            ...(contract.githubCodespaces || []),
            {
              id: codespaceId,
              name: codespaceOption.label,
              link: codespace.url
            }
          ]
        }
      })
    } catch (error) {
      console.error(`GitHub codespace ${codespaceId} submit : `, error)
    }
    setIsLoadingSubmit(false)
  }

  return {
    codespaceOptions,
    handlePopoverChange,
    handleSubmit,
    isLoadingSubmit
  }
}

export default useContractGitHub

type Props = {
  contract: IContractNormalized | null
  user: IUserNormalized | null
}
