import { useQueryParams } from "hooks"
import {
  WorkspaceTeamEditProps
} from "modules/Workspace/src//core/hooks/useWorkspaceTeams/types"
import { WorkspaceTeam } from "modules/Workspace/src//core/types/workspace-team"
import { WORKSPACE_TEAM_GROUPS } from "modules/Workspace/src/core/config"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { usePreviousPath } from "providers/PreviousPathProvider"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetWorkspace, useGetWorkspaceTeams } from "../index"
import { useWorkspaceTeamsDiscord } from "./discord"
import useEmailTeams from "./email"
import { useWorkspaceTeamsSlack } from "./slack"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

export const useWorkspaceTeams = (workspaceId: string) => {
  const { queryParams, setQueryParams } = useQueryParams()
  const isMobile = useIsMobile()
  const previousPath = usePreviousPath()
  const navigate = useNavigate()

  const { data: workspace = null } = useGetWorkspace(workspaceId, {
    enabled: !!workspaceId
  })

  const { data: getWorkspaceTeamsResponse  } = useGetWorkspaceTeams(
    { input: { workspaceId } },
    {
      enabled: !!workspaceId
    }
  )

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  // workspace team edit
  const [workspaceTeamEditProps, setWorkspaceTeamEditProps] =
    useState<WorkspaceTeamEditProps | null>(null)
  const workspaceTeamEditPropsMiddleware = (
    workspaceTeamEditProps: WorkspaceTeamEditProps | null
  ) => {
    if (workspaceTeamEditProps) {
      return {
        ...workspaceTeamEditProps,
        onUpdate: handleWorkspaceTeamUpdate(workspaceTeamEditProps.onUpdate),
        onDisconnect: handleWorkspaceTeamDisconnect(
          workspaceTeamEditProps.onDisconnect
        )
      }
    }

    return null
  }

  const discord = useWorkspaceTeamsDiscord({
    workspace,
    workspaceTeams: getWorkspaceTeamsResponse?.items || []
  })

  const slack = useWorkspaceTeamsSlack({
    workspace,
    workspaceTeams: getWorkspaceTeamsResponse?.items || []
  })

  const email = useEmailTeams({
    workspace,
    workspaceTeams: getWorkspaceTeamsResponse?.items || []
  })

  const handleTeamEdit = (workspaceTeam: WorkspaceTeam) => {
    setQueryParams({
      tab: WORKSPACE_TEAM_EDIT_TAB,
      communityType: workspaceTeam.communityType,
      workspaceId: workspaceTeam.communityWorkspaceId || ""
    })
  }

  const isCommunityWorkspaceEditModalOpen = useMemo(
    () => modalState.modalType === "workspaceTeamEdit",
    [modalState.modalType]
  )

  const handleWorkspaceEditModalClose = useCallback(() => {
    setWorkspaceTeamEditProps(null)

    navigate(previousPath || "./")
  }, [previousPath])

  useEffect(() => {
    const activeTab = queryParams.tab

    if (activeTab === WORKSPACE_TEAM_EDIT_TAB) {
      const methods = {
        discord: discord.getTeamEditProps,
        slack: slack.getTeamEditProps,
        email: email.getTeamEditProps
      }

      let normalizedEditProps
      const communityType =
        queryParams.communityType.toLowerCase() as keyof typeof methods
      const editProps: WorkspaceTeamEditProps = methods[communityType](
        queryParams.workspaceId
      )

      if (editProps) {
        normalizedEditProps = workspaceTeamEditPropsMiddleware(editProps)
        setWorkspaceTeamEditProps(normalizedEditProps)
      }

      if (!isMobile && normalizedEditProps) {
        modalDispatch({
          type: "open",
          modalType: ModalType.WorkspaceTeamEdit,
          props: normalizedEditProps,
          onClose: handleWorkspaceEditModalClose
        })
      }

      if (isCommunityWorkspaceEditModalOpen) {
        modalDispatch({
          type: "update",
          onClose: handleWorkspaceEditModalClose
        })
      }
    } else {
      setWorkspaceTeamEditProps(null)

      if (isCommunityWorkspaceEditModalOpen) {
        modalDispatch({ type: "close" })
      }
    }
  }, [
    JSON.stringify(queryParams),
    isCommunityWorkspaceEditModalOpen,
    handleWorkspaceEditModalClose,
    discord.getTeamEditProps,
    slack.getTeamEditProps,
    email.getTeamEditProps
  ])

  const handleWorkspaceTeamUpdate =
    (onUpdate: WorkspaceTeamEditProps["onUpdate"]) =>
      async (communityWorkspaceId: string) => {
        await onUpdate(communityWorkspaceId)
        handleWorkspaceEditModalClose()
      }

  const handleWorkspaceTeamDisconnect =
    (onDisconnect: WorkspaceTeamEditProps["onDisconnect"]) =>
      async (communityWorkspaceId: string) => {
        onDisconnect && (await onDisconnect(communityWorkspaceId))
        handleWorkspaceEditModalClose()
      }

  // workspace team edit

  // connected workspaces
  const workspaceTeamsGroups = useMemo(
    () =>
      WORKSPACE_TEAM_GROUPS.map(teamGroup => {
        return {
          ...teamGroup,
          teams: getWorkspaceTeamsResponse?.items.filter(
            workspaceTeam => workspaceTeam.type === teamGroup.type
          ) || []
        }
      }).filter(teamGroup => teamGroup.teams.length > 0) || [],
    [JSON.stringify(getWorkspaceTeamsResponse?.items)]
  )

  return {
    workspaceTeamsGroups,
    handleTeamEdit,
    workspaceTeamEditProps
  }
}

const WORKSPACE_TEAM_EDIT_TAB = "workspace-team-edit"
