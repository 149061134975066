import { Box, VStack } from "@chakra-ui/react"
import TeamOptionsGroupAccordion
  from "modules/Workspace/src/components/TeamOptionsGroupAccordion"
import React, { useMemo, useState } from "react"
import { Input } from "theme/components"
import Layout from "./Layout"

const DelegateModal = ({
  teamOptionsGroups,
  onTeamOptionChange,
  onDelegate,
  isLoadingDelegate,
  closeModal,
  isOpen
}) => {
  const [search, setSearch] = useState("")
  const handleSearchChange = event => setSearch(event.target.value)

  const filteredTeamOptionsGroups = useMemo(() => {
    if (!teamOptionsGroups) {
      return []
    }

    const searchValue = search.trim().toLowerCase()

    if (!searchValue) {
      return teamOptionsGroups
    }

    return teamOptionsGroups.map(teamGroup => {
      const filteredTeamOptions = teamGroup.teamOptions.filter(teamOption =>
        teamOption.name.toLowerCase().includes(searchValue)
      )

      return {
        ...teamGroup,
        teamOptions: filteredTeamOptions
      }
    })
  }, [JSON.stringify(teamOptionsGroups), search])

  return (
    <Layout
      isOpen={isOpen}
      closeModal={closeModal}
      onDelegate={onDelegate}
      isLoadingDelegate={isLoadingDelegate}
    >
      <SearchInput onChange={handleSearchChange} value={search} />

      <TeamOptionsGroups
        teamOptionsGroups={filteredTeamOptionsGroups}
        onTeamOptionChange={onTeamOptionChange}
      />
    </Layout>
  )
}

export default DelegateModal

const TeamOptionsGroups = ({ teamOptionsGroups = [], onTeamOptionChange }) => (
  <VStack align="stretch" w="full" maxW="380px" spacing="4">
    {teamOptionsGroups.map(teamGroup =>
      teamGroup.teamOptions.length ? (
        <TeamOptionsGroupAccordion
          key={teamGroup.type}
          groupName={teamGroup.label}
          teamOptions={teamGroup.teamOptions}
          onTeamOptionChange={onTeamOptionChange}
          defaultIndex={0}
        />
      ) : null
    )}
  </VStack>
)

const SearchInput = ({ onChange, value }) => (
  <Box pb={6}>
    <Input placeholder="Search" onInput={onChange} value={value} width="full" />
  </Box>
)
