import { useQuery } from "react-query"
import { communityUseCase } from "../index"
import {
  useGetCommunityAuthUrlInputType,
  useGetCommunityAuthUrlOptionsType
} from "../types/hooks"

export const useGetCommunityAuthUrl = (
  input: useGetCommunityAuthUrlInputType,
  options: useGetCommunityAuthUrlOptionsType = {}
) => {
  return useQuery<string, Error>(
    ["communityAuthUrl", input.communityType],
    () =>
      communityUseCase.getCommunityAuthUrl(input.communityType, input.options),
    options
  )
}
