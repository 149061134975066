import { VoidFunction } from "global-types"
import { useQueryParams } from "hooks"
import {
  useCreateContract, useDelegateHomepageContract,
  useGetHomepageContracts,
  useUpdateContract
} from "modules/Contract/src/core/hooks"
import { ContractSourceType } from "modules/Contract/src/core/types"
import { IUserNormalized } from "modules/User/src/core/types"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { v4 as uuidv4 } from "uuid"
import { HomepageTabs } from "./types"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

const useHomepageContracts = ({ user, activeTab, isMobile }: Props) => {
  const { setQueryParams } = useQueryParams()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { data: contracts = [] } = useGetHomepageContracts({
    enabled: !!user?.id && activeTab === HomepageTabs.CONTRACTS
  })
  const { mutateAsync: delegateHomepageContractAsync } =
    useDelegateHomepageContract()

  const handleDelegate = async (
    contractId: string,
    { onSuccess }: { onSuccess: VoidFunction }
  ) => {
    try {
      await delegateHomepageContractAsync(contractId)
      onSuccess()
    } catch (error) {
      console.error("Homepage contract delegate : ", error)
      onSuccess()
    }
  }

  const handleCreateClick = () => {
    setQueryParams({ tab: HomepageTabs.CONTRACT_CREATE })
  }

  const isContractCreateModalOpen = useMemo(
    () => modalState.modalType === "contractCreate",
    [modalState.modalType]
  )

  const isMobileRef = useRef(isMobile)

  const {
    mutateAsync: createContractAsync,
    isLoading: isLoadingCreateContract
  } = useCreateContract()

  useEffect(() => {
    isMobileRef.current = isMobile
  }, [isMobile])

  const handleContractCreateModalClose = useCallback(() => {
    if (!isMobileRef.current && activeTab === HomepageTabs.CONTRACT_CREATE) {
      setQueryParams({ tab: HomepageTabs.CONTRACTS })
    }
  }, [isMobileRef.current, activeTab, setQueryParams])

  useEffect(() => {
    if (
      isContractCreateModalOpen &&
      (activeTab !== HomepageTabs.CONTRACT_CREATE || isMobile)
    ) {
      modalDispatch({ type: "close" })
    }

    if (
      activeTab === HomepageTabs.CONTRACT_CREATE &&
      !isContractCreateModalOpen &&
      !isMobile
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.ContractCreate,
        props: {
          onSubmit: handleContractCreate,
          isLoadingSubmit: isLoadingCreateContract
        },
        onClose: handleContractCreateModalClose
      })
    }
    if (
      isContractCreateModalOpen &&
      activeTab === HomepageTabs.CONTRACT_CREATE
    ) {
      modalDispatch({
        type: "update",
        onClose: handleContractCreateModalClose
      })
    }
  }, [
    isContractCreateModalOpen,
    handleContractCreateModalClose,
    activeTab,
    isMobile,
    isLoadingCreateContract,
  ])


  const handleContractCreate = useCallback(
    async (name: string, description: string) => {
      const contractPk = uuidv4()

      try {
        await createContractAsync({
          input: {
            name,
            description,
            ownerId: user!.id,
            reporterId: user!.id,
            sourceType: ContractSourceType.COSMO_HOMEPAGE,
            pk: contractPk,
            sk: `owner#${user!.id}`
          }
        })

        if (isMobile) {
          setQueryParams({ tab: HomepageTabs.CONTRACTS })
        } else {
          modalDispatch({ type: "close" })
        }
      } catch (error) {
        console.error("Contract create : ", error)
      }
    },
    [user?.id, isMobile]
  )

  const { mutateAsync: updateContractAsync } = useUpdateContract()
  const handleCompleteToggle = (contractId: string) => {
    const contract = contracts.find(contract => contract.id === contractId)

    try {
      updateContractAsync({
        input: {
          id: contractId,
          completed: !contract?.completed
        }
      })
    } catch (error) {
      console.error("Homepage contract update : ", error)
    }
  }

  return {
    contracts,
    onDelegate: handleDelegate,
    onCreateClick: handleCreateClick,
    onCompleteToggle: handleCompleteToggle,
    handleContractCreate,
    isLoadingCreateContract
  }
}

export default useHomepageContracts

type Props = {
  user: IUserNormalized | null
  activeTab: string
  isMobile: boolean
}
