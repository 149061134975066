import ContractsSidebar from "modules/Contract/src/components/ContractsSidebar"
import React, { useCallback } from "react"
import EmptyWorkspace from "../EmptyWorkspace"
import Header from "./Header"
import Layout, { ContractsBoxLayout } from "./Layout"

function WorkspaceMobile({
  workspace,
  contractSidebarProps,
  onContractCreateClick
}) {
  if (!workspace) {
    return null
  }

  const renderHeader = useCallback(
    () => <Header workspace={workspace} />,
    [JSON.stringify(workspace)]
  )

  return (
    <Layout renderHeader={renderHeader}>
      {contractSidebarProps.contracts.length ? (
        <ContractsBoxLayout>
          <ContractsSidebar {...contractSidebarProps} pb="3" />
        </ContractsBoxLayout>
      ) : (
        <EmptyWorkspace
          onContractCreateClick={onContractCreateClick}
          isArchivedContractsTab={contractSidebarProps.isArchivedContractsTab}
          onArchivedContractsBackClick={contractSidebarProps.onArchivedContractsBackClick}
        />
      )}
    </Layout>
  )
}

export default WorkspaceMobile
