import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { workspaceTeamUseCase } from "../index"
import { WorkspaceTeam } from "../types/workspace-team"

const useGetWorkspaceTeamByIds = (ids: string[], options: Options = {}) =>
  useQuery<WorkspaceTeam[], Error>(
    ["workspaceTeamsByIds", ids],
    () => workspaceTeamUseCase.getByIds(ids),
    options
  )

export type Options = Omit<
  UseQueryOptions<WorkspaceTeam[], Error, WorkspaceTeam[], QueryKey>,
  "queryKey" | "queryFn"
>

export default useGetWorkspaceTeamByIds
