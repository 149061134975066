import { Box, Flex, HStack } from "@chakra-ui/react"
import React, { useCallback } from "react"
import {
  ContractDescription,
  ContractMessages,
  ContractName,
  ContractResources, ContractSubtasksSection
} from "../ui"
import Header from "./Header"
import { SendToJiraAction } from "./SendToJiraAction"
import { Button, Checkbox, Icon } from "../../../../../theme/components"

function ContractDetails({
  contract,
  onDelegateClick,
  messages = [],
  onContractSettingsClick,
  onContractDeleteClick,
  onCommentCreate,
  toggleCompleted,
  onUpdate,
  resources,
  onResourcesUpload,
  jiraProps = {},
  githubProps,
  onBackClick,
  onChildContractCreate,
  onChildContractCompleteToggle,
  childContracts,
  onChildContractClick,
  onChatClick,
  ...props
}) {
  const handleDelegateClick = () => {
    onDelegateClick && onDelegateClick(contract.pk)
  }

  const handleUpdate = updatedObj => {
    onUpdate && onUpdate(updatedObj)
  }

  const renderHeader = useCallback(
    () => (
      <Header
        contract={contract}
        toggleCompleted={toggleCompleted}
        onDelegateClick={handleDelegateClick}
        githubProps={githubProps}
        onBackClick={onBackClick}
        onContractSettingsClick={onContractSettingsClick}
        onContractDeleteClick={onContractDeleteClick}
      />
    ),
    [JSON.stringify(contract), githubProps]
  )

  return (
    <Layout renderHeader={renderHeader} {...props}>
      <Flex width="full">
        <Box display={{ base: "block", md: "none" }} mr={3} mt={2}>
          <Checkbox
            isChecked={contract.completed}
            onChange={e => toggleCompleted(contract.id)}
            size="lg"
          />
        </Box>

        <ContractName
          defaultValue={contract.name}
          onSubmit={name => handleUpdate({ name })}
        />
      </Flex>


      {!contract.sourceContractPk &&
        <HStack width="full" mt="4" spacing="4">
          {/*<ContractPointEstimation*/}
          {/*  contract={contract}*/}
          {/*  onChange={pointEstimation => handleUpdate({ pointEstimation })}*/}
          {/*/>*/}
          <Button
            variant="secondary"
            onClick={onChatClick}
          >
            <Icon name="comment" mr="2" mt="-0.5"/>
            Chat
          </Button>


          <SendToJiraAction
            contract={contract}
            projectOptions={jiraProps.projectOptions}
            onSubmit={jiraProps.handleSendToJira}
            isLoadingSendToJira={jiraProps.isLoadingSendToJira}
          />
        </HStack>
      }

      <ContractDescription
        defaultValue={contract.description}
        onSubmit={description => handleUpdate({ description })}
      />

      <ContractResources
        contractPk={contract.pk}
        resources={resources}
        onUpload={onResourcesUpload}
      />

      {!contract?.sourceContractPk && (
        <ContractSubtasksSection
          onCreate={onChildContractCreate}
          onCompleteToggle={onChildContractCompleteToggle}
          contracts={childContracts}
          onContractClick={onChildContractClick}
        />
      )}

      {contract?.sourceContractPk ? (
        <ContractMessages messages={messages}
          onCommentCreate={onCommentCreate} />
      ) : null}
    </Layout>
  )
}

export default ContractDetails

function Layout({ renderHeader, children, ...props }) {
  return (
    <Box
      roundedTop={{ base: "0", md: "lg" }}
      bgColor="white"
      overflowY="scroll"
      height="full"
      {...props}
    >
      {renderHeader && renderHeader()}

      <Flex
        direction="column"
        overflowY="scroll"
        pt={{ base: "4", md: "2" }}
        pb={{ base: "6", md: "8" }}
        pl={{ base: "0", md: "30px" }}
        pr={{ base: 0, md: "60px" }}
      >
        {children}
      </Flex>
    </Box>
  )
}
