import { WorkspaceTeamType } from "./types/workspace-team"

interface WorkspaceTeamGroup {
  type: WorkspaceTeamType
  label: string
}

export const WORKSPACE_TEAM_GROUPS: WorkspaceTeamGroup[] = [
  {
    type: WorkspaceTeamType.CHANNEL,
    label: "Channels"
  },
  {
    type: WorkspaceTeamType.USER,
    label: "Users"
  }
]

type DelegateTeamGroupName = "users" | "channels" | "predefined"

interface DelegateTeamGroup {
  types: WorkspaceTeamType[]
  label: string
  isPredefined?: boolean
  name: DelegateTeamGroupName
}

export const DELEGATE_TEAM_GROUPS: DelegateTeamGroup[] = [
  {
    types: [WorkspaceTeamType.CHANNEL, WorkspaceTeamType.USER],
    name: "predefined",
    label: "Predefined",
    isPredefined: true
  },
  {
    types: [WorkspaceTeamType.CHANNEL],
    label: "Channels",
    name: "channels"
  },
  {
    types: [WorkspaceTeamType.USER],
    label: "Users",
    name: "users"
  }
]

export const PLATFORM_TEAM_GROUP_FIELD_MAP: {
  [key in DelegateTeamGroupName]: string
} = {
  users: "usersGroupLabel",
  channels: "channelsGroupLabel",
  predefined: "predefinedGroupLabel"
}

export const DEFAULT_TEAM_EMOJI = "👽"

export const EMOJI_BG_COLORS = [
  "#f3f3f3",
  "#fee0e7",
  "#ffdad8",
  "#feebd1",
  "#fef2d0",
  "#ddf7e2",
  "#e0f5fe",
  "#f7f1fb"
]

export const DEFAULT_EMOJI_BG_COLOR = EMOJI_BG_COLORS[0]
