import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { workspaceTeamUseCase } from "../index"
import {
  CommunityType,
  GetWorkspaceTeamsByWorkspaceIdInput
} from "../types/graphql"
import { GetWorkspaceTeamsByWorkspaceIdResponse } from "../types"

const useGetWorkspaceTeams = (
  { input, communityType }: FnInput,
  options: Options = {}
) =>
  useQuery<GetWorkspaceTeamsByWorkspaceIdResponse, Error>(
    communityType ? ["workspaceTeams", input.workspaceId, communityType] : ["workspaceTeams", input.workspaceId],
    () => workspaceTeamUseCase.getAllByWorkspaceId(input),
    options
  )

export default useGetWorkspaceTeams

type FnInput = {
  communityType?: CommunityType
  input: GetWorkspaceTeamsByWorkspaceIdInput
}

export type Options = Omit<
  UseQueryOptions<GetWorkspaceTeamsByWorkspaceIdResponse, Error, GetWorkspaceTeamsByWorkspaceIdResponse, QueryKey>,
  "queryKey" | "queryFn"
>
