import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { sourceUseCase } from "../index"
import { INormalizedSourceWorkspace, SourceType } from "../types"

const useGetSourceWorkspaces = (sourceType: IFnInput, options: Options = {}) =>
  useQuery<INormalizedSourceWorkspace[], Error>(
    ["source-workspaces"],
    () => sourceUseCase.getWorkspaces(sourceType),
    options
  )

export default useGetSourceWorkspaces

export type IFnInput = SourceType

export type Options = Omit<
  UseQueryOptions<
    INormalizedSourceWorkspace[],
    Error,
    INormalizedSourceWorkspace[],
    QueryKey
  >,
  "queryKey" | "queryFn"
>
