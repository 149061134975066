import {
  INormalizedSourceWorkspace,
  SourceType,
  normalizedContractType,
  normalizedProjectType
} from "./types"

export const normalizeSourceContracts = (
  type: SourceType,
  contracts: any[]
): normalizedContractType[] => {
  switch (type) {
    case "jira":
      return contracts
        .map(contract => ({
          id: contract.id,
          name: contract.fields.summary,
          description: contract.renderedFields.description || "",
          key: contract.key
        }))
        .reverse()
  }

  return []
}

export const normalizeSourceProjects = (
  type: SourceType,
  projects: any[]
): normalizedProjectType[] => {
  switch (type) {
    case "jira":
      return projects.map(project => ({
        id: project.id,
        name: project.name
      }))
    case "discord":
      return projects.map(channel => ({
        id: channel.id,
        name: channel.name
      }))
    case "slack":
      return projects.map(channel => ({
        id: channel.id,
        name: channel.name
      }))
  }

  return []
}

export const normalizeSourceWorkspaces = (
  type: SourceType,
  workspace: any[]
): INormalizedSourceWorkspace[] => {
  switch (type) {
    case "jira":
      return workspace.map(workspace => ({
        id: workspace.id,
        name: workspace.name
      }))
  }

  return []
}
