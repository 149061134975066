export class PlatformLocalStorage {
  static LOCAL_STORAGE_PLATFORM_USER_EMAIL = "platformUserEmail"
  static LOCAL_STORAGE_PLATFORM_DOMAIN = "platformDomain"

  static set(platformUserEmail: string, platformDomain: string) {
    localStorage.setItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_USER_EMAIL, platformUserEmail)
    localStorage.setItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_DOMAIN, platformDomain)
  }

  static get() {
    return {
      platformUserEmail: localStorage.getItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_USER_EMAIL),
      platformDomain: localStorage.getItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_DOMAIN)
    }
  }

  static clear() {
    localStorage.removeItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_USER_EMAIL)
    localStorage.removeItem(PlatformLocalStorage.LOCAL_STORAGE_PLATFORM_DOMAIN)
  }
}
