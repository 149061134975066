const menuTheme = {
  Menu: {
    variants: {
      settings: {
        list: {
          py: 1,
          minWidth: "auto",
          borderColor: "line",
          mt: 2
        },
        item: {
          fontSize: "sm",
          fontWeight: "medium"
        }
      }
    }
  }
}

export default menuTheme
