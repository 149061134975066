import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import {
  ContractCreateInputNormalized,
  ContractSourceType,
  IContractNormalized,
  IContractNormalizedWithLoading
} from "../types"
import { mergeContracts } from "../utils"

export const useCreateContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createContract",
    input => contractUseCase.createContract(input.input),
    {
      onMutate: ({ input, sourceContractPk }) => {
        if (sourceContractPk) {
          handleChildContractCreate({
            sourceContractPk,
            newContract: input as IContractNormalized,
            queryClient
          })
        }
      },
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }

        const createdContract = data as IContractNormalized

        if (createdContract.sourceContractPk) {
          handleChildContractCreate({
            sourceContractPk: createdContract.sourceContractPk,
            newContract: createdContract,
            queryClient
          })
          return
        }

        const queryKey =
          createdContract.sourceType === ContractSourceType.COSMO_WORKSPACE
            ? ["workspaceContracts", variables.workspaceId]
            : "homepageContracts"

        queryClient.setQueryData<IContractNormalizedWithLoading[]>(
          queryKey,
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]

            const contractIndex = contracts.findIndex(
              contract => contract.pk === createdContract.pk
            )

            if (contractIndex > -1) {
              const contract = contracts[contractIndex]
              const mappedContract = mergeContracts(contract, createdContract)
              contracts[contractIndex] = {
                ...mappedContract,
                isLoading: false
              }
            } else {
              contracts.unshift(createdContract)
            }

            return contracts
          }
        )
      },
      ...options
    }
  )
}

const handleChildContractCreate = ({
  sourceContractPk,
  newContract,
  queryClient
}: handleChildContractCreateInput) => {
  queryClient.setQueryData<IContractNormalizedWithLoading[]>(
    ["childContracts", sourceContractPk],
    oldContracts => {
      const contracts = [
        ...(oldContracts || [])
      ] as IContractNormalizedWithLoading[]

      const contractIndex = contracts.findIndex(
        contract => contract.pk === newContract.pk
      )

      // update existing contract
      if (contractIndex > -1) {
        contracts[contractIndex] = newContract
        return contracts
      }

      // add new contract
      const contract = {
        ...newContract,
        completed: false
      } as IContractNormalizedWithLoading

      contracts.push(contract)

      return contracts
    }
  )
}

type handleChildContractCreateInput = {
  sourceContractPk: string
  newContract: IContractNormalized
  queryClient: ReturnType<typeof useQueryClient>
}

export type FnInput = {
  input: ContractCreateInputNormalized
  workspaceId?: string
  sourceContractPk?: string
}

export type Options = Omit<
  UseMutationOptions<
    IContractNormalizedWithLoading | null,
    Error,
    FnInput
  >,
  "mutationFn"
>
