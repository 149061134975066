export const TABS = [
  {
    label: "Workspaces",
    value: "workspaces",
    aliases: ["workspaces", "workspace-create"]
  },
  {
    label: "Contracts",
    value: "contracts",
    aliases: ["contracts", "contract-create"]
  }
]
