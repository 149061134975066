import {
  ButtonGroup,
  Editable,
  EditablePreview,
  EditableTextarea,
  useEditableControls
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "theme/components"
import { ContractSectionLayout } from "./index"

const ContractDescription = ({
  defaultValue,
  onSubmit,
  isEditable = true,
  heading,
  placeholder,
  saveButtonLabel,
  cancelButtonLabel
}) => (
  <ContractSectionLayout
    iconName="docs"
    heading={heading || "Description"}
  >
    <DescriptionField
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      isEditable={isEditable}
      placeholder={placeholder}
      saveButtonLabel={saveButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
    />
  </ContractSectionLayout>
)

export default ContractDescription

function DescriptionField({
  defaultValue,
  onSubmit,
  isEditable = true,
  placeholder,
  saveButtonLabel,
  cancelButtonLabel,
  ...props
}) {
  const [value, setValue] = useState(defaultValue || "")

  const textAreaRef = useRef(null)
  const handleSubmit = value => {
    const description = value.trim()
    if (description !== defaultValue) {
      onSubmit && onSubmit(description)
    }
  }

  const textAreaAdjust = event => {
    const enterHeight = event.key === "Enter" ? 24 : 0
    event.target.style.height = "1px"
    event.target.style.height = event.target.scrollHeight + enterHeight + "px"
  }

  const handleChange = value => setValue(value)

  useEffect(() => {
    setValue(defaultValue || "")
  }, [defaultValue])

  return (
    <Editable
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      isPreviewFocusable={true}
      onSubmit={handleSubmit}
      onEdit={() => textAreaAdjust({ target: textAreaRef.current })}
      submitOnBlur={false}
      onBlur={e => e.preventDefault()}
      placeholder={placeholder || "Add a description..."}
      isDisabled={!isEditable}
      {...props}
    >
      {({ isEditing }) => (
        <>
          <EditablePreview
            as="pre"
            width="full"
            fontSize="sm"
            fontFamily="body"
            whiteSpace="pre-wrap"
            px="3"
            py="2"
            bgColor="white"
            rounded="12px"
            _hover={{
              bgColor: "lightBlue"
            }}
            _disabled={{
              bg: "white"
            }}
          />

          <EditableTextarea
            ref={textAreaRef}
            resize="none"
            fontSize="sm"
            onKeyDown={textAreaAdjust}
            bg={isEditing ? "lightBlue" : "white"}
            bgColor="lightBlue"
            onBlur={e => e.preventDefault()}
            border="none"
            px="3"
            py="2"
            rounded="12px"
            _focus={{
              border: "none",
              boxShadow: "none"
            }}
            _placeholder={{
              color: "rgba(48, 48, 48, 0.6)"
            }}
          />

          <DescriptionControls
            saveButtonLabel={saveButtonLabel}
            cancelButtonLabel={cancelButtonLabel}
          />
        </>
      )}
    </Editable>
  )
}

function DescriptionControls({
  saveButtonLabel = "Save",
  cancelButtonLabel = "Cancel"
}) {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
    useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="end" w="full" mt="0" spacing="3">
      <Button variant="secondary" {...getCancelButtonProps()} size="sm">
        {cancelButtonLabel}
      </Button>

      <Button {...getSubmitButtonProps()} size="sm">
        {saveButtonLabel}
      </Button>
    </ButtonGroup>
  ) : (
    <></>
  )
}
