import colorsTheme from "../../../colorsTheme"

const checkboxTheme = {
  Checkbox: {
    variants: {
      primaryGreen: {
        control: {
          transition: "all 0.1s",
          borderRadius: "7px",
          border: "none",
          bgColor: "white",
          boxShadow: `inset 0 0 0 1px ${colorsTheme.colors.line}`,

          _hover: {
            boxShadow: `inset 0 0 0 1px ${colorsTheme.colors.line}`,
            bgColor: "white"
          },

          _checked: {
            boxShadow: `inset 0 0 0 1px ${colorsTheme.colors.success}`,
            border: "none",
            bgColor: "success",

            _hover: {
              boxShadow: `inset 0 0 0 1px ${colorsTheme.colors.success}`,
              bgColor: "success"
            },

            _disabled: {
              boxShadow: "none"
            }
          }
        }
      }
    },
    sizes: {
      md: {
        control: {
          height: "20px",
          width: "20px",

          _checked: {
            svg: {
              height: "10px",
              width: "10px"
            }
          }
        }
      },
      lg: {
        control: {
          height: "24px",
          width: "24px",

          _checked: {
            svg: {
              height: "12px",
              width: "12px"
            }
          }
        }
      }
    },
    defaultProps: {
      size: "md",
      variant: "primaryGreen"
    }
  }
}

export default checkboxTheme
