import { Grid } from "@chakra-ui/react"
import MainLayout from "modules/Workspace/src/components/layouts/MainLayout"
import React from "react"

function Layout({ children, renderHeader, onContractCreateClick }) {
  return (
    <MainLayout isBgImage onCreateClick={onContractCreateClick}>
      {renderHeader()}

      <Grid
        height="calc(100vh - 80px)"
        maxH="calc(100vh - 80px)"
        pl="30px"
        overflow="hidden"
        templateColumns="auto 256px"
        columnGap="6"
      >
        {children}
      </Grid>
    </MainLayout>
  )
}

export default Layout

export function ContractsBoxLayout({ children }) {
  return (
    <Grid
      templateColumns="358px minmax(680px, auto)"
      columnGap="3"
      overflowY="scroll"
    >
      {children}
    </Grid>
  )
}
