import { Box, Container, Grid, Heading } from "@chakra-ui/react"
import React from "react"

export function AuthLayout({ children, headerText }) {
  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      templateColumns="minmax(0, 480px)"
      className="h-screen"
    >
      <Container px={{ base: "4", md: "10" }} maxW="480px">
        <Box
          maxW={{ base: "320px", md: "400px" }}
          width="full"
          mx="auto"
          py={{ base: "4", md: "8" }}
          px={{ base: "0", md: "10" }}
          boxShadow={{ base: "none", md: "authContainer" }}
          rounded="md"
        >
          {headerText && (
            <Heading as="h5" fontSize="lg" align="center">
              {headerText}
            </Heading>
          )}

          {children}
        </Box>
      </Container>
    </Grid>
  )
}
