import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ButtonGroup
} from "@chakra-ui/react"
import React, { useRef } from "react"
import { Button } from "theme/components"

function ConfirmationModal({
  isOpen,
  closeModal,
  onConfirm,
  text,
  headerText,
  confirmButtonLabel
}) {
  const cancelRef = useRef()

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={closeModal}
      variant="confirmation"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{headerText}</AlertDialogHeader>

          <AlertDialogBody>{text}</AlertDialogBody>

          <AlertDialogFooter>
            <ButtonGroup spacing="3">
              <Button variant="secondary" ref={cancelRef} onClick={closeModal}>
                Cancel
              </Button>

              <Button variant="danger" onClick={onConfirm}>
                {confirmButtonLabel || "Delete"}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmationModal
