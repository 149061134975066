import { usePlatformAdminWorkspace } from "modules/Workspace/src/core/hooks"
import React from "react"
import WorkspaceResponsive from "./WorkspaceResponsive"

function PlatformAdminWorkspace() {
  const {
    workspace,
    contractSidebarProps,
    contractDetailsProps,
    contractSettingsProps,
    workspaceDeleteProps,
    sourceSettingsProps,
    communitySettingsProps,
    CDESettingsProps,
    handleContractCreate,
    handleCommunitySettingsClick,
    handleSourceSettingsClick,
    handleContractCreateClick,
    handleCDESettingsClick,
    handleArchivedContractsClick,
    teamsProps,
    workspaceTeamEditProps,
    contractDelegateProps,
    navigateToWorkspace,
    isMobile,
    activeTab,
    user,
    handleChatBackClick
  } = usePlatformAdminWorkspace()

  if (!workspace) {
    return null
  }

  return (
    <WorkspaceResponsive
      workspace={workspace}
      contractSidebarProps={contractSidebarProps}
      contractDetailsProps={contractDetailsProps}
      contractSettingsProps={contractSettingsProps}
      workspaceDeleteProps={workspaceDeleteProps}
      sourceSettingsProps={sourceSettingsProps}
      communitySettingsProps={communitySettingsProps}
      CDESettingsProps={CDESettingsProps}
      handleContractCreate={handleContractCreate}
      handleCommunitySettingsClick={handleCommunitySettingsClick}
      handleSourceSettingsClick={handleSourceSettingsClick}
      handleContractCreateClick={handleContractCreateClick}
      handleCDESettingsClick={handleCDESettingsClick}
      handleArchivedContractsClick={handleArchivedContractsClick}
      teamsProps={teamsProps}
      workspaceTeamEditProps={workspaceTeamEditProps}
      contractDelegateProps={contractDelegateProps}
      navigateToWorkspace={navigateToWorkspace}
      handleChatBackClick={handleChatBackClick}
      isMobile={isMobile}
      activeTab={activeTab}
      user={user}
    />
  )
}

export default PlatformAdminWorkspace
