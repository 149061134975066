import { VStack, useDisclosure } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { SettingsAccordion, SettingsDrawer } from "theme/components"
import { Discord, Jira, SourceProjectsList } from "../SourceSettings"
import { Slack } from "../SourceSettings/Slack"

function SourceSettingsDrawer({
  source,
  jira,
  discord,
  slack,
  isOpen,
  onClose
}) {
  const {
    isOpen: isOpenDrawer,
    onOpen,
    onClose: onCloseDrawer
  } = useDisclosure()

  const handleClose = () => {
    onClose && onClose()
    onCloseDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      onOpen()
    } else {
      handleClose()
    }
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpenDrawer}
      onClose={handleClose}
      headerText="Source"
    >
      <VStack spacing="3" align="start">
        <Jira
          onJiraProjectConnect={jira.handleJiraProjectConnect}
          jiraWorkspaceOptions={jira.jiraWorkspaceOptions}
          jiraWorkspaceProjectOptions={jira.jiraWorkspaceProjectOptions}
          onWorkspaceChange={jira.handleWorkspaceChange}
          onJiraConnect={jira.handleJiraConnect}
          isLoadingJiraProjectConnect={jira.isLoadingJiraProjectConnect}
          onAccordionChange={jira.handleAccordionChange}
        />

        <Discord
          discordGuildOptions={discord.discordGuildOptions}
          discordGuildChannelOptions={discord.discordGuildChannelOptions}
          onDiscordGuildChange={discord.handleDiscordGuildChange}
          onGuildChannelConnect={discord.handleGuildChannelConnect}
          isLoadingDiscordChannelConnect={
            discord.isLoadingDiscordChannelConnect
          }
          isDiscordConnected={discord.isDiscordConnected}
          onDiscordConnect={discord.handleDiscordConnect}
          onAccordionChange={discord.handleAccordionChange}
        />

        <Slack
          slackWorkspaceOptions={slack.slackWorkspaceOptions}
          slackWorkspaceChannelOptions={slack.slackWorkspaceChannelOptions}
          onSlackWorkspaceChange={slack.handleSlackWorkspaceChange}
          onWorkspaceChannelConnect={slack.handleWorkspaceChannelConnect}
          isLoadingSlackChannelConnect={slack.isLoadingSlackChannelConnect}
          isSlackConnected={slack.isSlackConnected}
          onSlackConnect={slack.handleSlackConnect}
          onAccordionChange={slack.handleAccordionChange}
        />

        <DisabledSources />
      </VStack>

      <SourceProjectsList
        projects={source.connectedProjects}
        onProjectEdit={source.handleProjectEdit}
      />
    </SettingsDrawer>
  )
}

export default SourceSettingsDrawer

function DisabledSources() {
  return (
    <>
      <SettingsAccordion name="Asana" iconName="asana" isDisabled />

      <SettingsAccordion name="Trello" iconName="trello" isDisabled />
    </>
  )
}
