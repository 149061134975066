const modalTheme = {
  Modal: {
    baseStyle: {
      dialog: {
        overflowY: "hidden",
        width: "100%",
        height: "100%"
      },
      dialogContainer: {
        width: "100%",
        height: "100%"
      },
      overlay: {
        bgColor: "rgba(48, 48, 48, .4)"
      }
    },
    variants: {
      contractCreate: {
        dialog: {
          p: "0",
          height: "auto"
        },
        header: {
          px: "20px",
          pt: "30px",
          pb: 0
        },
        body: {
          pt: "30px",
          px: "30px",
          pb: "26px"
        }
      },
      edit: {
        dialogContainer: {
          overflow: "hidden"
        },
        dialog: {
          shadow: "dialog",
          rounded: "12px",
          bgColor: "white",
          px: "0",
          py: "0",
          overflowY: "visible"
        },
        header: {
          py: "6",
          px: {
            base: "4",
            md: "6"
          }
        },
        body: {
          display: "flex",
          flexDirection: "column",
          pl: "60px",
          pr: 0,
          py: "10",
          overflowY: "auto"
        },
        footer: {
          height: "68px",
          px: "30px",
          shadow: "0px -6px 20px rgba(0, 0, 0, 0.05)"
        }
      },
      delegate: {
        dialogContainer: {
          overflow: "hidden"
        },
        dialog: {
          shadow: "dialog",
          rounded: "12px",
          bgColor: "white",
          px: "0",
          py: "0",
          overflowY: "visible"
        },
        header: {
          pt: "9",
          pb: "5",
          px: "10",
          borderBottom: "1px solid",
          borderColor: "line"
        },
        body: {
          display: "flex",
          flexDirection: "column",
          py: "6",
          px: "10",
          overflowY: "auto"
        },
        footer: {
          height: "68px",
          px: "30px",
          shadow: "0px -6px 20px rgba(0, 0, 0, 0.05)"
        }
      },
      sourceWorkspaceEdit: {
        dialogContainer: {
          overflow: "hidden",
          p: 0
        },
        dialog: {
          shadow: "dialog",
          rounded: "12px",
          bgColor: "white",
          px: "0",
          py: "0",
          overflowY: "visible"
        },
        header: {
          pt: "9",
          pb: "0",
          px: "10"
        },
        body: {
          display: "flex",
          flexDirection: "column",
          mt: "4",
          pt: "2",
          pb: "6",
          px: "10",
          overflowY: "auto"
        },
        footer: {
          height: "68px",
          px: "30px",
          shadow: "0px -6px 20px rgba(0, 0, 0, 0.05)"
        }
      },
      confirmation: {
        dialog: {
          height: "fit-content",
          rounded: "12px"
        },
        header: {
          px: 8,
          pt: "4",
          pb: "1"
        },
        body: {
          px: 8,
          py: "2"
        },
        footer: {
          px: 8,
          py: "4"
        }
      }
    },
    sizes: {
      xl: {
        dialogContainer: {
          px: {
            base: "0",
            md: "6",
            lg: "10"
          }
        },
        dialog: {
          maxW: "1280px",
          width: "100%",
          height: "calc(100vh - 128px)",
          maxH: {
            base: "auto",
            md: "calc(100vh - 128px)"
          },
          minH: {
            base: "100vh",
            md: "auto"
          },
          my: {
            base: "0",
            md: "64px"
          }
        }
      },
      lg: {
        dialogContainer: {
          px: {
            base: "0",
            lg: "10"
          }
        },
        dialog: {
          maxW: "800px",
          mt: "128px",
          height: "clamp(400px, 100vh - 128px, 584px)"
        }
      },
      md: {
        dialogContainer: {
          px: {
            base: "6",
            lg: "10"
          }
        },
        dialog: {
          maxW: "460px",
          maxH: {
            base: "calc(100vh - 48px)",
            md: "calc(100vh - 128px)"
          },
          my: {
            base: "6",
            md: "16"
          }
        }
      },
      sm: {
        dialogContainer: {
          px: 10
        },
        dialog: {
          maxW: "415px",
          my: 16
        }
      }
    }
  }
}

export default modalTheme
