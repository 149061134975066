import { Grid, Image } from "@chakra-ui/react"
import React from "react"
import { FiLogOut } from "react-icons/fi"
import { Link as RouterLink } from "react-router-dom"
import { IconButton } from "../ui"

function Sidebar({ onSignOut, onCreateClick, ...props }) {
  return (
    <Layout onSignOut={onSignOut} {...props}>
      {onCreateClick ? <CreateButton onClick={onCreateClick} /> : null}
    </Layout>
  )
}

export default Sidebar

function Layout({ onSignOut, children, ...props }) {
  return (
    <Grid
      roundedRight="16px"
      shadow="4px 0px 70px rgba(0, 0, 0, 0.05)"
      bgColor="white"
      templateRows="80px 1fr auto"
      {...props}
    >
      <Header />

      <Grid justifyContent="center" pt="5">
        {children}
      </Grid>

      <Footer onSignOut={onSignOut} />
    </Grid>
  )
}

function Header() {
  return (
    <Grid
      height="80px"
      alignItems="center"
      justifyContent="center"
      px="3"
      position="relative"
      _after={{
        content: '""',
        bgColor: "#ededed",
        height: "1px",
        position: "absolute",
        bottom: "0",
        left: "3",
        right: "3"
      }}
    >
      <RouterLink to="/">
        <Image
          src="/assets/images/logo-symbol.png"
          width="36px"
          height="36px"
        />
      </RouterLink>
    </Grid>
  )
}

function Footer({ onSignOut }) {
  return (
    <Grid alignSelf="end" pb="8" justifyContent="center">
      <IconButton icon={<FiLogOut />} onClick={onSignOut} />
    </Grid>
  )
}

function CreateButton({ onClick }) {
  return (
    <IconButton
      iconName="plus"
      bgColor="primaryPurple"
      rounded="full"
      size="md"
      _hover={{ bgColor: "primaryPurple" }}
      _active={{ bgColor: "primaryPurple" }}
      onClick={onClick}
      shadow="0px 4px 14px rgba(100, 87, 249, 0.5)"
    />
  )
}
