import CommunityAPIService from "../services/CommunityAPIService"
import {
  CommunityType,
  ConnectWorkspaceOptions,
  DisconnectWorkspaceOptions,
  GetWorkspaceTeamsOptions,
  UpdateWorkspaceOptions,
  assignContractToCommunityUserOptionsType,
  createCommunityChannelMessageOptionsType,
  getCommunityAuthUrlOptionsType,
  getCommunityChannelMessagesOptionsType,
  getCommunityWorkspaceMembersOptionsType
} from "../types"

class CommunityRepository {
  constructor(private readonly CommunityApiService: CommunityAPIService) {}

  getUserCommunityWorkspacesWithBot(communityType: CommunityType) {
    return this.CommunityApiService.getUserCommunityWorkspacesWithBot(
      communityType
    )
  }

  getCommunityWorkspaceMembers(
    communityType: CommunityType,
    options: getCommunityWorkspaceMembersOptionsType
  ) {
    return this.CommunityApiService.getCommunityWorkspaceMembers(
      communityType,
      options
    )
  }

  getWorkspaceTeams(
    communityType: CommunityType,
    options: GetWorkspaceTeamsOptions
  ) {
    return this.CommunityApiService.getWorkspaceTeams(communityType, options)
  }

  connectWorkspace(
    communityType: CommunityType,
    options: ConnectWorkspaceOptions
  ) {
    return this.CommunityApiService.connectWorkspace(communityType, options)
  }

  disconnectWorkspace(
    communityType: CommunityType,
    options: DisconnectWorkspaceOptions
  ) {
    return this.CommunityApiService.disconnectWorkspace(communityType, options)
  }

  assignContractToCommunityUser(
    communityType: CommunityType,
    options: assignContractToCommunityUserOptionsType
  ) {
    return this.CommunityApiService.assignContractToCommunityUser(
      communityType,
      options
    )
  }

  createCommunityChannelMessage(
    communityType: CommunityType,
    options: createCommunityChannelMessageOptionsType
  ) {
    return this.CommunityApiService.createCommunityChannelMessage(
      communityType,
      options
    )
  }

  getCommunityChannelMessages(
    communityType: CommunityType,
    options: getCommunityChannelMessagesOptionsType
  ) {
    return this.CommunityApiService.getCommunityChannelMessages(
      communityType,
      options
    )
  }

  getCommunityAuthUrl(
    communityType: CommunityType,
    options: getCommunityAuthUrlOptionsType
  ) {
    return this.CommunityApiService.getCommunityAuthUrl(communityType, options)
  }

  updateWorkspace(
    communityType: CommunityType,
    options: UpdateWorkspaceOptions
  ) {
    return this.CommunityApiService.updateWorkspace(communityType, options)
  }
}

export default CommunityRepository
