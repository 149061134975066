import { Box, Flex, Heading, Stack } from "@chakra-ui/react"
import React from "react"
import ContractCard from "./ContractCard"
import { IconButton } from "../../../../../theme/components"
import { IoArrowBackOutline } from "react-icons/io5"

const ContractsSidebar = ({
  contracts = [],
  activeContractPk,
  toggleContractCompleted,
  onPointEstimationChange,
  onDelegateClick,
  isArchivedContractsTab,
  onArchivedContractsBackClick,
  onContractCardClick,
  ...props
}) => (
  <Layout
    {...props}
    isArchivedContractsTab={isArchivedContractsTab}
    onArchivedContractsBackClick={onArchivedContractsBackClick}
  >
    {contracts.map(contract => (
      <ContractCard
        key={contract.id}
        contract={contract}
        toggleCompleted={toggleContractCompleted}
        onPointEstimationChange={onPointEstimationChange}
        isActive={contract.pk === activeContractPk}
        onDelegateClick={onDelegateClick}
        onClick={onContractCardClick}
      />
    ))}
  </Layout>
)

export default ContractsSidebar

const Layout = ({
  children,
  isArchivedContractsTab,
  onArchivedContractsBackClick,
  ...props
}) => (
  <Box width="100%" height="100%" overflowY="scroll" pb="8" pr="2" {...props}>
    {isArchivedContractsTab ? (
      <Flex align="center" mb={4}>
        <IconButton
          icon={<IoArrowBackOutline />}
          aria-label="arrow-back"
          onClick={onArchivedContractsBackClick}
          mr="3"
        />

        <Heading
          as="h4"
          fontSize="xl"
          lineheight="xl"
          noOfLines={1}
          fontWeight="bold"
        >
          Archived Contracts
        </Heading>
      </Flex>
    ) : null}

    <Stack directioin="column" spacing="2" minH="full">
      {children}
    </Stack>
  </Box>
)
