import { Button as ChakraButton, Image } from "@chakra-ui/react"
import React, { forwardRef } from "react"

export const Button = forwardRef(
  (
    {
      variant = "primaryDark",
      size = "lg",
      iconName,
      icon,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <ChakraButton
        as={props.href ? "a" : "button"}
        ref={ref}
        variant={variant}
        size={size}
        leftIcon={
          icon ||
          (iconName && (
            <Image
              src={`/assets/icons/${iconName}.svg`}
              alt={iconName}
              width="16px"
              height="16px"
            />
          ))
        }
        {...props}
      >
        {children}
      </ChakraButton>
    )
  }
)
Button.displayName = "Button"
