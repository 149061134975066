import { API, graphqlOperation } from "aws-amplify"
import {
  listAdminPlatformConfigs as listAdminPlatformConfigsQuery,
  listPlatformConfigs as listPlatformConfigsQuery
} from "../graphql/queries"
import { ListPlatformConfigsInput } from "../graphql/types"
import { ListPlatformConfigsResponse } from "./types"

class PlatformConfigAppSyncService {
  async listPlatformConfigs(
    input: ListPlatformConfigsInput
  ): Promise<ListPlatformConfigsResponse> {
    const response = await (API.graphql(
      graphqlOperation(listPlatformConfigsQuery, input)
    ) as Promise<any>)

    return {
      items: response.data.listPlatformConfigs.items || [],
      nextToken: response.data.listPlatformConfigs.nextToken
    }
  }

  async listAdminPlatformConfigs(
    input: ListPlatformConfigsInput
  ): Promise<ListPlatformConfigsResponse> {
    const response = await (API.graphql(
      graphqlOperation(listAdminPlatformConfigsQuery, input)
    ) as Promise<any>)

    return {
      items: response.data.listPlatformConfigs.items || [],
      nextToken: response.data.listPlatformConfigs.nextToken
    }
  }
}

export default PlatformConfigAppSyncService
