import { useQueryParams } from "hooks"
import { useGetWorkspaceTeams } from "modules/Workspace/src/core/hooks"
import {
  WorkspaceTeamCommunityType
} from "modules/Workspace/src/core/types/workspace-team"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { usePreviousPath } from "providers/PreviousPathProvider"
import { useCallback, useEffect, useMemo } from "react"
import { ContractActiveTab, ContractTabs } from "../../../components/types"
import { ContractSourceType, IContractNormalized } from "../../types"
import { useSoftDeleteContract, useUpdateContract } from "../index"
import { ContractSettingsData } from "./types"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

const useContractSettings = ({
  contract,
  onSuccessDelete,
  activeTab
}: Props) => {
  const { setQueryParams, removeQueryParams } = useQueryParams()
  const isMobile = useIsMobile()
  const previousPath = usePreviousPath()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { mutateAsync: softDeleteContractAsync } = useSoftDeleteContract()
  const { mutateAsync: updateContractAsync } = useUpdateContract()

  const { data: getWorkspaceTeamsResponse  } = useGetWorkspaceTeams(
    { input: {workspaceId: contract?.workspaceId || ""} },
    {
      enabled: !!contract?.workspaceId
    }
  )

  const predefinedTeamOptions = useMemo(() => {
    const predefinedTeamIds = contract?.predefinedTeamIds || []
    const predefinedTeams = getWorkspaceTeamsResponse?.items.filter(
      team => !predefinedTeamIds.includes(team.id)
    ) || []
    return predefinedTeams.map(team => ({
      label:
        team.communityType === WorkspaceTeamCommunityType.EMAIL
          ? team.teamId
          : team.name,
      value: team.id
    }))
  }, [
    JSON.stringify(getWorkspaceTeamsResponse?.items),
    JSON.stringify(contract?.predefinedTeamIds)
  ])

  const handleContractDeleteClick = useCallback(async () => {
    if (!contract) {
      return
    }

    try {
      const isHomepageContract = [
        ContractSourceType.COSMO_HOMEPAGE,
        ContractSourceType.REPORTER
      ].includes(contract.sourceType)
      const workspaceId: string = !isHomepageContract
        ? contract.workspaceId || ""
        : ""

      await softDeleteContractAsync({
        contractId: contract.id,
        workspaceId
      })

      modalDispatch({
        type: "update",
        onClose: () => {
        }
      })

      onSuccessDelete && onSuccessDelete()
    } catch (error) {
      console.error("delete contract : ", error)
    }

    modalDispatch({ type: "close" })
  }, [contract?.id, contract?.workspaceId, isMobile])

  const handleContractSettingsClick = () => {
    setQueryParams({
      tab: ContractTabs.CONTRACT_SETTINGS
    })
  }

  const handleContractSettingsChange = async (
    settingsData: ContractSettingsData
  ) => {
    if (
      !contract ||
      JSON.stringify(settingsData) ===
      JSON.stringify(contractSettingsProps.settingsData)
    ) {
      return
    }

    try {
      await updateContractAsync({
        workspaceId: contract.workspaceId || "",
        input: {
          id: contract.id,
          allMessagesSync: settingsData.allMessagesSync,
          isSubscribed: settingsData.isSubscribed
        }
      })
    } catch (error) {
      console.error("contract settings update : ", error)
    }
  }

  const handleAddPredefinedTeamClick = async (
    teamId: string,
    { onSuccess }: { onSuccess: () => void }
  ) => {
    if (
      !contract ||
      !predefinedTeamOptions.find(team => team.value === teamId)
    ) {
      return
    }

    try {
      await updateContractAsync({
        workspaceId: contract.workspaceId || "",
        input: {
          id: contract.id,
          predefinedTeamIds: [...(contract.predefinedTeamIds || []), teamId]
        }
      })

      onSuccess()
    } catch (error) {
      console.error("predefined team add : ", error)
    }
  }

  const inContractSettingsModalOpen = useMemo(
    () =>
      modalState.modalType === "contractSettings" &&
      modalState.attributes.context === "contract",
    [modalState.modalType]
  )

  const contractSettingsProps = useMemo(() => {
    const isContractReporter = contract?.ownerId === contract?.reporterId

    return {
      onContractDeleteClick: handleContractDeleteClick,
      settingsData: {
        showAllMessagesSync: isContractReporter,
        allMessagesSync: !!contract?.allMessagesSync,
        predefinedTeamOptions,
        isSubscribed: !!contract?.isSubscribed
      } as ContractSettingsData,
      onChange: handleContractSettingsChange,
      onBackClick: () =>
        setQueryParams({
          tab: ContractTabs.CONTRACT_DETAILS
        }),
      onAddPredefinedTeamClick: handleAddPredefinedTeamClick
    }
  }, [
    handleContractDeleteClick,
    previousPath,
    JSON.stringify(predefinedTeamOptions),
    contract?.allMessagesSync,
    contract?.ownerId,
    contract?.reporterId
  ])

  const handleModalClose = useCallback(() => {
    if (!isMobile && activeTab === ContractTabs.CONTRACT_SETTINGS) {
      removeQueryParams("tab")
    }
  }, [activeTab, isMobile, removeQueryParams])

  useEffect(() => {
    if (inContractSettingsModalOpen) {
      modalDispatch({
        type: "update",
        props: contractSettingsProps,
        onClose: handleModalClose
      })
    }

    if (
      inContractSettingsModalOpen &&
      (activeTab !== ContractTabs.CONTRACT_SETTINGS || isMobile)
    ) {
      modalDispatch({ type: "close" })
    }
    if (
      activeTab === ContractTabs.CONTRACT_SETTINGS &&
      !isMobile &&
      !inContractSettingsModalOpen
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.ContractSettings,
        props: contractSettingsProps,
        onClose: handleModalClose,
        attributes: { context: "contract" }
      })
    }
  }, [
    JSON.stringify(contractSettingsProps),
    inContractSettingsModalOpen,
    handleModalClose,
    isMobile,
    activeTab
  ])

  return {
    handleContractSettingsClick,
    contractSettingsProps
  }
}

export default useContractSettings

type Props = {
  contract: IContractNormalized | null
  activeTab: ContractActiveTab
  onSuccessDelete?: () => void
}
