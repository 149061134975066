import {
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Button, Checkbox } from "theme/components"

function SourceEditModal({
  sourceWorkspaceLabels,
  project = {},
  onUpdate,
  onDisconnect,
  closeModal,
  isOpen
}) {
  const [selectedLabels, setSelectedLabels] = useState([])
  useEffect(() => {
    setSelectedLabels([...(project.labels || [])])
  }, [JSON.stringify(project.labels)])

  const handleLabelChange = (action, label) => {
    if (action === "add") {
      setSelectedLabels(prevState => [...prevState, label])
    } else if (action === "remove") {
      setSelectedLabels(prevState => prevState.filter(l => l !== label))
    }
  }

  const handleUpdate = () => {
    if (JSON.stringify(selectedLabels) === JSON.stringify(project.labels)) {
      return
    }

    onUpdate && onUpdate(project.id, selectedLabels)
  }

  const handleDisconnect = () => {
    onDisconnect && onDisconnect(project.id)
  }

  return (
    <Layout
      isOpen={isOpen}
      closeModal={closeModal}
      onUpdate={handleUpdate}
      showUpdate={!!onUpdate}
      onDisconnect={handleDisconnect}
    >
      <VStack spacing="2" align="stretch">
        {sourceWorkspaceLabels?.map(label => (
          <LabelOption
            key={label}
            label={label}
            isSelected={selectedLabels.includes(label)}
            onChange={handleLabelChange}
          />
        ))}
      </VStack>
    </Layout>
  )
}

export default SourceEditModal

function Layout({
  isOpen,
  closeModal,
  showUpdate,
  onUpdate,
  onDisconnect,
  children
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      isCentered
      size="md"
      variant="sourceWorkspaceEdit"
    >
      <ModalOverlay />

      <ModalContent>
        <Header />

        <ModalBody>{children}</ModalBody>

        <Footer
          closeModal={closeModal}
          onUpdate={onUpdate}
          onDisconnect={onDisconnect}
          showUpdate={showUpdate}
        />
      </ModalContent>
    </Modal>
  )
}

function Header() {
  return (
    <ModalHeader>
      <Heading as="h3" fontSize="2xl" fontWeight="bold">
        Add Source Tags
      </Heading>

      <Text
        color="secondaryLight"
        fontSize="sm"
        lineHeight="sm"
        fontWeight="medium"
        mt="2"
      >
        Click on Emojis to change the design icon
      </Text>
    </ModalHeader>
  )
}

function Footer({ showUpdate, closeModal, onUpdate, onDisconnect }) {
  return (
    <ModalFooter>
      <Button
        variant="textDark"
        color="#666666"
        width="80px"
        onClick={onDisconnect}
      >
        Disconnect
      </Button>

      <ButtonGroup spacing="3" width="full" justifyContent="end">
        <Button
          variant="textDark"
          color="#666666"
          onClick={closeModal}
          width="80px"
        >
          Cancel
        </Button>

        {showUpdate ? (
          <Button onClick={onUpdate} width="112px">
            Update
          </Button>
        ) : null}
      </ButtonGroup>
    </ModalFooter>
  )
}

function LabelOption({ label, isSelected, onChange }) {
  const handleChange = event => {
    const isSelected = event.target.checked
    onChange(isSelected ? "add" : "remove", label)
  }

  return (
    <Flex align="center">
      <Checkbox isChecked={isSelected} onChange={handleChange} />

      <Flex
        align="center"
        justify="center"
        bgColor="#F3F3F3"
        width="9"
        height="9"
        rounded="full"
        ml="3"
      >
        <Text fontSize="md" lineHeight="md" fontWeight="medium">
          #
        </Text>
      </Flex>

      <Text fontSize="md" lineHeight="md" fontWeight="medium" ml="3">
        {label}
      </Text>
    </Flex>
  )
}
