import MessageAPIService from "../services/MessageAPIService"

class MessageAPIRepository {
  constructor(private readonly messageAPIService: MessageAPIService) {}

  getContractAllCommunityMessages(contractPk: string) {
    return this.messageAPIService.getContractAllCommunityMessages(contractPk)
  }
}

export default MessageAPIRepository
