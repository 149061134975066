import { IUserNormalized } from "modules/User/src//core/types"
import { useMemo } from "react"

const useConnectSlack = (workspaceId: string, user: IUserNormalized | null) => {
  const isSlackConnected = useMemo(() => !!user?.slackId, [user?.slackId])

  const connectWorkspace = () => {
    const params = {
      client_id: process.env.REACT_APP_SLACK_APP_CLIENT_ID as string,
      scope: "profile email openid",
      response_type: "code",
      state: JSON.stringify({ type: "user", workspaceId, userId: user?.id }),
      redirect_uri: process.env.REACT_APP_SLACK_APP_REDIRECT_URI as string
    }
    const url = new URL("https://slack.com/openid/connect/authorize")
    url.search = new URLSearchParams(params).toString()

    window.location.href = url.toString()
  }

  const connectBot = () => {
    const params = {
      client_id: process.env.REACT_APP_SLACK_APP_CLIENT_ID as string,
      scope:
        "channels:manage channels:read chat:write groups:read users:read files:read commands channels:history groups:history mpim:history im:history",
      state: JSON.stringify({ type: "bot", workspaceId, userId: user?.id }),
      redirect_uri: process.env.REACT_APP_SLACK_APP_REDIRECT_URI as string
    }
    const url = new URL("https://slack.com/oauth/v2/authorize")
    url.search = new URLSearchParams(params).toString()

    window.location.href = url.toString()
  }

  return {
    isSlackConnected,
    connectBot,
    connectWorkspace
  }
}

export default useConnectSlack
