import { IContractNormalized } from "modules/Contract/src//core/types"
import { Workspace } from "modules/Workspace/src//core/types"
import SourceAPIRepository from "../repositories/SourceAPIRepository"
import {
  ConnectProjectOptions,
  DisconnectProjectOptions,
  INormalizedSourceWorkspace,
  SendContractToSourceOptions,
  SourceType,
  UpdateProjectOptions,
  getSourceContractsOptionsType,
  getSourceWorkspaceProjectsOptionsType,
  normalizedContractType,
  normalizedProjectType
} from "../types"
import {
  normalizeSourceContracts,
  normalizeSourceProjects,
  normalizeSourceWorkspaces
} from "../utils"

class SourceUseCase {
  constructor(private sourceAPIRepository: SourceAPIRepository) {}

  async getSourceContracts(
    type: SourceType,
    options: getSourceContractsOptionsType
  ): Promise<normalizedContractType[]> {
    try {
      const sourceContracts = await this.sourceAPIRepository.getSourceContracts(
        type,
        options
      )
      return normalizeSourceContracts(type, sourceContracts)
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async sendContractToSource(
    sourceType: SourceType,
    options: SendContractToSourceOptions
  ): Promise<IContractNormalized | null> {
    try {
      return await this.sourceAPIRepository.sendContractToSource(
        sourceType,
        options
      )
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async getSourceWorkspaceProjects(
    type: SourceType,
    options: getSourceWorkspaceProjectsOptionsType
  ): Promise<normalizedProjectType[]> {
    try {
      const sourceProjects =
        await this.sourceAPIRepository.getSourceWorkspaceProjects(type, options)
      return normalizeSourceProjects(type, sourceProjects)
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async connectProject(
    sourceType: SourceType,
    options: ConnectProjectOptions
  ): Promise<Workspace | null> {
    try {
      return await this.sourceAPIRepository.connectProject(sourceType, options)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async updateProject(
    sourceType: SourceType,
    options: UpdateProjectOptions
  ): Promise<Workspace | null> {
    try {
      return await this.sourceAPIRepository.updateProject(sourceType, options)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async disconnectProject(
    sourceType: SourceType,
    options: DisconnectProjectOptions
  ): Promise<Workspace | null> {
    try {
      return await this.sourceAPIRepository.disconnectProject(
        sourceType,
        options
      )
    } catch (error) {
      console.error(error)
      return null
    }
  }

  // label
  async getLabels(
    type: SourceType,
    jiraWorkspaceId: string
  ): Promise<string[]> {
    try {
      return await this.sourceAPIRepository.getLabels(type, jiraWorkspaceId)
    } catch (error) {
      console.error(error)
      return []
    }
  }

  // workspace
  async getWorkspaces(type: SourceType): Promise<INormalizedSourceWorkspace[]> {
    try {
      const workspaces = await this.sourceAPIRepository.getWorkspaces(type)
      return normalizeSourceWorkspaces(type, workspaces)
    } catch (error) {
      console.error(error)
      return []
    }
  }
}

export default SourceUseCase
