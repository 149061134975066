export type GetWorkspacesByOwnerIdInput = {
  ownerId: string
  createdAt?: ModelStringKeyConditionInput
  sortDirection?: ModelSortDirection
  filter?: ModelWorkspaceFilterInput
  limit?: number
  nextToken?: string
}

type ModelWorkspaceFilterInput = {
  and?: ModelWorkspaceFilterInput[]
  contractId?: ModelStringInput
  createdAt?: ModelStringInput
  deletedAt?: ModelStringInput
  discordChannelIds?: ModelStringInput
  id?: ModelIDInput
  jiraProjectIds?: ModelStringInput
  name?: ModelStringInput
  not?: ModelWorkspaceFilterInput
  or?: ModelWorkspaceFilterInput[]
  ownerId?: ModelStringInput
  platformWorkspaceConfigId?: ModelStringInput
  slackChannelIds?: ModelStringInput
  updatedAt?: ModelStringInput
}

type ModelStringKeyConditionInput = {
  beginsWith?: string
  between?: string[]
  eq?: string
  ge?: string
  gt?: string
  le?: string
  lt?: string
}

export enum SearchableWorkspaceSortableFields {
  contractId = "contractId",
  createdAt = "createdAt",
  deletedAt = "deletedAt",
  discordChannelIds = "discordChannelIds",
  id = "id",
  jiraProjectIds = "jiraProjectIds",
  name = "name",
  ownerId = "ownerId",
  platformWorkspaceConfigId = "platformWorkspaceConfigId",
  slackChannelIds = "slackChannelIds",
  updatedAt = "updatedAt"
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc"
}

export type GetWorkspaceTeamsByWorkspaceIdInput = {
  workspaceId?: string
  sortDirection?: ModelSortDirection
  filter?: ModelWorkspaceTeamFilterInput
  limit?: number
  nextToken?: string
}

type ModelWorkspaceTeamFilterInput = {
  and?: ModelWorkspaceTeamFilterInput[]
  communityType?: ModelCommunityTypeInput
  communityWorkspaceId?: ModelStringInput
  createdAt?: ModelStringInput
  emoji?: ModelStringInput
  emojiBgColor?: ModelStringInput
  id?: ModelIDInput
  name?: ModelStringInput
  not?: ModelWorkspaceTeamFilterInput
  or?: ModelWorkspaceTeamFilterInput[]
  ownerId?: ModelStringInput
  surname?: ModelStringInput
  teamId?: ModelStringInput
  type?: ModelWorkspaceTeamTypeInput
  updatedAt?: ModelStringInput
  workspaceId?: ModelStringInput
}

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

type ModelCommunityTypeInput = {
  eq?: CommunityType
  ne?: CommunityType
}


export enum CommunityType {
  DISCORD = "DISCORD",
  EMAIL = "EMAIL",
  SLACK = "SLACK"
}

type ModelStringInput = {
  attributeExists?: boolean
  attributeType?: ModelAttributeTypes
  beginsWith?: string
  between?: [string]
  contains?: string
  eq?: string
  ge?: string
  gt?: string
  le?: string
  lt?: string
  ne?: string
  notContains?: string
  size?: ModelSizeInput
}

enum ModelAttributeTypes {
  _null = "null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}

type ModelSizeInput = {
  between?: [number]
  eq?: number
  ge?: number
  gt?: number
  le?: number
  lt?: number
  ne?: number
}

type ModelIDInput = {
  attributeExists?: boolean
  attributeType?: ModelAttributeTypes
  beginsWith?: ID
  between?: [ID]
  contains?: ID
  eq?: ID
  ge?: ID
  gt?: ID
  le?: ID
  lt?: ID
  ne?: ID
  notContains?: ID
  size?: ModelSizeInput
}

type ID = string

type ModelWorkspaceTeamTypeInput = {
  eq?: WorkspaceTeamType
  ne?: WorkspaceTeamType
}

enum WorkspaceTeamType {
  CHANNEL = "CHANNEL",
  USER = "USER"
}



