import { useCommunitySettings } from "modules/Community/src/core/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { Workspace } from "../../types"
import useSettingsTabRouter from "./useSettingsTabRouter"

const useCommunity = ({ workspace, user }: Props) => {
  const {
    community,
    discord,
    slack,
    email,
    isOpenDrawer,
    onDrawerClose,
    openDrawer,
    closeDrawer
  } = useCommunitySettings({
    workspace: workspace,
    user
  })

  const { handleSettingsClick, handleClose } = useSettingsTabRouter({
    closeDrawer,
    isOpenDrawer,
    onDrawerClose,
    openDrawer,
    settingsTabName: "community"
  })

  return {
    handleCommunitySettingsClick: handleSettingsClick,
    communitySettingsProps: {
      isOpen: isOpenDrawer,
      onClose: handleClose,
      community,
      discord,
      slack,
      email
    }
  }
}

export default useCommunity

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
