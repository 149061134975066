import MessageAPIRepository from "./repositories/MessageAPIRepository"
import MessageCRUDRepository from "./repositories/MessageCRUDRepository"
import MessageAPIService from "./services/MessageAPIService"
import MessageAppSyncService from "./services/MessageAppSyncService"
import MessageUseCase from "./useCases/MessageUseCase"

const messageUseCase = new MessageUseCase(
  new MessageCRUDRepository(new MessageAppSyncService()),
  new MessageAPIRepository(new MessageAPIService())
)

export { messageUseCase }
