export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      contractPk
      contractId
      workspaceTeamId
      message
      communityType
      communityMessageId
      actionType
      type
      ownerId
      username
      teamName
      teamEmoji
      attachmentIds
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
`
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contractPk
        contractId
        workspaceTeamId
        message
        communityType
        communityMessageId
        actionType
        type
        ownerId
        username
        teamName
        teamEmoji
        attachmentIds
        oldValue
        newValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessageFilterInput
    $sort: [SearchableMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMessageAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        contractPk
        contractId
        workspaceTeamId
        message
        communityType
        communityMessageId
        actionType
        type
        ownerId
        username
        teamName
        teamEmoji
        attachmentIds
        oldValue
        newValue
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getMessagesByContractPk = /* GraphQL */ `
  query GetMessagesByContractPk(
    $contractPk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesByContractPk(
      contractPk: $contractPk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractPk
        contractId
        workspaceTeamId
        message
        communityType
        communityMessageId
        actionType
        type
        ownerId
        username
        teamName
        teamEmoji
        attachmentIds
        oldValue
        newValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
