import React from "react"
import MainLayout from "./MainLayout"
import PlatformAdminLayout from "./PlatformAdminLayout"

const LayoutVariant = ({ variant = "main", ...props }) =>
  React.createElement(layoutVariants[variant], props)

export default LayoutVariant

const layoutVariants = {
  main: MainLayout,
  platformAdmin: PlatformAdminLayout
}
