import { ButtonGroup, Flex, Heading } from "@chakra-ui/react"
import WorkspaceSettingsMenu
  from "modules/Workspace/src/components/WorkspaceSettingsMenu"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button } from "theme/components"

const Header = ({
  workspace,
  onCDESettingsClick,
  onCommunitySettingsClick,
  onSourceSettingsClick,
  onDeleteWorkspaceClick,
  onArchivedContractsClick
}) => (
  <Layout>
    <Flex>
      <WorkspaceName name={workspace.name} />

      {workspace.contractId ? (
        <WorkspaceContractDetailsButton
          workspaceContractId={workspace.contractId}
        />
      ) : null}
    </Flex>

    <SettingsButtons
      onCDESettingsClick={onCDESettingsClick}
      onCommunitySettingsClick={onCommunitySettingsClick}
      onSourceSettingsClick={onSourceSettingsClick}
      onDeleteWorkspaceClick={onDeleteWorkspaceClick}
      onArchivedContractsClicks={onArchivedContractsClick}
    />
  </Layout>
)

export default Header

const Layout = ({ children }) => (
  <Flex align="center" justifyContent="space-between" height="80px" px="30px">
    {children}
  </Flex>
)

const WorkspaceName = ({ name }) => (
  <Heading
    as="h3"
    fontSize="3xl"
    lineheight="3xl"
    noOfLines={1}
    fontWeight="bold"
  >
    {name}
  </Heading>
)

const SettingsButtons = ({
  onCDESettingsClick,
  onCommunitySettingsClick,
  onSourceSettingsClick,
  onDeleteWorkspaceClick,
  onArchivedContractsClicks
}) => (
  <ButtonGroup ml={8} spacing={4}>
    <Button
      onClick={onSourceSettingsClick}
      variant="secondary"
      iconName="source"
      width="110px"
      bgColor="bgGray"
    >
      Source
    </Button>

    <Button
      onClick={onCommunitySettingsClick}
      variant="primaryPurple"
      iconName="community"
      width="130px"
    >
      Community
    </Button>

    {/*<Button onClick={onCDESettingsClick}>CDE</Button>*/}

    <WorkspaceSettingsMenu
      onDeleteWorkspaceClick={onDeleteWorkspaceClick}
      onArchivedContractsClick={onArchivedContractsClicks}
    />
  </ButtonGroup>
)

const WorkspaceContractDetailsButton = ({ workspaceContractId }) => (
  <Button
    as={RouterLink}
    to={`/contract/${workspaceContractId}`}
    size="md"
    ml="4"
    flexShrink="0"
  >
    Details
  </Button>
)
