import PlatformWorkspaceConfigAppSyncService from "../services/PlatformWorkspaceConfigAppSyncService"

class PlatformWorkspaceConfigCRUDRepository {
  constructor(
    private readonly platformWorkspaceConfigService: PlatformWorkspaceConfigAppSyncService
  ) {
  }

  async getById(id: string) {
    return this.platformWorkspaceConfigService.getPlatformWorkspaceConfig(id)
  }
}

export default PlatformWorkspaceConfigCRUDRepository
