export const getContractResource = /* GraphQL */ `
  query GetContractResource($id: ID!) {
    getContractResource(id: $id) {
      id
      contractPk
      type
      size
      name
      url
      createdAt
      updatedAt
    }
  }
`
export const listContractResources = /* GraphQL */ `
  query ListContractResources(
    $filter: ModelContractResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractPk
        type
        size
        name
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getContractResourcesByContractPk = /* GraphQL */ `
  query GetContractResourcesByContractPk(
    $contractPk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContractResourcesByContractPk(
      contractPk: $contractPk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractPk
        type
        size
        name
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
