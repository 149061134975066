import { Flex, Grid, Image, Link } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { IconButton } from "theme/components"

const Layout = ({ children, onContractCreateClick, platformConfig = {}, isWithNavbar }) => {
  const showNavbar = useMemo(
    () =>
      platformConfig.showSidebar && isWithNavbar
        ? onContractCreateClick || platformConfig.showLogo
        : false,
    [onContractCreateClick, platformConfig.showLogo, platformConfig.showSidebar]
  )

  return (
    <Grid
      className="h-screen"
      templateColumns="1fr"
      templateRows={showNavbar ? "56px 1fr" : "1fr"}
      bgColor="white"
      pt={{
        base: 3,
        md: 0,
      }}
    >
      {showNavbar ? (
        <MobileNavbar
          onContractCreateClick={onContractCreateClick}
          platformConfig={platformConfig}
        />
      ) : null}

      <Grid
        height="full"
        templateRows="repeat(1, minmax(100px, 1fr))"
        rowGap="3"
        px={4}
        pb={4}
      >
        <Grid overflowY="scroll">{children}</Grid>
      </Grid>
    </Grid>
  )
}

export default Layout

const MobileNavbar = ({ onContractCreateClick, platformConfig }) => (
  <Flex
    px="4"
    height="56px"
    width="full"
    align="center"
    justify="space-between"
    display={{ base: "flex", md: "none" }}
    pb="2"
  >
    {platformConfig.showLogo ? <CosmoLogo /> : null}

    <Flex>
      {onContractCreateClick ? (
        <IconButton
          iconName="plus"
          bgColor="primaryPurple"
          rounded="full"
          size="md"
          _hover={{ bgColor: "primaryPurple" }}
          _active={{ bgColor: "primaryPurple" }}
          onClick={onContractCreateClick}
          shadow="0px 4px 14px rgba(100, 87, 249, 0.5)"
        />
      ) : null}
    </Flex>
  </Flex>
)

const CosmoLogo = () => (
  <Link
    target="_blank"
    href="https://cosmoplatform.com/"
    rel="noopener noreferrer"
  >
    <Image src="/assets/images/logo-symbol.png" width="32px" height="32px" />
  </Link>
)
