import { Box, FormControl, FormErrorMessage, VStack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import { Button, Input } from "theme/components"

export function SignInForm({ onSignIn, isLoadingSignIn }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      email: "",
      password: ""
    }
  })

  const handleFormSubmit = values => {
    const emailNormalized = values.email.trim().toLowerCase()

    const onError = message => {
      setError("password", {
        type: "custom",
        message: message
      })
    }

    onSignIn(
      {
        email: emailNormalized,
        password: values.password
      },
      { onError }
    )
  }

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)} w="full" mt="6">
      <VStack spacing="5">
        <FormControl isInvalid={errors.email}>
          <Input
            type="email"
            placeholder="Email"
            {...register("email", { required: "Enter an email address" })}
          />

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.password}>
          <Input
            type="password"
            placeholder="Password"
            {...register("password", { required: "Enter your password" })}
          />

          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          w="full"
          variant="primaryDark"
          isLoading={isLoadingSignIn}
        >
          Log in
        </Button>
      </VStack>
    </Box>
  )
}

export default SignInForm
