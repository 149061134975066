export interface ContractResource {
  id: string
  contractPk: string | null

  type: ContractResourceType
  size: number
  name: string
  url: string

  createdAt: string
  updatedAt: string
}

export enum ContractResourceType {
  LINK = "LINK",
  COSMO_LINK = "COSMO_LINK",
  SOURCE_LINK = "SOURCE_LINK",
  COMMUNITY_LINK = "COMMUNITY_LINK",
  CDE_LINK = "CDE_LINK",
  MESSAGE_IMAGE = "MESSAGE_IMAGE",
  CONTRACT_IMAGE = "CONTRACT_IMAGE",
  CONTRACT_FILE = "CONTRACT_FILE",
}

export interface ContractResourceWithLoading extends ContractResource {
  isLoading?: boolean
}

export type ContractResourceCreateInput = Pick<
  ContractResource,
  "type" | "size" | "name" | "url"
> &
  Partial<Pick<ContractResource, "contractPk">>
