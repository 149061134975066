import contractResourceUseCase from "modules/ContractResource/src/core"
import { useCallback, useEffect } from "react"
import { useQueryClient } from "react-query"
import { messageUseCase } from "../index"
import { Message, MessageWithAttachments } from "../types"
import { normalizeMessage } from "../utils"

export const useMessagesSubscriptions = (
  contractPk: string,
  userId: string
) => {
  const queryClient = useQueryClient()

  const handleCreateMessageSubscription = useCallback(
    async (message: Message) => {
      const normalizedMessage = normalizeMessage(message)
      const messageWithAttachments = {
        ...normalizedMessage,
        attachments: []
      } as MessageWithAttachments

      if (
        message.type === "PIN" ||
        message.type === "COMMUNITY_MESSAGE"
      ) {
        if (message.attachmentIds?.length) {
          const messageAttachments = await contractResourceUseCase.findByIds(
            message.attachmentIds
          )
          messageWithAttachments.attachments = messageAttachments
        }

        queryClient.setQueryData(
          ["contractCommunityMessages", contractPk],
          (oldData: any) => [messageWithAttachments, ...(oldData || [])]
        )
      } else if (
        message.type === "COMMENT" && message.ownerId !== userId
      ) {
        queryClient.setQueryData(
          ["contractComments", contractPk],
          (oldData: any) =>   ({
            items: [messageWithAttachments, ...(oldData.items || [])],
            nextToken: oldData.nextToken
          })
        )
      }
    },
    [contractPk]
  )

  useEffect(() => {
    const createMessageSubscription = messageUseCase.createMessageSubscription(
      handleCreateMessageSubscription
    )

    return () => {
      createMessageSubscription()
    }
  }, [handleCreateMessageSubscription])
}
