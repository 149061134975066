import { Button } from "@chakra-ui/react"
import React from "react"
import { Icon } from "theme/components"

function SlackButton({ children, ...props }) {
  return (
    <Button
      variant="unstyled"
      alignItems="center"
      color="#000"
      backgroundColor="#fff"
      border="1px solid #ddd"
      rounded="4px"
      display="inline-flex"
      fontFamily="Lato, sans-serif"
      fontSize="14px"
      fontWeight="bold"
      height="44px"
      justifyContent="center"
      textDecoration="none"
      pl="4"
      pr="4"
      {...props}
    >
      <Icon name="slack" size="4" mr="3" />

      {children}
    </Button>
  )
}
export default SlackButton
