import { Box, Flex, Heading, VStack } from "@chakra-ui/react"
import React from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import { Link as RouterLink } from "react-router-dom"
import { IconButton } from "theme/components"
import LayoutVariant from "./layouts/LayoutVariant"
import { TeamGroup } from "./ui"

const WorkspaceTeamsPage = ({ teamsGroups, onTeamEdit, layoutVariant }) => (
  <Layout layoutVariant={layoutVariant}>
    <VStack spacing="6" align="stretch" overflowY="scroll" pb="8">
      {teamsGroups.map(teamGroup => (
        <TeamGroup
          key={teamGroup.type}
          teamGroup={teamGroup}
          onTeamEdit={onTeamEdit}
        />
      ))}
    </VStack>
  </Layout>
)

export default WorkspaceTeamsPage

const Layout = ({ layoutVariant = "main", children }) => (
  <LayoutVariant isBgImage variant={layoutVariant}>
    <Header />

    <Box pt="3" px="4" pb="8" overflowY="scroll">
      {children}
    </Box>
  </LayoutVariant>
)

const Header = () => (
  <Flex
    width="full" align="center" height="40px" px="4" pb="2"
  >
    <IconButton
      icon={<IoArrowBackOutline />}
      aria-label="arrow-back"
      as={RouterLink}
      to="./"
      relative="path"
    />

    <Heading
      as="h3"
      fontSize="xl"
      lineheight="xl"
      fontWeight="semibold"
      display="flex"
      align="center"
      ml="3"
      mb="-1"
    >
      Teams
    </Heading>
  </Flex>
)
