const typographyTheme = {
  fonts: {
    body: "Gilory, sans-serif",
    heading: "Gilory, sans-serif"
  },
  fontSizes: {
    xxs: "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "32px"
  },
  lineHeights: {
    xxs: "12px",
    xs: "14px",
    sm: "17px",
    md: "19px",
    lg: "22px",
    xl: "24px",
    "2xl": "29px",
    "3xl": "34px",
    "4xl": "40px"
  }
}

export default typographyTheme
