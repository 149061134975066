// example
// truncateMiddle('HHpBabpa3pxnSuTtspROD2A5', 10)
// returns: HHpBa.....OD2A5
export const truncateMiddle = (fullStr, strLen, separator = ".....") => {
  const realStrLength = strLen + separator.length

  if (fullStr.length <= realStrLength) {
    return fullStr
  }

  const sepLen = separator.length
  const charsToShow = realStrLength - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)
  const result =
    fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)

  return result
}

export const truncateEnd = (fullStr, strLen, separator = ".....") => {
  const realStrLength = strLen + separator.length

  if (fullStr.length <= realStrLength) {
    return fullStr
  }

  const sepLen = separator.length
  const charsToShow = realStrLength - sepLen
  const result = fullStr.substring(0, charsToShow) + separator

  return result
}

export const checkIsEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
