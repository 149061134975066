import { useQuery } from "react-query"
import { communityUseCase } from "../index"
import { CommunityWorkspaceNormalized } from "../types"
import {
  useGetUserCommunityWorkspacesWithBotInputType,
  useGetUserCommunityWorkspacesWithBotOptionsType
} from "../types/hooks"

export const useGetUserCommunityWorkspacesWithBot = (
  input: useGetUserCommunityWorkspacesWithBotInputType,
  options: useGetUserCommunityWorkspacesWithBotOptionsType = {}
) =>
  useQuery<CommunityWorkspaceNormalized[], Error>(
    ["getUserCommunityWorkspacesWithBot", input.communityType],
    () =>
      communityUseCase.getUserCommunityWorkspacesWithBot(input.communityType),
    options
  )
