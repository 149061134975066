export const listPlatformConfigs = /* GraphQL */ `
  query ListPlatformConfigs(
    $filter: ModelPlatformConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlatformConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const listAdminPlatformConfigs = /* GraphQL */ `
  query ListPlatformConfigs(
    $filter: ModelPlatformConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlatformConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminEmail
        adminWorkspaceId
        adminPlatformWorkspaceConfigId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getPlatformWorkspaceConfig = /* GraphQL */ `
  query GetPlatformWorkspaceConfig($id: ID!) {
    getPlatformWorkspaceConfig(id: $id) {
      id
      platformConfigId
      workspaceName
      delegateButton {
        isPrimaryView
        show
      }
      contractDeleteButton {
        isPrimaryView
        show
      }
      showLogo
      showSidebar
      delegatePlatformWorkspaceConfigId
      contentEn {
        emptyState
        contractDetails {
          done
          markAsDone
          delegateButtonLabel
          deleteButtonLabel
          descriptionSectionHeading
          descriptionPlaceholder
          descriptionSaveButtonLabel
          descriptionCancelButtonLabel
          attachmentsSectionHeading
          attachmentsAddButtonLabel
          commentsSectionHeading
          commentsInputPlaceholder
        }
        delegateModal {
          heading
          searchInputPlaceholder
          usersGroupLabel
          channelsGroupLabel
          predefinedGroupLabel
          addButtonLabel
          cancelButtonLabel
        }
        contractSettingsModal {
          heading
          emailNotificationsLabel
          deleteContractButtonLabel
        }
      }
      contentRu {
        emptyState
        contractDetails {
          done
          markAsDone
          delegateButtonLabel
          deleteButtonLabel
          descriptionSectionHeading
          descriptionPlaceholder
          descriptionSaveButtonLabel
          descriptionCancelButtonLabel
          attachmentsSectionHeading
          attachmentsAddButtonLabel
          commentsSectionHeading
          commentsInputPlaceholder
        }
        delegateModal {
          heading
          searchInputPlaceholder
          usersGroupLabel
          channelsGroupLabel
          predefinedGroupLabel
          addButtonLabel
          cancelButtonLabel
        }
        contractSettingsModal {
          heading
          emailNotificationsLabel
          deleteContractButtonLabel
        }
      }
      contentAm {
        emptyState
        contractDetails {
          done
          markAsDone
          delegateButtonLabel
          deleteButtonLabel
          descriptionSectionHeading
          descriptionPlaceholder
          descriptionSaveButtonLabel
          descriptionCancelButtonLabel
          attachmentsSectionHeading
          attachmentsAddButtonLabel
          commentsSectionHeading
          commentsInputPlaceholder
        }
        delegateModal {
          heading
          searchInputPlaceholder
          usersGroupLabel
          channelsGroupLabel
          predefinedGroupLabel
          addButtonLabel
          cancelButtonLabel
        }
        contractSettingsModal {
          heading
          emailNotificationsLabel
          deleteContractButtonLabel
        }
      }
      createdAt
      updatedAt
    }
  }
`

