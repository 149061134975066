import { Box, Grid, Image, Text, VStack } from "@chakra-ui/react"
import React from "react"
import {
  usePlatformWorkspaceContent
} from "../../providers/PlatformWorkspaceProvider"

// import { Button } from "theme/components"

const EmptyWorkspace = () => {
  const {emptyState} = usePlatformWorkspaceContent()
  return (
    <Box className="h-screen" p={{ base: 4, md: 6 }}>
      <Grid height="full">
        <Grid
          justifyContent="center"
          alignItems="center"
          bgColor="rgba(255, 255, 255, 0.4)"
          rounded="12px"
          border="1px solid"
          borderColor="bgSmall"
          p="4"
        >
          <Grid rowGap="4">
            <Image
              src="/assets/images/empty-workspace.png"
              alt="Empty workspace"
            />

            <VStack spacing="4" ml="10%">
              <Text fontSize="md" lineHeight="md">
                {emptyState || "You don’t have any contracts yet"}
              </Text>

              {/*<Button*/}
              {/*  variant="primaryPurple"*/}
              {/*  px="6"*/}
              {/*  onClick={onContractCreateClick}*/}
              {/*  width={{ base: "fit-content", md: "272px" }}*/}
              {/*>*/}
              {/*  Create First Contract*/}
              {/*</Button>*/}
            </VStack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmptyWorkspace
