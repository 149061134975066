import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text
} from "@chakra-ui/react"
import React from "react"
import { IconButton } from "theme/components"

export function SettingsDrawer({
  headerText,
  children,
  isOpen,
  onClose,
  ...props
}) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg" {...props}>
      <DrawerOverlay bgColor="rgba(48, 48, 48, .1)" />

      <DrawerContent maxWidth="600px" shadow="dialog" roundedLeft="16px">
        <DrawerCloseButton
          as={IconButton}
          iconName="close"
          top="6"
          right="30px"
          _hover={{
            bg: "transparent"
          }}
        />

        <DrawerHeader
          px="30px"
          py="0"
          minH="80px"
          height="80px"
          width="full"
          display="flex"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="line"
        >
          <Text fontSize="3xl" lineHeight="3xl" fontWeight="semibold">
            {headerText}{" "}
            <Text as="span" fontWeight="light">
              settings
            </Text>
          </Text>
        </DrawerHeader>

        <DrawerBody py="6" px="30px">
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
