import { Workspace } from "modules/Workspace/src//core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { sourceUseCase } from "../index"
import { ConnectProjectOptions, SourceType } from "../types"

const useConnectSourceProject = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "connectSourceProject",
    input => sourceUseCase.connectProject(input.sourceType, input.options),
    {
      onMutate: async ({ options }) => {
        const workspaceKey = ["workspace", options.workspaceId]
        await queryClient.cancelQueries(workspaceKey)
        const previousWorkspace =
          queryClient.getQueryData<Workspace>(workspaceKey)

        return { previousWorkspace }
      },
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }
        const workspaceSkSource = data as Workspace

        queryClient.setQueryData<Workspace>(
          ["workspace", variables.options.workspaceId],
          workspace => ({ ...workspace, ...workspaceSkSource })
        )
      },
      ...options
    }
  )
}

export { useConnectSourceProject }

export interface IFnInput {
  sourceType: SourceType
  options: ConnectProjectOptions
}

export type Options = Omit<
  UseMutationOptions<Workspace | null, Error, IFnInput, unknown>,
  "mutationFn" | "mutationKey"
>
