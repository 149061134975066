import { API } from "aws-amplify"
import {
  ConnectProjectOptions,
  DisconnectProjectOptions,
  SendContractToSourceOptions,
  SourceType,
  UpdateProjectOptions,
  getSourceContractsOptionsType,
  getSourceWorkspaceProjectsOptionsType
} from "../types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"

class SourceAPIService {
  async getSourceContracts(type: SourceType, options: getSourceContractsOptionsType) {
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        options: JSON.stringify(options)
      }
    })
    return API.get("sourceRestApi", `/${type}/contracts`, init)
  }

  async getSourceWorkspaceProjects(
    type: SourceType,
    options: getSourceWorkspaceProjectsOptionsType
  ) {
    const normalizedOptions =
      type === "jira" ? { workspaceId: "#" + options.id } : options
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        ...normalizedOptions
      }
    })

    return API.get("sourceRestApi", `/${type}/projects`, init)
  }

  async sendContractToSource(
    sourceType: SourceType,
    options: SendContractToSourceOptions
  ) {
    const init = await withCognitoAccessToken({
      body: options
    })

    return API.post("sourceRestApi", `/${sourceType}/send-contract`, init)
  }

  async connectProject(sourceType: SourceType, body: ConnectProjectOptions) {
    const init = await withCognitoAccessToken({
      body
    })
    return API.post("sourceRestApi", `/${sourceType}/project/connect`, init)
  }

  async updateProject(sourceType: SourceType, options: UpdateProjectOptions) {
    const init = await withCognitoAccessToken({
      body: options
    })
    return API.post("sourceRestApi", `/${sourceType}/project/update`, init)
  }

  async disconnectProject(sourceType: SourceType, options: DisconnectProjectOptions) {
    const init = await withCognitoAccessToken({
      body: options
    })
    return API.post("sourceRestApi", `/${sourceType}/project/disconnect`, init)
  }

  // label
  async getLabels(type: SourceType, workspaceId: string) {
    const normalizedWorkspaceId =
      type === "jira" ? "#" + workspaceId : workspaceId
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        workspaceId: normalizedWorkspaceId
      }
    })
    return API.get("sourceRestApi", `/${type}/labels`, init)
  }

  // label
  async getWorkspaces(type: SourceType) {
    const init = await withCognitoAccessToken({})
    return API.get("sourceRestApi", `/${type}/workspaces`, init)
  }
}

export default SourceAPIService
