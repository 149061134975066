export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      pointEstimation
      sourceContractPk
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
      }
      predefinedTeamIds
      workspaceId
      completed
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
      }
      githubCodespaces {
        id
        name
        link
      }
      createdAt
      updatedAt
    }
  }
`

export const getContractsByPk = /* GraphQL */ `
  query GetContractsByPk(
    $pk: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContractsByPk(
      pk: $pk
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        skIds
        ownerId
        sourceContractPk
        reporterId
        name
        description
        sourceType
        pointEstimation
        teams {
          id
          workspaceId
          ownerId
          communityWorkspaceId
          communityType
          teamId
          name
          surname
          type
          emoji
          emojiBgColor
          isPrivate
          createdAt
          updatedAt
        }
        predefinedTeamIds
        workspaceId
        completed
        deletedAt
        allMessagesSync
        isSubscribed
        jiraIssueId
        jiraProjects {
          id
          name
          link
        }
        githubCodespaces {
          id
          name
          link
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getContractsBySourceContractPk = /* GraphQL */ `
  query GetContractsBySourceContractPk(
    $sourceContractPk: String!
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContractsBySourceContractPk(
      sourceContractPk: $sourceContractPk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        skIds
        ownerId
        reporterId
        name
        description
        sourceType
        sourceContractPk
        pointEstimation
        predefinedTeamIds
        workspaceId
        completed
        variables
        deletedAt
        allMessagesSync
        isSubscribed
        jiraIssueId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

