import { FormControl, FormLabel } from "@chakra-ui/react"
import React from "react"
import { Switch } from "../../../../../theme/components/ui"

const SwitchFormControl = ({ label, isChecked, onChange, ...props }) => (
  <FormControl
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    {...props}
  >
    <FormLabel mb="0" fontSize="sm" lineHeight="sm">
      {label}
    </FormLabel>

    <Switch isChecked={isChecked} onChange={onChange} />
  </FormControl>
)

export default SwitchFormControl
