import { API, graphqlOperation } from "aws-amplify"
import {
  getWorkspaceTeam as getWorkspaceTeamQuery,
  getWorkspaceTeamsByWorkspaceId as getWorkspaceTeamsByWorkspaceIdQuery
} from "../graphql/queries"
import { WorkspaceTeam } from "../types/workspace-team"
import { GetWorkspaceTeamsByWorkspaceIdInput } from "../types/graphql"
import { GetWorkspaceTeamsByWorkspaceIdResponse } from "../types"

class WorkspaceTeamAppSyncService {
  async getById(id: string): Promise<WorkspaceTeam> {
    const res = await (API.graphql(
      graphqlOperation(getWorkspaceTeamQuery, { id })
    ) as Promise<any>)

    return res.data.getWorkspaceTeam
  }

  async getAllByWorkspaceId(input: GetWorkspaceTeamsByWorkspaceIdInput): Promise<GetWorkspaceTeamsByWorkspaceIdResponse> {
    const res = await (API.graphql(
      graphqlOperation(getWorkspaceTeamsByWorkspaceIdQuery, input)
    ) as Promise<any>)

    return {
      items: res.data.getWorkspaceTeamsByWorkspaceId.items,
      nextToken: res.data.getWorkspaceTeamsByWorkspaceId.nextToken
    }
  }
}

export default WorkspaceTeamAppSyncService
