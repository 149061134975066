const baseStyle = {
  minWidth: "80px",
  maxWidth: "320px",
  fontSize: "sm",
  lineHeight: "sm"
}

const variantTextStyles = {
  p: "0",
  fontSize: "sm",
  lineHeight: "sm",
  height: "auto",
  minH: "auto",
  bg: "transparent",
  shadow: "none",
  rounded: "none",
  overflow: "visible"
}

const variantLinkStyles = {
  p: "0",
  fontSize: "sm",
  lineHeight: "sm",
  height: "auto",
  minH: "auto",
  bg: "transparent",
  shadow: "none",
  rounded: "none",
  _hover: { textDecoration: "underline" }
}

const buttonTheme = {
  Button: {
    baseStyle: {
      ...baseStyle,
      fontWeight: "bold",
      position: "relative",
      zIndex: "1",
      borderRadius: "12px",
      width: "fit-content",
      ">.chakra-button__icon": {
        mr: 3
      },
      _focusVisible: {
        shadow: "none"
      }
    },
    variants: {
      primaryDark: {
        ...baseStyle,
        bgColor: "primaryDark",
        color: "#ffffff",
        px: "4",
        _disabled: {
          bgColor: "rgb(43,43,43, 0.4)"
        },
        _hover: {
          bgColor: "rgb(43,43,43, 0.9)",

          _disabled: {
            bgColor: "rgb(43,43,43, 0.4)"
          }
        }
      },
      primaryPurple: {
        ...baseStyle,
        color: "#ffffff",
        bgColor: "primaryPurple",
        _hover: {
          _disabled: {
            bgColor: "primaryPurple"
          }
        }
      },
      secondary: {
        ...baseStyle,
        backgroundColor: "bgGray",
        color: "primaryDark",
        shadow: "none",
        _hover: {
          _disabled: {
            bgColor: "bgGray"
          }
        }
      },
      green: {
        ...baseStyle,
        backgroundColor: "success",
        color: "white",
        border: "1px solid",
        borderColor: "line",
        rounded: "10px",
        fontWeight: "medium"
      },
      blue: {
        ...baseStyle,
        backgroundColor: "blue.500",
        color: "white",
        border: "1px solid",
        borderColor: "line",
        rounded: "10px",
        fontWeight: "medium"
      },

      outlineDark: {
        ...baseStyle,
        backgroundColor: "white",
        color: "primaryDark",
        border: "1px solid",
        borderColor: "line",
        rounded: "10px",
        fontWeight: "medium"
      },

      textPurple: {
        ...variantTextStyles,
        color: "primaryPurple"
      },
      textDark: {
        ...variantTextStyles,
        color: "primaryDark"
      },
      textWhite: {
        ...variantTextStyles,
        color: "white"
      },
      linkDark: {
        ...variantLinkStyles,
        color: "primaryDark"
      },
      danger: {
        ...baseStyle,
        color: "white",
        backgroundColor: "error"
      },
      icon: {
        ...baseStyle,
        p: "0"
      }
    },
    sizes: {
      lg: {
        height: "40px",
        minH: "40px",
        px: "4",
        fontSize: "sm",
        lineHeight: "sm"
      },
      md: {
        height: "32px",
        minH: "32px",
        px: "3",
        fontSize: "sm",
        lineHeight: "sm"
      },
      "icon-sm": {
        height: "24px",
        minH: "24px",
        width: "24px",
        minW: "24px",
        svg: {
          width: "16px",
          height: "16px"
        }
      },
      "icon-md": {
        height: "32px",
        minH: "32px",
        width: "32px",
        minW: "32px",
        svg: {
          width: "20px",
          height: "20px"
        }
      },
      "icon-lg": {
        height: "40px",
        minH: "40px",
        width: "40px",
        minW: "40px",
        svg: {
          width: "24px",
          height: "24px"
        }
      }
    },
    defaultProps: {
      size: "lg"
    }
  }
}

export default buttonTheme
