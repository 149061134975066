import { Box, Flex, Grid, Image } from "@chakra-ui/react"
import { withSignOut } from "modules/User/src/hocs/withSignOut"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button } from "theme/components"

const HomepageLayout = withSignOut(Layout)

export default HomepageLayout

function Layout({
  onSignOut,
  children,
  isMainPage,
  isLoadingSignOut,
  ...props
}) {
  return (
    <Grid
      className="h-screen"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPosition="center"
      templateRows="auto repeat(1, minmax(200px, 100%))"
      bgImage={
        isMainPage
          ? "/assets/images/homepage-bg.jpg"
          : "/assets/images/background.jpg"
      }
      {...props}
    >
      <Header onSignOut={onSignOut} isLoadingSignOut={isLoadingSignOut}
        isMainPage={isMainPage} />

      {children}
    </Grid>
  )
}

function Header({ onSignOut, isLoadingSignOut, isMainPage }) {
  return (
    <Box height={{ base: "60px", md: "80px" }}>
      <Box px={{ base: "4", md: "50px" }} height="full">
        <Flex align="center" justify="space-between" height="full">
          <RouterLink to="/">
            {isMainPage ? (
              <Image src="/assets/images/logo.svg" width="100px" />
            ) : (
              <Image
                src="/assets/images/logo-symbol.png"
                width="32px"
                height="32px"
              />
            )}
          </RouterLink>

          <Button
            variant="secondary"
            onClick={onSignOut}
            isLoading={isLoadingSignOut}
          >
            Sign out
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}
