import { Box, FormControl, FormErrorMessage, VStack } from "@chakra-ui/react"
import {
  authErrorMessages
} from "modules/User/src/core/utils/normalizeAuthError"
import React from "react"
import { useForm } from "react-hook-form"
import { Button, Input } from "theme/components"

function ResetPasswordForm({ onResetPassword, isLoadingResetPassword }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      code: "",
      password: ""
    }
  })

  const handleFormSubmit = values => {
    const codeNormalized = values.code.trim().toLowerCase()

    const onError = ({ field, message }) => {
      setError(field, {
        type: "custom",
        message
      })
    }

    if (values.password.length < 8) {
      onError({
        field: "password",
        message: authErrorMessages.INVALID_PASSWORD
      })
      return
    }

    onResetPassword(
      {
        code: codeNormalized,
        password: values.password
      },
      { onError }
    )
  }

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)} w="full" mt="6">
      <VStack spacing="5">
        <FormControl isInvalid={errors.code}>
          <Input
            type="number"
            placeholder="Confirmation code"
            {...register("code", { required: "Enter your confirmation code" })}
          />

          <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.password}>
          <Input
            type="password"
            placeholder="Password"
            {...register("password", { required: "Enter your password" })}
          />

          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" w="full" colorScheme="blue"
          isLoading={isLoadingResetPassword}>
          Reset password
        </Button>
      </VStack>
    </Box>
  )
}

export default ResetPasswordForm
