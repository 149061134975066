import WorkspaceAPIRepository from "./repositories/WorkspaceAPIRepository"
import WorkspaceCRUDRepository from "./repositories/WorkspaceCRUDRepository"
import WorkspaceTeamApiRepository from "./repositories/WorkspaceTeamApiRepository"
import WorkspaceTeamCRUDRepository from "./repositories/WorkspaceTeamCRUDRepository"
import WorkspaceAPIService from "./services/WorkspaceAPIService"
import WorkspaceAppSyncService from "./services/WorkspaceAppSyncService"
import WorkspaceTeamAPIService from "./services/WorkspaceTeamAPIService"
import WorkspaceTeamAppSyncService from "./services/WorkspaceTeamAppSyncService"
import WorkspaceTeam from "./useCases/WorkspaceTeamUseCase"
import WorkspaceUseCase from "./useCases/WorkspaceUseCase"

export const workspaceUseCase = new WorkspaceUseCase(
  new WorkspaceCRUDRepository(new WorkspaceAppSyncService()),
  new WorkspaceAPIRepository(new WorkspaceAPIService())
)

export const workspaceTeamUseCase = new WorkspaceTeam(
  new WorkspaceTeamCRUDRepository(new WorkspaceTeamAppSyncService()),
  new WorkspaceTeamApiRepository(new WorkspaceTeamAPIService())
)
