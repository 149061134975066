import { Flex, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
  Button,
  FormControl,
  Select,
  SettingsAccordion
} from "theme/components"

function Jira({
  jiraWorkspaceOptions,
  jiraWorkspaceProjectOptions,
  isLoadingJiraProjectConnect,
  onJiraConnect,
  onJiraProjectConnect,
  onWorkspaceChange,
  onAccordionChange
}) {
  const handleJiraProjectConnect = jiraProject => {
    onJiraProjectConnect(jiraProject)
  }

  const handleAccordionChange = index => {
    const isOpen = index === 0
    onAccordionChange && onAccordionChange(isOpen)
  }

  return (
    <SettingsAccordion
      name="Jira Workspace"
      iconName="jira"
      onChange={handleAccordionChange}
    >
      <ConnectJiraProjectForm
        jiraWorkspaceOptions={jiraWorkspaceOptions}
        jiraWorkspaceProjectOptions={jiraWorkspaceProjectOptions}
        onJiraProjectConnect={handleJiraProjectConnect}
        onWorkspaceChange={onWorkspaceChange}
        onJiraConnect={onJiraConnect}
        isLoadingJiraProjectConnect={isLoadingJiraProjectConnect}
      />
    </SettingsAccordion>
  )
}

export default Jira

function ConnectJiraProjectForm({
  jiraWorkspaceOptions = [],
  jiraWorkspaceProjectOptions = [],
  onJiraProjectConnect,
  onJiraConnect,
  isLoadingJiraProjectConnect,
  onWorkspaceChange
}) {
  const [jiraWorkspaceId, setJiraWorkspaceId] = useState("")
  const [jiraProjectId, setJiraProjectId] = useState("")

  const handleJiraProjectConnect = () => {
    onJiraProjectConnect({
      name: jiraWorkspaceProjectOptions.find(
        project => project.value === jiraProjectId
      ).label,
      id: jiraProjectId,
      workspaceId: jiraWorkspaceId,
      workspaceName: jiraWorkspaceOptions.find(
        workspace => workspace.value === jiraWorkspaceId
      ).label
    })
  }
  const handleWorkspaceChange = value => {
    onWorkspaceChange(value)
    setJiraWorkspaceId(value)
  }

  useEffect(() => {
    if (jiraWorkspaceProjectOptions.length > 0) {
      setJiraProjectId(jiraWorkspaceProjectOptions[0].value)
    }
  }, [jiraWorkspaceProjectOptions.length])

  useEffect(() => {
    onWorkspaceChange("")
    setJiraProjectId("")
  }, [])

  return (
    <VStack
      align="start"
      w="full"
      spacing="4"
      pb="32px"
      borderBottom="1px solid"
      borderColor="line"
    >
      <JiraWorkspacesAction
        jiraWorkspaceOptions={jiraWorkspaceOptions}
        jiraWorkspaceId={jiraWorkspaceId}
        onChange={handleWorkspaceChange}
        onJiraConnect={onJiraConnect}
      />

      <JiraProjectSelect
        jiraWorkspaceProjectOptions={jiraWorkspaceProjectOptions}
        jiraProjectId={jiraProjectId}
        onJiraProjectChange={setJiraProjectId}
      />

      <Flex width="full">
        <JiraProjectConnectButton
          isLoading={isLoadingJiraProjectConnect}
          isDisabled={!jiraProjectId || isLoadingJiraProjectConnect}
          onClick={handleJiraProjectConnect}
        />
      </Flex>
    </VStack>
  )
}

const JiraWorkspacesAction = ({
  jiraWorkspaceOptions,
  jiraWorkspaceId,
  onChange,
  onJiraConnect
}) => {
  return (
    <Flex w="full" align="end">
      <FormControl
        label="Jira Workspace name"
        helperText={<JiraConnectButton onClick={onJiraConnect} />}
        maxW="full"
      >
        <Select
          value={jiraWorkspaceId}
          options={jiraWorkspaceOptions}
          placeholder="Select workspace"
          flexShrink={0}
          onChange={event => onChange(event.target.value)}
        />
      </FormControl>
    </Flex>
  )
}

const JiraConnectButton = ({ onClick }) => (
  <Button variant="linkDark" width="fit-content" onClick={onClick}>
    Connect workspace
  </Button>
)

const JiraProjectConnectButton = ({ onClick, isDisabled, isLoading }) => (
  <Button
    variant="primaryPurple"
    width="fit-content"
    isDisabled={isDisabled}
    isLoading={isLoading}
    loadingText="Connecting..."
    ml="auto"
    onClick={onClick}
  >
    Connect project
  </Button>
)

const JiraProjectSelect = ({
  jiraProjectId,
  jiraWorkspaceProjectOptions = [],
  onJiraProjectChange
}) => (
  <FormControl label="Jira Workspace projects" maxW="full">
    <Select
      value={jiraProjectId}
      options={jiraWorkspaceProjectOptions}
      placeholder="Select project"
      onChange={event => onJiraProjectChange(event.target.value)}
    />
  </FormControl>
)
