import {
  QueryKey,
  UseQueryOptions,
  useQuery
} from "react-query"
import { platformWorkspaceConfigUseCase } from "../core"
import { PlatformWorkspaceConfig } from "../core/types"

const useGetPlatformWorkspaceConfigById = (
  id: string,
  options: Options = {}
) =>
  useQuery<PlatformWorkspaceConfig, Error>(
    ["platformWorkspaceConfig", id],
    () => platformWorkspaceConfigUseCase.getById(id),
    options
  )

export default useGetPlatformWorkspaceConfigById

type Options = Omit<
  UseQueryOptions<PlatformWorkspaceConfig, Error, PlatformWorkspaceConfig, QueryKey>,
  "queryKey" | "queryFn"
>

