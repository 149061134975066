import {
  IContract,
  IContractNormalized
} from "modules/Contract/src//core/types"

export const normalizeContract = (
  contract: IContract
): IContractNormalized => ({
  ...contract,
  skIds: JSON.parse(contract.skIds || "{}"),
  workspaceId:
    (contract?.workspaceId?.length || 0) > 1 ? contract.workspaceId : null
})
