import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React, { useMemo } from "react"
import ContractsTab from "./ContractsTab"
import WorkspacesTab from "./WorkspacesTab"
import { TABS } from "./data"

function ResourcesPanel({
  activeTab,
  onTabChange,
  workspacesTabProps,
  contractsTabProps
}) {
  const handleTabChange = index => {
    onTabChange(TABS[index].value)
  }

  const activeTabIndex = useMemo(() => {
    const index = TABS.findIndex(tab => tab.aliases.includes(activeTab))
    return index === -1 ? 0 : index
  }, [activeTab])

  return (
    <Layout onTabChange={handleTabChange} activeTabIndex={activeTabIndex}>
      <WorkspacesTab {...workspacesTabProps} />

      <ContractsTab {...contractsTabProps} />
    </Layout>
  )
}

export default ResourcesPanel

function Layout({ activeTabIndex, onTabChange, children }) {
  return (
    <Box p={{ base: "4", md: "10" }} width="full">
      <Box
        bgColor="rgba(255, 255, 255, 0.1)"
        border="1.5px solid"
        borderColor="rgba(234, 234, 234, 0.1)"
        backdropFilter="blur(2.5px)"
        rounded={{ base: "12px", md: "20px" }}
        height="full"
        maxWidth="600px"
        width="full"
        mx="auto"
      >
        <Tabs
          index={activeTabIndex}
          onChange={onTabChange}
          height="full"
          variant="homepage"
        >
          <Header />

          <TabPanels>
            {React.Children.map(children, child => (
              <TabPanel>{child}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

function Header() {
  return (
    <TabList height="50px" alignItems="end">
      {TABS.map(tab => (
        <Tab key={tab.value}>{tab.label}</Tab>
      ))}
    </TabList>
  )
}
