import {
  useConnectSlack,
  useGetUserCommunityWorkspacesWithBot
} from "modules/Community/src/core/hooks"
import {
  useConnectSourceProject,
  useDisconnectSourceProject,
  useGetSourceWorkspaceProjects
} from "modules/Source/src/core/hooks"
import {
  ConnectedSourceProject,
  SourceProjectEditProps
} from "modules/Source/src/core/types/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { useCallback, useMemo, useState } from "react"

const useSourceSettingsSlack = ({ workspace, user }: Props) => {
  const { isSlackConnected, connectWorkspace } = useConnectSlack(
    workspace?.id || "",
    user
  )

  const [isLoadingSlackChannelConnect, setIsLoadingSlackChannelConnect] =
    useState(false)
  const { mutateAsync: connectSourceProjectAsync } = useConnectSourceProject()
  const { mutateAsync: disconnectSourceProjectAsync } =
    useDisconnectSourceProject()
  const [activeSlackWorkspaceId, setActiveSlackWorkspaceId] = useState("")

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const handleAccordionChange = (isOpen: boolean) => setIsSettingsOpen(isOpen)

  const { data: userSlackWorkspacesWithBot = [] } =
    useGetUserCommunityWorkspacesWithBot(
      {
        communityType: "slack"
      },
      {
        enabled: !!user?.slackId && isSettingsOpen
      }
    )

  const { data: slackWorkspaceChannels = [] } = useGetSourceWorkspaceProjects(
    {
      type: "slack",
      options: {
        slackWorkspaceId: activeSlackWorkspaceId
      }
    },
    {
      enabled: !!activeSlackWorkspaceId
    }
  )

  const slackWorkspaceOptions = useMemo(
    () =>
      userSlackWorkspacesWithBot?.map(slackWorkspace => ({
        value: slackWorkspace.id,
        label: slackWorkspace.name
      })) || [],
    [userSlackWorkspacesWithBot.length]
  )
  const slackWorkspaceChannelOptions = useMemo(() => {
    if (!activeSlackWorkspaceId) {
      return []
    }
    return slackWorkspaceChannels
      .filter(
        channel =>
          !workspace?.slackChannels?.find(
            workspaceChannel => workspaceChannel.id === channel.id
          )
      )
      .map(channel => ({
        value: channel.id,
        label: channel.name
      }))
  }, [
    activeSlackWorkspaceId,
    JSON.stringify(slackWorkspaceChannels),
    JSON.stringify(workspace?.slackChannels)
  ])

  const connectedChannels = useMemo<ConnectedSourceProject[]>(
    () =>
      workspace?.slackChannels?.map(channel => ({
        id: channel.id,
        name: channel.name,
        sourceType: "slack",
        iconName: "slack"
      })) || [],
    [JSON.stringify(workspace?.slackChannels)]
  )

  const handleSlackWorkspaceChange = (guildId: string) =>
    setActiveSlackWorkspaceId(guildId)

  const handleWorkspaceChannelConnect = async (channelId: string) => {
    setIsLoadingSlackChannelConnect(true)

    const slackChannel = slackWorkspaceChannels.find(
      channel => channel.id === channelId
    )
    const slackWorkspace = userSlackWorkspacesWithBot.find(
      guild => guild.id === activeSlackWorkspaceId
    )

    if (!slackChannel || !slackWorkspace || !workspace?.id) {
      return
    }

    await connectSourceProjectAsync({
      sourceType: "slack",
      options: {
        workspaceId: workspace.id,
        slackChannel: {
          id: slackChannel.id,
          name: slackChannel.name,
          workspaceId: slackWorkspace.id,
          workspaceName: slackWorkspace.name
        }
      }
    })
    setIsLoadingSlackChannelConnect(false)
  }

  // slack channel edit

  const handleChannelDisconnect = async (channelId: string) => {
    try {
      await disconnectSourceProjectAsync({
        sourceType: "slack",
        options: {
          workspaceId: workspace?.id as string,
          slackChannelId: channelId
        }
      })
    } catch (error) {
      console.error("handleChannelDisconnect : ", error)
    }
  }

  const getChannelEditProps = useCallback(
    (channelId: string): SourceProjectEditProps => {
      const channel = workspace?.slackChannels?.find(
        channel => channel.id === channelId
      )

      return {
        sourceType: "slack",
        project: channel!,
        onDisconnect: handleChannelDisconnect
      }
    },
    [JSON.stringify(workspace?.slackChannels)]
  )

  // slack channel edit

  return {
    slackWorkspaceOptions,
    slackWorkspaceChannelOptions,
    connectedChannels,
    handleSlackWorkspaceChange,
    handleWorkspaceChannelConnect,
    handleChannelDisconnect,
    isLoadingSlackChannelConnect,
    isSlackConnected,
    handleSlackConnect: connectWorkspace,
    handleAccordionChange,
    getChannelEditProps
  }
}

export { useSourceSettingsSlack }

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
