import { Switch as ChakraSwitch } from "@chakra-ui/react"
import React, { forwardRef } from "react"

const Switch = forwardRef(({ isChecked, onChange = {}, ...props }, ref) => (
  <ChakraSwitch
    ref={ref}
    variant="primary"
    size="md"
    isChecked={isChecked}
    onChange={onChange}
    {...props}
  />
))
Switch.displayName = "Switch"

export default Switch
