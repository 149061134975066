import { Box, Flex, Text } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, FormControl, Input, SettingsAccordion } from "theme/components"
import * as XLSX from "xlsx"
import { validateEmail } from "../../helpers/validations"

const Email = ({ onFileUpload, isLoadingUpload, onAdd, isLoadingAdd }) => (
  <SettingsAccordion iconName="email" name="Email">
    <Flex width="full" direction={{ base: "column", md: "row" }}>
      <AddEmailForm onSubmit={onAdd} isLoading={isLoadingAdd} />

      <UploadEmailsAction
        onFileUpload={onFileUpload}
        isLoading={isLoadingUpload}
      />
    </Flex>
  </SettingsAccordion>
)

export default Email

function AddEmailForm({ onSubmit, isLoading }) {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ""
    }
  })
  const [email, setEmail] = useState("")

  const handleFormSubmit = values => {
    onSubmit &&
      onSubmit(values.email.trim().toLowerCase(), {
        onSuccess: () => setEmail("")
      })
  }

  return (
    <Flex
      as="form"
      direction="row"
      width="full"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormControl
        label="Email"
        maxW="320px"
        isInvalid={errors.email}
        errorMessage={errors.email?.message}
      >
        <Input
          value={email}
          onInput={event => setEmail(event.target.value)}
          // type="email"
          placeholder="Enter email"
          flexShrink="0"
          {...register("email", validateEmail)}
        />
      </FormControl>

      <Button
        type="submit"
        isDisabled={!email}
        isLoading={isLoading}
        onClick={handleSubmit}
        width="fit-content"
        px="10"
        mt="4"
        ml={{ base: 4, md: 6 }}
        alignSelf={{ base: "flex-end", md: "auto" }}
      >
        Add
      </Button>
    </Flex>
  )
}

function UploadEmailsAction({ onFileUpload, isLoading }) {
  const fileInputRef = useRef(null)
  const [error, setError] = useState("")

  const handleFileUpload = event => {
    setError("")
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      try {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const rows = XLSX.utils.sheet_to_json(worksheet)

        const emailList = rows
          .map(row => {
            return Object.keys(row).reduce((acc, key) => {
              acc[key.trim().toLowerCase()] = row[key].trim()
              return acc
            }, {})
          })
          .map(row => {
            return EXPECTED_SHEET_HEADERS.reduce((acc, header) => {
              acc[header] = row[header]
              return acc
            }, {})
          })
          .filter(row => {
            return EXPECTED_SHEET_HEADERS.every(
              header => row[header] && row[header].trim() !== ""
            )
          })

        if (emailList.length === 0) {
          setError("No valid emails found")
          return
        }

        onFileUpload && onFileUpload(emailList)
      } catch (error) {
        setError("Invalid file format")
      }
    }

    reader.readAsArrayBuffer(file)
  }

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    if (isLoading) {
      fileInputRef.current.value = ""
    }
  }, [isLoading])

  return (
    <Box ml={{ base: 0, md: 8 }} mt={{ base: 6, md: 4 }}>
      <Input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        display="none"
        accept=".csv, .numbers , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <Button
        onClick={triggerFileInput}
        variant="secondary"
        isLoading={isLoading}
      >
        Upload emails
      </Button>

      {error && (
        <Text fontSize="sm" mt={1} color="error">
          {error}
        </Text>
      )}
    </Box>
  )
}

const EXPECTED_SHEET_HEADERS = ["email", "name", "surname"]
