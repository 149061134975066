export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      pointEstimation
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
      }
      sourceContractPk
      predefinedTeamIds
      workspaceId
      completed
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
      }
      githubCodespaces {
        id
        name
        link
      }
      createdAt
      updatedAt
    }
  }
`
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      pointEstimation
      sourceContractPk
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
      }
      predefinedTeamIds
      workspaceId
      completed
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
      }
      githubCodespaces {
        id
        name
        link
      }
      createdAt
      updatedAt
    }
  }
`

export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      pk
      sk
      skIds
      ownerId
      reporterId
      name
      description
      sourceType
      sourceContractPk
      pointEstimation
      teams {
        id
        workspaceId
        ownerId
        communityWorkspaceId
        communityType
        teamId
        name
        surname
        type
        emoji
        emojiBgColor
        isPrivate
        createdAt
        updatedAt
        __typename
      }
      predefinedTeamIds
      workspaceId
      completed
      variables
      deletedAt
      allMessagesSync
      isSubscribed
      jiraIssueId
      jiraProjects {
        id
        name
        link
        __typename
      }
      githubCodespaces {
        id
        name
        link
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
