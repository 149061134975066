import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { workspaceUseCase } from "../index"
import { Workspace, WorkspaceCreateInput } from "../types"

const useCreateWorkspace = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createWorkspace",
    input => workspaceUseCase.createOne(input.input),
    {
      onSettled: data => {
        if (!data?.id) {
          return
        }

        const createdWorkspace = data as Workspace

        queryClient.setQueryData<Workspace[]>(
          "userWorkspaces",
          oldWorkspaces => {
            const workspaces = [...(oldWorkspaces || [])]
            workspaces.unshift(createdWorkspace)
            return workspaces
          }
        )
      },
      ...options
    }
  )
}

type Options = Omit<
  UseMutationOptions<
    Workspace | null,
    Error,
    {
      input: WorkspaceCreateInput
    },
    {
      previousWorkspace: Workspace | undefined
    }
  >,
  "mutationFn"
>

export default useCreateWorkspace
