import { API } from "aws-amplify"
import { IContractNormalized } from "modules/Contract/src/core/types"
import { withCognitoAccessToken } from "modules/User/src/core/helpers"
import {
  ModelContractFilterInput
} from "modules/Contract/src/core/types/graphql"

class WorkspaceAPIService {
  async getWorkspaceContracts(
    workspaceId: string,
    filter?: ModelContractFilterInput
  ): Promise<IContractNormalized[]> {
    const init = await withCognitoAccessToken({
      queryStringParameters: {
        filter: JSON.stringify(filter)
      }
    })
    return API.get("workspaceRestAPI", `/${workspaceId}/contracts`, init)
  }

  async softDeleteWorkspace(workspaceId: string) {
    const init = await withCognitoAccessToken({
      body: { workspaceId }
    })
    return API.post("workspaceRestAPI", `/soft-delete`, init)
  }
}

export default WorkspaceAPIService
