import {
  DEFAULT_EMOJI_BG_COLOR,
  DEFAULT_TEAM_EMOJI
} from "modules/Workspace/src/core/config"
import { useCreateWorkspaceTeams } from "modules/Workspace/src/core/hooks"
import {
  WorkspaceTeamCommunityType,
  WorkspaceTeamCreateInput,
  WorkspaceTeamType
} from "modules/Workspace/src/core/types/workspace-team"
import { useState } from "react"
import { IUserNormalized } from "../../../../../User/src/core/types"
import { Workspace } from "../../../../../Workspace/src/core/types"
import { EmailList } from "../../types/email"

const useEmailSettings = ({ workspace, user }: Props) => {
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [isLoadingAdd, setIsLoadingAdd] = useState(false)
  const { mutateAsync: createWorkspaceTeamsAsync } = useCreateWorkspaceTeams()

  const handleEmailListUpload = async (emailList: EmailList) => {
    try {
      setIsLoadingUpload(true)
      const workspaceTeamsCreateInput: WorkspaceTeamCreateInput[] =
        emailList.map(({ email, name, surname }) => ({
          type: WorkspaceTeamType.USER,
          workspaceId: workspace?.id || "",
          communityType: WorkspaceTeamCommunityType.EMAIL,
          teamId: email,
          name,
          surname,
          ownerId: user?.id || "",
          emoji: workspace?.defaultTeamEmoji?.emoji || DEFAULT_TEAM_EMOJI,
          emojiBgColor: workspace?.defaultTeamEmoji?.backgroundColor || DEFAULT_EMOJI_BG_COLOR
        }))
      await createWorkspaceTeamsAsync(workspaceTeamsCreateInput)
      setIsLoadingUpload(false)
    } catch (error) {
      console.error("handleEmailListUpload : ", error)
    }
  }

  const handleEmailAdd = async (
    email: string,
    { onSuccess }: { onSuccess: () => void }
  ) => {
    try {
      setIsLoadingAdd(true)
      const workspaceTeamsCreateInput: WorkspaceTeamCreateInput[] = [
        {
          type: WorkspaceTeamType.USER,
          workspaceId: workspace?.id || "",
          communityType: WorkspaceTeamCommunityType.EMAIL,
          teamId: email,
          name: "",
          surname: "",
          ownerId: user?.id || "",
          emoji: workspace?.defaultTeamEmoji?.emoji || DEFAULT_TEAM_EMOJI,
          emojiBgColor: workspace?.defaultTeamEmoji?.backgroundColor || DEFAULT_EMOJI_BG_COLOR
        }
      ]
      await createWorkspaceTeamsAsync(workspaceTeamsCreateInput)

      onSuccess && onSuccess()
      setIsLoadingAdd(false)
    } catch (error) {
      console.error("handleEmailAdd : ", error)
    }
  }

  return {
    isLoadingUpload,
    handleEmailListUpload,
    handleEmailAdd,
    isLoadingAdd
  }
}

export default useEmailSettings

export type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
