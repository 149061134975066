import { IContractNormalized } from "modules/Contract/src//core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { mergeContracts } from "../../../../Contract/src/core/utils"
import { sourceUseCase } from "../index"
import { SendContractToSourceOptions, SourceType } from "../types"

export const useSendContractToSource = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "createContractInSourceProject",
    (input: MutationInput) =>
      sourceUseCase.sendContractToSource(input.sourceType, input.options),
    {
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }

        const updatedContract = data as IContractNormalized

        if (variables.page === "contract") {
          queryClient.setQueryData<IContractNormalized>(
            ["mappedContract", updatedContract.id],
            oldContract =>
              mergeContracts(
                oldContract as IContractNormalized,
                data as IContractNormalized,
                ["name", "description", "pointEstimation"]
              )
          )
        } else {
          const queryKey = variables.workspaceId
            ? ["workspaceContracts", variables.workspaceId]
            : "homepageContracts"

          queryClient.setQueryData<IContractNormalized[]>(queryKey, old => {
            const oldContracts = old as IContractNormalized[]
            const index = oldContracts.findIndex(
              contract => contract.id === updatedContract.id
            )

            oldContracts[index] = mergeContracts(
              oldContracts[index] as IContractNormalized,
              data as IContractNormalized,
              ["name", "description", "pointEstimation"]
            )

            return oldContracts
          })
        }
      },
      ...options
    }
  )
}

export type MutationInput = {
  workspaceId?: string
  page?: "workspace" | "contract"
  sourceType: SourceType
  options: SendContractToSourceOptions
}

export type Options = Omit<
  UseMutationOptions<IContractNormalized | null, Error, MutationInput, unknown>,
  "mutationFn" | "mutationKey"
>
