import {
  IContractNormalized,
  IContractNormalizedWithLoading
} from "modules/Contract/src//core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { mergeContracts } from "../utils"

const useDelegateHomepageContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "delegateContract",
    (contractId: string) => contractUseCase.workspaceDelegate(contractId),
    {
      onSettled: data => {
        if (!data?.contract) {
          return
        }

        const updatedContract = data.contract as IContractNormalized

        queryClient.setQueryData<IContractNormalized[]>(
          "homepageContracts",
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]
            const contractIndex = contracts.findIndex(
              contract => contract.id === updatedContract.id
            )
            contracts[contractIndex] = mergeContracts(
              contracts[contractIndex],
              updatedContract,
              ["name", "description", "pointEstimation"]
            )

            return contracts
          }
        )
      },
      ...options
    }
  )
}

export default useDelegateHomepageContract

type Options = Omit<
  UseMutationOptions<
    {
      workspace: Workspace
      contract: IContractNormalized
    },
    Error,
    string
  >,
  "mutationFn"
>
