import { Box } from "@chakra-ui/react"
import { useMemo, useState } from "react"
import { Select } from "theme/components"
import {
  ActionForm,
  ActionFormButton,
  ActionLayout,
  ActionTriggerButton
} from "./ActionComponents"

export function SendToJiraAction({
  contract = {},
  projectOptions = [],
  onSubmit,
  isLoadingSendToJira
}) {
  const [projectId, setProjectId] = useState("")
  const handleProjectChange = event => {
    setProjectId(event.target.value)
  }

  const handleSubmit = () => {
    onSubmit && onSubmit(projectId)
  }

  const contractJiraProject = useMemo(
    () => contract.jiraProjects?.find(project => project.id === projectId),
    [projectId, contract.jiraProjects?.length]
  )

  return (
    <Box>
      <ActionLayout>
        <ActionTriggerButton iconName="jira">Send to Jira</ActionTriggerButton>

        <ActionForm header="Send to Jira">
          <Select
            label="Jira project"
            placeholder="Jira project"
            value={projectId}
            onChange={handleProjectChange}
            options={projectOptions}
          />

          <ActionButton
            isDisabled={!projectId}
            isLoading={isLoadingSendToJira}
            onClick={handleSubmit}
            link={contractJiraProject?.link}
          />
        </ActionForm>
      </ActionLayout>
    </Box>
  )
}

function ActionButton({ isDisabled, link, isLoading, onClick }) {
  if (link) {
    return <ActionFormButton link={link}>Go to issue</ActionFormButton>
  }

  return (
    <ActionFormButton
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      Send to Jira
    </ActionFormButton>
  )
}
