import { Workspace } from "modules/Workspace/src/core/types"
import {
  QueryKey,
  UseQueryOptions,
  useQuery
} from "react-query"
import { platformApiUseCase } from "../core"
import { PlatformWorkspaceConfig } from "../core/types"

const useGetPlatformWorkspace= (
  input: FnInput,
  options: Options = {}
) =>
  useQuery<FnOutput, Error>(
    ["platformWorkspace", input.platformWorkspaceConfigId],
    () => platformApiUseCase.getWorkspace(input),
    options
  )

export default useGetPlatformWorkspace

type Options = Omit<
  UseQueryOptions<FnOutput, Error, FnOutput, QueryKey>,
  "queryKey" | "queryFn"
>

type FnInput = {
  platformWorkspaceConfigId: string
  platformDomain: string
}

type FnOutput = {
  platformWorkspaceConfig: PlatformWorkspaceConfig
  workspace: Workspace
}
