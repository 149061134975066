import { IUser, IUserNormalized } from "./types"

export const jsonParseSafe = (string: string) => {
  try {
    return JSON.parse(string)
  } catch (error) {
    return null
  }
}

export const normalizeUser = (user: IUser): IUserNormalized => ({
  ...user,
  skIds: jsonParseSafe(user?.skIds || "{}")
})

export const denormalizeUser = (
  user: Partial<IUserNormalized>
): Partial<IUser> => {
  const denormalizedUser = { ...user } as Partial<IUser>
  if (user?.skIds) {
    denormalizedUser.skIds = JSON.stringify(user.skIds)
  }
  return denormalizedUser
}
