import { useQueryParams } from "hooks"
import {
  useCreateContract,
  useWorkspaceContract as useWorkspaceActiveContract
} from "modules/Contract/src/core/hooks"
import { ContractSourceType } from "modules/Contract/src/core/types"
import { IUserNormalized } from "modules/User/src/core/types"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo } from "react"
import { v4 as uuidv4 } from "uuid"
import { EmptyObject, Workspace } from "../../types"
import { WorkspaceActiveTab } from "../../types/global"
import { useGetWorkspaceContracts } from "../useGetWorkspaceContracts"
import { ModalType } from "../../../../../../providers/ModalProvider/types"
import { ContractTypes } from "../usePlatformWorkspace/types"

export const useWorkspaceContract = ({
  user,
  workspace = {},
  activeTab,
  isMobile
}: Props) => {
  const { removeQueryParams, setQueryParams, queryParams } = useQueryParams()
  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { mutateAsync: createContractAsync } = useCreateContract()

  const isArchivedContracts = useMemo(
    () => queryParams.type === ContractTypes.ARCHIVED,
    [queryParams.type]
  )
  const contractsFilter = useMemo(
    () => (
      isArchivedContracts ?
        {
          id: { ne: workspace.contractId || ""},
          deletedAt: { attributeExists: true }
        } : {
          id: { ne: workspace.contractId || ""},
          deletedAt: { attributeExists: false }
        }
    ), [queryParams.type, workspace.contractId]
  )

  // workspace contracts
  const { data: workspaceContracts = [] } = useGetWorkspaceContracts(
    {
      workspaceId: workspace.id,
      filter: contractsFilter,
      isArchivedContracts
    },
    {
      enabled: !!workspace.id,
      keepPreviousData: false
    }
  )
  // workspace contracts

  // contract create
  const handleContractCreateClick = () => {
    setQueryParams({ tab: "contract-create" })
  }

  const isContractCreateModalOpen = useMemo(
    () => modalState.modalType === "contractCreate",
    [modalState.modalType]
  )

  const handleContractCreateModalClose = useCallback(() => {
    if (!isMobile && activeTab === "contract-create") {
      removeQueryParams("tab")
    }
  }, [activeTab, isMobile, removeQueryParams])

  useEffect(() => {
    if (isContractCreateModalOpen && activeTab !== "contract-create") {
      modalDispatch({ type: "close" })
    }

    if (
      activeTab === "contract-create" &&
      !isContractCreateModalOpen &&
      !isMobile
    ) {
      modalDispatch({
        type: "open",
        modalType: ModalType.ContractCreate,
        props: {
          onSubmit: handleContractCreate
        },
        onClose: handleContractCreateModalClose
      })
    }

    if (isContractCreateModalOpen && activeTab === "contract-create") {
      modalDispatch({
        type: "update",
        onClose: handleContractCreateModalClose
      })
    }
  }, [handleContractCreateModalClose, isContractCreateModalOpen])

  const handleContractCreate = async (name: string, description: string) => {
    const contractPk = uuidv4()

    try {
      await createContractAsync({
        workspaceId: workspace.id,
        input: {
          workspaceId: workspace.id,
          name,
          description,
          ownerId: user!.id,
          reporterId: user!.id,
          sourceType: ContractSourceType.COSMO_WORKSPACE,
          pk: contractPk,
          sk: `owner#${user!.id}`
        }
      })

      setQueryParams({ tab: "contract", contractPk })
    } catch (error) {
      console.error("Contract create : ", error)
    }
  }
  // contract create

  const handleContractCardClick = (contractPk: string) => {
    setQueryParams({ tab: "contract", contractPk })
  }

  const activeContractProps = useWorkspaceActiveContract({
    workspace,
    contractsFilter,
    isArchivedContracts
  })

  return {
    workspaceContracts,
    handleContractCreate,
    handleContractCreateClick,
    handleContractCardClick,
    isArchivedContractsTab: isArchivedContracts,
    ...activeContractProps
  }
}

type Props = {
  user: IUserNormalized | null
  workspace: Workspace | EmptyObject
  activeTab: WorkspaceActiveTab
  isMobile: boolean
}
