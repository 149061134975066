import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized } from "../types"

const useGetHomepageContracts = (options: Options = {}) =>
  useQuery<IContractNormalized[], Error>(
    "homepageContracts",
    () => contractUseCase.getHomepageContracts(),
    options
  )

export default useGetHomepageContracts

type Options = Omit<
  UseQueryOptions<
    IContractNormalized[],
    Error,
    IContractNormalized[],
    QueryKey
  >,
  "queryKey" | "queryFn"
>
