import {
  useCheckIfUserExistsInPlatformConfig,
  useGetPlatformWorkspace
} from "modules/Platform/src/hooks"
import {
  useSignInWithToken,
  useSignOut
} from "modules/User/src/core/hooks"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useCurrentAuthenticatedUser
} from "../../../../../User/src/core/hooks/useCurrentAuthenticatedUser"
import { ICognitoUser } from "../../../../../User/src/core/types"
import {
  PlatformLocalStorage
} from "../../../../../Platform/src/core/helpers/platformLocalStorage"

const usePlatformConfig = () => {
  const { platformWorkspaceConfigId = "" } = useParams()
  const [platformDomain, setPlatformDomain] = useState("")
  const {
    data: cognitoUser,
    isLoading: isLoadingCognitoUser
  } = useCurrentAuthenticatedUser() as {
    data: ICognitoUser,
    isLoading: boolean
  }

  const {
    data: {
      platformWorkspaceConfig,
      workspace
    } = {}
  } = useGetPlatformWorkspace(
    {
      platformDomain,
      platformWorkspaceConfigId
    },
    {
      enabled: !!platformDomain && !!platformWorkspaceConfigId
    }
  )

  const { mutateAsync: checkIfUserExistsInPlatformConfigAsync } =
    useCheckIfUserExistsInPlatformConfig()
  const { mutateAsync: signInWithToken } = useSignInWithToken()
  const { mutateAsync: signOutAsync } = useSignOut()

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      const { token, email } = event.data
      console.log("data : ", event.data)
      if (!email || !token) {
        return
      }

      const platformHostname = new URL(event.origin).hostname
      try {
        // if (cognitoUser?.attributes?.email !== email) {
        await signOutAsync()
        PlatformLocalStorage.clear()
        await signInWithToken({ email, token })
        PlatformLocalStorage.set(email, platformHostname)
        // }

        setPlatformDomain(platformHostname)
      } catch (error) {
        console.error("Error handling auth postMessage:", error)
      }
    }

    // Add the event listener
    window.addEventListener("message", handlePostMessage)

    return () => {
      window.removeEventListener("message", handlePostMessage)
    }
  }, [
    checkIfUserExistsInPlatformConfigAsync,
    signInWithToken,
    signOutAsync,
    // cognitoUser,
    // isLoadingCognitoUser
  ])


  // useEffect(() => {
  //   (async () => {
  //     console.log("start auto login")
  //     const { platformUserEmail, platformDomain } = PlatformLocalStorage.get()
  //     const userEmail = cognitoUser?.attributes?.email
  //     console.log("login data : ", {
  //       platformUserEmail,
  //       userEmail,
  //       platformDomain
  //     })
  //
  //     if (platformUserEmail && userEmail && platformDomain && userEmail === platformUserEmail) {
  //       console.log("setting platform domain")
  //       setPlatformDomain(platformDomain)
  //     }
  //   })()
  // }, [cognitoUser?.attributes?.email])

  return {
    platformWorkspaceConfig,
    workspace
  }
}

export default usePlatformConfig
