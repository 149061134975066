import { AnyObject } from "global-types"
import { Dispatch, ReactNode, Reducer } from "react"

export type emptyObjectType = Record<string, never>
export enum ModalType {
  ContractCreate = "contractCreate",
  ContractSettings = "contractSettings",
  SourceEdit = "sourceEdit",
  WorkspaceTeamEdit = "workspaceTeamEdit",
  Delegate = "delegate",
  WorkspaceCreate = "workspaceCreate",
  Confirmation = "confirmation",
  PlatformContractSettings = "platformContractSettings",
  PlatformContractDelegate = "platformContractDelegate",
}

export type modalReducerType = Reducer<
  IModalReducerState,
  Partial<IModalReducerState>
>

export interface IModalReducerState {
  modalType: ModalType | null
  type: "close" | "open" | "update"
  props: AnyObject
  attributes: AnyObject
  activeModal: ReactNode
  onClose: (options?: any) => void
}

export type IModalReducerAction = Partial<IModalReducerState>

export type modalTypes = {
  [key in ModalType]: ReactNode
}

export interface IModalProviderProps {
  children?: ReactNode
  modals: modalTypes | emptyObjectType
}

export type IModalContext = {
  state: IModalReducerState
  dispatch: Dispatch<Partial<IModalReducerState>>
}
