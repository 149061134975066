import { Flex, Grid, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Icon } from "theme/components"

function WorkspacesTab({ workspaces = [], onCreateClick }) {
  return (
    <Layout onCreateClick={onCreateClick}>
      {workspaces.map(workspace => (
        <WorkspaceCard
          key={workspace.id}
          workspaceName={workspace.name}
          workspaceId={workspace.id}
        />
      ))}
    </Layout>
  )
}

export default WorkspacesTab

function Layout({ onCreateClick, children }) {
  return (
    <Grid height="full" templateRows="1fr auto">
      <VStack
        align="stretch"
        overflowY="scroll"
        p={{ base: "3", md: "5" }}
        spacing="3"
      >
        {children}
      </VStack>

      <Footer onCreateClick={onCreateClick} />
    </Grid>
  )
}

function Footer({ onCreateClick }) {
  return (
    <Grid
      height="50px"
      placeItems="center"
      position="relative"
      px="5"
      _before={{
        content: '""',
        position: "absolute",
        height: "1px",
        bgColor: "hsla(0, 0%, 92%, 0.1)",
        left: 5,
        right: 5,
        top: 0
      }}
    >
      <Button variant="textWhite" onClick={onCreateClick}>
        <Grid
          placeItems="center"
          height="6"
          width="6"
          bgColor="primaryDark"
          rounded="full"
          mr="2"
        >
          <Icon name="plus-8" />
        </Grid>
        Create Workspace
      </Button>
    </Grid>
  )
}

function WorkspaceCard({ workspaceName, workspaceId }) {
  return (
    <Flex
      as={RouterLink}
      to={`/workspace/${workspaceId}`}
      justify="space-between"
      bgColor="white"
      height={{ base: "62px", md: "72px" }}
      minHeight={{ base: "62px", md: "72px" }}
      rounded="12px"
      px={{ base: "3", md: "4" }}
      py={{ base: "2", md: "3" }}
    >
      <Text
        fontSize={{ base: "md", md: "lg" }}
        lineHeight={{ base: "md", md: "lg" }}
        color="primaryDark"
        noOfLines={2}
      >
        {workspaceName}
      </Text>

      <Icon
        name="arrow-right"
        color="primaryDark"
        size="14px"
        alignSelf="center"
        ml="2"
      />
    </Flex>
  )
}
