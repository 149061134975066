import { IContractNormalized } from "modules/Contract/src//core/types"
import { useQuery } from "react-query"
import { contractUseCase } from "../index"
import { useGetShareLinkContractOptionsType } from "../types/hooks"

export const useGetShareLinkContract = (
  linkId: string,
  options: useGetShareLinkContractOptionsType = {}
) =>
  useQuery<IContractNormalized | null, Error>(
    ["shareLinkContract", linkId],
    () => contractUseCase.getShareLinkContract(linkId),
    options
  )
