import { HamburgerIcon } from "@chakra-ui/icons"
import { Box, Flex, Grid, Image } from "@chakra-ui/react"
import { withSignOut } from "modules/User/src/hocs/withSignOut"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, IconButton, Sidebar } from "theme/components"

const MainLayout = ({
  onCreateClick,
  onSignOut,
  children,
  isBgImage,
  showNavbar = false,
  ...props
}) => (
  <Grid
    className="h-screen"
    templateColumns={{ base: "1fr", md: "80px 1fr" }}
    templateRows={{
      base: showNavbar ? "56px 1fr" : "1fr",
      md: "1fr"
    }}
    bgImage={isBgImage ? "/assets/images/background.jpg" : undefined}
    bgSize="cover"
    {...props}
  >
    <Sidebar
      onSignOut={onSignOut}
      onCreateClick={onCreateClick}
      display={{ base: "none", md: "grid" }}
    />

    <MobileNavbar
      display={{ base: showNavbar ? "flex" : "none", md: "none" }}
    />

    <Box
      overflowY="auto"
      mt={{ base: "3", md: 0 }}
    >{children}</Box>
  </Grid>
)

export default withSignOut(MainLayout)

const MobileNavbar = props => (
  <Flex
    px="4"
    height="56px"
    width="full"
    align="center"
    justify="space-between"
    {...props}
  >
    <RouterLink to="/">
      <Image src="/assets/images/logo-symbol.png" width="32px" height="32px" />
    </RouterLink>

    <Flex>
      <Button variant="secondary" size="md" as={RouterLink} to="?tab=teams">
        Teams
      </Button>

      <IconButton
        as={RouterLink}
        to="?tab=menu"
        relative="path"
        icon={<HamburgerIcon />}
        ml="3"
      />
    </Flex>
  </Flex>
)
