import { IUserNormalized } from "modules/User/src/core/types"
import { useMemo } from "react"
import {
  useContractComments,
  useContractCommunityMessages
} from "../../../../Contract/src/core/hooks"
import { IContractNormalized } from "../../../../Contract/src/core/types"

const useContractMessages = (
  user: IUserNormalized | null,
  contract: IContractNormalized | null
) => {
  const { contractCommunityMessages } = useContractCommunityMessages(contract)
  const { contractComments, handleCommentCreate } = useContractComments(user, contract)

  const contractMessages = useMemo(() => {
    return [...contractCommunityMessages, ...contractComments].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }, [contractCommunityMessages.length, contractComments.length])

  return {
    contractMessages,
    handleCommentCreate
  }
}

export default useContractMessages
