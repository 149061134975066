import { ModelPlatformConfigFilterInput } from "../graphql/types"
import PlatformConfigCRUDRepository from "../repositories/PlatformConfigCRUDRepository"

class PlatformConfigUseCase {
  constructor(
    private readonly platformConfigCRUDRepository: PlatformConfigCRUDRepository
  ) {}

  async getOne(filter: ModelPlatformConfigFilterInput) {
    return (
      await this.platformConfigCRUDRepository.listPlatformConfigs({ filter })
    ).items[0]
  }

  async getAdminPlatformConfig(filter: ModelPlatformConfigFilterInput) {
    return (
      await this.platformConfigCRUDRepository.listAdminPlatformConfigs({
        filter
      })
    ).items[0]
  }

  async checkIfUserExistsInPlatform(email: string, platformDomain: string) {
    const platformConfig = await this.getOne({
      domain: { eq: platformDomain },
      userEmails: { contains: email }
    })

    return !!platformConfig?.id
  }
}

export default PlatformConfigUseCase
