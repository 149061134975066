import WorkspaceAPIService from "../services/WorkspaceAPIService"

class WorkspaceAPIRepository {
  constructor(private readonly workspaceApiService: WorkspaceAPIService) {}

  getWorkspaceContracts = this.workspaceApiService.getWorkspaceContracts
  softDeleteWorkspace = this.workspaceApiService.softDeleteWorkspace
}

export default WorkspaceAPIRepository
