import CDESettingsDrawer from "modules/CDE/src/components/CDESettingsDrawer"
import CommunitySettingsDrawer
  from "modules/Community/src/components/CommunitySettingsDrawer"
import ContractDetails from "modules/Contract/src/components/ContractDetails"
import ContractsSidebar from "modules/Contract/src/components/ContractsSidebar"
import SourceSettingsDrawer
  from "modules/Source/src/components/SourceSettingsDrawer"
import TeamsSidebar from "modules/Workspace/src/components/TeamsSidebar"
import React, { useCallback } from "react"
import EmptyWorkspace from "../EmptyWorkspace"
import Header from "./Header"
import Layout, { ContractsBoxLayout } from "./Layout"
import { ContractChat } from "../../../modules/Contract/src/components"

function WorkspaceDesktop({
  workspace,
  contractSidebarProps,
  contractDetailsProps,
  sourceSettingsProps,
  communitySettingsProps,
  CDESettingsProps,
  teamsSidebarProps,
  onCDESettingsClick,
  onCommunitySettingsClick,
  onSourceSettingsClick,
  onContractCreateClick,
  onDeleteWorkspaceClick,
  onArchivedContractsClick,
  user,
  activeTab,
  onChatBackClick
}) {
  if (!workspace) {
    return null
  }

  const renderHeader = useCallback(
    () => (
      <Header
        workspace={workspace}
        onCDESettingsClick={onCDESettingsClick}
        onCommunitySettingsClick={onCommunitySettingsClick}
        onSourceSettingsClick={onSourceSettingsClick}
        onDeleteWorkspaceClick={onDeleteWorkspaceClick}
        onArchivedContractsClick={onArchivedContractsClick}
      />
    ),
    [JSON.stringify(workspace)]
  )

  return (
    <>
      <Layout
        renderHeader={renderHeader}
        onContractCreateClick={onContractCreateClick}
      >
        {contractDetailsProps.contract ? (
          <ContractsBoxLayout>
            <ContractsSidebar {...contractSidebarProps} />

            {activeTab === "chat" ? (
              <ContractChat
                contract={contractDetailsProps.contract}
                user={user}
                onBackClick={onChatBackClick}
              />
            ) : (
              <ContractDetails {...contractDetailsProps} />
            )}
          </ContractsBoxLayout>
        ) : (
          <EmptyWorkspace
            onContractCreateClick={onContractCreateClick}
            isArchivedContractsTab={contractSidebarProps.isArchivedContractsTab}
            onArchivedContractsBackClick={contractSidebarProps.onArchivedContractsBackClick}
          />
        )}

        <TeamsSidebar {...teamsSidebarProps} />
      </Layout>

      <CDESettingsDrawer {...CDESettingsProps} />
      <SourceSettingsDrawer {...sourceSettingsProps} />
      <CommunitySettingsDrawer {...communitySettingsProps} />
    </>
  )
}

export default WorkspaceDesktop
