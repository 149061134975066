import { Box } from "@chakra-ui/react"
import ContractDetails from "modules/Contract/src/components/ContractDetails"
import MainLayout from "modules/Workspace/src/components/layouts/MainLayout"
import React from "react"

function ContractPage({ contractDetailsProps }) {
  if (!contractDetailsProps.contract?.id) {
    return <Layout />
  }

  return (
    <Layout>
      <ContractDetails {...contractDetailsProps} />
    </Layout>
  )
}

export default ContractPage

function Layout({ children }) {
  return (
    <MainLayout>
      <Box overflow="auto" px="4" pb="8">
        {children ? children : <></>}
      </Box>
    </MainLayout>
  )
}
