import {
  useGetAdminPlatformConfigAsync,
  useGetPlatformWorkspaceConfigById
} from "./index"
import { useSignInWithToken, useSignOut } from "modules/User/src/core/hooks"
import { useEffect, useState } from "react"
import {
  useCurrentAuthenticatedUser
} from "../../../User/src/core/hooks/useCurrentAuthenticatedUser"
import { ICognitoUser } from "../../../User/src/core/types"
import { PlatformLocalStorage } from "../core/helpers/platformLocalStorage"

const useAdminPlatformConfig = () => {
  const [platformWorkspaceConfigId, setPlatformWorkspaceConfigId] = useState("")
  const [adminWorkspaceId, setAdminWorkspaceId] = useState("")
  const {
    data: cognitoUser,
    isLoading: isLoadingCognitoUser
  } = useCurrentAuthenticatedUser() as {
    data: ICognitoUser,
    isLoading: boolean
  }

  const { data: platformWorkspaceConfig } = useGetPlatformWorkspaceConfigById(
    platformWorkspaceConfigId,
    {
      enabled: !!platformWorkspaceConfigId
    }
  )

  const { mutateAsync: signInWithToken } = useSignInWithToken()
  const { mutateAsync: signOutAsync } = useSignOut()
  const getAdminPlatformConfigAsync = useGetAdminPlatformConfigAsync()

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      const { token, email } = event.data

      if (!email || !token || isLoadingCognitoUser) {
        return
      }

      try {
        const parsedOrigin = new URL(event.origin).hostname


        PlatformLocalStorage.set(email, parsedOrigin)

        if (!cognitoUser?.attributes?.email || cognitoUser?.attributes?.email !== email) {
          await signOutAsync()
          PlatformLocalStorage.clear()
          await signInWithToken({ email, token })
          PlatformLocalStorage.set(email, parsedOrigin)
        }

        const platformConfig = await getAdminPlatformConfigAsync({
          domain: { eq: parsedOrigin },
          adminEmail: { eq: email }
        })

        if (!platformConfig.id) {
          await signOutAsync()
          PlatformLocalStorage.clear()
          return
        }

        setPlatformWorkspaceConfigId(platformConfig.adminPlatformWorkspaceConfigId)
        setAdminWorkspaceId(platformConfig.adminWorkspaceId)
      } catch (error) {
        console.error("Error handling auth postMessage:", error)
      }
    }

    // Add the event listener
    window.addEventListener("message", handlePostMessage)

    return () => {
      window.removeEventListener("message", handlePostMessage)
    }
  }, [signInWithToken, signOutAsync, cognitoUser, isLoadingCognitoUser])

  useEffect(() => {
    (async () => {
      const { platformUserEmail, platformDomain } = PlatformLocalStorage.get()
      const userEmail = cognitoUser?.attributes?.email

      if (platformUserEmail && userEmail && platformDomain && userEmail === platformUserEmail) {
        const platformConfig = await getAdminPlatformConfigAsync({
          domain: { eq: platformDomain },
          adminEmail: { eq: platformUserEmail }
        })

        setPlatformWorkspaceConfigId(platformConfig?.adminPlatformWorkspaceConfigId)
        setAdminWorkspaceId(platformConfig?.adminWorkspaceId)
      }
    })()
  }, [cognitoUser?.attributes?.email])

  return {
    platformConfig: platformWorkspaceConfig,
    adminWorkspaceId
  }
}

export default useAdminPlatformConfig
