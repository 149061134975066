import { useSendContractToSource } from "modules/Source/src//core/hooks"
import { Workspace } from "modules/Workspace/src//core/types"
import { SelectOption } from "modules/Workspace/src//core/types/global"
import { useMemo } from "react"
import { IContractNormalized } from "../../types"

const useContractJira = ({ workspace, contract }: Props) => {
  const projectOptions = useMemo<SelectOption[]>(
    () =>
      workspace?.jiraProjects?.map(project => ({
        label: project.name,
        value: project.id
      })) || [],
    [workspace?.jiraProjects?.length]
  )

  const {
    mutateAsync: sendContractToSourceAsync,
    isLoading: isLoadingSendToJira
  } = useSendContractToSource()
  const handleSendToJira = async (jiraProjectId: string) => {
    const jiraProject = [...(workspace?.jiraProjects || [])]?.find(
      project => project.id === jiraProjectId
    )

    if (!jiraProject) {
      return
    }

    await sendContractToSourceAsync({
      sourceType: "jira",
      page: "contract",
      options: {
        contractId: contract?.id || "",
        jiraProjectId,
        workspaceId: workspace?.id || ""
      }
    })
  }

  return {
    projectOptions,
    handleSendToJira,
    isLoadingSendToJira
  }
}

export default useContractJira

type Props = {
  workspace: Workspace | null
  contract: IContractNormalized | null
}
