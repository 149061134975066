import { MutationOptions, useMutation, useQueryClient } from "react-query"
import { userUseCase } from "../index"

export const useSignInFacebook = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation("signInFacebook", () => userUseCase.signInFacebook(), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries("currentUser")

      if (options.onSuccess) {
        options.onSuccess(data, variables, context)
      }
    }
  })
}
