import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Gilory';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/assets/fonts/Gilroy-Light.otf') format('opentype');
      }
       @font-face {
        font-family: 'Gilory';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/Gilroy-Regular.ttf') format('truetype');
      }
       @font-face {
        font-family: 'Gilory';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/assets/fonts/Gilroy-Medium.ttf') format('truetype');
      }
       @font-face {
        font-family: 'Gilory';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/assets/fonts/Gilroy-Bold.ttf') format('truetype');
      }
      `}
  />
)

export default Fonts
