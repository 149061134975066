import { Flex, Grid, Heading, Text } from "@chakra-ui/react"
import PointEstimationField from "modules/Contract/src/components/ContractDetails/PointEstimationField"
import { useShareContract } from "modules/Contract/src/core/hooks/useShareContract"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Icon } from "theme/components"

function ShareContract() {
  const { contract, contractLink, handleContractPick } = useShareContract()

  if (!contract) {
    return null
  }

  return (
    <Layout
      contract={contract}
      contractLink={contractLink}
      onContractPick={handleContractPick}
    >
      <Heading as="h3" fontSize="2xl" lineHeight="2xl">
        {contract.name}
      </Heading>

      <Flex align="stretch" direction="column" mt="8">
        <Flex align="center">
          <Icon name="docs" />

          <Heading as="h5" fontSize="md" lineHeight="md" ml="3" mb="-0.5">
            Description
          </Heading>
        </Flex>

        <Text fontSize="sm" lineHeight="sm" ml="8" mt="3">
          {contract.description}
        </Text>
      </Flex>
    </Layout>
  )
}

export default ShareContract

function Layout({ children, contract, contractLink, onContractPick }) {
  return (
    <Grid
      className="h-screen"
      placeItems="center"
      bgImage="/assets/images/background.jpg"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <Flex
        direction="column"
        rounded="12px"
        bgColor="white"
        maxW="800px"
        width="full"
        maxH={{
          base: "full",
          md: "clamp(calc(100% - 128px), 100%, 760px)"
        }}
        height="full"
        overflow="hidden"
      >
        <Header
          pointEstimation={contract.pointEstimation}
          contractLink={contractLink}
          onContractPick={onContractPick}
        />

        <Flex
          direction="column"
          pl={{ base: "4", md: "8" }}
          pr={{ base: "4", md: "16" }}
          pt="2"
          pb="6"
        >
          {children}
        </Flex>
      </Flex>
    </Grid>
  )
}

function Header({ pointEstimation, contractLink, onContractPick }) {
  return (
    <Flex
      height="64px"
      justify="end"
      align="center"
      px={{ base: "4", md: "8" }}
      bgColor="white"
    >
      {/*<Flex align="center">*/}
      {/*  <PointEstimationField value={pointEstimation} isEditable={false} />*/}

      {/*  <Text fontSize="xs" lineHeight="xs" mb="-0.5" ml="3" color="secondary">*/}
      {/*    Story Points*/}
      {/*  </Text>*/}
      {/*</Flex>*/}

      <PickContractButton
        contractLink={contractLink}
        onClick={onContractPick}
      />
    </Flex>
  )
}

function PickContractButton({ contractLink, onClick }) {
  if (contractLink) {
    return (
      <Button as={RouterLink} to={contractLink}>
        Go to contract
      </Button>
    )
  }

  return <Button onClick={onClick}>Pick contract</Button>
}
