import { VStack, useDisclosure } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { SettingsAccordion, SettingsDrawer } from "theme/components"
import {
  CommunityWorkspacesList,
  Discord,
  Email,
  Slack
} from "../CommunitySettings"

function CommunitySettings({
  community,
  discord,
  slack,
  email,
  isOpen,
  onClose
}) {
  const {
    isOpen: isOpenDrawer,
    onOpen,
    onClose: onCloseDrawer
  } = useDisclosure()
  const handleClose = () => {
    onClose && onClose()
    onCloseDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      onOpen()
    } else {
      handleClose()
    }
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpenDrawer}
      onClose={handleClose}
      headerText="Community"
      pb="20"
    >
      <VStack spacing="3" align="start">
        <Discord
          guildOptions={discord.guildOptions}
          isLoadingGuildConnect={discord.isLoadingGuildConnect}
          onDiscordConnect={discord.handleDiscordConnect}
          isDiscordConnected={discord.isDiscordConnected}
          onGuildConnect={discord.handleGuildConnect}
          onAccordionChange={discord.handleAccordionChange}
        />

        <Slack
          onWorkspaceConnect={slack.connectWorkspace}
          onBotConnect={slack.connectBot}
          isSlackConnected={slack.isSlackConnected}
          onAccordionChange={slack.setAccordionState}
          workspaceOptions={slack.workspaceOptions}
          onAddWorkspace={slack.addWorkspace}
        />

        <Email
          onFileUpload={email.handleEmailListUpload}
          isLoadingUpload={email.isLoadingUpload}
          onAdd={email.handleEmailAdd}
          isLoadingAdd={email.isLoadingAdd}
        />

        <SettingsAccordion name="Telegram" iconName="telegram" isDisabled />
      </VStack>

      <CommunityWorkspacesList
        workspaces={community.connectedWorkspaces}
        onWorkspaceEdit={community.handleWorkspaceEdit}
      />
    </SettingsDrawer>
  )
}

export default CommunitySettings
