import MessageAPIRepository from "../repositories/MessageAPIRepository"
import {
  CommentCreateInput, FindByContractPkInput, ListMessagesResponse,
  Message,
  MessageCRUDRepository,
  MessageSubscriptionCallbackFunction,
  MessageSubscriptionResponse,
  MessageType,
  MessageWithAttachments
} from "../types"
import { ModelMessageFilterInput } from "../types/graphql"

class MessageUseCase {
  constructor(
    private readonly messageCRUDRepository: MessageCRUDRepository,
    private readonly messageAPIRepository: MessageAPIRepository
  ) {}

  createMessageSubscription(
    callback: MessageSubscriptionCallbackFunction
  ): MessageSubscriptionResponse {
    return this.messageCRUDRepository.createMessageSubscription(callback)
  }

  async getContractCommunityMessages(
    contractPk: string
  ): Promise<MessageWithAttachments[]> {
    try {
      return await this.messageAPIRepository.getContractAllCommunityMessages(
        contractPk
      )
    } catch (error) {
      console.error("getContractAllCommunityMessages : ", error)
      return []
    }
  }

  async createComment(input: CommentCreateInput): Promise<Message> {
    return this.messageCRUDRepository.createOne({
      ...input,
      type: MessageType.COMMENT
    })
  }

  async getContractComments(input: FindByContractPkInput): Promise<ListMessagesResponse> {
    try {
      return await this.messageCRUDRepository.findByContractPk(input)
    } catch (error) {
      console.error("getAllContractComments : ", error)
      return {
        items: [],
        nextToken: null
      }
    }
  }
}

export default MessageUseCase
