import CDEAPIRepository from "../repositories/CDEAPIRepository"
import { CDEResponseType, CDEType, ICDENormalized } from "../types"
import { normalizeCDE, normalizeCDEUser } from "../utils"

class CDEUseCase {
  constructor(private readonly CDEAPIRepo: CDEAPIRepository) {}
  async getUser(CDEType: CDEType) {
    const response = await this.CDEAPIRepo.getUser(CDEType)
    return normalizeCDEUser(CDEType, response)
  }

  async getAllCodespaces(CDEType: CDEType): Promise<ICDENormalized[]> {
    const response: CDEResponseType[] = await this.CDEAPIRepo.getAllCodespaces(
      CDEType
    )
    return response.map(codespace => normalizeCDE(CDEType, codespace))
  }
}

export default CDEUseCase
