import { useCDESettingsGitHub } from "modules/CDE/src/hooks/useCDESettings/github"
import { IUserNormalized } from "modules/User/src//core/types"
import { Workspace } from "modules/Workspace/src//core/types"
import { useState } from "react"

export const useCDESettings = ({ workspace, user }: Props) => {
  const github = useCDESettingsGitHub({
    workspace,
    user
  })

  // CDE settings drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const toggleDrawer = () => setIsOpenDrawer(prevState => !prevState)
  const openDrawer = () => setIsOpenDrawer(true)
  const closeDrawer = () => setIsOpenDrawer(false)
  const onDrawerClose = () => {
    setIsOpenDrawer(false)
  }

  // CDE settings drawer

  return {
    isOpenDrawer,
    toggleDrawer,
    onDrawerClose,
    openDrawer,
    closeDrawer,
    github
  }
}

type Props = {
  workspace: Workspace | null

  user: IUserNormalized | null
}
