import { IUserNormalized } from "modules/User/src/core/types"
import {
  IWorkspaceSlackWorkspace,
  Workspace
} from "modules/Workspace/src/core/types"
import { useMemo, useState } from "react"
import {
  useConnectCommunityWorkspace,
  useConnectSlack,
  useGetUserCommunityWorkspacesWithBot
} from "../../hooks"

const useSlackSettings = ({ workspace, user }: Props) => {
  const { isSlackConnected, connectWorkspace, connectBot } = useConnectSlack(
    workspace?.id || "",
    user
  )

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const { data: slackWorkspacesWithBot = [] } =
    useGetUserCommunityWorkspacesWithBot(
      { communityType: "slack" },
      {
        enabled: !!user?.slackId && isAccordionOpen
      }
    )

  const { mutateAsync: connectCommunityWorkspaceAsync } =
    useConnectCommunityWorkspace()
  const addWorkspace = async (
    slackWorkspaceId: string,
    { onSuccess }: { onSuccess: () => void }
  ) => {
    const slackWorkspace = slackWorkspacesWithBot.find(
      slackWorkspace => slackWorkspace.id === slackWorkspaceId
    )

    try {
      await connectCommunityWorkspaceAsync({
        communityType: "slack",
        options: {
          slackWorkspace: {
            id: slackWorkspaceId,
            name: slackWorkspace?.name || ""
          },
          workspaceId: workspace!.id
        }
      })

      onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  const connectedSlackWorkspaces = useMemo<IWorkspaceSlackWorkspace[]>(
    () =>
      (workspace?.slackWorkspaces || []).map(slackWorkspace => ({
        id: slackWorkspace.id,
        name: slackWorkspace.name,
        communityType: "slack",
        iconName: "slack"
      })),
    [workspace?.slackWorkspaces?.length]
  )

  const slackWorkspaceOptions = useMemo(() => {
    return slackWorkspacesWithBot
      .filter(slackWorkspace => {
        const isAlreadyConnected = connectedSlackWorkspaces.find(
          connectedSlackWorkspace =>
            connectedSlackWorkspace.id === slackWorkspace.id
        )

        return !isAlreadyConnected
      })
      .map(slackWorkspace => ({
        label: slackWorkspace.name,
        value: slackWorkspace.id
      }))
  }, [slackWorkspacesWithBot.length, connectedSlackWorkspaces.length])

  return {
    isSlackConnected,
    connectWorkspace,
    connectBot,
    setAccordionState: setIsAccordionOpen,
    workspaceOptions: slackWorkspaceOptions,
    addWorkspace,
    connectedSlackWorkspaces
  }
}

export default useSlackSettings

export type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
