import { Box, Grid, Spinner } from "@chakra-ui/react"
import React from "react"

const LoadingWorkspace = () => (
  <Box className="h-screen" p={{ base: 4, md: 6 }}>
    <Grid height="full">
      <Grid
        justifyContent="center"
        alignItems="center"
        bgColor="rgba(255, 255, 255, 0.4)"
        rounded="12px"
        border="1px solid"
        borderColor="bgSmall"
        p="4"
      >
        <Grid rowGap="4">
          <Spinner
            color="secondaryLight"
            speed="0.8s"
            thickness='3px'
            height="64px"
            width="64px"
          />
        </Grid>
      </Grid>
    </Grid>
  </Box>
)

export default LoadingWorkspace
