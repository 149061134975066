export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      pk
      sk
      skIds
      name
      discordId
      slackId
      slackWorkspaces {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const getUsersByPk = /* GraphQL */ `
  query GetUsersByPk(
    $pk: String!
    $sk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPk(
      pk: $pk
      sk: $sk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        skIds
        name
        discordId
        slackId
        slackWorkspaces {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

