import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { ENV, GA_MEASUREMENT_ID } from "./config"
import ReactGA from "react-ga4"


const root = ReactDOM.createRoot(document.getElementById("root"))

// TODO: all function executions inside functions new line
// add eslint rule
root.render(<App />)

if (ENV === "production") {
  ReactGA.initialize(GA_MEASUREMENT_ID, {
    testMode: true
  })
}
