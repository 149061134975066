import { Box, Flex, Heading, VStack } from "@chakra-ui/react"
import LayoutVariant from "modules/Workspace/src/components/layouts/LayoutVariant"
import React from "react"
import { IoArrowBackOutline } from "react-icons/io5"
import { Link as RouterLink } from "react-router-dom"
import { IconButton, SettingsAccordion } from "theme/components"
import {
  CommunityWorkspacesList,
  Discord,
  Email,
  Slack
} from "../CommunitySettings"

const SettingsOptionsPage = ({
  community,
  discord,
  slack,
  email,
  layoutVariant
}) => (
  <Layout layoutVariant={layoutVariant}>
    <VStack spacing="3" align="start">
      <Discord
        guildOptions={discord.guildOptions}
        isLoadingGuildConnect={discord.isLoadingGuildConnect}
        onDiscordConnect={discord.handleDiscordConnect}
        isDiscordConnected={discord.isDiscordConnected}
        onGuildConnect={discord.handleGuildConnect}
        onAccordionChange={discord.handleAccordionChange}
      />

      <Slack
        onWorkspaceConnect={slack.connectWorkspace}
        onBotConnect={slack.connectBot}
        isSlackConnected={slack.isSlackConnected}
        onAccordionChange={slack.setAccordionState}
        workspaceOptions={slack.workspaceOptions}
        onAddWorkspace={slack.addWorkspace}
      />

      <Email
        onFileUpload={email.handleEmailListUpload}
        isLoadingUpload={email.isLoadingUpload}
        onAdd={email.handleEmailAdd}
        isLoadingAdd={email.isLoadingAdd}
      />

      <DisabledCommunities />
    </VStack>

    <CommunityWorkspacesList
      workspaces={community.connectedWorkspaces}
      onWorkspaceEdit={community.handleWorkspaceEdit}
    />
  </Layout>
)

export default SettingsOptionsPage

const Layout = ({ children, layoutVariant }) => (
  <LayoutVariant
    bgImage="/assets/images/background.jpg"
    variant={layoutVariant}
  >
    <Header />

    <Box pt="3" px="4" pb="8" overflowY="auto">
      <VStack height="full" align="stretch" spacing={5}>
        {children}
      </VStack>
    </Box>
  </LayoutVariant>
)

function Header() {
  return (
    <Flex width="full" align="center" height="40px" px="4" pb="2">
      <IconButton
        icon={<IoArrowBackOutline />}
        aria-label="arrow-back"
        as={RouterLink}
        to="./"
        relative="path"
      />

      <Heading
        as="h3"
        fontSize="xl"
        lineheight="xl"
        fontWeight="semibold"
        display="flex"
        align="center"
        ml="3"
        mb="-1"
      >
        Community
      </Heading>
    </Flex>
  )
}

const DisabledCommunities = () => (
  <SettingsAccordion name="Telegram" iconName="telegram" isDisabled />
)
