import { Storage } from "aws-amplify"

class FileService {
  async uploadFile(file: File, fileName: string): Promise<string> {
    const { key } = await Storage.put(fileName, file, {
      contentType: file.type
    })
    return process.env.REACT_APP_S3_BUCKET_URL + "/public/" + key
  }

  async deleteFile(fileName: string): Promise<void> {
    await Storage.remove(fileName)
  }
}

export default FileService
