import { UpdateWorkspaceOptionsWorkspaceTeam } from "modules/Community/src//core/types"
import {
  useDisconnectCommunityWorkspace,
  useGetCommunityWorkspaceTeams,
  useUpdateCommunityWorkspace
} from "modules/Community/src/core/hooks"
import { WorkspaceTeamEditProps } from "modules/Workspace/src//core/hooks/useWorkspaceTeams/types"
import {
  WorkspaceTeam,
  WorkspaceTeamOption,
  WorkspaceTeamOptionsGroup
} from "modules/Workspace/src//core/types/workspace-team"
import {
  DEFAULT_EMOJI_BG_COLOR,
  DEFAULT_TEAM_EMOJI,
  WORKSPACE_TEAM_GROUPS
} from "modules/Workspace/src/core/config"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Workspace } from "../../types"

export const useWorkspaceTeamsDiscord = ({
  workspace,
  workspaceTeams = []
}: Props) => {
  // community
  const { mutateAsync: updateCommunityWorkspaceAsync } =
    useUpdateCommunityWorkspace()
  const { mutateAsync: disconnectCommunityWorkspaceAsync } =
    useDisconnectCommunityWorkspace()

  // community

  // team edit
  const [activeEditGuildId, setActiveEditGuildId] = useState<string | null>(
    null
  )
  const { data: editGuildTeams = [] } = useGetCommunityWorkspaceTeams(
    {
      communityType: "discord",
      options: {
        guildId: activeEditGuildId || ""
      }
    },
    {
      enabled: !!activeEditGuildId,
      keepPreviousData: false
    }
  )

  const [editGuildTeamOptions, setEditGuildTeamOptions] = useState<
    WorkspaceTeamOption[]
  >([])

  useEffect(() => {
    if (!activeEditGuildId || !editGuildTeams.length) {
      setEditGuildTeamOptions([])
      return
    }

    const connectedGuildTeams = workspaceTeams?.filter(
      team => team.communityWorkspaceId === activeEditGuildId
    )

    const editGuildTeamOptions = editGuildTeams.map(team => {
      const connectedTeam = connectedGuildTeams.find(
        connectedTeam => connectedTeam.teamId === team.id
      )

      return {
        id: team.id,
        name: team.name,
        emoji: connectedTeam?.emoji || workspace?.defaultTeamEmoji?.emoji || DEFAULT_TEAM_EMOJI,
        emojiBgColor: connectedTeam?.emojiBgColor || workspace?.defaultTeamEmoji?.backgroundColor || DEFAULT_EMOJI_BG_COLOR,
        type: team.type,
        isSelected: !!connectedTeam
      }
    }) as WorkspaceTeamOption[]

    setEditGuildTeamOptions(editGuildTeamOptions)
  }, [activeEditGuildId, editGuildTeams.length, workspaceTeams?.length])

  const teamOptionsGroups = useMemo<WorkspaceTeamOptionsGroup[]>(
    () =>
      WORKSPACE_TEAM_GROUPS.map(group => {
        const teamOptions =
          editGuildTeamOptions?.filter(team => team.type === group.type) || []

        return {
          ...group,
          teamOptions
        }
      }),
    [JSON.stringify(editGuildTeamOptions)]
  )

  const handleGuildDisconnect = async (guildId: string) => {
    await disconnectCommunityWorkspaceAsync({
      communityType: "discord",
      options: {
        guildId,
        workspaceId: workspace?.id || ""
      }
    })
  }

  const handleGuildUpdate = async (guildId: string) => {
    const selectedEditGuildTeamOptions = editGuildTeamOptions.filter(
      team => team.isSelected
    )

    const updatedGuildTeams: UpdateWorkspaceOptionsWorkspaceTeam[] =
      selectedEditGuildTeamOptions.map(team => {
        const teamInWorkspaceTeams = workspaceTeams?.find(
          workspaceTeam => workspaceTeam.teamId === team.id
        )

        const updatedWorkspaceTeam: UpdateWorkspaceOptionsWorkspaceTeam = {
          teamId: team.id,
          emoji: team.emoji,
          emojiBgColor: team.emojiBgColor,
          type: team.type,
          name: team.name
        }

        if (teamInWorkspaceTeams) {
          updatedWorkspaceTeam.id = teamInWorkspaceTeams.id
        }

        return updatedWorkspaceTeam
      })

    await updateCommunityWorkspaceAsync({
      communityType: "discord",
      options: {
        workspaceId: workspace?.id || "",
        workspaceTeams: updatedGuildTeams,
        guildId
      }
    })
  }

  const handleEditTeamOptionChange = (newTeam: WorkspaceTeamOption) => {
    const newEditGuildTeamOptions = editGuildTeamOptions.map(team => {
      if (team.id === newTeam.id) {
        return {
          ...team,
          ...newTeam
        }
      }

      return team
    })

    setEditGuildTeamOptions(newEditGuildTeamOptions)
  }

  const getTeamEditProps = useCallback(
    (guildId: string): WorkspaceTeamEditProps => {
      setActiveEditGuildId(guildId)
      const workspaceDiscordGuild = workspace?.discordGuilds?.find(
        guild => guild.id === activeEditGuildId
      )
      const communityWorkspace: WorkspaceTeamEditProps["communityWorkspace"] = {
        id: workspaceDiscordGuild?.id || "",
        name: workspaceDiscordGuild?.name || "",
        communityType: "discord",
        iconName: "discord"
      }

      return {
        communityWorkspace,
        teamOptionsGroups,
        onTeamOptionChange: handleEditTeamOptionChange,
        onDisconnect: handleGuildDisconnect,
        onUpdate: handleGuildUpdate
      }
    },
    [
      activeEditGuildId,
      JSON.stringify(workspace?.discordGuilds),
      JSON.stringify(teamOptionsGroups)
    ]
  )
  const resetEditForm = () => {
    setActiveEditGuildId(null)
    setEditGuildTeamOptions([])
  }

  // team edit

  return {
    getTeamEditProps,
    handleGuildUpdate,
    resetEditForm
  }
}

type Props = {
  workspace: Workspace | null
  workspaceTeams: WorkspaceTeam[]
}
