import { Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Button, Select, SettingsAccordion } from "theme/components"

export function Discord({
  discordGuildOptions,
  discordGuildChannelOptions,
  onGuildChannelConnect,
  isLoadingDiscordChannelConnect,
  onDiscordGuildChange,
  isDiscordConnected,
  onDiscordConnect,
  onAccordionChange
}) {
  const handleGuildChannelConnect = (discordGuild, discordChannel) => {
    onGuildChannelConnect(discordChannel.id)
  }

  const handleAccordionChange = index => {
    const isOpen = index === 0
    onAccordionChange && onAccordionChange(isOpen)
  }

  return (
    <SettingsAccordion
      iconName="discord"
      name="Discord"
      onChange={handleAccordionChange}
    >
      <DiscordActionRouter
        action={isDiscordConnected ? "add-channel" : "connect"}
        discordGuildOptions={discordGuildOptions}
        discordGuildChannelOptions={discordGuildChannelOptions}
        onGuildChannelConnect={handleGuildChannelConnect}
        onDiscordGuildChange={onDiscordGuildChange}
        isLoadingDiscordChannelConnect={isLoadingDiscordChannelConnect}
        onConnect={onDiscordConnect}
      />
    </SettingsAccordion>
  )
}

function DiscordActionRouter({
  action,
  discordGuildOptions,
  discordGuildChannelOptions,
  onDiscordGuildChange,
  isLoadingDiscordChannelConnect = false,
  onGuildChannelConnect,
  onConnect
}) {
  if (action === "add-channel") {
    return (
      <AddDiscordChannelAction
        discordGuildOptions={discordGuildOptions}
        discordGuildChannelOptions={discordGuildChannelOptions}
        onDiscordGuildChange={onDiscordGuildChange}
        isLoadingDiscordChannelConnect={isLoadingDiscordChannelConnect}
        onGuildChannelConnect={onGuildChannelConnect}
      />
    )
  } else if (action === "connect") {
    return <ConnectDiscordAction onConnect={onConnect} />
  }
}

function AddDiscordChannelAction({
  discordGuildOptions,
  discordGuildChannelOptions,
  onDiscordGuildChange,
  isLoadingDiscordChannelConnect = false,
  onGuildChannelConnect
}) {
  return (
    <AddDiscordChannelForm
      discordGuildOptions={discordGuildOptions}
      discordGuildChannelOptions={discordGuildChannelOptions}
      onDiscordGuildChange={onDiscordGuildChange}
      onGuildChannelConnect={onGuildChannelConnect}
      isLoadingDiscordChannelConnect={isLoadingDiscordChannelConnect}
    />
  )
}

function ConnectDiscordAction({ onConnect }) {
  return (
    <>
      <Text fontWeight="medium">Connect your Discord account</Text>

      <Button colorScheme="purple" mt="2" onClick={onConnect}>
        Connect Discord
      </Button>
    </>
  )
}

function AddDiscordChannelForm({
  discordGuildOptions,
  discordGuildChannelOptions,
  onDiscordGuildChange,
  onGuildChannelConnect,
  isLoadingDiscordChannelConnect = false
}) {
  const [guildId, setGuildId] = useState("")
  const [channelId, setChannelId] = useState("")
  const handleGuildChannelConnect = () => {
    onGuildChannelConnect(
      {
        name: discordGuildOptions.find(guild => guild.value === guildId).label,
        id: guildId
      },
      {
        name: discordGuildChannelOptions.find(
          channel => channel.value === channelId
        ).label,
        id: channelId
      }
    )
  }
  const handleDiscordGuildChange = event => {
    const value = event.target.value
    onDiscordGuildChange(value)
    setGuildId(value)
  }

  useEffect(() => {
    if (discordGuildChannelOptions.length > 0) {
      setChannelId(discordGuildChannelOptions[0].value)
    }
  }, [discordGuildChannelOptions.length])

  useEffect(() => {
    onDiscordGuildChange("")
    setChannelId("")
  }, [])

  return (
    <VStack
      align="start"
      w="full"
      spacing="4"
      pb="8"
      borderBottom="1px solid"
      borderColor="line"
    >
      <Select
        placeholder="Discord guilds"
        w="full"
        onChange={handleDiscordGuildChange}
        value={guildId}
        options={discordGuildOptions}
      />

      <Select
        placeholder="Discord guild channels"
        w="full"
        onChange={e => setChannelId(e.target.value)}
        value={channelId}
        options={discordGuildChannelOptions}
      />

      <Button
        variant="primaryPurple"
        onClick={handleGuildChannelConnect}
        isDisabled={!channelId || isLoadingDiscordChannelConnect}
        loadingText="Adding channel..."
        isLoading={isLoadingDiscordChannelConnect}
        alignSelf="flex-end"
      >
        Add channel
      </Button>
    </VStack>
  )
}
