import { useQueryParams } from "hooks"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { WorkspaceActiveTab } from "../../types/global"
import { useGetWorkspace, useGetWorkspaceTeams } from "../index"
import { useWorkspaceSettings } from "../index"

export const useWorkspaceActions = () => {
  const location = useLocation()
  const { queryParams } = useQueryParams()
  const workspaceId = location.pathname.split("/")[2] || ""

  const { data: workspace = null } = useGetWorkspace(workspaceId, {
    enabled: !!workspaceId
  })

  const { data: getWorkspaceTeamsResponse } = useGetWorkspaceTeams(
    { input: { workspaceId } },
    {
      enabled: !!workspaceId
    }
  )

  const activeTab = useMemo<WorkspaceActiveTab>(
    () => queryParams.tab as WorkspaceActiveTab,
    [queryParams.tab]
  )

  const {
    workspaceDeleteProps,
    openWorkspaceDeleteModal,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  } =
    useWorkspaceSettings({
      workspaceId: workspaceId,
      activeTab
    })

  return {
    workspace,
    workspaceTeams: getWorkspaceTeamsResponse?.items || [],
    activeTab,
    workspaceDeleteProps,
    openWorkspaceDeleteModal,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  }
}
