import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { contractUseCase } from "../index"
import { IContractNormalized } from "../types"

export const useGetChildContracts = (contractPk: string, options: Options = {}) =>
  useQuery<IContractNormalized[], Error>(
    ["childContracts", contractPk],
    () => contractUseCase.getChildContracts(contractPk),
    options
  )

type Options = Omit<
  UseQueryOptions<
    IContractNormalized[],
    Error,
    IContractNormalized[],
    QueryKey
  >,
  "queryKey" | "queryFn"
>
