import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import contractResourceUseCase from "../core"
import { ContractResource } from "../core/types/contract-resource-model-types"

export const useGetContractResources = (
  contractPk: string,
  options: Options = {}
) =>
  useQuery<ContractResource[], Error>(
    ["contractResources", contractPk],
    () => contractResourceUseCase.getContractResources(contractPk),
    options
  )

export type Options = Omit<
  UseQueryOptions<ContractResource[], Error, ContractResource[], QueryKey>,
  "queryKey" | "queryFn"
>
