import { CommunityWorkspaceNormalized } from "modules/Community/src//core/types"
import { ConnectedCommunityWorkspace } from "modules/Community/src//core/types/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { useMemo, useState } from "react"
import {
  useConnectCommunityWorkspace,
  useDiscordConnect,
  useGetUserCommunityWorkspacesWithBot
} from "../../hooks"

const useDiscordSettings = ({ workspace, user }: Props) => {
  // community
  const { isDiscordConnected, handleDiscordConnect } = useDiscordConnect(
    workspace?.id || "",
    user
  )
  const { mutateAsync: connectCommunityWorkspaceAsync } =
    useConnectCommunityWorkspace()

  // community

  // guilds
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const handleAccordionChange = (isOpen: boolean) => setIsSettingsOpen(isOpen)

  const { data: userDiscordGuildsWithBot = [] } =
    useGetUserCommunityWorkspacesWithBot(
      {
        communityType: "discord"
      },
      {
        enabled: !!user?.discordId && isSettingsOpen
      }
    )

  const guildOptions = useMemo(() => {
    const connectedGuildIds =
      (workspace?.discordGuilds?.map(guild => guild.id) as string[]) || []
    return (
      userDiscordGuildsWithBot
        ?.filter(guild => !connectedGuildIds.includes(guild.id))
        .map(guild => ({
          label: guild.name,
          value: guild.id
        })) || []
    )
  }, [workspace?.discordGuilds?.length, userDiscordGuildsWithBot.length])

  const connectedGuilds = useMemo<ConnectedCommunityWorkspace[]>(
    () =>
      (workspace?.discordGuilds || []).map(guild => ({
        id: guild.id,
        name: guild.name,
        communityType: "discord",
        iconName: "discord"
      })),
    [workspace?.discordGuilds?.length]
  )

  const handleGuildConnect = async (guildId: string) => {
    const guild = userDiscordGuildsWithBot.find(
      guild => guild.id === guildId
    ) as CommunityWorkspaceNormalized

    await connectCommunityWorkspaceAsync({
      communityType: "discord",
      options: {
        guild,
        workspaceId: workspace!.id
      }
    })
  }

  // guilds

  return {
    handleGuildConnect,
    guildOptions,
    connectedGuilds,
    isDiscordConnected,
    handleAccordionChange,
    handleDiscordConnect
  }
}

export default useDiscordSettings

export type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
