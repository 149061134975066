import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { workspaceTeamUseCase } from "../index"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType,
  WorkspaceTeamUpdateInput
} from "../types/workspace-team"
import { GetWorkspaceTeamsByWorkspaceIdResponse } from "../types"

const useUpdateWorkspaceTeams = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "updateWorkspaceTeams",
    ({ updateInputs }: FnInput) =>
      workspaceTeamUseCase.updateWorkspaceTeams(updateInputs),
    {
      onSettled: (data, _error, { workspaceId, updateInputs }) => {
        const updatedWorkspaceTeams = (data as WorkspaceTeam[]) || []

        queryClient.setQueryData<GetWorkspaceTeamsByWorkspaceIdResponse>(
          ["workspaceTeams", workspaceId],
          (workspaceTeams) => {
            if (!workspaceTeams) {
              return { items: [], nextToken: null }
            }

            return {
              items: updateWorkspaceTeams(workspaceTeams.items || [], updatedWorkspaceTeams),
              nextToken: workspaceTeams.nextToken
            }
          })

        if (
          updateInputs[0].communityType === WorkspaceTeamCommunityType.EMAIL
        ) {
          queryClient.setQueryData<GetWorkspaceTeamsByWorkspaceIdResponse>(
            [
              "workspaceTeams",
              workspaceId,
              WorkspaceTeamCommunityType.EMAIL
            ],
            workspaceTeams => {
              if (!workspaceTeams) {
                return {
                  items: updatedWorkspaceTeams,
                  nextToken: null
                }
              }

              return {
                items: updateWorkspaceTeams(workspaceTeams.items || [], updatedWorkspaceTeams),
                nextToken: workspaceTeams.nextToken
              }
            }
          )
        }
      },
      ...options
    }
  )
}

export default useUpdateWorkspaceTeams

const updateWorkspaceTeams = (
  workspaceTeams: WorkspaceTeam[],
  updatedWorkspaceTeams: WorkspaceTeam[]
) =>
  workspaceTeams.map(workspaceTeam => {
    const updatedWorkspaceTeam = updatedWorkspaceTeams.find(
      updatedWorkspaceTeam => updatedWorkspaceTeam.id === workspaceTeam.id
    )

    if (updatedWorkspaceTeam) {
      return updatedWorkspaceTeam
    }

    return workspaceTeam
  })

export type FnInput = {
  workspaceId: string
  updateInputs: WorkspaceTeamUpdateInput[]
}

export type Options = Omit<
  UseMutationOptions<unknown, Error, FnInput>,
  "mutationFn"
>
