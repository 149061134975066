import { API, graphqlOperation } from "aws-amplify"
import { Observable } from "zen-observable-ts"
import { createMessage } from "../graphql/mutations"
import {
  getMessage as getMessageQuery,
  getMessagesByContractPk as getMessagesByContractPkQuery,
  listMessages as listMessagesQuery
} from "../graphql/queries"
import { onCreateMessage } from "../graphql/subscriptions"
import {
  FindByContractPkInput,
  MessageAppSyncService as IMessageAppSyncService,
  Message,
  MessageCreateInput,
  MessageSubscriptionCallbackFunction,
  MessageSubscriptionResponse, ListMessagesResponse
} from "../types"
import { ModelMessageFilterInput } from "../types/graphql"

class MessageAppSyncService implements IMessageAppSyncService {
  async findById(id: string): Promise<Message> {
    const res = await (API.graphql(
      graphqlOperation(getMessageQuery, { id })
    ) as Promise<any>)
    return res.data.getMessage
  }

  async findByContractPk({
    contractPk,
    filter = {},
    sortDirection = "DESC"
  }: FindByContractPkInput): Promise<ListMessagesResponse> {
    const res = await (API.graphql(
      graphqlOperation(getMessagesByContractPkQuery, {
        contractPk,
        filter,
        sortDirection
      })
    ) as Promise<any>)
    return {
      items: res.data.getMessagesByContractPk.items || [],
      nextToken: res.data.getMessagesByContractPk.nextToken
    }
  }

  async findAll(filter: ModelMessageFilterInput = {}): Promise<Message[]> {
    const res = await (API.graphql(
      graphqlOperation(listMessagesQuery, { filter })
    ) as Promise<any>)
    return res.data.listMessages.items
  }

  async createOne(input: MessageCreateInput): Promise<Message> {
    const res = await (API.graphql(
      graphqlOperation(createMessage, { input })
    ) as Promise<any>)
    return res.data.createMessage
  }

  createMessageSubscription(
    callback: MessageSubscriptionCallbackFunction
  ): MessageSubscriptionResponse {
    const subscription = API.graphql(
      graphqlOperation(onCreateMessage)
    ) as Observable<any>
    const subscriptionObserver = subscription.subscribe({
      next: (eventData: any) => {
        const message = eventData.value.data.onCreateMessage
        callback(message)
      }
    })

    return () => subscriptionObserver.unsubscribe()
  }
}

export default MessageAppSyncService
