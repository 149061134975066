import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType
} from "modules/Workspace/src//core/types/workspace-team"

export type cognitoIdType = string
export type emptyObjectType = Record<string, never>

export type contractSkIdsType = cognitoIdType | "jira" | "discord"

export type IContractSkIdsType = {
  [key in contractSkIdsType]: string
}

export type EmptyObject = Record<string, never>

export interface IContract {
  id: string
  pk: string
  sk: string
  skIds: string | null

  // user#<userPk>
  teams: ContractWorkspaceTeam[] | null
  predefinedTeamIds: string[] | null

  ownerId: string
  reporterId: string
  name: string
  description: string | null
  sourceType: ContractSourceType
  sourceContractPk: string | null
  pointEstimation: number | null
  workspaceId: string | null
  completed: boolean | null
  deletedAt: string | null
  jiraIssueId: string | null
  jiraProjects: ContractJiraProject[] | null
  githubCodespaces: ContractGitHubCodespace[] | null

  // settings
  allMessagesSync: boolean | null
  isSubscribed: boolean | null

  createdAt: string
  updatedAt: string
}

export interface ContractThread {
  id: string
  link: string
  workspaceTeamId: string
  communityWorkspaceId: string
  communityChannelId: string
  communityType: WorkspaceTeamCommunityType
}

export interface ContractWorkspaceTeam extends WorkspaceTeam {
  isEditable?: boolean
  isPrivate?: boolean
}

export interface IContractNormalized extends Omit<IContract, "skIds"> {
  skIds: IContractSkIdsType | emptyObjectType
}

export interface IContractNormalizedWithLoading extends IContractNormalized {
  isLoading?: boolean
}

export interface ContractJiraProject {
  id: string
  name: string
  link: string
}

export interface ContractGitHubCodespace {
  id: string
  name: string
  link: string
}

export enum ContractSourceType {
  JIRA = "JIRA",
  DISCORD = "DISCORD",
  REPORTER = "REPORTER",
  COSMO_WORKSPACE = "COSMO_WORKSPACE",
  COSMO_HOMEPAGE = "COSMO_HOMEPAGE",
  CONTRACT="CONTRACT"
}

export interface IContractCreateInput
  extends Omit<Partial<IContractNormalized>, "id"> {
  pk: string
  sk: string
}

export type contractUpdateInputType = Partial<IContract>

export type ContractCreateInputNormalized = Partial<IContractNormalized> & {
  pk?: string
  sk?: string
  workspaceId?: string
  sourceType: ContractSourceType
  sourceContractPk?: string
  ownerId?: string
  name: string
}

export type contractUpdateInputNormalizedType = Partial<IContractNormalized>

export type contractSubscriptionCallbackFunctionType = (
  contract: IContract
) => void
export type contractSubscriptionResponseType = () => void

export interface IListContractsResponse {
  contracts: IContract[]
  nextToken: string | null
}

export interface IListContractsNormalizedResponse {
  contracts: IContractNormalized[]
  nextToken: string | null
}

export type GetContractsByPkResponse = {
  items: IContract[]
  nextToken: string | null
}

export type GetContractResponse = {
  items: IContract[]
  nextToken: string | null
}

export type DelegateInput = {
  workspaceTeams: {
    id: string
    isPrivate: boolean
  }[]
}
