import { QueryKey, UseQueryOptions, useQuery } from "react-query"
import { messageUseCase } from "../index"
import { MessageWithAttachments } from "../types"

export const useGetContractCommunityMessages = (
  contractPk: string,
  options: Options = {}
) =>
  useQuery<MessageWithAttachments[], Error>(
    ["contractCommunityMessages", contractPk],
    () => messageUseCase.getContractCommunityMessages(contractPk),
    options
  )

export type Options = Omit<
  UseQueryOptions<
    MessageWithAttachments[],
    Error,
    MessageWithAttachments[],
    QueryKey
  >,
  "queryKey" | "queryFn"
>
