import { createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

type PreviousPathContextType = {
  previousPath: string | null
}

export const PreviousPathContext = createContext<PreviousPathContextType>({
  previousPath: null
})

function PreviousPathProvider({ children }: { children: React.ReactNode }) {
  const [previousPath, setPreviousPath] = useState<string | null>(null)
  const [currentPath, setCurrentPath] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    const fullPath = location.pathname + location.search

    if (fullPath !== currentPath) {
      setPreviousPath(currentPath)
      setCurrentPath(fullPath)
    }
  }, [JSON.stringify(location)])

  return (
    <PreviousPathContext.Provider value={{ previousPath }}>
      {children}
    </PreviousPathContext.Provider>
  )
}

export default PreviousPathProvider

export const usePreviousPath = (): string | null => {
  const context = useContext(PreviousPathContext)

  if (!context) {
    throw new Error(
      "usePreviousPath must be used within a PreviousPathProvider"
    )
  }

  return context.previousPath
}
