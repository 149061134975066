import { Flex, Heading } from "@chakra-ui/react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button } from "theme/components"

function Header({ workspace }) {
  return (
    <Flex align="center" justify="space-between" px="4" height="32px">
      <Heading
        as="h3"
        fontSize="2xl"
        lineheight="2xl"
        fontWeight="bold"
        noOfLines="1"
      >
        {workspace.name}
      </Heading>

      {workspace.contractId ? (
        <WorkspaceContractDetailsButton
          workspaceContractId={workspace.contractId}
        />
      ) : null}
    </Flex>
  )
}

export default Header

function WorkspaceContractDetailsButton({ workspaceContractId }) {
  return (
    <Button
      as={RouterLink}
      to={`/contract/${workspaceContractId}`}
      size="md"
      ml="4"
      flexShrink="0"
    >
      Details
    </Button>
  )
}
