import {
  DelegateInput,
  IContractNormalized,
  IContractNormalizedWithLoading
} from "modules/Contract/src//core/types"
import { UseMutationOptions, useMutation, useQueryClient } from "react-query"
import { contractUseCase } from "../index"
import { mergeContracts } from "../utils"

const useDelegateContract = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    "delegateContract",
    ({ contractId, input }: FnInput) =>
      contractUseCase.delegate(contractId, input),
    {
      onSettled: (data, _error, variables) => {
        if (!data?.id) {
          return
        }

        const updatedContract = data as IContractNormalized

        queryClient.setQueryData<IContractNormalized>(
          ["mappedContract", variables.contractId],
          oldContract =>
            mergeContracts(
              oldContract as IContractNormalized,
              data as IContractNormalized,
              ["name", "description", "pointEstimation"]
            )
        )

        if (variables.page === "contract") {
          return
        }

        const queryKey = variables.workspaceId
          ? ["workspaceContracts", variables.workspaceId]
          : "homepageContracts"

        queryClient.setQueryData<IContractNormalizedWithLoading[]>(
          queryKey,
          oldContracts => {
            const contracts = [
              ...(oldContracts || [])
            ] as IContractNormalizedWithLoading[]
            const contractIndex = contracts.findIndex(
              contract => contract.pk === updatedContract.pk
            )
            const oldContract = contracts[contractIndex]

            contracts[contractIndex] = mergeContracts(
              oldContract,
              updatedContract,
              ["name", "description", "pointEstimation"]
            )

            return contracts
          }
        )
      },
      ...options
    }
  )
}

export { useDelegateContract }

export type FnInput = {
  contractId: string
  page?: "workspace" | "contract"
  workspaceId?: string
  input: DelegateInput
}

export type Options = Omit<
  UseMutationOptions<IContractNormalized | null, Error, FnInput>,
  "mutationFn"
>
