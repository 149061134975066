type Procedure = (...args: any[]) => void

const debounce = (func: Procedure, timeout = 300): Procedure => {
  let timer: NodeJS.Timeout | null = null
  return (...args: any[]) => {
    clearTimeout(timer as NodeJS.Timeout)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export default debounce
