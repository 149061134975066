import {
  useDiscordConnect,
  useGetUserCommunityWorkspacesWithBot
} from "modules/Community/src/core/hooks"
import {
  useConnectSourceProject,
  useDisconnectSourceProject,
  useGetSourceWorkspaceProjects
} from "modules/Source/src/core/hooks"
import {
  ConnectedSourceProject,
  SourceProjectEditProps
} from "modules/Source/src/core/types/hooks"
import { IUserNormalized } from "modules/User/src/core/types"
import { Workspace } from "modules/Workspace/src/core/types"
import { useCallback, useMemo, useState } from "react"

const useSourceSettingsDiscord = ({ workspace, user }: Props) => {
  const { isDiscordConnected, handleDiscordConnect } = useDiscordConnect(
    workspace?.id || "",
    user
  )

  const [isLoadingDiscordChannelConnect, setIsLoadingDiscordChannelConnect] =
    useState(false)
  const { mutateAsync: connectSourceProjectAsync } = useConnectSourceProject()
  const { mutateAsync: disconnectSourceProjectAsync } =
    useDisconnectSourceProject()
  const [activeDiscordGuildId, setActiveDiscordGuildId] = useState("")

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const handleAccordionChange = (isOpen: boolean) => setIsSettingsOpen(isOpen)

  const { data: userDiscordGuildsWithBot = [] } =
    useGetUserCommunityWorkspacesWithBot(
      {
        communityType: "discord"
      },
      {
        enabled: !!user?.discordId && isSettingsOpen
      }
    )
  const { data: discordGuildChannels = [] } = useGetSourceWorkspaceProjects(
    {
      type: "discord",
      options: {
        guildId: activeDiscordGuildId
      }
    },
    {
      enabled: !!activeDiscordGuildId
    }
  )

  const discordGuildOptions = useMemo(
    () =>
      userDiscordGuildsWithBot?.map(guild => ({
        value: guild.id,
        label: guild.name
      })),
    [userDiscordGuildsWithBot.length]
  )
  const discordGuildChannelOptions = useMemo(() => {
    if (!activeDiscordGuildId) {
      return []
    }
    return discordGuildChannels
      .filter(
        channel =>
          !workspace?.discordChannels?.find(
            workspaceChannel => workspaceChannel.id === channel.id
          )
      )
      .map(channel => ({
        value: channel.id,
        label: channel.name
      }))
  }, [
    activeDiscordGuildId,
    JSON.stringify(discordGuildChannels),
    JSON.stringify(workspace?.discordChannels)
  ])

  const connectedChannels = useMemo<ConnectedSourceProject[]>(
    () =>
      workspace?.discordChannels?.map(channel => ({
        id: channel.id,
        name: channel.name,
        sourceType: "discord",
        iconName: "discord"
      })) || [],
    [JSON.stringify(workspace?.discordChannels)]
  )

  const handleDiscordGuildChange = (guildId: string) =>
    setActiveDiscordGuildId(guildId)

  const handleGuildChannelConnect = async (channelId: string) => {
    setIsLoadingDiscordChannelConnect(true)

    const discordChannel = discordGuildChannels.find(
      channel => channel.id === channelId
    )
    const discordGuild = userDiscordGuildsWithBot.find(
      guild => guild.id === activeDiscordGuildId
    )

    if (!discordChannel || !discordGuild || !workspace?.id) {
      return
    }

    await connectSourceProjectAsync({
      sourceType: "discord",
      options: {
        workspaceId: workspace.id,
        discordChannel: {
          id: discordChannel.id,
          name: discordChannel.name,
          guildId: discordGuild.id,
          guildName: discordGuild.name
        }
      }
    })
    setIsLoadingDiscordChannelConnect(false)
  }

  // discord channel edit

  const handleChannelDisconnect = async (channelId: string) => {
    try {
      await disconnectSourceProjectAsync({
        sourceType: "discord",
        options: {
          workspaceId: workspace?.id as string,
          discordChannelId: channelId
        }
      })
    } catch (error) {
      console.error("handleChannelDisconnect : ", error)
    }
  }

  const getChannelEditProps = useCallback(
    (channelId: string): SourceProjectEditProps => {
      const channel = workspace?.discordChannels?.find(
        channel => channel.id === channelId
      )

      return {
        sourceType: "discord",
        project: channel!,
        onDisconnect: handleChannelDisconnect
      }
    },
    [JSON.stringify(workspace?.discordChannels)]
  )

  // discord channel edit

  return {
    discordGuildOptions,
    discordGuildChannelOptions,
    connectedChannels,
    handleDiscordGuildChange,
    handleGuildChannelConnect,
    handleChannelDisconnect,
    isLoadingDiscordChannelConnect,
    isDiscordConnected,
    handleDiscordConnect,
    handleAccordionChange,
    getChannelEditProps
  }
}

export { useSourceSettingsDiscord }

type Props = {
  workspace: Workspace | null
  user: IUserNormalized | null
}
